@import '/src/stylesheets/utils';

.container {
  .lineDiv {
    height: rem(1px);
    background-color: var(--ir-warm-gray-030);
    margin: rem(10px) 0 0;
  }

  .heading {
    display: flex;
    width: 100%;
    color: var(--ir-neutral-010);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px);
    justify-content: space-between;
  }

  [class*='accordion-button']:not(.collapsed) {
    background-color: var(--ir-gray-000);
    border-color: var(--ir-gray-000);
    box-shadow: none;
    background-size: 0;
  }

  [class*='accordion-button']:not(.collapsed)::after {
    display: flex;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(360deg);
  }

  [class*='accordion-button'][aria-expanded='false']::after {
    transform: rotate(180deg);
  }

  [class*='accordion-button'],
  [class*='collapsed'] {
    display: flex;
    gap: rem(8px);
    flex-direction: row-reverse;
    align-items: inherit;
    padding-bottom: 0;
  }

  [class*='accordion-body'] {
    padding-top: rem(8px);
    padding-left: 0;
    padding-right: 0;
  }

  [class*='accordion-header'] {
    [class*='accordion-button'] {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .BadgesContainer {
    display: flex;
    gap: rem(8px);
  }

  .NewBadge {
    background-color: var(--ir-warm-gray-040);
  }

  .PendingBadge {
    background-color: var(--ir-segment-yellow-100);
  }

  .NewBadge,
  .PendingBadge {
    height: fit-content;
    padding: rem(0) rem(3px);
    color: var(--ir-neutral-black-primary);
    border-radius: rem(2px)
  }
}
