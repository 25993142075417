@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(10px) rem(8px);
  flex-direction: column;
  align-items: flex-start;
  border-radius: rem(4px);
  border: 1px solid var(--ir-gray-92);
  gap: rem(4px);

  .Header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .CompanyInformation {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .CompanyFirstName,
  .CompanySecondName {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
    font-size: rem(18px);
    font-weight: 600;
  }

  .CompanySecondName {
    color: var(--ir-neutral-black-primary);
    font-size: 16px;
  }

  .StatusContainer {
    display: flex;
    gap: rem(12px);
  }

  .EmployedStatus {
    height: fit-content;
    padding: 0 rem(3px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    border-radius: rem(2px);
    text-transform: uppercase;
    font-weight: 600;
  }

  .JobPosition {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-weight: 600;
  }

  .JobDescription {
    display: flex;
    flex-direction: row;
    gap: rem(5px);
  }

  .Description {
    color: var(--ir-warm-gray-800);
    font-weight: 600;
  }

  .JobDuration {
    display: flex;
    gap: rem(8px);
    margin-top: rem(5px);
    margin-bottom: rem(15px);
  }

  .Duration {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-weight: 600;
  }

  .TotalTime {
    padding: 0 rem(3px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-primary);
    border-radius: rem(2px);
    font-size: rem(12px);
    font-weight: 600;
    line-height: rem(24px);
    align-items: flex-start;
  }

  .MonthlyIncome {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .Title {
    color: var(--ir-warm-gray-060);
    font-weight: 600;
    text-transform: capitalize;
  }

  .Income {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .VerifierStatus {
    display: flex;
    background: var(--ir-neutral-green-50);
    color: var(--ir-success-light-pill-text);
    padding-left: rem(3px);
    gap: rem(2px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
    align-items: center;
  }
}
