@import '/src/stylesheets/utils';

.checkBoxBorder {
  border: 1px solid var(--ir-gray-200);
}

.reactSwitch {
  border-radius: 100px !important;
}

:global(.react-switch-bg) {
  font-size: 2rem;
}

.uncheckedOuterCircle,
.checkedOuterCircle {
  display: flex;
  width: 16px;
  height: 16px;
  margin: auto;
  border: 1px solid var(--ir-gray-200);
  align-items: center;
  border-radius: 10px;
  justify-content: center;

  &::before {
    display: flex;
    width: 25px;
    height: 22px;
    margin: auto;
    opacity: 0.2;
    align-items: center;
    border-radius: 10px;
    content: '';
    justify-content: center;
  }
}

.uncheckedOuterCircle {
  background-color: var(--ir-gray-220);
}

.checkedOuterCircle {
  background-color: var(--color-dark-green);
}

.renterUncheckedOuterCircle,
.renterCheckedOuterCircle {
  display: flex;
  width: rem(16px);
  height: rem(16px);
  margin: auto;
  align-items: center;
  border-radius: 10px;
  justify-content: center;

  &::before {
    display: flex;
    width: 25px;
    height: 22px;
    margin: auto;
    opacity: 0.2;
    align-items: center;
    border-radius: 10px;
    content: '';
    justify-content: center;
  }
}

.renterUncheckedOuterCircle {
  border: 2px solid var(--ir-segment-cyan-900);
  background-color: var(--ir--success-progress);
}

.renterCheckedOuterCircle {
  border: 2px solid var(--ir-neutral-states-color-700);
  background-color: var(--ir-gray-200);
}

.OffText {
  display: flex;
  height: 100%;
  color: var(--ir-neutral-000);
  align-items: center;
  font-size: 10px;
  justify-content: center;
  padding-right: 14px;
}

.OnText {
  display: flex;
  height: 100%;
  margin: auto;
  color: var(--color-dark-green);
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  margin-right: -1rem;
}

.renterOnText {
  display: flex;
  height: 100%;
  margin: auto;
  color: var(--ir--success-progress);
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  margin-right: -1rem;
}
