@import '/src/stylesheets/utils';

.VerifierCardContainer {
    display: flex;
    flex-direction: column;
    color: var(--ir-gray-1050);

    .mainHeading {
        color: var(--ir-warm-gray-060);
        font-size: rem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: rem(20px); /* 142.857% */
    }

    .infoContainer {
        display: flex;
        flex-direction: column;

        .heading {
            font-size: rem(10px);
            font-weight: 600;
            line-height: rem(14px); 
        }

        .subHeading {
            color: var(--ir-warm-gray-060);
            font-size: rem(10px);
            font-weight: 600;
            line-height: rem(14px); 
        }
    }
}

