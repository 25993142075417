@import '/src/stylesheets/utils';

.RentalContainer {
  display: flex;
  padding: rem(52px) rem(40px);
  flex-direction: column;
  justify-content: center;
  gap: rem(10px);
  background-color: var(--ir-gray-000);
  border-radius: rem(24px);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
}

.RentalSubContainer {
  display: flex;
  padding-block: rem(12px) rem(32px);
  flex-direction: column;
  gap: rem(32px);
}

.ErrorContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.RentalAddressInfo {
  display: flex;
  flex-direction: column;
  gap: rem(18px);
}

.HistoryTitleContainer {
  display: flex;
  padding: rem(12px) 0;
  flex-direction: column;
  justify-content: center;
  gap: rem(12px);
}

.TitleContainer {
  text-align: center;
  color: var(--ir-gray-1050);
  font-size: rem(32px);
  font-weight: 600;
}

.DescriptionContainer {
  text-align: justify;
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
}

.BodyContainer {
  padding: rem(32px) rem(230px);
  background-color: var(--ir-segment-cyan-300);
  overflow-x: hidden;
  padding-top: 12rem;
}

.HeaderContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 6rem;
  z-index: 1034;
}

.HeadingContainer {
  padding: rem(10px) 16.1rem;
  text-align: start;
  font-size: rem(32px);
  font-weight: 600;
  color: var((--ir-gray-000));
  background-color: var(--ir-segment-blue-750);
}

.LogoContainer {
  display: flex;
  padding: 2rem 16.1rem;
  background: var(--ir-gray-000);
  align-items: center;
  gap: rem(10px);
}

.InfoPanel {
  display: flex;
  padding: rem(12px) rem(10px);
  border: 1px solid var(--ir-segment-blue-96);
  text-align: left;
  color: var(--ir-neutral-black-primary);
  font-size: rem(14px);
  font-weight: 600;
  gap: rem(10px);
  background-color: var(--ir-segment-blue-100);
  border-radius: rem(4px);
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
}

.LinkContainer {
  color: var(--ir-segment-cyan-700);

  &.TextContainer {
    text-decoration: none;
    font-weight: 400;
  }
}

.RequestDetailsContainer {
  display: flex;
  padding: rem(28px) rem(16px) rem(10px);
  align-items: center;
}

.LabelContainer {
  display: flex;
  align-items: center;
}

.RequestDetails {
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
  font-weight: 600;

  &.FormField {
    font-size: rem(12px);

    label {
      padding: rem(19px) rem(0) rem(5px) rem(16px);
      opacity: 1 !important;
    }
  }

  &.ResponseFormField {
    font-size: rem(12px);

    input {
      background-color: var(--ir-neutral-white-bg);
      border: 1px solid var(--ir-gray-200);
    }

    label {
      padding: rem(19px) rem(0) rem(5px) rem(16px);
      opacity: 1 !important;
    }
  }

  &.DateContainer {
    gap: rem(20px);
    justify-content: flex-start;
  }

  &.PdfContainer {
    gap: rem(10px);
    justify-content: flex-end;
    text-decoration: none;
  }
}

.StatusContainer {
  font-weight: 400;
}

.Link:hover {
  color: var(--ir--neutral-sea-blue-500);
  cursor: pointer;
}

.PillContainer {
  display: flex;
  justify-content: flex-end;
}

.PillHeading {
  font-weight: 600;
}

.ContactDetailsContainer {
  display: flex;
  text-align: start;
  gap: rem(16px);
}

.ContactDetailsContainer > * {
  flex: 1 1 0;
}

.RentalContactCard {
  display: flex;
  height: stretch;
  padding: rem(10px) rem(8px);
  border: 1px solid var(--ir-gray-92);
  text-align: left;
  color: var(--ir-warm-gray-800);
  border-radius: rem(4px);
  flex-direction: column;
  gap: rem(12px);
  font-size: rem(18px);
  font-weight: 600;
}

.EmploymentLayoutContainer {
  --bs-gutter-x: 0;
}

.EmploymentCompanyTag {
  color: var(--ir-neutral-black-primary);
  font-weight: 600;
  font-size: rem(16px);
}

.EmploymentDetails {
  display: flex;
  text-align: start;
  align-items: flex-start;
  gap: rem(12px);
}

.RentalTitle {
  display: flex;
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
  gap: rem(10px);

  &.RentalTitleLarge {
    font-weight: 700;
    font-size: rem(18px);
    gap: rem(12px);
  }

  &.SpaceContainer {
    justify-content: unset;
  }
}

.RentalAddressContainer {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
  color: var(--ir-neutral-black-100);
  font-size: rem(18px);
  font-weight: 600;
}

.RentalSectionContainer {
  display: flex;
  text-align: start;
  color: var(--ir-warm-gray-060);
  font-weight: 600;
  flex-direction: column;
  gap: rem(5px);
  flex: 1;
}

.RentalLayoutContainer {
  display: flex;
  gap: rem(10px);
}

.RenterNameContainer {
  display: flex;
  align-items: center;
  height: rem(62px);
}

.RentalFormContainer {
  display: flex;
  padding: rem(32px) rem(0);
  flex-direction: column;
  gap: rem(22px);

  [class*='flag-dropdown'] {
    display: none;
  }

  [class*='react-tel-input'] [class*='form-control'] {
    padding-left: rem(33px);
    height: rem(55px);
    padding-top: rem(30px);
    background-color: var(--ir-gray-000) !important;
  }

  [class*='PhoneNumber_container'] {
    margin-top: rem(4.5px);

    [class*='customCountryCode'] {
      top: rem(23px);
    }

    [class*='label'] {
      top: rem(4px);
      color: var(--ir-neutral-black-primary);
      opacity: 1;
      font-weight: 600;
      font-size: rem(10px);
    }
  }
}

.RentalFormFieldContainer {
  display: flex;
  gap: rem(10px);
}

.RentalFormFieldContainer > * {
  flex: 1 1 0;
}

.dropDownFillArrow {
  width: rem(24px);
  height: rem(24px);
  text-align: center;
  background-color: var(--ir-neutral-white-bg);
  font-weight: 600;
  color: var(--ir-neutral-black-primary);
  border-radius: rem(2px);
  font-size: rem(12px);
  margin-right: 1rem;
}

.HorizantalLine {
  height: 1px;
  margin: 0;
  color: var(--ir-warm-gray-045);
  opacity: 1;
}

.EmploymentQuestionnaireContainer {
  overflow: auto;
}

.RentalQuestionnaireContainer {
  display: flex;
  padding: rem(8px) rem(8px) rem(0) 2px;
  border: 1px solid var(--ir-neutral-black-200);
  flex-direction: column;
  border-radius: rem(4px);
}

.RentalQuestionnaireDescription {
  padding: rem(0) rem(5px);
  text-align: justify;
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
  font-style: normal;
  font-weight: 400;
  gap: rem(10px);
}

.RentalQuestionSection {
  display: flex;
  padding: rem(12px) 0;
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
  font-weight: 600;
  flex-direction: column;
  gap: rem(5px);
}

.RentalQuestionContainer {
  display: flex;
  padding: rem(20px) rem(0) rem(30px);
  flex-direction: column;
  border-bottom: 1px solid var(--ir-warm-gray-045);
}

.RentalQuestion {
  padding: rem(12px);
  text-align: start;
  color: var(--ir-neutral-black-100);
  font-size: rem(16px);
  font-style: normal;
  font-weight: 600;
}

.RentalAnswerContainer {
  display: flex;
  padding: rem(0) rem(38px);
  gap: rem(22px);
}

.RentalAnswerCheckbox {
  color: var(--ir-neutral-black-primary);
  font-size: rem(16px);
  font-style: normal;
  font-weight: 600;

  input {
    width: 1.143rem !important;
    height: 1.143rem !important;
    border: 2px solid var(--ir-neutral-black-primary);
  }

  input:checked {
    background-color: var(--ir-segment-cyan-900) !important;
  }
}

.AnswerResponseContainer {
  padding: rem(12px) rem(28px) rem(0) rem(28px);
  text-align: start;
}

.AnswerResponse {
  background-color: var(--ir-neutral-white-bg);
  border: 1px solid var(--ir-gray-200);
}

.FormContainer {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
}

.ConsentContainer {
  display: flex;
  padding: 0 rem(12px);
  flex-direction: column;
  gap: rem(12px);
}

.Consent {
  text-align: right;
  color: var(--ir-dark-gray-1000);
  font-size: rem(14px);
  font-style: normal;
  font-weight: 400;

  label {
    text-align: start;
    padding-left: rem(8px);
  }

  input {
    width: 1.143rem !important;
    height: 1.143rem !important;
    border: 2px solid var(--ir-neutral-black-primary);
  }

  input:checked {
    background-color: var(--ir-segment-cyan-900) !important;
  }
}

.FormButtonContainer {
  align-self: center;
}

.FormButton {
  width: fit-content;
  padding: 0.4rem;
  border: 1px solid var(--ir-segment-cyan-700);
  text-align: center;
  color: var(--ir-segment-cyan-700);
  font-weight: 600;
  font-size: 1.14rem;
}

.FooterContainer {
  display: flex;
  padding: rem(32px) rem(0) rem(30px) rem(0);
  flex-direction: column;
  gap: rem(30px);
  background-color: var(--ir-segment-cyan-300);
  color: var(--ir-dark-blue-100);
  font-size: rem(13px);
  font-style: normal;
  font-weight: 400;
}

.CustomSelectContainer {
  text-align: start;
  color: var(--ir-gray-1050);
}

.CustomOption {
  font-size: rem(14px);
  font-style: normal;
  font-weight: 600;

  &.TextContainer {
    font-weight: 400;
  }

  &.CustomText {
    font-style: italic;
  }

  .OtherText {
    font-weight: 400;
  }
}

.TextSection {
  width: max-content;
  text-align: start;
}

.DisplayContainer {
  display: flex;
}

.TextRightContainer {
  text-align: right;
}

.RequestDateContainer {
  display: flex;
  width: 100%;
  color: var(--ir-neutral-black-primary);
  gap: rem(20px);
  justify-content: flex-start;
  font-size: rem(16px);
  font-weight: 600;
}

.RentalLedger {
  display: flex;
  padding: rem(5px) rem(8px);
  text-align: left;
  color: var(--ir-neutral-black-primary);
  gap: rem(5px);
  font-size: rem(13px);
  align-items: center;
  font-weight: 400;
}

.ReactSelectContainer {
  [class*='-control'] {
    padding: rem(5px) rem(0) rem(5px) rem(7px);

    [class*='ReactSelect_valueContainer'] {
      text-align: start;

      [class*='placeholder'] {
        color: var(--ir-warm-gray-060);
        font-size: rem(16px);
        font-style: normal;
        font-weight: 400;
      }

      [class*='ReactSelect_defaultChild'] {
        width: stretch;
      }
    }
  }
}

.OptionalField {
  font-weight: 400;
  cursor: pointer;
}

.RentalDocumentsContainer {
  display: flex;
  padding: rem(4px) rem(8px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);
  gap: rem(60px);
}

.RentalDocumentsContainer > * {
  flex: 1 1;
}

.ImageContainer {
  background-color: var(--ir-gray-000);
}

.CustomDropZoneContainer {
  background-color: var(--ir-neutral-white-bg);
}

.SupportingDocumentsContainer {
  flex-direction: column;
  gap: rem(16px);
  display: flex;
}

.SupportingDocumentsHeading {
  text-align: left;
  color: var(--ir-neutral-black-primary);
  font-size: rem(18px);
  font-weight: 700;
  text-transform: uppercase;

  .OptionalFieldContainer {
    font-size: rem(16px);
    font-weight: 400;
  }
}

@media (max-width: $screen-large-screen) {
  .BodyContainer {
    padding: rem(32px) 10rem;
    padding-top: 12rem;
  }

  .LogoContainer {
    padding-inline: 9.9rem;
  }

  .HeadingContainer {
    padding: rem(10px) 9.7rem;
  }
}

@media (max-width: $screen-mobile-landscape-medium) {
  .BodyContainer {
    padding: rem(32px) 4rem;
    padding-top: 12rem;
  }

  .LogoContainer {
    padding-inline: 3.9rem;
  }

  .HeadingContainer {
    padding: rem(10px) 3.7rem;
  }

  .RentalSectionContainer {
    flex: unset;
  }

  .RequestDetailsContainer {
    gap: rem(20px);
  }
}

@media (max-width: $screen-mobile-small) {
  .BodyContainer {
    padding: rem(32px) rem(5px);
    padding-top: 15rem;
  }

  .LogoContainer {
    padding-inline: rem(5px);
  }

  .HeadingContainer {
    padding: rem(10px) rem(3.7px);
  }

  .RentalContainer {
    padding: rem(52px) rem(15px);
  }
}
