@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(10px);
  background: linear-gradient(90deg, var(--ir-semantics-disabled) 0%, rgb(214 214 214 / 0%) 100%);
  opacity: 0.3;
  border-radius: rem(5px);
  gap: rem(10px);

  .SkeletonBox {
    width: 100%;
    height: rem(40px);
    background: var(--ir-gray-000);
    opacity: 0.5;
    border-radius: rem(3px);
  }
}
