@import '/src/stylesheets/utils';

.container {
  position: relative;
  padding: 1rem 0;

  .title {
    height: 42px;
    color: var(--ir-warm-gray-800);
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 3rem;
  }

  .partnershipContainer {
    display: flex;
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
    border-radius: 4px;
  }

  .partnershipIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-right: 1rem;
    width: minmax(7%, 200px);
  }

  .partnershipText {
    margin: auto;
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .inputContainer {
    align-items: center;
    display: flex;
    margin-top: 1rem;
    text-align: start;
  }

  .statement {
    padding: 1rem 0;
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: 13px;
    font-weight: 400;
  }

  .btnPrimary {
    display: flex;
    width: fit-content;
  }

  .alignError {
    position: relative;
    text-align: start;
  }

  .inputLabel {
    color: var(--ir-neutral-black-primary);
    font-size: 14px;
    font-weight: 600;
  }

  .optionals {
    color: var(--ir-neutral-black-primary);
    font-size: 12px;
    font-weight: 400;
  }

  .stateDropDownContainer {
    position: relative;
  }

  .stateDropDown {
    position: absolute;
    top: 20px;
    right: -150px;
    width: 200px;
    z-index: 100;
  }
}

.floatingContainer {
  position: relative;
  color: var(--ir-neutral-000);
  margin-top: 5px;

  .label {
    position: absolute;
    left: 1rem;
    font-size: 0.8rem;
    font-weight: 600;
  }
}

.placeholderPaddingRemoved {
  padding: 0;
}

.placesAutocomplete {
  width: 100%;
  height: rem(55px);
  padding: 1rem 0 0 0.8rem;
  border: 1px solid var(--ir-gray-200);
  background-color: var(--ir-warm-gray-100);
  border-radius: 4px;
  outline: none;
}

.placesAutocomplete::placeholder {
  font-size: 16px;
  opacity: 0.7;
}

.placesDropDown {
  position: absolute;
  width: 100%;
  z-index: 101;
}

.customErrorForAbsolutePosition {
  top: rem(61px);
}

.errorRelativenWithPlacesAutoComplete {
  margin-bottom: 52px;
  position: relative;
}

.suggestionInput {
  padding: 0 10px;
  border: 1px solid var(--ir-warm-gray-300);
  text-align: start;
  background-color: var(--ir-warm-gray-100);
  border-radius: rem(4px);
}

.noSuggestionInput {
  display: none;
}

.suggestionOptions {
  background-color: #fff;
  color: #303333;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    background-color: #d1eff2;
    color: #343741;
    font-weight: 500;
  }
}

.invalid {
  background-color: var(--ir-field-error-background) !important;
  border: 1px solid var(--ir-field-error);

  &:focus {
    border: 1px solid var(--ir-field-error);
  }
}

.boldLabel {
  position: absolute;
  left: 0.8rem;
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  font-weight: 600;
}
