@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: fit-content;
  gap: rem(10px);
  color: var(--ir-neutral-000);
  align-items: center;

  .RadioContainer {
    width: rem(20px);
    height: rem(20px);
    box-shadow: none;
  }

  .RadioContainer input:checked {
    background-color: var(--ir-neutral-000);
    border: $border-base;
    box-shadow: none;
  }
}

.OuterContainer {
  box-shadow: 0 0 0 rem(2px) var(--ir-gray-200);
  border-radius: 2.25em;
  width: rem(24px);
  height: rem(24px);
  padding: rem(2px) rem(0) rem(0) rem(2px);

  input {
    appearance: none;
    border-radius: 1.24em;
    border: 2px solid var(--ir-neutral-states-color-700);
  }

  input:checked {
    appearance: none;
    background-color: var(--ir--success-progress);
    border-radius: 1.24em;
    border: 2px solid var(--ir-segment-cyan-900);
  }
}
