@import '/src/stylesheets/utils';

.Container {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .Heading {
    padding: rem(20px) rem(0);
    text-align: center;
    color: var(--ir-neutral-black-100);
    font-size: rem(24px);
    font-weight: 700;
  }

  .Description {
    text-align: center;
    color: var(--ir-gray-02);
    font-size: rem(18px);
  }

  .PersonIcon {
    margin-top: rem(15px);
    margin-bottom: rem(20px);
  }
}
