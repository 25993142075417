@import '/src/stylesheets/utils';

.propertySelectContainer {
  position: relative;
  font-size: rem(12px);
  font-weight: 600;
  line-height: rem(20px);
  letter-spacing: 0;
  text-align: left;
  color: var(--ir-warm-gray-800);
  min-width: 23rem;

  [class*='-control'] {
    position: absolute;
    right: rem(10px);
    bottom: rem(10px);
    width: fit-content;
    border: none;
    min-height: unset;
  }

  [class*='-control'] > :first-child {
    display: contents;
  }

  [class*='-Input'] {
    display: contents;
  }

  [class*='singleValue'] {
    display: none;
  }

  [class*='-indicatorSeparator'] {
    display: none;
  }

  [class*='-indicatorContainer'] {
    padding: unset;
  }

  [class$='-Svg'] {
    background: var(--ir-neutral-white-bg);
  }

  .textContainer {
    display: flex;
    position: relative;
    height: rem(34px);
    padding: rem(8px);
    border: rem(1px) solid var(--ir-neutral-default-gray);
    background: var(--ir-gray-000);
    border-radius: rem(4px);
    padding-top: rem(8px);
    padding-right: rem(30px);
    max-width: 23rem;
    overflow: hidden;
  }

  .infoIconContainer {
    position: absolute;
    right: rem(8px);
  }

  .iconContainer {
    margin-bottom: rem(4px);
  }

  .enabledContainer {
    width: fit-content;
    padding: rem(3px);
    background: var(--ir-warm-gray-040);
    color: var(--ir-segment-cyan-700);
    padding-left: rem(5px);
    white-space: nowrap;
  }

  .disabledContainer {
    width: fit-content;
    padding: rem(3px);
    margin-right: rem(12px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-010);
    border-radius: rem(2px);
  }
}
