@import '/src/stylesheets/utils';

.Container {
  display: flex;
  position: relative;
  padding: rem(10px) rem(8px);
  border: 1px solid var(--ir-gray-92);
  background: var(--ir-warm-gray-100);
  flex-direction: column;
  gap: rem(12px);
  border-radius: rem(4px);

  .NameContainer {
    display: flex;
  }

  .DetailsContainer {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .DetailsHeading {
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      text-transform: capitalize;
    }

    .Details {
      color: var(--ir-neutral-black-100);
      text-overflow: ellipsis;
      font-size: rem(16px);
    }
  }

  .RowContainer {
    gap: 0;
  }
}

.PillsContainer {
  position: absolute;
  top: rem(10px);
  right: rem(8px);
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .RowContainer {
      display: flex;
      flex-direction: column;
      gap: rem(8px);
    }
  }
}
