@import '/src/stylesheets/utils';

.search {
  outline-width: 0;
  position: relative;
  width: 100%;

  [type='search']::-webkit-search-cancel-button {
    width: rem(10px);
    height: rem(10px);
    appearance: none;
    background-image: url($search-cancel-icon);
    background-size: rem(10px) rem(10px);
  }
}

.search input {
  border-radius: rem(30px);
  font-size: rem(14px);
  height: rem(30px);
  padding-left: 2.5rem;
}

.search input:focus {
  border-color: #ced4da;
  box-shadow: none;
}

.search .fasearch {
  position: absolute;
  top: rem(8px);
  left: rem(16px);
  color: var(--ir-warm-gray-800);
}
