.profileInfoContainer {
  cursor: pointer;
  display: flex;
  padding: 10px 0;

  .profileImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 0.5rem;
    overflow: hidden;
    object-fit: fill;
  }

  .profileImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profileLabel {
    font-size: 12px;
  }

  .profileName {
    font-size: 14px;
    font-weight: 700;
    text-align: start;
  }
}
