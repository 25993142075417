@import '/src/stylesheets/utils';

.Container {
  padding-bottom: rem(40px);
  border-bottom: 1px solid var(--ir-primary-primary-93);

  .Heading {
    padding: rem(3px) rem(5px);
    text-align: start;
    color: var(--ir-segment-cyan-900);
    font-size: rem(16px);
    font-weight: 600;
  }

  .ReportColumns {
    margin-bottom: rem(20px);
  }
}
