@import '/src/stylesheets/utils';

.container {
  margin-bottom: rem(5px);

  .descriptionCard {
    display: flex;
    flex-direction: column;

    .editButton {
      padding: 0;
      border: none;
      background: transparent;
    }

    .outerEmailDiv {
      display: flex;
      justify-content: space-between;
      margin-bottom: rem(2px);
    }

    .emailDiv {
      display: flex;
      justify-content: space-between;

      .email {
        font-weight: 600;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--ir-gray-1050);
      }
    }

    .actionDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .faded {
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
        color: var(--ir-neutral-010);
      }
    }

    .otherInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-right: rem(10px);
      font-size: rem(10px);
      line-height: rem(14px);
      font-weight: 600;
      flex: 1;

      .text {
        color: var(--ir-gray-1050);
      }

      .label {
        color: var(--ir-neutral-000);
      }
    }

    .relationshipDiv {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .phoneNumberDiv {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
