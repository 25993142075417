@import '/src/stylesheets/utils';

.EmptyStateContainer {
  display: flex;
  flex-direction: column;
  gap: rem(25px);
  margin: rem(108px) rem(60px);

  &.NoRecordFound {
    margin: 0;
  }
}

.Content {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: rem(10px);
}

.Title {
  position: relative;
}

.EmptyStateText {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: fit-content;
  margin: auto;
  text-align: center;
  color: var(--ir-neutral-010);
  font-size: rem(32px);
  line-height: rem(36px);
  font-weight: 400;

  &.NoRecordFound {
    color: #303333;
    font-size: rem(24px);
  }
}

.ImageContainer {
  margin-bottom: rem(10px);
}

.ButtonContainer {
  display: flex;
  gap: rem(15px);
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.SendRequestButton {
  display: flex;
  width: fit-content;
  padding: rem(10px) rem(30px);
  gap: rem(8px);
  align-items: center;
}

.HelpText {
  text-align: start;
  color: var(--ir-neutral-black-primary);
  font-size: rem(14px);
  line-height: rem(24px);
  font-weight: 300;
}

.LearMoreLink {
  color: var(--ir-segment-cyan-900);
  cursor: pointer;
  text-decoration: underline;
}

.ImageClassOnMobileView {
  visibility: hidden;
}

.MobileViewContainer {
  display: none;
}

@media (max-width: $screen-mobile-navbar) {
  .ImageClass {
    visibility: hidden;
  }

  .ImageClassOnMobileView {
    visibility: visible;
  }

  .EmptyStateContainer {
    margin: rem(15px);
    padding-top: rem(10px);
  }

  .Description {
    display: none;
  }

  .MobileViewContainer {
    display: block;
  }

  .Content {
    flex-direction: column;
  }

  .EmtpyStateText {
    padding: 0 rem(17px);
  }
}
