@import '/src/stylesheets/utils';

.container {
  :global(.modal-footer) {
    display: flex;
    padding: 0.5rem 3rem;
    border: none;
    justify-content: flex-end;
  }

  :global(.modal-body) {
    padding: rem(25px) rem(40px);
    background: var(--ir-segment-cyan-300);
  }

  .button {
    width: fit-content;
    padding: rem(4px) rem(8px);
  }
}
