@import '/src/stylesheets/utils';

.Outter2FAContainer {
  [class*='modal-dialog'] {
    display: flex;
    justify-content: center;
  }

  [class*='modal-content'] {
    width: 78%;
    min-height: 420px;
    border: 1px solid #d5d6d6;
  }

  .Modal2FAContainer {
    background-color: #fff !important;
    padding: 50px 60px;

    .ButtonContainer2FA {
      display: flex;
      gap: 20px;
      justify-content: center;
    }

    .CancelButton {
      padding: 8px 26px;
    }

    .Continue {
      width: 116px;
      padding: 8px 26px;
      background: #17adbc;
      color: #fff;
      border-color: #17adbc;
    }

    .ModalHeaderText {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #d5d6d6;
      padding-bottom: 20px;
    }

    .ModalBodyText {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 25px;
    }

    .ModalBodyText2 {
      text-align: center;
    }

    .FontStyle1 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24.51px;
      letter-spacing: -0.01em;
      color: #303333;
      margin-bottom: 0;
    }

    .FontStyle2 {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.03em;
      color: #303333;
      margin-bottom: 0;
    }

    .FontStyle3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.03em;
      color: #303333;
      margin-bottom: 0;
    }

    .FontStyle4 {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.03em;
      color: #50abba;
      margin-bottom: 0;
    }

    .PasswordField {
      padding-right: 3rem !important;
    }

    .ForgotPassword {
      width: 79%;
      text-align: right;
      margin-top: 5px;
      margin-bottom: 40px;
    }

    .SuccessContainer {
      display: flex;
      gap: 6px;
    }

    .ModalHeader {
      margin-top: rem(30px);
      width: 100%;
      text-align: center;
    }

    .SuccessContainer2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;
    }

    .SuccessStyle1 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32.68px;
      color: #303333;
    }

    .SuccessStyle2 {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.51px;
      letter-spacing: -0.01em;
      color: #303333;
    }

    .CheckCircle {
      margin-bottom: 20px;
    }

    .ForgotPasswordLink {
      width: fit-content;
      font-size: 13px;
      font-weight: 400;
      line-height: 17.7px;
      color: #5e6566;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: var(--ir--neutral-sea-blue-400);
      }
    }

    [class*='container'] {
      display: flex;
      justify-content: center;
    }

    [class*='form-floating'] {
      width: 59%;
    }

    [class*='errorMessage'] {
      padding-left: 105px;
    }

    .phoneNumberContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;

      [class*='container'] {
        display: block;
        width: auto;
      }

      [class*='errorMessage'] {
        padding-left: 5px;
      }

      [class*='country-list'] {
        height: 10rem;
        padding: 0;
      }

      [class*='form-control'] {
        padding-top: rem(25px);
        width: 100%;
        height: rem(55px);
      }

      [class*='flag'] {
        margin-top: rem(-3px) !important;
      }

      [class*='open'],
      [class*='selected-flag'],
      [class*='react-tel-input'],
      [class*='selected-flag']:hover,
      [class*='flag-dropdown'] {
        border: none;
        background: transparent !important;
        background-color: transparent;
      }

      [class*='react-tel-input'] {
        width: 100%;
        border: 1px solid var(--ir-gray-200);
        background-color: var(--ir-warm-gray-100) !important;
        border-radius: rem(4px);
        font-size: rem(14px);
      }

      :global(.country) {
        padding: rem(10px) 0 0 rem(44px) !important;
      }

      [class*='form-control']:focus,
      [class*='form-control'],
      [class*='form-control']:hover {
        border: 1px solid #d2d6d6;
        background: #f7fafa !important;
        color: var(--ir-neutral-black-100);
        border-radius: 0 0 rem(4px) rem(4px);
        box-shadow: 0 0 0 1px transparent;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(20px);
      }

      [class*='special-label'] {
        top: rem(6px);
        left: rem(6px);
        text-align: left;
        color: var(--ir-neutral-010);
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
        background-color: transparent;
      }

      [class*='label'] {
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        color: #5e6566;
        margin-top: 5px;
      }

      [class*='flag'] [class*='arrow'] {
        border-top: rem(4px) solid var(--ir-neutral-000);
      }

      [class='dial-code'] {
        width: 2rem;
      }

      [class='country'],
      [class='country_highlight'],
      [class*='preferred'] {
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        text-align: initial;
        justify-content: flex-end;
      }
    }

    .ErrorText {
      text-align: center;
      color: var(--ir-field-error);
      margin-top: rem(10px);
      font-size: rem(14px);
      font-weight: 600;
      align-items: baseline;
    }

    .ErrorIcon {
      margin-top: rem(-5px);
    }

    .FooterText {
      padding: rem(12px) rem(0);
      text-align: center;
      color: var(--ir-neutral-black-primary);
      font-size: rem(14px);
    }

    .FooterHighlightedText {
      padding: unset;
      border: unset;
      color: var(--ir-segment-cyan-900);
      font-size: rem(14px);
      font-weight: 600;
      box-shadow: unset;

      &:focus-visible {
        outline: none;
      }

      @media (max-width: 768px) {
        [class*='container'] {
          width: 100%;
        }
      }
    }

    .OTPButtonMargin {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .OTPHeaderContainer {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 50px;
      margin-bottom: 10px;
    }

    .OTPStyle1 {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
    }

    .OTPStyle2 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    .OTPInput {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 768px) {
      [class*='form-floating'] {
        width: 100%;
      }

      [class*='errorMessage'] {
        padding-left: 0;
      }

      .ForgotPassword {
        width: 100%;
      }

      .FontStyle3 {
        text-align: center;
      }

      .SuccessContainer {
        flex-direction: column;
      }

      .FontStyle1,
      .FontStyle2,
      .FontStyle3,
      .FontStyle4,
      .SuccessStyle1,
      .SuccessStyle2 {
        text-align: center;
      }
    }
  }

  @media (max-width: 768px) {
    .Modal2FAContainer {
      padding: 40px 20px;
    }
  }

  @media (max-width: 576px) {
    [class*='modal-content'] {
      width: 100%;
    }
  }
}
