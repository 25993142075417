@import '/src/stylesheets/utils';

.container {
  display: flex;
  font-weight: 600;
  color: var(--ir-gray-1050);

  .description {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    flex: 1;

    .innerDiv {
      display: flex;
      flex-direction: column;
    }
  }

  .company {
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px);
    color: var(--ir-warm-gray-800);
  }

  .title {
    font-size: rem(10px);
    line-height: rem(14px);
    color: var(--ir-warm-gray-800);
    font-weight: 600;
  }

  .date {
    font-weight: 600;
    font-size: rem(10px);
    line-height: rem(14px);
    color: var(--ir-warm-gray-800);
  }

  .details {
    display: flex;
    flex-direction: row;
    gap: rem(9px);
    align-items: center;
  }

  .label {
    color: var(--ir-neutral-000);
    font-size: rem(10px);
  }

  .jobDescription {
    display: flex;
    flex-direction: column;
  }
}
