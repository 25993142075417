@import '/src/stylesheets/utils';


.Heading {
  display: flex;
  text-align: center;
  color: var(--ir-warm-gray-800);
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  justify-content: center;
  line-height: 40px;
  margin-bottom: 32px;
}

.VerticalMargin {
  margin: rem(20px) 0;
}
