@import '/src/stylesheets/utils';

.title {
  padding: rem(8px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-gray-000);
  margin-bottom: rem(2px);
  border-radius: rem(4px);

  .topDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    display: flex;
    flex-direction: row;
    font-size: rem(12px);
    line-height: rem(16px);
    gap: rem(8px);
    align-items: flex-end;

    .heading {
      font-weight: 600;
      color: var(--ir-gray-1050);
    }

    .description {
      font-size: rem(10px);
      color: var(--ir-neutral-000);
    }

    .icon {
      svg {
        width: rem(16px);
        height: rem(16px);
      }
    }
  }

  .nothingToShow {
    display: flex;
    padding-left: rem(25px);

    .text {
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(24px);
      color: var(--ir-gray-1050);
    }
  }
}
