@import '/src/stylesheets/utils';

.Container {
  padding: rem(10px);
  text-align: center;
  align-items: center;
  color: var(--ir-warm-gray-060);
  font-size: rem(24px);
  font-weight: 600;
}
