.container {
  :global(.progress) {
    height: 5px;
    
    :global(.progress-bar) {
      background-color: var(--ir--success-progress);
    }

  }

}

.decoratorsContainer {
  width: 200px;
}
