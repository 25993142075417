@import '/src/stylesheets/utils';

.pageItem {
  a {
    background-color: inherit;
    border: 1px transparent solid;
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(19px);
    margin-left: rem(5px) !important;
    margin-right: rem(5px) !important;

    &:focus {
      background: #fff;
      box-shadow: none !important;
      color: var(--ir-neutral-black-100);
    }

    &:hover {
      border: 1px solid #add8e6;
      background: #f7f9fa;
      color: var(--ir-neutral-black-100);
      border-radius: 4px;
    }
  }

  &:not(:first-child) {
    [class$='page-link'] {
      margin-left: 0;
      padding: rem(4px) rem(8px);
      background-color: inherit;
      font-size: rem(16px);
      font-weight: 400;
      line-height: rem(19px);
    }
  }

  &:first-child {
    [class$='page-link'] {
      background-color: inherit;
      font-size: rem(16px);
      font-weight: 400;
      line-height: rem(19px);
      margin-left: 0;
      padding: rem(4px) rem(8px);
    }
  }

  span {
    border: none;
  }
}

.focus {
  a {
    border: 1px solid #add8e6;
    background: #e6f8fa;
    color: var(--ir-neutral-black-100);
    border-radius: 4px;
    box-shadow: none !important;
    margin-left: 0;
  }
}

.paginationCol {
  align-items: center;
  background-color: var(--ir-segment-cyan-300);
  display: flex;
  justify-content: center;
  padding-top: rem(20px);
}

.noBackgroundColor {
  align-items: center;
  display: flex;
  background-color: #fff;
  justify-content: center;
  padding-top: rem(20px);
}

.perPageCol {
  align-items: center;
  background-color: var(--ir-segment-cyan-300);
  display: flex;
  justify-content: center;

  [class$='-ValueContainer'] {
    font-size: rem(14px);
    font-weight: 600;
    min-height: rem(28px);
  }
}

.perPageColLegacy{
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: center;

  [class$='-ValueContainer'] {
    font-size: rem(14px);
    font-weight: 600;
    min-height: rem(28px);
  }
}
