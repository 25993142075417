@import '/src/stylesheets/utils';

.Container {
  padding: rem(4px) rem(0) rem(4px) rem(16px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);
  border-radius: rem(4px);

  &.RangeContainer {
    display: flex;
  }
}

.Label {
  font-size: rem(10px);
  font-weight: 600;
  color: var(--ir-neutral-010);
}

.DateMainContainer {
  flex: 10;
}

.DateContainer {
  display: flex;
  gap: rem(8px);
  align-items: center;
}

.Date {
  color: var(--ir-neutral-black-100);
}

.VerticalDivider {
  height: rem(20px);
  border-left: 1px solid var(--ir-gray-220);
  margin-top: rem(14px);
  flex: 0.3;
}
