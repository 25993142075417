@import '/src/stylesheets/utils';

.PropertyUnitSpecsContainer {
  display: flex;
  justify-content: space-evenly;
  border-right: rem(1px) solid var(--ir-warm-gray-030);
}

@media (max-width: $screen-mobile-navbar) {
  .PropertyUnitSpecsContainer {
    border-right: none;
  }
}
