@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.navBarWrapper {
  background-color: rgb(255 255 255);
  box-shadow: 2px 0 5px rgb(0 0 0 / 10%);

  .navBarContainer {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1rem;
    width: 100%;

    .agentNavbar {
      width: 100%;
    }

    .navToggler {
      border: none;

      &:focus {
        box-shadow: none;
      }
    }

    .navTogglerMobile {
      display: flex;
      justify-content: space-between;
      padding-left: 2%;
      padding-right: 2%;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .mobileNavBar {
    width: 100%;
  }

  .IntellirentLogo {
    display: flex;
    font-size: rem(24px);
    font-weight: 200;
    line-height: rem(20px);

    .navIntelli {
      color: var(--ir-warm-gray-750);
    }

    .navRent {
      color: var(--ir-segment-blue-650);
    }
  }

  .AgentEmail {
    color: var(--ir-neutral-black-100);
    max-width: rem(300px);
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .DesktopAgentEmail {
    min-width: rem(400px);
    text-align: end;
    color: var(--ir-neutral-black-100);
    padding-bottom: rem(30px);
  }

  .bottomLinksAlignment {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 1rem;
  }

  .linkStyleDiv {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-size: rem(11px);
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .linkDiv {
    display: contents;
  }

  .svgDiv {
    width: rem(60px);
    height: rem(30px);
    color: var(--color-svg-gray-light);

    &.active {
      color: var(--ir-segment-cyan-900);
    }
  }

  .navbarHeading {
    font-size: rem(20px);
    font-weight: 600;
    line-height: rem(27px);
    color: var(--ir-warm-gray-800);
  }

  .navbarSubHeading {
    text-align: left;
    color: var(--ir-neutral-000);
    font-size: rem(14px);
  }

  .horizantalTagDiv {
    margin-top: rem(20px);
    margin-bottom: rem(12px);

    &.horizontalScroll {
      overflow-x: scroll;
    }
  }

  .scrollableDiv {
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-evenly;
    gap: rem(10px);

    &.scrollable {
      width: max-content;
    }
  }

  .tagDiv {
    width: rem(113px);
    padding: rem(4px) rem(8px) rem(4px) rem(8px);
    border: 1px var(--color-svg-gray-dark) solid;
    color: var(--color-svg-gray-dark);
    border-radius: 4px;
    background-color: rgb(255 255 255);
    font-size: rem(14px);
    font-weight: 600;
    text-decoration: none;

    &:focus,
    &.active {
      border: 1px solid var(--ir-gray-1050);
      color: var(--ir-gray-1050);
      background-color: var(--ir-gray-200);
    }
  }

  .navbarAlignment {
    display: flex;
    gap: rem(10px);
  }

  .navbarMenu {
    display: flex;
    gap: rem(16px);
  }

  .organizationIconDiv {
    display: flex;
    flex-direction: row;
    gap: rem(5px);
    align-items: center;
  }

  .navBarRow {
    padding-left: 2%;
    width: 100%;
  }

  .ProfileNotSetRow {
    justify-content: space-between;
  }

  .nameDiv {
    font-size: rem(12px);
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .privateProfileNavbarRow {
    justify-content: space-between;
  }

  .navBarRowCol1 {
    display: flex;
    gap: 2rem;
    justify-content: left;
    padding: unset;
    padding-bottom: 0.5rem;
    padding-left: 4%;
  }

  .navBarRowCol3 {
    display: flex;
    gap: rem(12px);
    justify-content: flex-start;
    padding: unset;
  }

  .ProfileNotSetCol3 {
    justify-content: flex-end !important;
  }

  .helpLink {
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1250px) {
    .navBarRowCol3 {
      gap: 0.2rem;
    }

    .navBarRowCol1 {
      gap: 1rem;
      padding-left: 3%;
    }
  }

  @media (max-width: 1100px) {
    .navBarRowCol3 {
      justify-content: flex-start;
    }

    .navBarRowCol1 {
      padding-left: 0.5%;
    }

    .navBarContainer {
      padding-left: 2%;
      padding-right: 2%;
    }
  }

  @media (max-width: 900px) {
    .navBarRowCol1 {
      padding-left: 0%;
    }

    .navBarContainer {
      padding-left: 0%;
      padding-right: 0%;
    }
  }

  @media (max-width: $screen-mobile-navbar) {
    .navBarContainer,
    .navBarRowCol1,
    .navBarRowCol3 {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;
    }

    .navBarRowCol1,
    .navBarRowCol2 {
      margin-left: 0;
      padding-left: 0;
    }

    .alignIcon {
      text-align: left;
    }

    .hideIcon {
      display: none;
    }

    .navBarContainer {
      position: relative;
    }

    .searchBar {
      position: relative;
      top: 0;
      width: 60%;
    }

    .navBarRowCol3 {
      margin-top: 1.5rem;
    }
  }

  @media (max-width: 425px) {
    .AgentEmail {
      max-width: rem(200px);
    }
  }

  @media (max-width: 375px) {
    .scrollableDiv {
      width: max-content;
    }
  }
}

.navbarHeight {
  height: 10rem;
  background-color: rgb(255 255 255);
}

.optionsDiv {
  text-align: center;
}

.horizantalLineDiv {
  width: 100%;
  color: var(--color-horizantal-line);
  margin-top: 0;

  &.bottomLine {
    margin-bottom: 0;
  }
}

.dropdownToggle {
  display: flex;
  color: #2d3030;
  align-items: center;
  gap: 0.2rem;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-900);
  }

  &:global(.active) {
    color: var(--ir-segment-cyan-900);
  }

  &.active {
    color: var(--ir-segment-cyan-900);
  }
}

.linkContainer,
.linkContainerActive {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 0.2rem;
}

.linkContainerActive {
  color: var(--ir-segment-cyan-900);
  font-weight: 600;
}

.dropDownIcon {
  color: var(--ir-neutral-black-primary);
  margin-bottom: 3px;
}

.SpaceBetween {
  justify-content: space-between;
}

.FlexEnd {
  justify-content: flex-end !important;
}
