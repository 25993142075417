@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.container {
  background-color: var(--ir-neutral-white-bg);
  height: inherit;
  padding-top: rem(100px);
  overflow-y: scroll;
  text-align: center;
  padding-bottom: rem(20px);

  .renterTextContainer {
    width: fit-content;
  }

  .renterCardBody {
    padding-top: rem(75px) !important;
    padding-bottom: rem(70px) !important;
  }
}

@media (min-width: 1800px) {
  .renterTextContainer {
    width: 29%;
    height: auto;
  }
}

.renterSignupCol {
  .renterCardContainer {
    width: 100%;
  }
}

.PasswordField {
  padding-right: 3rem !important;
}

@media (min-width: 992px) {
  .container {
    text-align: left;

    .renterContainerRow {
      gap: 5rem;
    }

    .signupSubText {
      width: rem(350px);
    }
  }
}

@media (max-width: 1115px) {
  .container {
    .renterHomeDesign {
      left: 26%;
    }
  }
}

@media (min-width: $screen-mobile-navbar) {
  .intellirentFooterContainer {
    display: none;
  }
}

@media (max-width: 1128px) {
  .container {
    .renterSignUpTextContainer {
      .firstNameLimiter {
        font-size: rem(24px) !important;
      }
    }
  }
}

@media (max-width: 991px) {
  .renterSignUpTextContainer {
    .firstNameLimiter {
      font-size: rem(21.5px) !important;
    }

    .renterSignUpText {
      text-align: left;
    }
  }
}

@media (max-width: 851px) {
  .renterContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .renterContainerRow {
      gap: 0;
    }

    .renterTextContainer {
      padding: unset;
    }

    .renterSignUpTextContainer {
      .firstNameLimiter {
        font-size: rem(21.5px) !important;
      }
    }
  }
}

.renterSignUpText {
  font-size: rem(28px);
}

.containerRow {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;

  .renterSignUpSubText {
    max-width: rem(365px);
    font-size: rem(14px);
    font-weight: 400;
    text-align: left;
    line-height: rem(20px);
    margin-bottom: 5%;
    min-width: rem(330px);

    .inLineTextWrap {
      white-space: 'nowrap';
      font-weight: 600;
    }
  }
}

.signupCol {
  display: flex;
  justify-content: center;
  position: relative;
  width: 40rem;
}

@media (min-width: 1800px) {
  .signupCol {
    width: 31.6%;
    height: auto;
  }
}

@media (max-width: 575px) {
  .signupCol {
    display: block;
    justify-content: center;
    position: relative;
    padding: 0;
  }
}

.homeDesign {
  position: absolute;
  bottom: 44%;
  left: 43%;
  max-width: 369px;
}

.signupTextContainer {
  font-size: rem(28px);
  font-weight: 600;
  line-height: rem(32px);
  margin-bottom: rem(10px);
}

.signupSubText {
  font-size: rem(16px);
  font-weight: 400;
  line-height: rem(20px);
  margin-bottom: 5%;
  white-space: normal;
}

.cardContainer {
  width: 90%;
  border: none;
  border-radius: rem(24px);
  box-shadow: 0 rem(4px) rem(4px) rgb(0 0 0 / 25%);
}

@media (min-width: 575px) and (max-width: 767px) {
  .signupCol {
    width: 30rem;
  }
}

@media (max-width: 575px) {
  .cardContainer {
    position: relative;
    width: 100%;
    border: none;
    border-radius: 24px;
    box-shadow: rgb(0 0 0 / 4%) 0 3px 5px;
  }
}

@media (max-width: $screen-mobile-navbar) {
  .renterContainerRow {
    gap: unset;
    flex-direction: column;

    .renterSignUpText {
      padding-top: rem(32px);
      padding-bottom: rem(9px);
      margin-top: unset;
    }

    .renterSignUpSubText {
      margin-bottom: rem(29px);
    }

    .renterSignupCol {
      .renterCardContainer {
        flex-direction: column;
        padding-bottom: unset;
      }
    }
  }

  .signupTextContainer {
    margin-top: rem(45px);
  }

  .container {
    padding-top: unset;

    .renterSignUpTextContainer {
      margin-left: 0%;

      .firstNameLimiter {
        font-size: rem(20px);
      }

      .renterSignUpText {
        text-align: center;
      }
    }

    .renterSignupCol {
      margin-right: 0%;
    }
  }

  .intellirentFooterContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: rem(10px);
    margin-bottom: rem(64px);

    .intellirentFooter {
      width: rem(155px);
      height: rem(48px);
      padding: rem(5px) rem(32px);
      background: var(--ir-warm-gray-100);
      text-align: center;
      box-shadow: 0 rem(4px) rem(28px) rgb(15 172 188 / 10%);
      border-radius: rem(4px);

      .navHeading {
        font-size: rem(24px);
        font-weight: 200;

        .navIntelli {
          color: var(--ir-warm-gray-750);
        }

        .navRent {
          color: var(--ir-segment-blue-650);
        }
      }
    }
  }

  .renterSignUpTextContainer {
    .renterSignUpText {
      font-size: rem(24px);
    }

    .renterSignUpSubText {
      min-width: rem(330px);
      width: 100%;
      max-width: unset;
      text-align: center;
    }
  }
}

.privacyPolicyContainer {
  text-align: center;
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  font-weight: 400;
  gap: rem(5px);
  line-height: rem(14px);
  margin-bottom: rem(32px);
  margin-top: rem(32px);
}

.createBtn {
  align-items: center;
  display: flex;
  justify-content: center;
}

.loginLink,
.SignupLink {
  width: fit-content;
  color: var(--ir-segment-cyan-900);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-700);
    opacity: 0.7;
    text-decoration: underline;
  }
}

.SignupLink {
  text-decoration: underline;
  white-space: nowrap;
}

.btnPrimary {
  display: flex;
  width: fit-content;
}

.googleContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.orLine {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;

  .orText {
    margin: 0 6px;
    color: var(--ir-neutral-default-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.line {
  width: stretch;
}

@media (max-width: 356px) {
  .container {
    .signupCol {
      .cardContainer {
        .renterCardBody {
          padding-left: 2rem !important;
          padding-right: 2rem !important;
        }
      }
    }

    .renterSignUpTextContainer {
      .renterSignUpSubText {
        min-width: unset;
      }
    }
  }
}

.FormHeading {
  display: flex;
  text-align: center;
  color: var(--ir-gray-1050);
  justify-content: center;
  font-size: rem(22px);
  font-weight: 600;
  margin-bottom: rem(20px);
}
