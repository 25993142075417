@import '/src/stylesheets/utils';

.noVehicleCard {
  display: flex;
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-gray-000);

  .infoRow {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem(14px);
    display: inline-flex;
    width: 100%;
    padding: rem(8px);
  }

  .infoItem {
    flex: 1 1 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    gap: 0.5rem;
    font-size: rem(16px);
    font-weight: 600;
  }

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }
}
