@import '/src/stylesheets/utils';

.container {
  text-align: start;

  .infoDiv {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    padding-bottom: rem(30px);
  }

  .NoGuarantor{
    padding-left: rem(10px);
    font-size: rem(18px);
    font-weight: 600;
  }

  .tag {
    display: flex;
    background-color: var(--ir-gray-200);
    padding: 0 rem(2px);
    border-radius: 2px;
    font-weight: 600;
    align-items: flex-start;
  }

  .SmallButton {
    display: flex;
    width: fit-content;
    padding: rem(4px) rem(8px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-1050);
  }

  .horizontalLine {
    width: 100%;
    height: rem(1px);
    text-align: center;
    border-bottom: 1px solid var(--ir-gray-93);
    line-height: 0.1em;
    flex: 1; /* Allow this div to grow and fill remaining space */
  }

  .subTitle {
    text-transform: uppercase;
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .section {
    display: flex;
    padding: rem(10px);
    border: 1px solid var(--ir-gray-92);
    border-radius: rem(4px);
  }

  .Info {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  .label {
    font-size: rem(14px);
    font-weight: 600;
    text-transform: capitalize;
    color: var(--ir-warm-gray-060);
  }

  .value {
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }

  .badgeContainer {
    align-items: flex-end;

    .badge {
      display: flex;
      padding: rem(2px) rem(10px);
      align-items: flex-start;
      gap: rem(6px);
      background-color: var(--ir-neutral-green-410);
      color: var(--ir-gray-000);
      font-style: italic;
      border-radius: rem(4px);
    }
  }

  .MessageContainer {
    display: flex;
    padding: rem(12px) rem(5px);
    align-items: center;
    gap: rem(8px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-92);
    background: var(--ir-warm-gray-100);
  }

  .Heading {
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-weight: 600;
    margin-bottom: rem(8px);
  }

  .EmptySectionTitle {
    padding-left: rem(10px);
    font-size: rem(16px);
    font-weight: 600;
  }

  .IncomeSection {
    gap: rem(12px);
    display: flex;
    flex-direction: column;
  }

  .QuestionContainer {
    padding: rem(8px) rem(12px);
    border: 1px solid var(--ir-gray-92);
    color: var(--ir-gray-1050);
    font-size: rem(14px);
    border-radius: rem(4px) 0;
  }

  .ChoiceContainer {
    padding: rem(8px) rem(12px);
    border: 1px solid var(--ir-gray-92);
    border-top: none;
    flex: 10;
  }

  .TitleContainer {
    color: var(--ir-warm-gray-060);
    font-size: rem(10px);
    font-weight: 600;
  }

  .ResponseContainer {
    color: var(--ir-gray-1050);
    font-size: rem(16px);
    font-weight: 600;
  }

  .AnswerContainer {
    display: $webkit-box;
    color: var(--ir-gray-1050);
    background-color: var(--ir-neutral-white-bg);
    font-size: rem(12px);
    font-weight: 600;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    max-height: rem(66px);
    overflow: hidden;
    border-radius: 0 rem(4px);
  }
}

.ApplicationDetails {
  margin-bottom: rem(48px);

  // padding-left: rem(8px);
  padding-right: rem(8px);
}

.PillsSizeContainer {
  font-weight: 600;
  text-transform: uppercase;
  font-size: rem(14px);
}

.BackgroundQuestionContainerMobile {
  flex-direction: column;
}

.BackgroundQuestionContainer {
  display: flex;
  gap: rem(22px);
  margin-bottom: rem(12px);

  &.PillGapContainer {
    gap: rem(12px);
  }
}

.SubmissionContainerMobile {
  align-items: flex-start !important;
}

.SubmissionContainer {
  display: flex;
  justify-content: flex-end;
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  font-style: normal;
  line-height: rem(16px);

  .DateTagMobile {
    display: flex;
  }

  .Date {
    font-weight: 600;
  }
}

@media (max-width: 744px) {
  .SubmissionContainer {
    flex-direction: column;
    gap: rem(10px);
    margin-bottom: rem(30px);
  }
}
