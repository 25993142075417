@import '/src/stylesheets/utils';

.Container {
  display: flex;
  height: auto;
  background: linear-gradient(to bottom, #fff, var(--ir-segment-cyan-500));
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: none;
  justify-content: center;
  padding-bottom: rem(30px);
  -webkit-overflow-scrolling: touch;

  .Heading {
    font-weight: 600;
    font-size: rem(32px);
  }

  .Description {
    color: var(--ir-neutral-black-100);
    font-weight: 600;
    font-size: rem(24px);
  }

  .Categories {
    display: grid;
    margin: rem(50px) calc(20%);
    gap: rem(24px);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(min-content, max-content);

    .ProfileTypeBox {
      padding: rem(24px);
      text-align: start;
      background-color: var(--ir-dark-blue-100);
      box-shadow: 0 4px 8px rgb(0 0 0 / 37.9%);
      border-radius: rem(24px);
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      .ProfileTypeHeading {
        font-weight: 600;
        font-size: rem(28px);
        color: var(--ir-gray-000);
      }

      .ProfileTypeDescription {
        margin-top: rem(20px);
        font-weight: 400;
        font-size: rem(18px);
        color: var(--ir-gray-000);
      }
    }
  }

  @media screen and (max-width: 765px) {
    .Categories {
      grid-template-columns: 1fr;
    }
  }
}

@media screen and (max-width: 765px) {
  .Container {
    padding-bottom: rem(60px);
  }
}

@media screen and (min-width: 1100px) {
  .Container {
    height: 100%;
  }
}

@media screen and (min-height: 1100px) {
  .Container {
    height: 100%;
  }
}

@media screen and (max-height: 800px) {
  .Container {
    justify-content: unset;
  }
}
