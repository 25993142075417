@import './stylesheets/variables';
@import '/src/stylesheets/utils';

.App {
  height: 100%;
  text-align: center;
  font-family: $font-family-open-sans, $font-family-sans-serif;
}

#finicityConnectIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

@media (min-width: 768px) {
  #finicityConnectIframe {
    top: 10px;
    left: 20.5%;
    width: 60%;
    height: 99%;
  }
}

@media (min-width: 1200px) {
  #finicityConnectIframe {
    left: 29.5%;
    width: 41%;
  }
}
