@import '/src/stylesheets/utils';

.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-top: rem(2px) solid rgb(163 176 178 / 50%);
  min-width: fit-content;

  .sideBar {
    position: sticky;
    top: 0;
    width: rem(17px);
    height: 100vh;
    border: rem(1px) solid var(--ir-gray-200);
    background: var(--ir-segment-cyan-300);

    [class='EZDrawer'] {
      position: sticky;
      top: 0;
      height: 100vh;
    }

    [class*='__overlay'] {
      height: 100%;
    }
  }

  .rightArrowIcon {
    position: absolute;
    top: rem(15px);
    right: rem(-8px);
    rotate: 180deg;
    cursor: pointer;
  }

  .leftArrowIcon {
    position: absolute;
    top: rem(15px);
    right: rem(-8px);
    cursor: pointer;
  }

  .drawerClass {
    position: relative;
    width: rem(204px) !important;
    border: rem(1px) solid var(--ir-gray-200);
    background: var(--ir-segment-cyan-300);
    box-shadow: none;
    min-height: 100%;
  }

  .pageContent {
    position: relative;
    width: 100%;
    margin-left: rem(220px);
    margin-right: 5%;
    transition-duration: 500ms;
    text-align: center;
  }

  .pageContentUnselected {
    align-items: center;
    margin-left: 5%;
    transition-duration: 500ms;
  }

  .homeLogo {
    position: absolute;
    top: rem(20px);
    right: rem(80px);
    z-index: -1;
  }

  .pageOverLay {
    margin: unset;
    z-index: -10000;
  }
}

[class^='main'] {
  height: 100%;
  z-index: -1;
}

@media (max-width: 575px) {
  .mainContainer {
    height: unset;

    [class*='_homeLogo'] {
      display: none;
    }

    .drawerClass {
      width: 100%;
    }
  }
}
