@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(40px);
  padding: rem(40px) 0;

  .CreditBackgroundHeader {
    display: flex;
    justify-content: space-between;
  }

  .ReportValidity {
    padding-left: rem(25px);
  }

  .ExperianLogo {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .ExperianText {
    color: var(--ir-neutral-dark-black);
    font-size: rem(32px);
    line-height: rem(36px);
  }

  .CreditIconContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .CreditReportContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ArrowIcon {
    cursor: pointer;
    color: var(--ir-warm-gray-850);
    transition: transform 0.5s ease;
  }

  .Heading {
    padding: rem(3px) rem(5px);
    color: var(--ir-warm-gray-850);
    font-size: rem(24px);
    font-weight: 600;
  }

  .RemainingDays {
    display: flex;
    padding: rem(3px) rem(5px);
  }

  .SpaceProvider {
    flex: 1;
  }

  .PillContainer {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .ValidityDates {
    display: flex;
    gap: rem(5px);
    justify-content: flex-end;
  }

  .DateContainer {
    display: flex;
    padding: rem(3px) rem(5px);
    gap: rem(5px);
    align-items: baseline;
  }

  .Label {
    color: var(--ir-gray-02);
    font-size: rem(11px);
    text-transform: uppercase;
  }

  .Date {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
  }

  .NoDate {
    width: rem(86px);
    color: var(--ir-warm-gray-060);
  }

  .ErrorContainer {
    width: 100%;
    padding: rem(12px) 0 rem(58px) 0;
    min-height: rem(290px);
  }

  .ReportStatusText {
    padding: 0 rem(60px);
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
  }

  .LinkText {
    text-decoration: unset;
    color: var(--ir-segment-cyan-900);
  }

  .ExperianLogoContainer {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
  }

  .ReportButton {
    width: fit-content;
    height: fit-content;
    padding: rem(4px) rem(8px);
  }

  .LogoWithButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ButtonsContainer {
    display: flex;
    gap: rem(10px);
  }
}

@media (max-width: rem(490px)) {
  .Container {
    .CreditBackgroundHeader {
      flex-direction: column;
    }
  }
}

.DocumentItem {
  background-color: #f7fafa;
  padding-left: rem(20px);
  margin-bottom: rem(20px);
  cursor: pointer;
  padding-bottom: rem(8px);
  width: 100%;
}

.DocumentImage {
  width: 90%;
}

.NoSsn {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.NovaPTag {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  margin-bottom: 0%;
}

.outterNovaClass {
  width: 100%;
  padding: 1%;
  border: 1px solid #e6eff0;
  margin-bottom: 0%;
}

.outterNovaClass2 {
  width: 98%;
  padding: 1%;
  border: 1px solid #e6eff0;
  margin-bottom: 0%;
  margin-left: 5px;
}

.outterNovaClass3 {
  width: 94%;
  padding: 1%;
  border: 1px solid #e6eff0;
  margin-bottom: 0%;
  margin-left: 5px;
}

.outterNovaClass4 {
  width: 92%;
  padding: 1.5%;
  border: 1px solid #e6eff0;
  margin-bottom: 0%;
  background-color: #f6fbff;
}

.Other {
  background-color: #e6eff0;
  display: inline;
  padding: 0%;
}

.NovaPartnerDiv {
  display: flex;
}

.NovaPartner {
  font-size: 13px;
  font-weight: 600;
  line-height: 16.34px;
}

.AgentSpacing {
  width: max-content;
  color: #5e6566;
  margin-right: 3px;
  margin-bottom: 0%;
}

.DescriptionOther {
  font-size: 13px;
  font-weight: 600;
  line-height: 16.34px;
  margin-bottom: 1%;
  color: #8d9899;
}

.AgentLearnMore {
  color: #1496a2;
}

.Attachments {
  width: 98%;
}

.TopContainer {
  display: flex;
  width: 90%;
  text-align: start;
  flex-direction: column;
  align-items: flex-end;
}

.SsnLogo {
  margin-right: 1%;
  width: 22px;
}

.InformationIcon {
  margin-right: 1.5%;
  position: relative;
  top: -3px;
}

.InformationText {
  margin-bottom: 0%;
  font-size: 12px;
  font-weight: 600;
  line-height: 16.34px;
  margin-right: 0.5%;
  text-align: start;
}

.LadderContainer {
  display: flex;
  gap: rem(10px);
  margin-bottom: rem(12px);
}

.Ladder {
  display: flex;
  margin-left: 4px;
  width: 10px;
}

.Ladder2 {
  display: flex;
  margin-left: 4px;
  width: 30px;
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-350);
}

.HorizontalLine {
  width: 100%;
  margin-top: 1rem;
  border-top: rem(1px) solid var(--ir-gray-350);
}

.LadderWidth1 {
  width: 98%;
}

.LadderWidth2 {
  width: 98%;
}
