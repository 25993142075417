:root {
  /* --------IR GRAY COLORS START------ */
  // GRAY COLORS
  --ir-gray-900: #111827;
  --ir-gray-800: #1f2937;
  --ir-gray-1000: #30333380;
  --ir-gray-1100: #323232;
  --ir-gray-1050: #303333;
  --ir-gray-700: #374151;
  --ir-gray-750: #3033335e;
  --ir-gray-600: #4b5563;
  --ir-gray-650: #212529af;
  --ir-gray-500: #6b7280;
  --ir-gray-400: #9ca3af;
  --ir-gray-350: #abb9ba;
  --ir-gray-300: #d1d5db;
  --ir-gray-200: #e6eff0;
  --ir-gray-220: #d2d6d6;
  --ir-gray-250: #d9e1e2;
  --ir-gray-100: #f3f4f6;
  --ir-gray-110: #f7f9fa;
  --ir-gray-050: #f9fafb;
  --ir-gray-000: #fff;
  --ir-gray-92: #e1eaeb;
  --ir-gray-93: #d5d6d6;
  --ir-gray-80: #ccc;
  --ir-gray-70: #d8d8d8;
  --ir-gray-60: #fafcfd;
  --ir-gray-02: #5e5666;
  --ir-gray-01: #eceff0;

  /* --------IR GRAY COLORS END------ */

  /* --------IR WARM GRAY COLORS START------ */
  // WARM GRAY COLORS
  --ir-warm-gray-950: #141414;
  --ir-warm-gray-900: #272727;
  --ir-warm-gray-850: #979999;
  --ir-warm-gray-800: #303333;
  --ir-warm-gray-750: #58595b;
  --ir-warm-gray-700: #444;
  --ir-warm-gray-600: #636363;
  --ir-warm-gray-500: #808080;
  --ir-warm-gray-400: #afafaf;
  --ir-warm-gray-350: #a9a9a9;
  --ir-warm-gray-300: #dbdbdb;
  --ir-warm-gray-200: #ebebeb;
  --ir-warm-gray-100: #fff;
  --ir-warm-gray-050: #f5f5f5;
  --ir-warm-gray-040: #e6f8fa;
  --ir-warm-gray-045: #e6e6e6;
  --ir-warm-gray-030: #e1e8eb;
  --ir-warm-gray-035: #e9ecef;
  --ir-warm-gray-060: #8d9899;
  --ir-warm-gray-0650: #8d989980;

  /* --------IR WARM GRAY COLORS END------ */

  /* ------- IR NEUTRAL COLORS START------------- */
  // IR NEUTRAL COLOR
  --ir-neutral-050: #e1e8eb;
  --ir-neutral-040: #e3e3e3;
  --ir-neutral-060: #e0e0e0;
  --ir-neutral-030: #ddd;
  --ir-neutral-020: #aaa;
  --ir-neutral-010: #a4b1b3;
  --ir-neutral-000: #8d9899;
  --ir-neutral-001: #8d9899;
  --ir-neutral-transparent-bg: transparent;
  --ir-neutral-white-bg: #f7fafa;
  --ir-neutral-default-gray: #aebbbd;
  --ir-neutral-default-bg: #888;
  --ir-neutral-states-color: #aebbbd;
  --ir-neutral-states-color-700: #dce5e6;
  --ir-neutral-states-color-500: #c4c4c4;
  --ir-neutral-states-color-400: #e3ebec;
  --ir-loader-backdrop: #f7f7f799;

  /* ------- IR NEUTRAL COLORS END------------- */

  /* ------- IR SEGMENT COLORS START------------- */
  // SEGMENT COLORS

  --ir-segment-blue-900: #1a4298;
  --ir-segment-blue-100: #f6fbff;
  --ir-segment-blue-110: #0d6efd;
  --ir-segment-blue-96: #d7e8f5;
  --ir-segment-blue-800: #3472f7;
  --ir-segment-blue-83: #3c8fd3;
  --ir-segment-blue-700: #3daaed;
  --ir-segment-blue-710: #003c4d;
  --ir-segment-blue-1000: #1496a22c;
  --ir-segment-blue-990: #1496a259;
  --ir-segment-blue-600: #1dc7ea;
  --ir-segment-blue-650: #0facbc;
  --ir-segment-blue-750: #16adbc;
  --ir-segment-cyan-900: #17adbc;
  --ir-segment-cyan-1000: #4eabba;
  --ir-segment-cyan-1050: #11adbc;
  --ir-segment-cyan-950: #b9e6eb;
  --ir-segment-cyan-800: #1ca1b7;
  --ir-segment-cyan-light: #8ad5dd;
  --ir-segment-cyan-700: #1496a2;
  --ir-segment-cyan-600: #90d1db;
  --ir-segment-cyan-500: #c7e8ed;
  --ir-segment-cyan-300: #f6f9fa;
  --ir-segment-cyan-400: #fbfdfe;
  --ir-segment-teal-primary-95: #d1eff2;
  --ir-segment-yellow-100: #fae8be;
  --ir-dark-gray-1000: #4d4d4d;

  /* ------- IR SEGMENT COLORS END------------- */

  /* ------- IR SEMENTIC COLORS START------------- */
  // SEMANTIC COLORS
  --ir-semantics-success: #0da931;
  --ir-semantics-inactive: #dc950e;
  --ir-semantics-warning-dark: #f3cc78;
  --ir-semantics-warning: #f7971c;
  --ir-semantics-error: #f3163e;
  --ir-semantics-disabled: #d6d6d6;
  --ir-semantics-danger: #ff4a55;
  --ir-field-error: #ef476f;
  --ir-field-error-background: #fff8f9;
  --ir-danger-pill: #fcdbe3;
  --ir-danger-pill-text: #f00;
  --ir-success-pill: #8bd99b;
  --ir-success-pill-text: #fcdbe3;
  --ir-warning-pill: #fffbf2;
  --ir-warning-pill-text: #eea700;
  --ir-warning-pill-text-50: #ffd16680;
  --ir-warning-pill-status-text: #ffd166;
  --ir-success-light-pill: #d0f5e0;
  --ir-success-light-pill-text: #00ac47;

  /* ------- IR SEMENTIC COLORS END------------- */
  // TODO: Adjust color names when added to color scheme
  // Only used for input component right
  --color-svg-gray-dark: #838585;
  --color-svg-gray-light: #a3b0b2;
  --color-horizantal-line: #dde4e8;
  --color-dark-green: #60e69a;
  --color-bright-green: #8bd99b;
  --color-white: #fff;
  --color-neutral-light-gray: #ccd6de;
  --color-neutral-cream-white: #f8f9fc;
  --color-neutral-cream-white-100: #e6f8fa9d;
  --color-neutral-light-blue-gray: #adb8bf;
  --ir-neutral-disable-color: #666;
  --ir-neutral-black-primary: #5e6566;
  --ir-primary-primary-93: #caebee;
  --ir-neutral-black-half: #bdc6c7;
  --ir-black: #000;
  --ir-neutral-dark-black: #2d3030;
  --ir-neutral-dark-black-90: #636a6b;
  --ir-neutral-black: #252728;
  --ir-neutral-black-100: #303333;
  --ir-neutral-black-200: #e1eaeb77;
  --ir-neutral-black-300: #cecece77;
  --ir-neutral-black-10: #898c8c;
  --ir-neutral-black-05: #bdc5d7;
  --ir-neutral-black-00: #a4a4a4;
  --ir-transparent-bg: transparent;
  --ir--success-progress: #54bdc7;
  --ir--neutral-green-400: #60e69a;
  --ir-neutral-green-410: #88d99b;
  --ir--neutral-green-200: #8bd99b;
  --ir--neutral-sea-blue-400: #33adbf;
  --ir--neutral-sea-blue-500: #3a8ed3;
  --ir--neutral-sea-blue-100: #87c7fb;
  --ir--neutral-sea-blue-150: #d6e8f5;
  --ir-stripe-button-purple: #7d28f7;
  --ir-neutral-pink-100: #fcdbe3;
  --ir-neutral-yellow-500: #eea700;
  --ir-neutral-yellow-50: #fffbf2;
  --ir-neutral-green-500: #00ac47;
  --ir-neutral-green-510: #219653;
  --ir-neutral-green-50: #d0f5e0;
  --ir-dark-blue-100: #0a2540;

  /* ------- IR MODAL COLORS START------------- */
  --ir--modal-backdrop: #808080;

  /* ------- IR MODAL COLORS END------------- */
}
