@import '/src/stylesheets/utils';

.PropertyCard {
  padding: rem(5px);
  border: none;
}

.Gradient {
  height: rem(10px);
  background: linear-gradient(180deg, var(--ir-warm-gray-030) 0%, rgb(225 232 235 / 0%) 100%);
}

.LabelValueContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: baseline;
}

.Label {
  flex: 1;
  text-align: start;
}

.Value {
  flex: 1;
  text-align: start;
}

.PropertyTitle {
  text-align: start;
}

.PropertyAddress {
  font-weight: 400;
  display: flex;
  gap: rem(5px);
  align-items: flex-start;
  line-height: rem(14px);
  margin-top: rem(3px);
}
