@import '/src/stylesheets/utils';

.popoverBody {
  display: flex;
  width: rem(236px);
  padding: rem(8px) rem(12px);
  flex-direction: column;
  gap: 1rem;

  .buttonWithIcon {
    display: flex;
    align-items: center;
    gap: rem(4px);
    color: var(--ir-gray-1050);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px);
  }
}

.HelpLink {
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}
