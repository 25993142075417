@import '/src/stylesheets/utils';

.Container {
  .BankruptcyContainer {
    margin-top: rem(24px);
    text-align: start;
  }

  .Heading {
    padding: rem(3px) rem(5px);
    color: var(--ir-segment-cyan-900);
    font-size: rem(16px);
    font-weight: 600;
  }
}
