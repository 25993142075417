@import '/src/stylesheets/utils';

.container {
  position: relative;
  color: var(--ir-neutral-000);
  margin-top: rem(5px);

  .label {
    position: absolute;
    left: 1rem;
    z-index: 1;
    opacity: 0.7;
    font-size: 0.8rem;
    font-weight: 700;
  }

  .customCountryCode {
    position: absolute;
    top: rem(15px);
    left: rem(14px);
    z-index: 1;
    color: var(--ir-gray-500);

    &.fieldColor {
      color: var(--ir-neutral-black-100);
    }
  }

  [class*='react-tel-input'] [class*='country-list'] [class*='search'] {
    z-index: 1;
    position: sticky;
    top: 0;
    padding: rem(4px) 0 rem(1px) 0;
    text-align: center;
  }

  [class*='country-list'] [class*='search'] [class*='search-emoji'] {
    display: revert;
  }

  [class*='country-list'] {
    height: 10rem;
    padding: 0;
  }

  [class*='form-control'] {
    padding-top: rem(30px);
  }

  [class*='flag'] {
    margin-top: rem(-2px) !important;
  }

  [class*='open'],
  [class*='selected-flag'],
  [class*='react-tel-input'],
  [class*='selected-flag']:hover,
  [class*='flag-dropdown'] {
    border: none;
    background: transparent !important;
    background-color: transparent;
  }

  [class*='react-tel-input'] {
    background-color: var(--ir-neutral-white-bg) !important;
    border-radius: rem(4px);
    font-size: rem(14px);
  }

  :global(.country) {
    padding: rem(10px) 0 0 rem(44px) !important;
  }

  [class*='form-control']:focus,
  [class*='form-control'],
  [class*='form-control']:hover {
    background: var(--ir-neutral-white-bg) !important;
    border-color: transparent;
    border-radius: 0 0 rem(4px) rem(4px);
    box-shadow: 0 0 0 1px transparent;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    line-height: rem(20px);
    color: var(--ir-neutral-black-100);
  }

  [class*='special-label'] {
    top: rem(6px);
    left: rem(6px);
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-family: 'Open Sans', sans-serif;
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    background-color: transparent;
  }

  [class*='flag'] [class*='arrow'] {
    border-top: rem(4px) solid var(--ir-neutral-000);
  }

  [class='dial-code'] {
    width: 2rem;
  }

  [class='country'],
  [class='country_highlight'],
  [class*='preferred'],
  [class='country highlight'] {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    text-align: initial;
    justify-content: flex-end;
  }

  [class*='react-tel-input'] [class*='form-control'] {
    width: 100%;
    height: 50px;
    font-size: 1rem;
    border: 1px solid var(--ir-gray-200);
    background-color: var(--ir-neutral-white-bg);
    border-radius: 4px;
    padding-top: 1.4rem;

    &.invalid {
      background-color: var(--ir-field-error-backgrounds);
      border: 1px solid var(--ir-field-error);

      &:hover {
        border: 1px solid var(--ir-field-error);
      }

      &:focus {
        border: 1px solid var(--ir-field-error);
      }
    }
  }

  [class*='react-tel-input'] [class*='form-control']:focus {
    border: 1px solid var(--ir-gray-200);
    box-shadow: none;
    outline: none;
  }

  [class*='react-tel-input'] [class*='form-control']:hover {
    border: 1px solid var(--ir-gray-200);
  }
}
