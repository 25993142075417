@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(25px) rem(40px);
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border: 1px solid var(--ir-segment-cyan-300);

  .Heading {
    color: var(--ir-neutral-black-100);
    font-size: rem(24px);
    font-weight: 600;
    margin-bottom: rem(10px);
  }

  .TextContainer {
    width: 100%;
    text-align: center;
    color: var(--ir-neutral-black-100);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    align-self: stretch;
    margin-bottom: rem(30px);
  }

  .HighlightedText {
    font-weight: 600;
  }

  .ButtonClass {
    margin-top: rem(30px);
    font-size: rem(18px);
    font-weight: 600;
    align-items: center;
  }

  .LoadingIcon {
    margin-top: rem(-4px);
    animation: spin 15s linear infinite; /* Apply the spin animation */
  }

  .FooterText {
    padding: rem(12px) rem(0);
    text-align: center;
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
  }

  .FooterHighlightedText {
    padding: unset;
    border: unset;
    color: var(--ir-segment-cyan-900);
    font-size: rem(14px);
    font-weight: 600;
    box-shadow: unset;

    &:focus-visible {
      outline: none;
    }
  }

  .ErrorText {
    margin-top: rem(10px);
    color: var(--ir-field-error);
    font-size: rem(14px);
    font-weight: 600;
    align-items: baseline;
  }

  .ErrorIcon {
    margin-top: rem(-5px);
  }

  .BlurText {
    opacity: 0.5;
  }

  .OTPContainer {
    position: relative;
  }

  .TooltipContainer {
    margin-top: rem(35px);
  }

  .Tooltip {
    font-size: rem(14px);
    top: rem(-55px);
    left: 7%;
    width: rem(261px);
  }

  .WarningIcon {
    margin: unset;
    padding: unset;
    border: unset !important;
    background-color: unset !important;
  }

  [class*='tooltip'] > :last-child {
    background-color: var(--ir-warning-pill-text-50);
    border: 1px solid var(--ir-warning-pill-text);
    color: var(--ir-warm-gray-750);
    font-weight: 600;
    white-space: nowrap;
    max-width: 100%;
  }

  [class*='tooltip-arrow'] {
    bottom: -1px;
    left: 48%;
  }

  [class*='tooltip'] > :nth-last-child(2)::before {
    border-top-color: var(--ir-warning-pill-text-50) !important;
  }

  @keyframes spin {
    0% {
      transform: rotate(3600deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
