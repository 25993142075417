.Container {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.WarningContainer {
  color: #303333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.DescriptionContainer {
  color: #636a6b;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &.Dark {
    color: #303333;
  }
}

.TitleContainer {
  color: #303333;

  /* Report/Data Lrg */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ApplicantContainer {
  display: flex;
  justify-content: space-between;
}

.ApplicantLabelContainer {
  color: var(--Gray-2, #5e5666);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
}

.ApplicantValueContainer {
  color: #303333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ApplicantInfoContainer {
  display: flex;
  flex-direction: column;
}

.ApplicantInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.FooterContainer {
  text-align: center;
  color: #303333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
