@import '/src/stylesheets/utils';

.dateContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 3.6rem;
  border: 1px solid var(--ir-gray-200);
  background: #fff;
  border-radius: 4px;
  margin-top: 5px;

  :global(.react-datepicker__header) {
    background-color: #fff;
    border: unset;
  }

  :global(.react-datepicker__day-names .react-datepicker__day-name) {
    color: var(--ir-neutral-black-primary);
    text-transform: uppercase;
  }

  :global(.react-datepicker__day) {
    width: 23px;
    text-align: center;
    color: var(--ir-neutral-black-primary);
    font-size: 16px;
    font-weight: 400;
    margin-left: 5px;
    margin-right: 5px;

    &:focus-visible {
      outline: none;
    }
  }

  :global(.react-datepicker__day--selected) {
    background-color: var(--ir-warm-gray-030);
  }

  :global(.react-datepicker__day--disabled) {
    color: var(--ir-warm-gray-300);
  }

  .customCalendarIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  .presentTextContainer {
    margin-top: rem(17px);
    font-size: rem(14px);
    font-weight: 400;
    color: var(--ir-gray-1050);
  }

  .datePickerContainer {
    margin-left: 0.6rem;
    margin-top: 1rem;
    display: flex;

    :global(.react-datepicker__month) {
      padding-bottom: 3rem;
    }

    .popperCustomClass {
      margin-left: -1.3rem;
      z-index: 999;
    }

    .customCalendarIconLeft {
      margin-left: rem(8.9px);
      margin-right: rem(9.93px);
      margin-top: rem(2px);
      cursor: pointer;
    }
  }

  .label {
    position: absolute;
    left: 0.8rem;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }

  .iconLeftLabel {
    left: rem(16px);
    color: var(--ir-neutral-010);
    font-weight: 600;
    font-size: rem(10px);
  }

  .removeBorder {
    background-color: inherit;
    border: none;
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(20px);
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;

    &::placeholder {
      font-weight: 400;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .headerContainer {
    align-items: center;
    display: flex;
    margin: unset;
    padding: 8px 6px;

    .customButton {
      width: 24px;
      height: 24px;
      padding: unset;
      border: none;
      background: var(--ir-warm-gray-030);
      opacity: 1;
      border-radius: 4px;
      font-weight: 700;
    }

    .monthSelect,
    .yearSelect {
      margin: 0 2px;
      padding: 4px 0 4px 8px;
      border: 1px solid var(--ir-warm-gray-800);
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
    }

    .customSelect::-ms-expand {
      display: none;
    }

    .customSelect {
      appearance: none;
      background-image: url('../../../assets/svgs/downArrowIcon.svg');
      background-position: right 0.5em center;
      background-repeat: no-repeat;
      background-size: 12px 7.41px;
    }

    .monthSelect {
      width: 107px;
    }

    .yearSelect {
      margin-left: 8px;
      width: 65px;
    }
  }

  .iconRotate {
    transform: rotate(180deg);
  }

  .footerContainer {
    position: absolute;
    bottom: 0.5rem;

    .horizontalLine {
      border-top: 1px solid var(--ir-warm-gray-350);
      margin-bottom: 5px;
      margin-left: -3px;
      width: 234px;
    }

    .customFooter {
      display: flex;
      gap: 8.5rem;

      .buttonReset {
        padding: unset;
        border: none;
        background: var(--ir-warm-gray-030);
        color: var(--ir-warm-gray-800);
        opacity: 1;
        border-radius: 4px;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
        margin-left: 0.4rem;
      }

      .buttonOk {
        padding: unset;
        border: none;
        background: none;
        color: var(--ir-warm-gray-800);
        opacity: 1;
        box-shadow: none;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.customStorybookClass {
  border-radius: 4px;
  width: 255px;
}

.dateError {
  background-color: var(--ir-field-error-background);
  border: 1px solid var(--ir-field-error);
}

.marginTop {
  min-height: 20px;
}
