@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(45px) rem(20px);
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0 0 rem(25px) rem(25px);
  background: linear-gradient(0deg, #000 0%, #21474d 100%);

  .Heading {
    align-self: stretch;
    color: var(--ir-gray-01);
    font-size: rem(24px);
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.72px;
  }
}
