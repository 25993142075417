@import '/src/stylesheets/utils';

.ReactSelect {
  [class*='-control'] [class*='-ValueContainer'] {
    min-height: unset;
  }

  [class*='-control'] {
    min-height: rem(25px);
    border: rem(1px) solid var(--ir-warm-gray-800);

    :last-child {
      max-height: 2.2rem;
      align-items: center;
    }
  }

  [class*='-menu'] {
    width: max-content !important;
    text-align: left;

    [class*='-option'] {
      padding: rem(1px) rem(8px) !important;
      cursor: pointer;
    }
  }

  [class*='-control']:last-child {
    [class*='-indicatorContainer'] {
      height: 2.2rem;

      svg {
        color: var(--ir-warm-gray-800);
        align-self: center;
      }
    }
  }

  [class*='-placeholder'] {
    font-size: rem(14px);
    font-weight: 600;
    color: var(--ir-warm-gray-800);
  }
}

.OptionDisabledContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: rem(120px);
  padding: 0 rem(8px);
}

.CheckIcon {
  opacity: 0.5;
}

.OptionDisabled {
  opacity: 0.5;

  &:hover {
    background: none;
  }
}

.DropdownDummyOption {
  font-size: rem(14px);
  font-weight: 600;
  padding: rem(1px) rem(8px);
  color: var(--ir-warm-gray-060);
}

.DropdownOptionLabel {
  font-size: rem(16px);
  font-weight: 600;
  color: var(--ir-warm-gray-800);
}
