@import '/src/stylesheets/utils';

.Container {
  .AssociatedApplication {
    display: flex;
    position: relative;
    flex: 1;
  }

  .VerticalLine {
    border-right: 1px solid var(--ir-neutral-states-color-400);
  }

  .Application {
    flex: 1;
  }

  .Overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 80%);
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
}
