@import '/src/stylesheets/utils';

// 72 is renter Navbar height

.renterContainer {
  position: relative;
  height: calc(100% - 5rem);
  overflow: hidden;

  // overflow-y: auto;

  [class*='Card_agentProfileContainer'] {
    min-height: calc(100vh - 153.5px);
    overflow-x: hidden;

    // overflow-y: auto;
    display: grid;
    z-index: 10;
  }

  [class*='_datePickerContainer'] {
    [class*='_calendarContainer'] {
      position: relative;
    }
  }

  [class*='_containerRow'] {
    position: relative;
    min-height: calc(100% - rem(75px));
    z-index: 1;
  }

  [class*='_card'] {
    box-shadow: 0 rem(-4px) rem(32px) rgb(15 172 188 / 10%) !important;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 175px);
  }

  .renterAddButton {
    width: fit-content;
    height: rem(24px);
    padding: rem(0) rem(5px);
    font-size: rem(12px);
  }

  .renterContainerRightArrow {
    position: absolute;
    top: 1rem;
    right: 10rem;
    color: var(--ir-segment-blue-1000);
    z-index: -2;
  }

  .renterContainerLeftArrow {
    position: fixed;
    bottom: -10rem;
    left: -10rem;
  }

  .propertyInformation {
    display: none;
  }
}

.renterButtonContainer {
  text-align: right;
  margin-bottom: rem(14px);

  .dullButton {
    width: fit-content;
    border-color: var(--ir-neutral-010);
    color: var(--ir-neutral-010);
  }

  .nextButton {
    width: fit-content;
  }
}

.Container {
  display: flex;
  height: 100%;
  padding: 0 rem(20px);
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;

  .Greeting {
    text-align: left;
    color: var(--ir-gray-700);
    font-size: rem(18px);
    font-weight: 400;
  }

  .GreetingContainer {
    width: 100%;
  }

  .ButtonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .viewRenterCriteriaBtn {
    float: right;
    width: fit-content;
    height: 2rem;
    padding: 0 1rem;
    margin-top: rem(24px);
    margin-bottom: rem(20px);
  }

  .continueBtn {
    align-self: flex-end;
    border: 1px solid var(--ir-segment-cyan-700) !important;
    color: var(--ir-segment-cyan-700) !important;
    font-weight: 600;
  }
}

.Header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: rem(25px);
}

.renterCriteriaDoc {
  display: flex;
  overflow-x: hidden;
  height: 100%;
  flex-direction: column;

  .renterCriteriaHeader {
    align-items: baseline;
    margin-top: rem(20px);

    .actionButtons {
      border: none;
      background: none;
      box-shadow: none;
    }

    .h1 {
      font-size: rem(16px);
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .MediumText {
    margin-top: rem(20px);
    font-weight: 500;
    font-size: rem(14px);
  }

  .OrganizationName {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: rem(16px);
    font-weight: 600;
    padding: rem(10px) 0;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
  }

  .viewRenterCriteriaBtn {
    float: right;
    width: fit-content;
    height: 2rem;
    padding: 0 1rem;
    margin-top: rem(24px);
    margin-bottom: rem(20px);
  }

  .continueBtn {
    align-self: flex-end;
    border: 1px solid var(--ir-segment-cyan-700) !important;
    color: var(--ir-segment-cyan-700) !important;
    font-weight: 600;
  }
}

@media (max-width: 744px) {
  .renterContainer {
    [class*='Card_agentProfileContainer'] {
      height: 100%;
      padding: 0 !important;
      background: linear-gradient(to right, rgb(247 250 250 / 52.3%) 4%, rgb(84 189 199 / 50%) 117%);
      box-shadow: none;
      align-items: self-end;
    }

    [class*='_card'] {
      border-radius: rem(45px) rem(45px) 0 0 !important;
      padding: 0;
      flex-direction: column;
      max-height: none;
    }

    [class*='_containerRow'] {
      // height: 80%;
      background-color: var(--ir-warm-gray-100);
      border-radius: rem(45px) (45px) 0 0 !important;
    }

    .renterContainerLeftArrow {
      display: none;
    }

    .renterContainerRightArrow {
      top: -1.5rem;
      right: -9rem;
      color: var(--ir-segment-blue-990);
    }

    .propertyInformation {
      display: flex;
      position: absolute;
      top: rem(-50px) !important;
      z-index: -1;
      justify-content: center;
      width: 100%;
    }
  }
}

@media (max-width: 375px) {
  .renterContainer {
    .propertyInformation {
      display: flex;
      position: absolute;
      top: rem(-50px) !important;
      z-index: -1;
      justify-content: center;
      width: 100%;
    }
  }
}

.renterBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;

  .questionLabel {
    display: flex;
    text-align: start;
    align-items: flex-start;

    .textContainer {
      font-weight: 500;
      font-size: rem(18px);
      color: va(--ir-gray-1050);
    }
  }

  .renterCircularProgressBarContainer {
    margin-bottom: rem(48px);
    margin-top: rem(24px);
    display: flex;
    justify-content: space-between;

    .placeholderContainer {
      margin-top: rem(-10px);

      [class*='-control'] {
        width: fit-content;
        height: rem(32px);
      }

      [class*='-placeholder'] {
        margin-top: rem(-10px);
        font-size: rem(16px);
        font-weight: 600;
        color: var(--ir-neutral-default-gray);
      }

      [class*='-indicatorContainer'] {
        margin-top: rem(-10px);
        padding-left: unset;
      }
    }
  }

  .renterContentContainer {
    text-align: start;

    .countryContainer {
      display: flex;
      flex-wrap: wrap;
      gap: rem(24px);
      margin-bottom: rem(24px);
    }

    .nonRefundableContainer {
      display: flex;
      margin: rem(24px) 0;
      padding: rem(8px);
      border: 1px solid var(--ir-gray-200);
      color: var(--ir-warm-gray-800);
      justify-content: space-between;
      font-size: rem(12px);
      font-style: normal;
      font-weight: 600;
      border-radius: rem(4px);
      align-items: center;

      .nonRefundableFee {
        padding: rem(4px);
        background: var(--ir-warm-gray-040);
        color: var(--ir-segment-cyan-700);
        border-radius: rem(4px);
      }
    }
  }

  .renterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .renterPrimaryButton {
    width: fit-content !important;
  }
}

.looksGoodButton {
  display: flex;
  justify-content: flex-end;
}

.renterIconsContainer {
  margin-top: rem(15px);
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1rem;

  .imageSize {
    width: rem(48px) !important;
    height: rem(48px) !important;
    cursor: default;
  }
}

.renterH2 {
  width: 90%;
  color: var(--ir-neutral-black-100);
  font-weight: 500;
  font-size: rem(18px);
  margin-bottom: 2rem;
}

.highlightedText {
  color: var(--ir-segment-cyan-700);
}

.renterSpan {
  color: var(--ir-segment-cyan-700);
}

.selectedPropertyContainer {
  width: 100%;
  padding: 1rem;
  background-color: var(--ir-warm-gray-100);
  text-align: end;
  padding-top: 0.5rem;

  .selectedPropertyBtnPadding {
    width: fit-content;
    padding: 0.4rem;
  }

  .propertyBtnColor {
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
  }
}

.propertyInviteViewButton {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: rem(16px);

  .selectedPropertyContainer {
    width: 98%;
    margin: 0;
    padding: 0;
    box-shadow: none;
  }

  .continueWithRenterCriteriaBtn {
    margin-top: 0 !important;
  }
}

@media (max-width: 744px) {
  .selectedPropertyContainer {
    bottom: 0;
    padding-bottom: 0;
    padding-right: rem(30px);

    .selectedPropertyBtnPadding {
      padding: 0.4rem;
    }
  }
}

@media (min-width: 550px) {
  .selectedPropertyContainer {
    padding-right: 1.2rem;
    margin-top: 1rem;
    box-shadow: none;
  }
}

.renterInvitePropertyContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
  flex-direction: column;
  padding-right: rem(15px);

  .selectedPropertyContainer {
    margin-top: 1rem;
    padding-right: 0;

    button {
      margin-right: 0;
    }
  }
}

.renterInvitePropertyH2 {
  width: 100%;
  text-align: left;
  color: var(--ir-neutral-black-100);
  font-weight: 500;
  font-size: rem(18px);
  margin-bottom: 0.6rem;
}

.renterInvitePropertyH2Width {
  display: none;
  width: 60%;
  margin-top: 1rem;
}

.viewRenterCriteriaBtn {
  float: right;
  width: fit-content;
  height: 2rem;
  padding: 0 1rem;
  margin-top: rem(24px);
  margin-bottom: rem(20px);
}

.MoveInSecondLine {
  padding: unset;
}

.continueBtn {
  align-self: flex-end;
  border: 1px solid var(--ir-segment-cyan-700) !important;
  color: var(--ir-segment-cyan-700) !important;
  font-weight: 600;
}

@media (max-width: 744px) {
  // .renterInvitePropertyContainer {
  //   justify-content: flex-end;
  //   padding-inline: rem(24px);

  //   .selectedPropertyContainer {
  //     padding-right: 0;
  //   }

  //   .renterInvitePropertyH2Width {
  //     display: block;
  //   }
  // }

  // .renterCriteriaDoc {
  //   .documentRow {
  //     padding: 1rem 3rem;
  //   }
  // }
}

@media (max-width: 744px) {
  // .renterContainer {
  //   // height: calc(100% - 5rem);
  // }

  .renterBody {
    padding: rem(0) rem(12px) 0 rem(24px);
  }

  .renterCriteriaDoc {
    .documentRow {
      height: 70vh;
    }
  }
}
