@import '/src/stylesheets/utils';

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: rem(5px);

  .confirmationHeading {
    width: 75%;
    text-align: left;
    color: var(--ir-neutral-black-100);
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: 0.6rem;
  }

  .floatingText {
    font-size: rem(10px);
    font-weight: 600;
    text-align: left;
    color: var(--ir-neutral-010);
  }

  .renterAddButton {
    width: fit-content;
    height: rem(24px);
    padding: rem(0) rem(5px) !important;
    font-size: rem(12px) !important;
  }

  .countDiv {
    border-radius: rem(4px);
    padding: rem(8px);
    border: 1px solid var(--ir-gray-200);
    text-align: left;
  }

  .editButton {
    width: rem(18px);
    height: rem(18px);
    margin-right: rem(10px);
  }

  .infoDiv {
    padding-left: rem(36px);
    padding-right: rem(52px);
  }

  .valueText {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-warm-gray-800);
    word-break: break-all;
  }

  .labelText {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-000);
  }

  .infoContainer {
    display: flex;
    flex-direction: row;
  }

  .renterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
  }

  .adultCountDiv {
    display: flex;
    justify-content: space-between;
  }

  .emailText {
    font-weight: 600;
    font-size: rem(16px);
    color: var(--ir-neutral-black-100);
    padding-bottom: rem(10px);
    word-break: break-all;
  }

  .inviteInfoContainer {
    display: flex;
    padding: rem(8px) rem(10px) rem(8px) rem(8px);
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
    flex-direction: row;
    align-items: flex-start;
    gap: rem(10px);
    border-radius: rem(4px);
  }

  .inviteInfo {
    display: flex;
    text-align: left;
    color: var(--ir-neutral-black-primary);
    flex-direction: row;
    font-weight: 600;
    font-size: rem(10px);
    align-items: baseline;
  }

  .infoIconDiv {
    margin-right: rem(10px);
  }

  .inviteTextDiv {
    width: 100%;
  }

  .adultListDiv {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
  }
}

@media (max-width: 745px) {
  .container {
    padding: 0 rem(12px);
  }
}
