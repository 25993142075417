@import '/src/stylesheets/utils';

.container {
  .titleSubtext {
    display: flex;
    padding-left: rem(25px);
    line-height: rem(14px);
    font-size: rem(12px);
    color: var(--ir-gray-1050);
    margin-bottom: rem(8px);
  }

  .OccupantsCount {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    font-weight: 600;
  }

  .lighterText {
    color: var(--ir-neutral-010);
  }
}
