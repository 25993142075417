@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.outletDiv {
  height: calc(100vh - 73px);
  overflow: scroll;
}

.ProgressBar {
  [class^='progress-bar'] {
    background-color: var(--ir-segment-cyan-900) !important;
  }
}

@media (max-width: $screen-mobile-navbar) {
  [class^='main'] {
    overflow: hidden;
  }

  .mobileNavBarDiv {
    height: 5rem;
  }

  .topNavbar {
    height: rem(60px);

    &.extraMarginDiv {
      margin-top: 11.5rem;
      height: unset;
    }
  }

  .outletDiv {
    height: 94vh;
    overflow: scroll;

    &.BottomNavbar {
      height: calc(100% - 10rem);
    }

    &.extraHeightDiv {
      height: 73.5vh;
    }

    &.ResumeOutletDiv {
      height: 100%;
    }
  }
}
