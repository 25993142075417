@import '/src/stylesheets/utils';

.Container {
  display: flex;
  margin: rem(8px) rem(0);
  padding: rem(8px) rem(10px) rem(8px) rem(8px);
  border: 1px solid var(--ir-segment-yellow-100);
  background: var(--ir-warning-pill);
  align-items: flex-start;
  gap: rem(10px);
  align-self: stretch;
  border-radius: rem(4px);

  .InfoIcon {
    min-width: rem(20px);
    min-height: rem(20px);
    padding: rem(2px);
  }

  .Text {
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-weight: 600;
  }
}
