@import '/src/stylesheets/utils';

.renterButtonContainer {
  text-align: right;
  margin-top: rem(24px);

  .renterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700) !important;
    text-align: start;
    color: var(--ir-segment-cyan-700) !important;
    font-weight: 600;
    font-size: rem(16px);
  }
}

.heading {
  font-size: rem(24px);
  font-weight: 700;
  text-align: center;
}

.subHeading {
  font-size: rem(18px);
  font-weight: 500;
  text-align: center;
}

.spanColor {
  color: var(--ir-segment-cyan-700);
}
