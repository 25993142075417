@import '/src/stylesheets/utils';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: $font-family-open-sans, $font-family-sans-serif;
  font-style: normal;
  color: var(--ir-gray-1050);
  gap: rem(16px);

  .heading {
    text-align: center;
    color: var(--ir-gray-1050);
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .subHeading1 {
    font-weight: 600;
    font-size: rem(18px);
    line-height: rem(24px);
    text-align: center;
  }

  .subHeading2 {
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(24px);
  }

  .buttonsContainer {
    display: flex;
    gap: rem(40px);
    margin: rem(30px) 0;
  }
}

.contentClassName {
  border: none;
  border-radius: rem(24px);
}

.ActionButton {
  padding: rem(8px) rem(15px);
}
