@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  gap: rem(24px);

  .heading {
    color: var(--ir-warm-gray-800);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 400;
    line-height: rem(24px);

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .information {
    display: flex;
    padding: rem(8px) rem(10px) rem(8px) rem(8px);
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
    border-radius: rem(4px);
    gap: 10px;

    .informationText {
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-style: normal;
      line-height: rem(14px);
      font-weight: 600;

      span {
        font-weight: 700;
      }

      .linkButton {
        padding: 0;
        border: none;
        background: transparent;
        color: var(--ir-stripe-button-purple);
        font-size: rem(10px);
        font-style: normal;
        font-weight: 600;
        line-height: rem(14px);
        text-decoration: none;
      }
    }
  }

  .uploadInformation {
    display: flex;
    position: relative;
    padding: rem(16px) 0;
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: rem(4px);
    box-shadow: 0 rem(-4px) rem(32px) 0 rgb(15 172 188 / 10%);

    .button {
      position: absolute;
      top: 15rem;

      .buttonText {
        display: flex;
        align-items: center;
        gap: rem(5px);
      }
    }
  }
}

.AcceptedContainer {
  margin: rem(8px) 0;
  padding: rem(8px) rem(5px);
  border: rem(1px) solid var(--ir-neutral-states-color-700);
  border-radius: rem(8px);
  justify-content: start;
  row-gap: rem(1px);

  .AcceptedText {
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
    font-size: rem(14px);
    margin-bottom: rem(8px);
  }

  .AcceptedItemContainer {
    .AcceptedItem {
      display: inline-flex;
      float: left;
      align-items: center;
      gap: rem(5px);

      .Icon {
        width: rem(14px);
        height: rem(20px);
      }

      .AcceptedItemTitle {
        padding-top: rem(1px);
        font-size: rem(12px);
        font-weight: 600;
        color: var(--ir-neutral-black-primary);
      }
    }
  }
}
