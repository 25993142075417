@import '/src/stylesheets/utils';

.AnimalHeader {
  display: flex;
  padding: rem(8px);
  align-items: center;
  border-radius: rem(4px);
  border: 1px solid var(--ir-gray-220);
  background: var(--Neutral-White, var(--ir-gray-000));
  justify-content: space-between;
  margin-top: rem(14px);

  .HeaderContent {
    display: flex;
    gap: rem(8px);
  }

  .HeaderText {
    color: var(--ir-warm-gray-800);
    font-weight: 600;
  }

  .IconButton {
    margin: unset;
    padding: unset;
    border: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }
}
