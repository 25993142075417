@import '/src/stylesheets/utils';

.Container {
  .priorAddressContainer {
    .renterH2Color {
      color: var(--ir-segment-cyan-700);
    }

    .addresBoxContainer {
      margin-bottom: rem(8px);
    }

    .errorDatePickerContainer {
      margin-bottom: rem(34px) !important ;

      [class*='_dateContainer'] {
        height: rem(45px);
      }
    }

    .rangeDatePickerContainer {
      margin-bottom: rem(24px);
    }

    .addressButtonConatiner {
      .priorAddressButton {
        width: fit-content;
      }
    }

    .textFieldContainer {
      position: relative;
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      margin-bottom: rem(8px);
      border-radius: rem(4px);

      [class*='-control'] {
        border: none !important;
        color: var(--ir-neutral-black-100);
        padding-left: rem(15px);
        padding-top: rem(5px);
        font-weight: 400;
        background-color: inherit;
      }

      .textFieldLabel {
        position: absolute;
        top: rem(4px);
        left: rem(16px);
        z-index: 1;
        font-size: rem(10px);
        font-weight: 600;
        color: var(--ir-neutral-010);
      }
    }
  }
}
