@import '/src/stylesheets/utils';

.VerifierModalContainer {
  display: flex;
  flex-direction: column;
  padding: rem(12px) rem(5px) rem(0);

  .HeadingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(8px);
  }

  .VerifierHeading {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .IconContainer {
    rotate: 90deg;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .IconArrowUp {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }

  .VerifierInformationContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: rem(2px);
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .VerifierLabel {
    color: var(--ir-warm-gray-060);
    font-weight: 600;
    white-space: nowrap;
  }

  .CompletedTime {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }

  .InformationContainer {
    display: flex;
    height: 100%;
    padding: rem(10px) rem(8px);
    border: 1px solid var(--ir-gray-92);
    flex-direction: column;
    gap: rem(12px);
  }

  .Row {
    display: flex;
    gap: rem(8px);
  }

  .ContentContainer {
    flex: 1;
  }

  .ContentLabel {
    color: var(--ir-warm-gray-060);
    font-weight: 600;
  }

  .Content {
    color: var(--ir-neutral-black-100);
    font-weight: 600;
    font-size: rem(16px);
  }

  .DoubleRow {
    .Content {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15rem;
    }
  }

  .EventHistoryContainer {
    display: flex;
    padding: rem(12px) rem(5px);
    border: 1px solid var(--ir-gray-200);
    gap: rem(8px);
    border-top: unset;
    border-radius: 0 0 rem(4px) rem(4px);
    align-items: center;
    cursor: not-allowed;
  }

  .EventHistory {
    color: var(--ir-warm-gray-800);
    font-weight: 600;
    font-size: rem(16px);
  }

  .HorizontalLine {
    width: 100%;
    border-bottom: 1px solid var(--ir-warm-gray-045);
    margin-top: rem(23px);
  }

  .TopLeftRounded {
    border-top-left-radius: rem(4px);
  }

  .TopRightRounded {
    border-top-right-radius: rem(4px);
  }

  .ResponseNotSubmitted {
    font-size: rem(24px);
    position: absolute;
    top: 40%;
    left: 30%;
    color: var(--ir-neutral-black-primary);
    font-style: italic;
    transform: rotate(-10deg);
  }

  .BlurInformation {
    opacity: 0.3;
  }

  .ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
  }

  .SendInviteButton {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .Button {
    width: fit-content;
    padding: rem(4px) rem(8px);
  }
}

.HoverContent {
  white-space: nowrap;
}

@media (max-width: $screen-laptop-md) {
  .VerifierModalContainer {
    .DoubleRow {
      .Content {
        max-width: 9.5rem;
      }
    }
  }
}

@media (max-width: $screen-mobile-navbar) {
  .VerifierModalContainer {
    .CompletedTime {
      max-width: 8rem;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

    .DoubleRow {
      .Content {
        max-width: 6.7rem;
      }
    }
  }
}

@media (max-width: $screen-tablet-sm) {
  .VerifierModalContainer {
    .CompletedTime {
      max-width: 6rem;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

    .DoubleRow {
      .Content {
        max-width: 4.7rem;
      }
    }
  }
}

@media (max-width: $screen-mobile-landscape) {
  .VerifierModalContainer {
    .VerifierLabel {
      max-width: 5rem;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }

    .CompletedTime {
      max-width: 4rem;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
}

@media (min-width: 768px) {
  .VerifierModalContainer {
    .LeftInformationContainer {
      padding-right: unset;
    }

    .RightInformationContainer {
      padding-left: unset;
    }
  }
}

@media (max-width: 765px) {
  .VerifierModalContainer {
    .TopLeftRounded,
    .TopRightRounded {
      [class*='DoubleRow'] {
        flex-direction: column;
      }

      [class*='VerifierAddressContainer'] {
        [class*='Content'] {
          max-width: calc(100vw - 90px);
        }
      }
    }

    [class*='CompletedTime'] {
      max-width: 100%;
    }

    [class*='VerifierLabel'] {
      max-width: 100%;
    }
  }
}
