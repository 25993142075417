@import '/src/stylesheets/utils';

.popoverContainer {
  &:global(.popover) {
    border: 0.5px solid var(--ir-warm-gray-045);
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  }

  [class*='popover-arrow'] {
    display: none;
  }
}

.PopOverClass {
  z-index: 1;
}

.popoverBody {
  padding: rem(5px);

  .popoverWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    button {
      padding: none;
      border: none;
      background: transparent;
    }

    .popoverBtn,
    .popoverDeleteBtn {
      display: flex;
      width: 100%;
      padding: rem(5px);
      color: var(--ir-warm-gray-800);
      font-size: rem(12px);
      align-items: center;
      margin-bottom: rem(5px);
      text-decoration: none;

      &:hover {
        color: rgb(0 0 0);
        background-color: var(--ir-warm-gray-040);
      }

      &.active {
        color: rgb(0 0 0);
      }
    }

    .popoverDeleteBtn {
      display: flex;
      justify-content: center;
      font-weight: 600;
      align-items: center;
    }
  }
}

.overlayTrigger {
  cursor: pointer;
}
