@import '/src/stylesheets/utils';

.PropertyCard {
  padding: rem(10px);
  border-radius: 0;
  border: 1px solid var(--ir-warm-gray-030);
  background: var(--ir-warm-gray-100);
  gap: rem(15px);
}

.PropertyAddressContainer {
  display: flex;
}

.MarginRight {
  margin-right: rem(10px);
}

.PropertyTitle {
  display: flex;
  gap: rem(15px);
  border-right: rem(1px) solid var(--ir-warm-gray-030);
  flex-wrap: wrap;
}

.PropertyAddress {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.PropertyStreetAddress {
  display: flex;
  align-items: center;
  gap: rem(5px);
  padding-left: 0;
}

.OrganizationOwner {
  display: flex;
  gap: rem(5px);
  text-align: start;
  align-items: center;
  justify-content: center;
}

.OrganizationName {
  display: flex;
  flex-direction: column;
}
