@import '/src/stylesheets/utils';

.container {
  text-align: left;

  .mainHeading {
    color: var(--ir-gray-1050);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 500;
    line-height: rem(24px);
  }

  /* Styling for a border container */

  .borderContainer {
    flex: 10;
    padding: rem(8px);
    border: 1px solid var(--ir-gray-200); /* Border color */
    background: var(--ir-gray-000); /* Background color */
    text-align: left;
    border-radius: rem(4px);

    &.paddingLessBorder {
      padding: 0 rem(8px);
    }

    &.borderLessContainer {
      border: unset;
    }
  }

  .noTopBorder {
    border-top: none !important;
  }

  /* Styling for an answer container */

  .questionDiv {
    justify-content: left;
    padding: rem(8px);
    font-size: rem(12px);
    text-align: left;
  }

  .buttonDiv {
    justify-content: end;
  }

  .answerContainer {
    display: flex;
    padding: rem(5px) rem(5px);
    border: 1px solid var(--ir-gray-200); /* Border color */
    background: var(--ir-neutral-white-bg); /* Background color */
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(8px);
    border-radius: 0 rem(4px) rem(4px) 0;

    /* Styling for text inside the answer container */

    .text {
      color: var(--ir-gray-1050); /* Text color */
      font-size: rem(14px);
    }

    /* Styling for the header inside the answer container */

    .header {
      color: var(--ir-neutral-black-primary); /* Header text color */
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(14px);
    }
  }

  /* Styling for text pills */

  .textPill {
    display: flex;
    width: fit-content;
    padding: 0 rem(2px);
    text-align: left;
    color: var(--ir-gray-1050);
    border-radius: rem(2px);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px);
    align-items: center;
  }

  /* Styling for active text pills */

  .activePill {
    background: var(--ir-segment-blue-96);
  }

  /* Styling for disabled text pills */

  .disabledPill {
    background: var(--ir-gray-200);
  }

  .secondaryHeading {
    color: var(--ir-neutral-000);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20px);
  }

  .innerGap {
    height: rem(8px);
  }

  .titleMargin {
    margin-bottom: rem(8px);
  }

  .backgroundQuestions {
    [class^='RenterDrawer_childrenContainer'] {
      height: fit-content;
    }

    [class='EZDrawer'] {
      [id*='EZDrawer__container'] {
        height: fit-content !important;
      }
    }

    [class^='RenterDrawer_backgroundIcon'] {
      top: 0;
    }
  }

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }

  .bottomPadding {
    margin-bottom: rem(10px);
  }

  .topDiv {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .tag {
      height: fit-content;
      padding: rem(0) rem(2px);
      line-height: rem(14px);
      background-color: var(--ir-gray-200);
      border-radius: rem(2px);

      .text {
        color: var(--ir-black);
        font-size: rem(10px);
        line-height: rem(14px);
      }
    }
  }

  /* Styling for ladder */

  .personalInformationContainer {
    .fieldContainer {
      display: flex;
    }

    &.marginRight {
      margin-right: rem(2.5px);
    }

    .bottomPadding {
      margin-bottom: rem(10px);
    }
  }

  .branchContainer {
    display: flex;
    min-height: 100%;
    flex: 0.2;
    margin-left: rem(4px);

    .verticalLine {
      border-left: rem(1px) solid var(--ir-gray-220);
    }

    .horizontalLine {
      width: 100%;
      margin-top: rem(10px);
      border-top: rem(1px) solid var(--ir-gray-220);
    }
  }

  /* Styling For React Select */

  .questionSelectDiv {
    display: flex;
    justify-content: flex-end;
  }

  .questionSelect {
    width: rem(82px);
    font-size: rem(14px);
    font-weight: 600;

    [class*='-control'] {
      min-height: rem(28px);
    }

    [class*='-ValueContainer'] {
      min-height: unset;
    }

    [class*='indicatorContainer'] {
      padding: unset;
    }
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  align-items: flex-end;
  justify-content: center;
}

@media (max-width: $screen-mobile-navbar) {
  .buttonContainer {
    margin-top: 2rem;
    height: fit-content;
  }
}

.IdentityVerificationContainer {
  overflow-x: hidden;
}
