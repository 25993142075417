@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(12px);

  .InquiryHeader {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    min-width: rem(415px);
  }

  .LeftHeadings {
    display: flex;
    gap: rem(80px);
    width: 50%;
    justify-content: flex-start;
  }

  .InquiryReports {
    min-width: rem(415px);
  }

  .RightHeadings {
    width: 50%;
    justify-content: flex-start;
    text-align: start;
  }

  .Heading {
    color: var(--ir-segment-cyan-1050);
    font-size: rem(16px);
    font-weight: 600;
  }

  .ScrollableInformation {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
  }
}

@media (max-width: $screen-mobile-small) {
  .Container {
    .ScrollableInformation {
      width: calc(100vw - rem(60px));
      overflow: auto;
    }
  }
}
