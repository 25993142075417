@import '/src/stylesheets/utils';

.dashContainer {
  align-items: center;
  background-color: rgb(255 255 255);
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .applicantStatus {
    display: flex;
    font-size: 32px;
    font-weight: 300;
    gap: 0.5rem;
    justify-content: flex-start;
    padding-left: 10%;

    [class^='ReactSelect_defaultChild'] {
      width: rem(1px);
      opacity: 0;
    }
  }

  .verticalLine {
    border-left: 1px solid #000;
    height: 25px;
    margin-top: 11px;
  }

  .activeOption {
    color: rgb(0 0 0);
  }

  .horizontalLine {
    border-top: 1px solid var(--ir-warm-gray-030);
    width: 100%;
  }

  .applicantButton {
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
    padding-top: 0.3rem;
    position: relative;
  }

  .PropertyApplicationListContainer {
    margin: rem(15px);
    padding: 0 8%;
    padding-bottom: rem(30px);
  }

  .dashRow1 {
    padding-bottom: 3.5rem;
    padding-top: 2rem;
    display: flex;
    flex-wrap: wrap;

    [class*='ReactSelect_valueContainer'] {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
    }

    [class*='-control'] {
      border: none;
    }

    [class*='_container'] {
      margin-top: 0;
    }

    [class*='_floatingLabel'] {
      color: rgb(0 0 0);
      font-size: 32px;
      font-weight: 400;
    }

    [class*='-singleValue'] {
      color: var(--ir-gray-000);
    }

    [class*='-option'] {
      padding: 0 2px 0 20px;
      line-height: rem(20px);
      text-align: left;
    }

    [class*='ReactSelect_container'] {
      width: 13rem;
    }
  }

  .InvitedContainer {
    margin: rem(20px);
    padding: 0 8%;

    .AgentSpecificInvitesContainer {
      margin: rem(20px) rem(0);
    }
  }

  @media (max-width: $screen-mobile-navbar) {
    .dashRow1 {
      justify-content: center;
    }
  }

  @media (max-width: 726px) {
    .applicantButton {
      justify-content: center;
      padding-right: 0%;
    }

    .applicantStatus {
      justify-content: center;
      padding-left: 0%;
    }

    .PropertyApplicationListContainer {
      margin: 0;
      padding: 10px;
      padding-bottom: rem(30px);
    }
  }
}

.FloatingButton {
  display: flex;
  position: fixed;
  right: rem(12px);
  bottom: rem(94px);
  width: fit-content;
  border-radius: rem(30px);
  z-index: 1033;
  font-weight: 600;
  gap: rem(8px);
  align-items: center;
}

.InfoPanel {
  padding-left: 10%;
  padding-top: rem(37px);
  display: flex;
  flex: auto;
  padding-right: 10%;
}

.InfoWidth {
  width: 117%;
  padding: rem(20px);
}

.LegacyTableContainer {
  margin: rem(15px);
  padding: 0 8%;
  padding-bottom: rem(30px);
}

.PaddingLeft {
  padding-left: 53px;
}
