@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  text-align: start;
  color: var(--ir-neutral-black-primary);
  flex-direction: column;
  gap: rem(8px);

  .Header {
    padding: 0 rem(5px);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .UpdateInformation {
    display: flex;
    flex-direction: column;
    gap: rem(2px);
    padding: 0 rem(5px);
  }

  .LastUpdated,
  .DwellingType {
    font-size: rem(12px);
    display: flex;
    gap: rem(5px);
    text-transform: capitalize;
  }

  .Value {
    color: var(--ir-neutral-black-100);
    font-weight: 600;
  }
}
