@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .IconContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .ArrowIcon {
    cursor: pointer;
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
  }

  .Heading {
    font-size: rem(24px);
    border: unset;
  }

  .ArrowIconExpanded {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .ErrorContainer {
    width: 100%;
    padding: rem(12px) 0 rem(58px) 0;
    min-height: rem(290px);
  }
}
