@import '/src/stylesheets/utils';

.DescriptionCardContainer {
  display: flex;
  flex-direction: column;
  color: var(--ir-gray-1050);

  .Footer {
    display: flex;
    gap: rem(20px);
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(24px);

    .pill {
      height: fit-content;
      padding: 0 rem(2px);
      background: var(--ir-gray-200);
      border-radius: rem(2px);
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(14px); /* 140% */
    }
  }

  .subHeading {
    color: var(--ir-warm-gray-060);
    font-size: rem(12px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20px);
  }

  .date {
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px);
  }

  .addressType {
    color: var(--ir-warm-gray-060);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px); /* 140% */
  }
}
