@import '/src/stylesheets/utils';

.Container {
  .LogoContainer {
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    height: 6rem;
    padding-left: 15vw;
    background: var(--ir-gray-000);
    align-items: center;
    gap: rem(10px);
  }

  .VerificationContainer {
    display: flex;
    width: 100vw;
    height: 100vh;
    background-color: var(--ir-segment-cyan-300);
    align-items: center;
    justify-content: center;
  }

  .BodyContainer {
    display: flex;
    width: rem(1024px);
    padding: rem(52px) rem(40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    border-radius: rem(24px);
    background: var(--ir-warm-gray-100);
    box-shadow: 0 rem(4px) rem(4px) 0 rgb(0 0 0 / 25%);
  }

  .Header {
    display: flex;
    gap: rem(10px);
    align-items: center;
    padding: rem(12px) 0;
  }

  .Heading {
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-size: rem(32px);
    font-weight: 600;
  }

  .Content {
    display: flex;
    padding: rem(32px) 0 rem(0);
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12px);
    align-self: stretch;

    [class*='formik-field'] {
      width: 100%;
    }

    [class*='TextField_container'] {
      color: var(--ir-neutral-black-primary);
      font-weight: 600;
    }

    [class*='form-control'] {
      padding: rem(5px) 0 rem(5px) rem(16px);
      border: 1px solid var(--ir-gray-200);

      &:not(:placeholder-shown) ~ label {
        opacity: unset;
        padding-left: rem(16px);
      }
    }
  }

  .ContentHeading {
    width: 100%;
    text-align: center;
    color: var(--ir-neutral-black-100);
    font-size: rem(18px);
  }

  .CodeContainer {
    display: flex;
    width: 100%;
    padding: rem(12px) 0 rem(32px) 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: rem(8px);
  }

  .ErrorText {
    margin-top: rem(10px);
    color: var(--ir-field-error);
    font-size: rem(14px);
    font-weight: 600;
    align-items: baseline;
    text-transform: capitalize;
  }

  .ErrorIcon {
    margin-top: rem(-5px);
  }

  .Button {
    padding: rem(10px) rem(30px);
  }

  .LoadingIcon {
    margin-top: rem(-4px);
    animation: spin 15s linear infinite; /* Apply the spin animation */
  }

  @keyframes spin {
    0% {
      transform: rotate(3600deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
