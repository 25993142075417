@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(3px) rem(5px);
  text-align: start;
  color: var(--ir-neutral-black-100);
  gap: rem(5px);
  text-transform: uppercase;
  align-items: baseline;

  .Item {
    color: var(--ir-neutral-black-100);
    font-size: rem(14px);
    font-weight: 600;
  }

  .LeftInformation {
    display: flex;
    gap: rem(42px);
    flex: 1;
    justify-content: flex-start;
  }

  .RightInformation {
    flex: 1;
    justify-content: flex-start;
    text-align: start;
  }
}

.GreyBackground {
  background: var(--ir-gray-110);
}
