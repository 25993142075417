@import '/src/stylesheets/utils';

.Document {
  display: flex;
  width: auto;
  padding: rem(4px) rem(0);
  flex-direction: column;
  gap: rem(8px);
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: rem(60px);

  .Label {
    display: flex;
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-weight: 600;
    text-transform: capitalize;
    gap: rem(12px);
  }

  .BellRow {
    display: flex;
    gap: rem(5px);
    align-items: center;
  }
}

.Info {
  display: flex;
  width: 100%;
  padding: rem(4px) rem(0) rem(8px) rem(0);
  border: 1px solid var(--ir-gray-200);
  background-color: var(--ir-gray-000);
  gap: rem(8px);
}

.FileName {
  padding: rem(4px) rem(16px) rem(4px) 0;
  align-items: center;
  gap: rem(8px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-weight: 400;
  color: var(--ir-neutral-black-100);

  &:hover {
    opacity: 0.7;
  }
}