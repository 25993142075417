@import '/src/stylesheets/utils';

.textEditor {
  background-color: #fff;
}

.qlPicker {
  &:global(.ql-picker) {
    width: fit-content !important;

    svg {
      display: none;
    }
  }
}

.quillContainer {
  div {
    height: rem(300px);
  }
}

.qlAlign {
  svg {
    margin-bottom: rem(5px);
  }
}

.qlLabel {
  font-size: 5px;
}
