@import '/src/stylesheets/utils';

.Container {
  border-top: 1px solid var(--ir-warm-gray-030);
  padding: rem(8px) rem(0);

  &:hover {
    background: var(--ir-neutral-white-bg);
  }

  .Button {
    display: flex;
    width: 100%;
    margin: unset;
    padding: 0 rem(8px) 0 rem(5px);
    border: unset;
    background: unset;
    color: var(--ir-neutral-black-primary);
    justify-content: space-between;
    font-size: rem(12px);
    font-weight: 600;
    box-shadow: unset;
    cursor: pointer;

    &:hover {
      background: unset;
    }
  }

  .IconLabels {
    display: flex;
    justify-content: flex-end;
    gap: rem(8px);
    align-items: baseline;
  }

  .NewLabel,
  .PendingLabel {
    border-radius: rem(2px);
    padding: rem(2px);
    text-transform: capitalize;
    font-size: rem(10px);
    font-weight: 600;
  }

  .NewLabel {
    background: var(--ir-warm-gray-040);
  }

  .PendingLabel {
    background: var(--ir-segment-yellow-100);
  }

  .ArrowIcon {
    transform: rotate(-90deg);
    margin-top: rem(1px);
    min-width: rem(14px);
    min-height: rem(14px);
  }
}
