@import '/src/stylesheets/utils';

.FinancialInstitutions {
  display: flex;
}

.Ladder {
  display: flex;
  margin-left: 4px;
  width: 10px;
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-350);
}

.HorizontalLine {
  width: 100%;
  margin-top: rem(22px);
  border-top: rem(1px) solid var(--ir-gray-350);
}

.VerifierCardHorizontalLine {
  margin-top: rem(28px);
}

.NotFound {
  padding-left: rem(10px);
  font-size: rem(16px);
  font-weight: 600;
}

.FinancialReports {
  display: flex;
  padding-top: rem(10px);
  flex-direction: column;
  gap: rem(12px);
  width: 100%;
}

.FinancialHeading {
  color: var(--ir-neutral-black-100);
  font-size: rem(16px);
  font-weight: 600;
}

.FinancialReportHeader {
  display: flex;
  gap: rem(10px);
}

.InstitutionCount {
  padding: 0 rem(2px);
  border-radius: rem(2px);
  background: var(--ir-gray-200);
  color: var(--ir-neutral-black-100);
  font-size: rem(16px);
  font-weight: 600;
}
