@import '/src/stylesheets/variables';
@import '/src/stylesheets/utils';

.navContainer {
  background-image: radial-gradient(280px at 87% -60px, #acebf1, rgb(255 255 255) 70%);
}

.btnPrimary {
  display: flex;
  width: fit-content;
  margin-right: 6rem;
  padding: rem(6px) rem(20px);
}

@media (max-width: $screen-mobile-navbar) {
  .hideNavbar {
    display: none;
  }

  .btnPrimary {
    margin-right: 3rem;
  }
}

.navHeading {
  font-size: 24px;
  font-weight: 200;
  margin-left: 40px;

  .navIntelli {
    color: #58595b;
  }

  .navRent {
    color: #0facbc;
  }
}

.navDropdown {
  align-items: center;
  display: flex;

  ::after {
    display: none;
  }
}
