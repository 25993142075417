@import '/src/stylesheets/utils';

.Container {
  position: relative;
  width: rem(10px);
  height: rem(12px);
  border: 1.5px solid var(--ir-gray-1100);
  border-top: unset;
  border-right: unset;

  .Count {
    position: absolute;
    top: -2px;
    left: 1px;
    width: fit-content;
    height: fit-content;
    padding: 0 rem(2px);
    border: 1.5px solid var(--ir-gray-1100);
    font-size: rem(8px);
    font-weight: 800;
    line-height: rem(9px);
  }
}
