@import '/src/stylesheets/utils';

.Container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .InfoContainer {
    display: flex;
    gap: rem(10px);
    align-items: baseline;

    .Details {
      display: flex;
      flex-direction: column;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      align-items: baseline;

      .Heading {
        color: var(--ir-warm-gray-060);
        font-size: rem(14px);
        text-transform: capitalize;
      }

      .Data {
        word-break: break-all;
        overflow: hidden;
        color: var(--ir-neutral-black-100);
        text-overflow: ellipsis;
        font-size: rem(16px);
        text-decoration: unset;
      }
    }
  }

  .Button {
    display: none;
    width: fit-content;
    padding: 0;
    border: none;
    background: transparent;
    align-items: center;
    justify-content: center;
    border-radius: rem(4px);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(24px);
    box-shadow: none;
  }
}

.Container:hover {
  .Button {
    display: flex;
  }
}
