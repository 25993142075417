@import '/src/stylesheets/utils';

.Container {
  .SubHeadingContainer {
    display: flex;
    gap: rem(12px);
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px); /* 100% */
    text-transform: uppercase;

    .Pill {
      padding: 0 rem(2px);
      background: var(--ir-gray-200);
      color: var(--ir-neutral-black-100);
      border-radius: rem(2px);
    }
  }

  .NotExist {
    padding-left: rem(10px);
    font-size: rem(16px);
    font-weight: 600;
  }

  .SubHeadingContainerForEmpty {
    display: flex;
    gap: rem(12px);
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px); /* 100% */
    text-transform: uppercase;
    border-bottom: 1px solid var(--ir-gray-93);
    padding-bottom: rem(10px);
    padding-top: rem(10px);
  }  

  .MinorEmpty{
    display: flex;
    gap: rem(12px);
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px); /* 100% */
    padding-top: 20px;
    border-top: 1px solid var(--ir-gray-93);
    border-bottom: 1px solid var(--ir-gray-93);
    padding-bottom: 10px;

  }
}
