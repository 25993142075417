@import '/src/stylesheets/utils';

.CapsuleContainer {
  display: flex;
  flex-direction: column;
  padding: rem(5px) rem(15px);
  border-radius: rem(5px);
  border: 1px var(--ir-warm-gray-030) solid;
  gap: rem(5px);
  align-items: center;
  background: var(--ir-gray-000);

  .AnimalInformation {
    display: flex;
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-dark-black);
    gap: rem(5px);
  }

  .CapsuleLabel {
    font-weight: 600;
    font-size: rem(12px);
    color: var(--ir-neutral-dark-black-90);
  }

  .PillContainer {
    font-size: rem(10px);
    font-weight: 600;
  }

  .LabelContainer {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }
}
