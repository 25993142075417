@import '/src/stylesheets/utils';

.container {
  display: flex;
  text-align: left;
  flex-direction: column;

  .HeaderContainer {
    padding-bottom: rem(10px) !important;
  }

  .AnimalTag {
    width: fit-content;
    padding: 0 rem(2px);
    background: var(--ir-neutral-black-primary);
    color: var(--ir-gray-000);
    align-items: center;
    border-radius: rem(2px);
    font-size: rem(11px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .SelectContainer {
    [class$='-ValueContainer'] {
      min-height: unset;
    }
  }

  .ImagesContainer,
  .DocumentsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(2px);
  }

  .DocumentsContainer {
    margin-top: rem(12px);
  }

  .Label {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
    line-height: rem(20px); /* 166.667% */
  }

  .DeleteButton {
    display: flex;
    margin: rem(24px) 0;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-gray-220);
    background: var(--ir-gray-000);
    color: var(--ir-field-error);
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: rem(4px);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px); /* 150% */
  }

  .heading {
    font-size: rem(24px);
    font-weight: 700;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  .subHeading {
    font-size: rem(18px);
    text-align: center;
    color: var(--ir-warm-gray-800);
  }

  .textFieldContainer {
    position: relative;
  }

  .headingLabel {
    font-size: rem(18px);
    font-weight: 500;
    color: var(--ir-warm-gray-800);
  }

  .renterSpan {
    color: var(--ir-segment-cyan-700);
  }

  .reactSelectClasses {
    [class*='-control']:hover {
      border-color: var(--ir-gray-200);
    }

    [class*='-placeholder'],
    [class*='-singleValue'] {
      padding: rem(18px) rem(6px) rem(11px) 0;
    }
  }

  .textFieldLabel {
    position: absolute;
    top: rem(8px);
    left: rem(12px);
    z-index: 1;
    font-size: rem(10px);
    font-weight: 600;
    color: var(--ir-neutral-010);

    &.assistiveAnimalFieldLabel {
      top: rem(1px);
    }

    &.reactSelectFieldLabel {
      top: rem(10px);
      left: 50%;
    }

    &.unitFieldLabel {
      top: rem(25px);
      left: 74%;
      font-size: rem(14px);
      font-weight: 400;
    }
  }

  .renterButtonContainer {
    padding-top: rem(10px);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-end;
    gap: 1rem;
  }

  .renterButton {
    width: fit-content;
    padding: rem(4px) rem(8px) rem(4px) rem(8px);
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .ButtonsContainer {
    display: flex;
    width: 100%;
    padding: rem(12px) 0;
    align-items: flex-start;
    gap: rem(16px);
    align-self: stretch;
    flex-wrap: wrap;
  }

  .TypeButton {
    display: flex;
    width: 100%;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-gray-220);
    background: var(--ir-gray-000);
    color: var(--ir-neutral-black-primary);
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: rem(5px);
    border-radius: rem(4px);
    font-size: rem(16px);
    font-weight: 700;
    line-height: rem(24px); /* 150% */
    text-transform: capitalize;
  }

  .TypeButtonActive {
    border: 1px solid var(--ir-segment-cyan-900);
    color: var(--ir-segment-cyan-700);
  }

  .TickIcon {
    min-width: rem(22px);
    min-height: rem(22px);
  }
}

.AnimalModalBody {
  padding: 0 2rem 1rem;
}

.inputBackgroundColor {
  padding-top: 1rem;
  background-color: var(--ir-neutral-white-bg);

  [class*='_container'] [class*='form-control']:focus {
    background-color: var(--ir-neutral-white-bg) !important;
  }
}

.reactInputBackgroundColor {
  [class*='-control'] {
    background-color: var(--ir-neutral-white-bg);
    margin-bottom: rem(20px);
  }
}

.reactselectFieldsContainer {
  display: flex;
  gap: 1rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  .textFieldContainer {
    flex: 1;
  }
}

.reactSelectStyle {
  width: 45%;

  [class*='-control'],
  [class*='ReactSelect_container'],
  [class*='-container'] {
    border: none;
    background: transparent;
  }

  [class*='-singleValue'] {
    font-size: rem(14px);
    font-weight: 400;
    color: var(--ir-neutral-010);
  }
}

@media (max-width: 400px) {
  .container {
    .textFieldLabel {
      &.reactSelectFieldLabel {
        left: 40%;
      }
    }

    .ButtonsContainer {
      flex-direction: column;
    }
  }

  .reactSelectStyle {
    width: 55%;
  }
}

@media (max-width: 425px) {
  .container {
    .ButtonsContainer {
      flex-direction: column;
    }
  }
}
