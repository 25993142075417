@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  padding: rem(5px);
  gap: rem(10px);
  justify-content: flex-start;

  .Heading {
    display: flex;
    width: 100%;
    padding: rem(5px) 0;
    text-align: start;
    color: var(--ir-neutral-black-100);
    gap: rem(10px);
    font-size: rem(18px);
    font-weight: 600;
    line-height: rem(36px);
    text-transform: capitalize;
    align-items: center;
    border-bottom: 1px solid var(--ir-gray-92);
  }

  .CounterText {
    height: fit-content;
    padding: 0 rem(5px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    border-radius: rem(2px);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
    line-height: normal;
  }
}
