@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .typeHeading {
    text-transform: capitalize;
    color: var(--ir-gray-1050);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(14px);

    &.SpaceContainer {
      padding-block: rem(15px);
    }

    span {
      color: var(--ir-neutral-black-primary);
      font-size: rem(14px);
      font-style: normal;
      font-weight: 400;
    }
  }

  .PresentDescription {
    color: var(--ir-neutral-black-100);
  }

  .HighlightedText {
    color: var(--ir-segment-cyan-700);
  }

  .inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .typeSubHeading {
      color: var(--ir-neutral-black-primary);
      font-size: rem(14px);
      font-weight: 600;
    }

    .formInputContainer {
      display: flex;
      width: 23rem;
      height: rem(34px);
      padding: rem(5px) rem(8px);
      border: 1px solid var(--ir-neutral-default-gray);
      background: var(--ir-gray-000);
      align-items: center;
      justify-content: space-between;
      border-radius: rem(4px);
    }
  }
}

@media (max-width: 726px) {
  .container {
    .inputContainer {
      .formInputContainer {
        width: 12rem;
      }
    }
  }
}
