@import '/src/stylesheets/utils';

.InformationRow {
  --bs-gutter-x: 8px;
}

.MainContainer {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  [class*='valueContainer'] {
    margin-top: rem(-10px);

    [class*='floatingLabel'] {
      font-size: rem(11.2px);
      color: var(--ir-neutral-000);
    }

    [class*='singleValue'] {
      font-weight: 400;
    }
  }

  .CardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.CardRowContainer {
  width: 100%;
  max-width: 70%;
  margin-bottom: rem(40px);
}

.Container {
  display: flex;
  width: 100%;
  padding: rem(24px) rem(40px);
  background: var(--ir-gray-000);
  flex-direction: column;
  align-items: flex-start;
  gap: rem(10px);
  border-radius: rem(24px);
  box-shadow: 0 rem(4px) rem(4px) 0 rgb(0 0 0 / 25%);

  .MainHeading {
    width: 100%;
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-size: rem(32px);
  }

  .SubHeading {
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-size: rem(18px);
    font-weight: 500;
  }

  .OrganizationDetails {
    display: flex;
    padding: rem(10px) rem(8px);
    flex-direction: column;
    align-items: flex-start;
    gap: rem(12px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-350);
    background: var(--ir-gray-000);
    margin-bottom: rem(30px);
  }

  .InvitedByDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .InviteSubHeading {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
    text-transform: capitalize;
  }

  .InvitedBy {
    text-align: start;
    color: var(--ir-neutral-black-100);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-weight: 600;
  }

  .InviteHeading {
    text-align: start;
    color: var(--ir-neutral-black-100);
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem(18px);
    font-weight: 600;
  }

  .PersonalDetails {
    display: flex;
    margin: rem(2px) 0 rem(30px);
    padding: rem(12px) 0;
    flex-direction: column;
    align-items: flex-start;
    gap: rem(8px);
    align-self: stretch;
  }

  .InformationRow {
    width: 100%;
  }

  .StateSelectField {
    position: relative;
    text-align: left;

    .TextFieldLabel {
      position: absolute;
      top: rem(4px);
      left: rem(20px);
      z-index: 1;
      font-size: rem(11.2px);
      color: var(--ir-neutral-000);
      font-weight: 600;
    }

    [class$='-control'] {
      min-height: rem(55px) !important;
    }
  }

  .Footer {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .SubmitButton {
    align-items: center;
    text-align: center;
  }

  .Spinner {
    width: rem(20px);
    height: rem(20px);
  }

  [class*='flag-dropdown'] {
    display: none;
  }

  [class*='react-tel-input'] [class*='form-control'] {
    padding-left: rem(33px);
  }
}

@media screen and (max-width: 765px) {
  .CardRowContainer {
    max-width: 90%;
  }

  .MainContainer {
    height: auto;
  }
}

@media screen and (min-height: 765px) {
  .MainContainer {
    .CardContainer {
      height: 100%;
    }
  }
}

@media screen and (max-width: 765px) {
  .CardRowContainer {
    padding-bottom: rem(100px);
  }
}
