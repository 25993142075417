@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  padding: 0 rem(44px);
  align-items: center;
  gap: rem(12px);
  align-self: stretch;
  justify-content: space-between;

  .VerificationContent {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .Heading {
    color: var(--ir-warm-gray-800);
    font-size: rem(20px);
    font-weight: 600;
  }

  .ExitSign {
    display: flex;
    align-items: center;
  }

  .CloseText {
    padding: rem(4px) rem(8px);
    color: var(--ir-warm-gray-800);
    font-size: rem(14px);
    font-weight: 600;
  }

  .CrossIcon {
    min-width: rem(18px);
    min-height: rem(18px);
  }
}
