.container {
  margin-left: .2rem;

  :global(.spinner-border){
    width: 17px;
    height: 17px;
    border-width: .15em;
  }
}

