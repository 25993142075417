@import '/src/stylesheets/utils';

.RenterApplicationHeading {
  width: 100%;
  text-align: left;
  color: var(--ir-neutral-black-100);
  font-weight: 500;
  font-size: rem(18px);
  margin-bottom: 0.6rem;
}

.HighlightedText {
  color: var(--ir-segment-cyan-700);
}

.SelectProperty {
  width: 98%;
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.MoveInDateHeading {
  text-align: left;
  color: var(--ir-neutral-black-100);
  font-size: rem(18px);
  margin-top: rem(24px);
}

.MoveInDate {
  width: 100%;
}

.ContinueButtonContainer {
  display: flex;
  width: 100%;
  text-align: end;
  background-color: var(--ir-warm-gray-100);
  padding-top: 0.5rem;
  gap: rem(16px);
  flex-direction: column;
  align-items: flex-end;

  .Button {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .PrimaryButton {
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
    margin-right: 3%;
  }
}

.AlignTextStart {
  text-align: start;
  margin-bottom: rem(45px);
}
