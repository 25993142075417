@import '/src/stylesheets/utils';

.HeadingContainer {
  display: flex;
  padding: rem(5px);
  margin-bottom: rem(15px);
}

.LeftArrowIcon {
  cursor: pointer;
}

.Heading {
  width: 100%;
  text-align: center;
  color: var(--ir-neutral-black-100);
  font-size: rem(20px);
  font-weight: 600;
  line-height: rem(22px);
  align-items: center;
}

.ViewApplicationContainer {
  margin-bottom: rem(10px);

  .InformationBox {
    display: flex;
    padding: rem(8px) rem(10px);
    border: rem(1px) solid var(--ir--neutral-sea-blue-100);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir--neutral-sea-blue-150);
    margin-bottom: rem(18px);

    .InformationIcon {
      width: rem(24px);
      height: rem(24px);
    }

    .InformationText {
      text-align: left;
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-weight: 600;
      margin-bottom: unset;
    }
  }

  .ScrollableContent {
    ::-webkit-scrollbar {
      width: rem(6px);
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--ir-warm-gray-400);
      border-radius: rem(100px);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--ir-warm-gray-400);
    }
  }
}

@media (max-width: 786px) {
  .HeadingContainer {
    margin-top: 10px;
  }
}
