@import '/src/stylesheets/utils';

.RenterInfoDetailsModal {
  padding: 0 rem(24px);
  text-align: center;
  color: var(--ir-warm-gray-800);
  font-style: normal;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(24px);

  .Title {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .Description {
    font-size: rem(14px);
    font-weight: 500;
  }

  .Heading {
    padding: rem(24px) 0 rem(16px);

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .FormContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: rem(20px);
  }

  .ButtonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: rem(40px);

    .RenterPhotoInformationButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}
