@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .EvictionNotice,
  .OrderResponse {
    padding-bottom: rem(40px);
    border-bottom: 1px solid var(--ir-primary-primary-93);
  }

  .Heading {
    padding: rem(3px) rem(5px);
    text-align: start;
    color: var(--ir-segment-cyan-900);
    font-size: rem(16px);
    font-weight: 600;
  }

  .CanidateExtraInformation {
    margin-top: rem(20px);
  }

  .InformationColumns {
    margin: unset;
  }
}
