@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .TradeLineReports {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .TradeLineItem {
    padding-bottom: rem(20px);
  }

  .ItemDivider {
    border-bottom: 1px solid var(--ir-gray-92);
  }
}
