@import '/src/stylesheets/utils';

.contentClassName {
  .container {
    margin: rem(35px);

    .messageDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: rem(24px);
      gap: 1rem;
    }

    .headingDiv {
      font-size: rem(24px);
      font-weight: 700;
      text-align: center;
    }

    .description {
      font-size: rem(18px);
      font-weight: 500;
      text-align: center;
    }

    .dullButton {
      width: fit-content;
      padding: rem(12px) rem(40px) rem(12px) rem(40px);
      color: var(--ir-neutral-010);
      border-color: var(--ir-neutral-010);
    }

    .renterButton {
      width: fit-content;
      padding: rem(12px) rem(40px) rem(12px) rem(40px);
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-gray-000);
      font-weight: 600;
      font-size: rem(18px);
    }

    .buttonDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

.hideCrossButton {
  display: none !important;
}
