@import '/src/stylesheets/utils';

.circularProgressBarContainer {
  position: relative;
  width: fit-content;

  .circularProgressBar {
    width: rem(35px);
    height: rem(35px);
    transform: rotate(-90deg);
  }

  .circularProgressBarBackground {
    fill: none;
    stroke: var(--ir-warm-gray-040);
    stroke-width: 2.5;
  }

  .circularProgressBarProgress {
    fill: none;
    stroke: var(--ir-segment-cyan-900);
    stroke-width: 2.5;
    stroke-linecap: round;
    transform-origin: center;
    transition: stroke-dashoffset 0.5s ease-in-out;
  }

  .circularProgressBarText {
    position: absolute;
    top: rem(10px);
    left: rem(0);
    width: 100%;
    text-align: center;
    color: var(--ir-neutral-001);
    font-size: rem(10px);
    font-weight: 700;
    text-anchor: middle;
  }
}
