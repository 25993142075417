@import '/src/stylesheets/utils';

.Container {
  display: flex;
  position: relative;
  margin: rem(30px);
  flex-direction: column;
  gap: rem(20px);

  .LoaderRows {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .StatusContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: rem(24px);
  }

  .ErrorMessage {
    text-align: center;
    color: var(--ir-neutral-black-primary);
    font-size: rem(32px);
    font-weight: 300;
  }

  .SuccessReportMessage{
    width: rem(500px);
    text-align: center;
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px);
  }

  .ErrorDiscriptionContainer {
    display: flex;
    flex-direction: column;
    gap: rem(15px);
    align-items: flex-start;
    margin: auto;
  }

  .Code {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .ExtraDiscription {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    max-width: rem(540px);
  }

  .ErrorButton {
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: rem(4px) rem(8px);
    color: var(--ir-neutral-black-100);
    font-weight: 600;
  }
}
