@import '/src/stylesheets/utils';


.Container {
  display: flex;
  width: 100%;
  padding: rem(5px) 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: rem(12px);
}

.Heading {
  font-size: rem(16px);
  font-weight: 600;
}

.Icon {
  padding: rem(5px) rem(5px) 0 rem(5px);
}

.Info{
  padding: 0 rem(10px);
}

.Disclaimer {
  display: flex;
  justify-content: center;
  background-color: var(--ir-segment-blue-96);
  padding: rem(10px) 0;
  border-radius: 5px;
  border: 1px solid var(--ir--neutral-sea-blue-500);
  margin-top: rem(10px);
}
