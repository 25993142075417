@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .Heading {
    padding: rem(5px) 0;
    text-align: start;
    color: var(--ir-neutral-black-100);
    font-size: rem(18px);
    font-weight: 600;
    line-height: rem(36px);
    border-bottom: 1px solid var(--ir-gray-92);
  }
}
