@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(15px);
  max-width: rem(600px);

  .ErrorMessage {
    display: flex;
    flex-direction: column;
    gap: rem(25px);
  }

  .Message {
    text-align: center;
    color: var(--ir-neutral-black-100);
    font-size: rem(32px);
    font-style: normal;
    font-weight: 300;
    letter-spacing: rem(-0.64px);
  }

  .SupportMessage {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-weight: 300;
  }

  .SupportLink {
    color: var(--ir-segment-cyan-900);
    font-weight: 300;
    text-decoration-color: var(--ir-segment-cyan-900);
  }

  .ErrorCode {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }
}
