@import '/src/stylesheets/utils';

.OrganizationOwnerContainer {
  display: flex;
  gap: rem(5px);
  text-align: start;
  align-items: center;
  justify-content: center;
}

.OrganizationNameContainer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 992px) {
  .OrganizationOwnerContainer {
    justify-content: flex-start;
  }
}
