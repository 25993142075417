@import '/src/stylesheets/utils';

.Container {
  display: flex;
  gap: rem(10px);

  .InputBox {
    display: flex;
    width: rem(42px);
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-gray-200);
    text-align: center;
    color: var(--ir-warm-gray-800);
    min-height: rem(50px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: rem(4px);
    box-shadow: 0 rem(2px) rem(4px) 0 rgb(0 0 0 / 40%);
    outline: none;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(20px); /* 142.857% */

    &:focus {
      background: var(--ir-gray-200);
    }
  }
}
