@import '/src/stylesheets/utils';

.IncomeReport {
  display: flex;
  padding: rem(22px) rem(22px) 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(5px);
  align-self: stretch;
  border: 1px solid var(--ir-gray-93);
  background: var(--ir-gray-110);

  .Heading {
    display: flex;
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
    gap: rem(4px);
    align-items: center;
  }

  .InnerDiv {
    display: flex;
    flex-direction: column;
    gap: rem(5px);
  }

  .LeftDiv {
    align-items: flex-start;
    align-self: baseline;
    display: flex;
    gap: rem(30px);
  }

  .RightDiv {
    align-items: center;
    justify-content: center;
    align-self: stretch;

    .EstMonthly {
      display: flex;
      text-align: center;
      color: var(--ir-neutral-black-100);
      flex-direction: column;
      align-items: center;
      font-weight: 600;

      .Text {
        font-size: rem(14px);
      }

      .LargetText {
        font-size: rem(18px);
      }
    }
  }

  .PillContainer {
    font-size: rem(14px);
    font-weight: 600;
    text-transform: uppercase;

    [class*='Heading'] {
      font-size: rem(14px);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .OuterDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 10%;
  }

  .Info {
    display: flex;
    align-items: center;
    gap: rem(4px);
    justify-content: space-between;
  }

  .Label {
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
  }

  .Value {
    text-align: right;
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .InformationText {
    color: var(--ir-neutral-black-primary);
    font-weight: 500;
    margin-bottom: rem(12px);
  }
}
