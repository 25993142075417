@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.CustomCLass {
  [class='modal-content'] {
    height: 95vh;
    overflow: hidden;
    border-radius: rem(24px);
  }
}

.CustomHeader {
  margin-top: rem(30px);
}

.Approved {
  background-color: #d0f5e0;
  padding: 2px;
  border-radius: 2px;
}

.Denied {
  background-color: #fcdbe3;
  padding: 2px;
  border-radius: 2px;
}

.Archived {
  background-color: #e1eaeb;
  padding: 2px;
  border-radius: 2px;
}

.Active {
  background-color: #e6f8fa;
  padding: 2px;
  border-radius: 2px;
}

.Title {
  gap: 12px;
}

.MainContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.SecondContainer {
  display: flex;
  flex-direction: column;
  padding: 32px 40px;
  gap: 10px;
}

.ContainerHeading {
  text-align: center;
  color: #303333;
  font-size: 24px;
  font-weight: 400;
}

.StatusText {
  font-size: 24px;
  font-weight: 600;
  color: #303333;
}

.NottextContainer {
  text-align: center;
  color: #303333;
  font-size: 16px;
  font-weight: 400;
}

.NottextContainerDenied {
  color: #303333;
  font-size: 16px;
  font-weight: 400;
}

.AdverseLetter {
  font-size: 18px;
  font-weight: 500;
  line-height: 24.51px;
}

.NottextContinue {
  text-align: center;
  color: #303333;
  font-size: 18px;
  font-weight: 400;
}

.NotText {
  font-size: 18px;
  font-weight: 600;
  color: #303333;
}

.ButtonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0 40px;
  margin-bottom: rem(30px);
}

.ButtonDeniedContainer1 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  padding: 0 40px;
  margin-bottom: rem(40px);
}

.ButtonDeniedContainer2 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.deniedInfoBox {
  display: flex;
}

.CancelBtn {
  width: auto;
  height: rem(45px);
  font-size: rem(14px);
  background-color: var(--ir-gray-000);
  color: #303333;
}

.SaveAndCloseBtn {
  width: rem(150px);
  height: rem(45px);
  font-size: rem(14px);
}

.DeniedCancelBtn {
  width: auto;
  height: rem(34px);
  padding: 4px 8px;
  color: #303333;
  font-size: rem(14px);
  background-color: var(--ir-gray-000);
}

.NoBtn {
  width: rem(140px);
  height: rem(34px);
  padding: 4px 8px;
  font-size: rem(14px);
}

.SaveBtn {
  width: rem(150px);
  height: rem(34px);
  padding: 4px 8px;
  font-size: rem(14px);
}

.SaveDeniedBtn{
  width: rem(175px);
  height: rem(34px);
  padding: 4px 8px;
  font-size: rem(14px);
}

@media (max-width: $screen-laptop-md) {
  .CustomCLass {
    [class*='Modal_dialogClassName__'] {
      max-width: unset;
      margin: rem(30px);
    }
  }
}

@media (max-width: 550px) {
  .MainContainer {
    .ContainerHeading {
      display: block;
    }
  }

  .ButtonDeniedContainer1 {
    flex-direction: column-reverse;
  }

  .NottextContainer {
    display: block;
  }

  .SecondContainer {
    .ContainerHeading {
      display: block;
    }
  }

  .SaveBtn {
    width: auto;
    height: auto;
  }
}

@media (max-width: 986px) {
  .MainContainer {
    .ContainerHeading {
      display: block;
    }
  }

  .NottextContainer {
    display: block;
  }

  .SecondContainer {
    .ContainerHeading {
      display: block;
    }
  }

  .SaveBtn {
    width: auto;
    height: auto;
  }
}
