@import '/src/stylesheets/utils';

.container {
  :global(.modal-footer) {
    padding: 0.5rem 2rem;
  }


  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .sendInviteButton {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .button {
    width: fit-content;
    padding: rem(4px) rem(8px);
  }
}
