@import '/src/stylesheets/utils';

.addressEditDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: rem(2px);

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      font-size: 0.6rem;
    }

    [class*='-control'] {
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(15px);
      padding-top: rem(18px);
      font-weight: 400;
      border-radius: rem(4px);
    }

    .textFieldLabel {
      position: absolute;
      top: rem(4px);
      left: rem(16px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }
  }

  .SelectContainer {
    [class*='-control'] {
      padding: rem(4px) 0 rem(4px) rem(16px);
      background: var(--ir-neutral-white-bg);

      [class*='valueContainer'] {
        padding: unset;
        gap: rem(4px);
      }

      [class*='floatingLabel'] {
        color: var(--ir-neutral-black-primary);
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
      }

      &:hover {
        border-color: var(--ir-gray-200);
      }
    }
  }

  .DropDownIconContainer {
    margin-right: rem(16px);
    margin-top: rem(5px);
  }
}
