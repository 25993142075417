@import '/src/stylesheets/utils';

.ResumeSideMenuContainer {
  display: flex;
  flex-direction: column;
  margin-top: rem(48px);
  margin-right: rem(10px);
  margin-left: rem(40px);

  .MenuListContainer {
    display: flex;
    flex-direction: column;
    gap: rem(5px);
  }

  .ItemContainer {
    text-align: left;
    cursor: pointer;
  }

  .ExtraPadding {
    padding-left: rem(9px);
  }

  .LeftBorder {
    border-left: 1px solid var(--ir-gray-220);
  }

  .ItemContentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem(10px);
  }

  .Heading {
    padding-left: rem(6px);
    font-size: rem(12px);
  }

  .StatusPillContainer {
    padding-bottom: rem(2px);
  }

  .LevelOne {
    color: var(--ir-neutral-dark-black-90);
  }

  .LevelTwo {
    padding: rem(2px) rem(0);
    color: var(--ir-warm-gray-060);
  }

  .SelectedLevelOne {
    color: var(--ir-segment-cyan-700);
    border-left: rem(2px) solid var(--ir-segment-cyan-700);
    font-weight: 600;
  }

  .SelectedLevelTwo {
    color: var(--ir-neutral-black-100);
    font-weight: 700;
  }

  .SelectedLevelThree {
    color: var(--ir-segment-cyan-700);
    font-weight: 700;
  }
}
