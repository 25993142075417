@import '/src/stylesheets/utils';

.container {
  margin-bottom: rem(5px);

  .descriptionCard {
    display: flex;
    flex-direction: column;
  }

  .faded {
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    color: var(--ir-neutral-010);
  }

  .topDiv {
    display: flex;
    justify-content: space-between;

    .name {
      font-weight: 600;
      line-height: rem(24px);
      font-size: rem(16px);
      color: var(--ir-warm-gray-800);
    }
  }

  .bottomDiv {
    display: flex;
    justify-content: flex-start;
    flex: 1;
  }

  .otherInfo {
    display: flex;
    flex-direction: column;
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    flex: 1;

    .name {
      color: var(--ir-warm-gray-800);
    }

    .label {
      color: var(--ir-neutral-000);
    }
  }
}
