@import '/src//stylesheets/utils.scss';

.rangeDatePickerContainer {
  display: flex;
  height: rem(50px);
  border: rem(1px) solid var(--ir-gray-200);
  justify-content: flex-start;
  border-radius: rem(4px);
  background-color: var(--ir-neutral-white-bg);
  gap: rem(8px);

  [class*='-popper'] {
    padding-top: unset !important;
    margin-top: rem(-10px);
  }

  [class*='_dateContainer'] {
    width: fit-content;
    border: none;
    background-color: inherit;

    [class*='_datePickerContainer'] {
      [class*='_calendarContainer'] {
        position: relative;
      }
    }
  }

  [class*='_removeBorder'] {
    width: rem(105px);
    font-weight: 400;
    font-size: rem(14px);
    color: var(--ir-neutral-black-100);
    margin-top: rem(-5px);
  }

  .leftRangeDatePicker {
    flex: 1;
  }

  .rightRangeDatePicker {
    flex: 1;
    display: flex;
    gap: rem(8px);

    .verticalLine {
      width: rem(0);
      height: rem(20px);
      align-items: center;
      border-left: rem(1px) solid var(--ir-gray-220);
      margin-top: rem(22px);
    }
  }
}

@media (max-width: 350px) {
  .rangeDatePickerContainer {
    gap: rem(0);

    [class*='_removeBorder'] {
      width: rem(97px);
    }

    .leftRangeDatePicker,
    .rightRangeDatePicker {
      gap: rem(0);

      [class*='_customCalendarIconLeft'] {
        margin-right: rem(0);
        margin-left: rem(2px);
      }
    }
  }
}


