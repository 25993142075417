@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .body {
    margin-top: rem(22px);
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .sendButton {
      display: flex;
      width: fit-content;
      height: fit-content;
      padding: rem(4px) rem(8px);
      align-items: center;
    }
  }

  .EmailAddressField {
    padding-right: 13rem;
  }

  .selectContainer {
    position: absolute;
    top: rem(121px);
    right: rem(47px);
    background: none;

    .description {
      font-size: rem(12px);
      color: var(--ir-neutral-black-primary);
    }

    .label {
      font-size: rem(14px);
      color: var(--ir-warm-gray-800);
    }

    [class$='-container'] {
      min-width: rem(82px);
      width: fit-content;
    }

    [class*='-control'] {
      position: absolute;
      right: 0;
      width: rem(170px);
      height: fit-content;
      border: none;
      background: var(--ir-neutral-white-bg);
      box-shadow: none;
    }

    [class$='-menu'] {
      position: relative;
      top: rem(42px);
      right: 0;
      left: 0;
      width: 50.2rem;
    }

    [class$='-ValueContainer'] {
      min-height: unset;
      height: rem(32px);
    }

    [class$='-singleValue'] {
      font-size: rem(14px);
      font-weight: 600;
      letter-spacing: 0;
      text-align: left;
    }
  }

  .fieldLabel {
    color: var(--ir-gray-1050);
    font-size: rem(18px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(19px);

    .smallerText {
      color: var(--ir-neutral-black-primary);
      font-size: rem(14px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .fieldLabelLighter {
    color: var(--ir-gray-1050);
    font-size: rem(14px);
    font-style: normal;
    line-height: rem(19px);
  }

  .disabled {
    [class$='-control'] {
      background-color: var(--ir-warm-gray-035);
    }
  }

  .fieldsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    [class='formik-field'] {
      width: 85%;
    }
  }

  .orgTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: rem(5px);
    margin: rem(10px) 0;

    .text {
      color: var(--ir-neutral-black-primary);
      font-size: rem(16px);
      font-weight: 600;
      line-height: normal;
    }

    .lighterText {
      font-size: rem(14px);
      color: var(--ir-neutral-black-primary);
    }
  }

  .permissions {
    display: flex;
    justify-content: space-between;

    [class='form-check-input'] {
      width: rem(16px);
      height: rem(16px);
    }

    .checkboxLabel {
      display: flex;
      color: var(--ir-neutral-black-primary);
      font-size: rem(12px);
      line-height: rem(14px);
      font-weight: 600;
      gap: rem(10px);
      align-items: flex-end;
    }
  }

  .permissionsError {
    min-height: rem(20px);

    [class^='ErrorMessage'] {
      padding-left: 0;
    }
  }
}

// If screen size is less than 991px wide
@media (max-width: 991px) {
  .container {
    .fieldsDiv {
      flex-direction: column;

      [class='formik-field'] {
        width: 100%;
      }
    }

    .selectContainer {
      [class$='-menu'] {
        width: 28.9rem;
      }
    }
  }
}

.checboxAdditionalText {
  margin-top: rem(5px);
  margin-bottom: rem(10px);
  padding: rem(0) rem(22px);
  font-size: rem(10px);
  font-weight: 400;
}

// If screen size is less than 575px wide
@media (max-width: 575px) {
  .container {
    .selectContainer {
      [class$='-menu'] {
        width: 34.2rem;
      }
    }
  }
}

@media (max-width: 550px) {
  .container {
    .selectContainer {
      [class$='-menu'] {
        width: 100%;
      }
    }
  }
}
