@import '/src/stylesheets/utils';

.Container {
  display: flex;
  gap: rem(10px);
  text-transform: uppercase;

  .PillContainer {
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 0 rem(3px);
    align-items: center;
    border-radius: rem(2px);
    font-size: rem(12px);
    gap: rem(2px);

    .Heading {
      text-transform: capitalize;
    }
  }

  .Italic {
    font-style: italic;
  }

  .Danger {
    background: var(--ir-danger-pill);
    color: var(--ir-danger-pill-text);
  }

  .Success {
    background: var(--ir-success-pill);
    color: var(--ir-gray-000);
  }

  .Warning {
    background: var(--ir-warning-pill);
    color: var(--ir-warning-pill-text);
  }

  .WarningDark {
    background-color: var(--ir-semantics-warning-dark);
    color: var(--ir-neutral-dark-black-90);
  }

  .SuccessLight {
    background: var(--ir-success-light-pill);
    color: var(--ir-success-light-pill-text);
  }

  .Secondary {
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
  }

  .DangerLight {
    background: var(--ir-danger-pill);
    color: var(--ir-field-error);
  }

  .Links {
    background: var(--ir-warm-gray-040);
    color: var(--ir-segment-cyan-700);
  }

  .PrimaryLight {
    color: var(--ir-segment-cyan-700);
    background-color: var(--ir-warm-gray-040);
  }

  .MidnightSteel {
    color: var(--ir-neutral-black-primary);
    background-color: var(--ir-gray-200);
  }

  .WarnGrey {
    color: var(--ir-gray-000);
    background-color: var(--ir-warm-gray-060);
  }
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    max-width: 10rem;
  }
}
