@import '/src/stylesheets/utils';

.renterFurtherInformation {
  display: flex;
  flex-direction: column;

  .textContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: rem(24px);
    padding-bottom: rem(24px);

    .heading {
      font-style: normal;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(24px);
      color: var(--ir-warm-gray-800);
    }

    .questionsContainer {
      display: flex;
      flex-direction: column;
      gap: rem(8px);
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(20px);

    .renterButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}
