@import '/src/stylesheets/utils';

.vehicleDescriptionCard {
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: rem(8px);
  flex-direction: column;

  .heading {
    align-self: stretch;
    color: var(--ir-gray-1050);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px);
    word-wrap: break-word;

    &.FontContainer {
      font-size: rem(18px);
    }
  }

  .faded {
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    color: var(--ir-neutral-010);
  }

  .infoRow {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem(14px);
    display: inline-flex;

    .infoItem {
      flex: 1 1 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      display: inline-flex;

      .label {
        width: rem(96px);
        color: var(--ir-gray-1050);
        align-self: stretch;
        font-size: rem(10px);
        font-weight: 600;
        word-wrap: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.FontContainer {
          font-size: rem(16px);
        }
      }

      .subLabel {
        align-self: stretch;
        color: var(--ir-neutral-000);
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
        word-wrap: break-word;

        &.FontContainer {
          font-size: rem(14px);
        }
      }
    }
  }
}

@media (max-width: 744px) {
  .vehicleDescriptionCard {
    .infoRow {
      .infoItem {
        .label {
          width: rem(60px);
        }
      }
    }
  }
}
