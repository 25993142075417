@import '/src/stylesheets/utils';

.RenterPropertyCard {
  border-radius: 0.4rem;
  margin-top: 1rem;
  width: rem(410px);
  border: 1px solid var(--ir-gray-200);
  align-self: center;
}

.RenterPropertyCardBody {
  padding: rem(8px);
  border: 1px solid var(--ir-gray-200);
  border-radius: rem(5px) rem(5px) 0 0;
  border-bottom: 0;
}

.SelectedPropertyCard {
  background-color: var(--ir-neutral-white-bg);
  border: rem(2px) solid var(--ir-segment-blue-83);
}

.PropertyImageContainer {
  flex: 0.5;
  padding-right: rem(8px);
}

.PropertyDetailsContainer {
  flex: 1;
  text-align: left;
  padding-left: 0;
}

.StreetAddressContainer {
  display: flex;
  gap: 0.5rem;

  .LocationSvg {
    min-width: rem(16px);
    margin-top: rem(2px);
  }
}

.PropertyImage {
  object-fit: cover;
  width: rem(81px);
  height: rem(81px);
}

.PropertyDescription {
  font-weight: 600;
  font-size: rem(10px);
  color: var(--ir-warm-gray-800);
}

.MarginTop {
  margin-top: 0.5rem;
}

.PropertyRentContainer {
  display: flex;
  text-align: center;
  font-size: rem(14px);
  justify-content: center;
  margin-top: 1.2rem;
}

.PaddingLeft {
  margin-left: 1.7rem;
}

.PaddingNone {
  padding: 0;
  line-height: 1rem;
  color: var(--ir-neutral-000);
}

.PropertyTitle {
  font-size: rem(16px);
  color: var(--ir-warm-gray-800);
}

.LighterText {
  font-size: rem(15px);
  color: var(--ir-warm-gray-060);
}

.ButtonContainer {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3rem;

  .SelectButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
    font-size: rem(16px);
    font-weight: 600;
    margin-left: 2.5rem;
    margin-top: 0.3rem;
  }

  .SelectButtonActive {
    border: transparent;
    color: var(--ir-warm-gray-100);
    background-color: var(--ir-segment-blue-83);
  }
}

.PropertyAgentInfoContainer {
  background-color: var(--ir-neutral-white-bg);
  padding: 0.5rem;
  border: 1px solid var(--ir-gray-200);
  border-radius: 0 0 5px 5px;
}

.AddressContainer {
  display: flex;
  text-align: start;
  flex-direction: column;
}

@media (min-width: $screen-tablet) {
  .RenterPropertyCard {
    .SelectButton {
      margin-top: 1rem;
    }

    .PropertyImage {
      width: rem(161px);
      height: rem(133px);
    }
  }
}

@media (max-width: $screen-tablet) {
  .RenterPropertyCard {
    width: 98%;
    min-width: rem(327px);
  }
}

@media (max-width: $screen-mobile-portrait) {
  .RenterPropertyCard {
    min-width: 18rem;
  }
}
