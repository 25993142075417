@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(4px) rem(8px);
  align-items: flex-end;
  gap: rem(22px);
  align-self: stretch;
  border-radius: rem(8px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);

  .DocumentDetails {
    display: flex;
    padding: rem(4px) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .DocumentHeading {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    text-transform: capitalize;
  }

  .DetailsInformation {
    display: flex;
    padding: rem(4px) rem(2px);
    align-items: center;
    gap: rem(12px);
    align-self: stretch;
    border-radius: 0 rem(4px) rem(4px) 0;
  }

  .DocumentId {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .State {
    display: flex;
    padding: 0 rem(2px);
    align-items: flex-start;
    border-radius: rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .Documents {
    display: flex;
    gap: rem(10px);
    max-width: rem(300px);
    overflow-x: auto;
  }

  [class*='Document'] {
    width: fit-content;
  }
}
