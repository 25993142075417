@import '/src/stylesheets/utils';

.ResidencyContainer {
  .HeadingContainer {
    display: flex;
    padding: 0 0 rem(12px) rem(0);
    gap: rem(8px);
    justify-content: space-between;
    align-items: center;
  }

  .TitleContainer {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .DocumentContainer {
    margin-bottom: rem(5px);
  }

  .Title {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .AttachmentRow {
    margin-top: rem(5px);
    display: flex;
    padding: rem(4px) rem(8px);
    background-color: var(--ir-neutral-white-bg);
    border: 1px solid var(--ir-gray-200);
    overflow-x: scroll;
    white-space: nowrap;
  }

  .AttachmentRow::-webkit-scrollbar {
    display: none;
  }

  .ResponseNotSubmitted {
    width: 100%;
    font-size: rem(20px);
    font-weight: 600;
    text-align: center;
  }

  [class*='_SupportingDocuments'] {
    [class*='_Document'] {
      width: 100%;
      margin-right: unset;
    }
  }
}
