@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: rem(12px);
  align-self: stretch;
  border-radius: rem(4px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-gray-000);

  .BankInformationContainer {
    display: flex;
    align-items: center;
    gap: rem(12px);
    align-self: stretch;
  }

  .DropDownIcon {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .ShowInformation {
    transform: rotate(90deg);
    transition: transform 0.5s ease;
  }

  .BankIcon {
    width: rem(24px);
    height: rem(24px);
    border-radius: rem(3px);
  }

  .BankInformation {
    display: flex;
    padding: 0 rem(12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .BankName {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-weight: 600;
  }

  .AccountInformation {
    display: flex;
    align-items: center;
    gap: rem(8px);
  }

  .Type {
    padding: 0 rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .Number {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
  }

  .Currency {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .Balance {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
  }
}
