@import '/src/stylesheets/utils';

.container {
  position: relative;
  padding: rem(8px) rem(21px);
  border: 1px solid var(--ir-gray-200);
  border-radius: rem(4px);

  .IconButton {
    margin: unset;
    padding: unset;
    border: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }

  .RowContainer {
    padding-top: rem(4px);
  }

  .FloatingLabel {
    position: absolute;
    top: rem(-8px);
    left: rem(8px);
    padding: 0 rem(2px);
    background: var(--ir-neutral-black-primary);
    color: var(--ir-gray-000);
    align-items: center;
    gap: rem(10px);
    border-radius: rem(2px);
    font-size: rem(11px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .title {
    display: flex;
    align-items: center;
    gap: rem(10px);
  }

  .displayImage {
    object-fit: cover;
    width: rem(90px);
    height: rem(120px);
  }

  .valueText {
    margin-right: rem(10px);
    color: var(--ir-warm-gray-800);
    font-weight: 600;
    font-size: rem(10px);
    text-overflow: ellipsis;
    overflow: hidden;

    &.FontSizeContainer {
      font-size: rem(16px);
    }
  }

  .editIcon {
    text-align: end;
  }

  .alignImage {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .labelText {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-000);

    &.FontSizeContainer {
      font-size: rem(14px);
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: initial;
    gap: rem(10px);
  }

  .infoContainer {
    display: flex;
    flex-direction: row;
  }

  .typeValueContainer {
    font-size: rem(16px);
    font-weight: 600;
    text-align: left;
    color: var(--ir-neutral-black-primary);
  }

  .PillContainer {
    display: flex;
    align-items: center;
    gap: rem(12px);
    justify-content: flex-end;
  }

  .typeLabel {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background-color: var((--ir-gray-200));
    color: var(--ir-warm-gray-800);
    font-size: rem(10px);

    &.FontSizeContainer {
      font-size: rem(14px);
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.displayAtEnd {
  text-align: end;
}

.furryFriendTypeDiv {
  justify-content: end;
}

.PillsSizeContainer {
  font-weight: 600;
  text-transform: uppercase;
  font-size: rem(14px);
}
