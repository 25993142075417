@import '/src/stylesheets/utils';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: rem(16px);
  font-family: $font-family-open-sans, $font-family-sans-serif;
  font-style: normal;
  color: var(--ir-gray-1050);
  gap: rem(16px);

  .heading {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
    color: var(--ir-gray-1050);
  }

  .subHeading {
    font-weight: 400;
    font-size: rem(18px);
    line-height: rem(24px);
  }

  .buttonsContainer {
    display: flex;
    gap: rem(40px);
    padding-bottom: rem(50px);
  }
}

.contentClassName {
  margin-top: rem(100px);
  border: none;
  border-radius: rem(24px);
}
