@import '/src/stylesheets/utils';

.container {
  color: var(--ir-neutral-000);
  margin-top: rem(5px);

  :global(.form-control) {
    position: relative;
    height: rem(55px);
    border: 1px solid var(--ir-gray-200);
    border-radius: 4px;

    &.prefixStringPadding {
      padding-left: 20px;
    }

    &:focus {
      border: 1px solid var(--ir-gray-200);
      box-shadow: none;
    }
  }

  .error {
    background-color: var(--ir-field-error-background) !important;
    border: 1px solid var(--ir-field-error);

    &:focus {
      border: 1px solid var(--ir-field-error);
    }
  }

  .icon {
    display: flex;
    position: absolute;
    top: 32%;
    right: 16px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .prefixString {
    position: absolute;
    top: 44%;
    color: var(--ir-warm-gray-800);
    align-items: center;
    font-size: rem(16px) ;
    font-weight: 600;
    justify-content: center;
    line-height: rem(20px);
    margin-left: rem(10px);
    z-index: 1;
  }

  .phone_number {
    width: 100%;
    height: 40px;
    padding: 1rem;
    border: 1px solid var(--ir-gray-200);
    background-color: var(--ir-neutral-white-bg);
    border-radius: 4px;

    &:focus {
      border: 1px solid var(--ir-gray-200);
      box-shadow: none;
      outline: none;
    }
  }

  :global(.form-floating > label) {
    top: -8px;
  }
}
