@import '/src/stylesheets/utils';

.EmptyContainer {
  display: flex;
  align-self: center;
  margin: auto;
}

.CustomPropertyHeader {
  padding: 1.5rem 1rem 0;
}

.RenterH2 {
  text-align: start;
  color: var(--ir-neutral-black-100);
  font-weight: 500;
  font-size: rem(18px);
  margin-bottom: 2rem;
}

.CustomPropertyCardContainer {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
}

.CustomPropertyDateContainer {
  width: 95%;
  margin-bottom: 1rem;
}

.PropertiesListSection {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;

  .MoveInDateContainer {
    padding: rem(10px) 0;
    border: 1px solid var(--ir-gray-200);
    border-top: unset;
    border-bottom-left-radius: rem(4px);
    border-bottom-right-radius: rem(4px);
    background-color: var(--ir-neutral-white-bg);
  }

  .MoveInDateHeading {
    text-align: left;
    color: var(--ir-neutral-black-100);
    font-size: rem(18px);
    padding-left: rem(10px);
  }

  .MoveInDate {
    width: 100%;
    padding: 0 rem(10px);
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(20px) rem(30px);
  }

  .Title {
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    color: var(--ir-neutral-black-100);
  }
}

.PropertiesListContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  align-items: center;
  flex-direction: column;
}

.MaxHeightWhenSelected {
  max-height: calc(65vh);
}

.ContinueButtonContainer {
  width: 100%;
  padding: 1rem;
  background-color: var(--ir-warm-gray-100);
  text-align: end;
  padding-top: 0.5rem;
  margin-top: rem(10px);

  .Button {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .PrimaryButton {
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
  }
}

@media (max-width: $screen-mobile-navbar) {
  // .MaxHeightWhenSelected {
  //   max-height: calc(58vh + 50px);
  // }

  .ContinueButtonContainer {
    margin-top: rem(20px);
    padding-bottom: 0;

    .Button {
      padding: 0.4rem;
    }
  }
}
