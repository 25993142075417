@import '/src/stylesheets/utils';

.container {
  background-color: var(--ir-neutral-white-bg);
  height: inherit;
  overflow-y: hidden;
  padding-top: rem(149px);
  text-align: center;
}

@media (max-height: 680px) {
  .container {
    padding-top: rem(50px);
  }
}

@media (max-height: 580px) {
  .container {
    padding-top: rem(20px);
  }
}

@media (min-width: 992px) {
  .container {
    text-align: left;
  }
}

@media (max-width: 575px) {
  .container {
    height: 100vh;
    background: linear-gradient(155deg, rgb(212 212 212 / 52.3%) 6%, var(--ir-segment-blue-750) 88%);
    text-align: center;
    overflow-y: hidden;
    padding-top: 128px;
  }
}

.containerRow {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

.signUpCol {
  display: flex;
  justify-content: center;
  position: relative;
  width: 32rem;
}

@media (max-width: 575px) {
  .signUpCol {
    display: block;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    width: 100%;
    padding: 0;
  }
}

@media (min-width: 575px) and (max-width: 767px) {
  .signUpCol {
    width: 30rem;
  }
}

.homeDesignTop {
  position: absolute;
  bottom: 46%;
  left: 41%;
  max-width: 369px;
  z-index: 0;
}

@media (max-width: 575px) {
  .homeDesignTop {
    position: absolute;
    bottom: 70%;
    left: 41%;
    max-width: 369px;
    z-index: 0;
  }
}

.homeDesignBottom {
  position: absolute;
  top: 90%;
  right: 33%;
  max-width: 369px;
  z-index: 1;
}

@media (max-width: 575px) {
  .homeDesignBottom {
    position: absolute;
    top: 60%;
    right: 47%;
    max-width: 369px;
    z-index: 1;
  }
}

.cardContainer {
  position: relative;
  width: 90%;
  border: none;
  border-radius: 24px;
  box-shadow: rgb(0 0 0 / 4%) 0 3px 5px;
}

@media (max-width: 575px) {
  .cardContainer {
    position: relative;
    width: 100%;
    border: none;
    border-radius: 24px;
    box-shadow: rgb(0 0 0 / 4%) 0 3px 5px;
    padding-bottom: 100%;
  }
}

.privacyPolicyContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--ir-neutral-black-primary);
  font-size: 12px;
  font-weight: 400;
  gap: 5px;
  line-height: 14px;
  margin-bottom: 32px;
  margin-top: 5px;
}

.createBtn {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.loginLink {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  width: fit-content;
  text-decoration: none;
  color: var(--ir-segment-cyan-900);

  &:hover {
    color: var(--ir-segment-cyan-900);
    opacity: 0.7;
  }
}

.btnPrimary {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: rem(12px) rem(40px);
}

.googleContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.loginHeading {
  display: flex;
  color: var(--ir-warm-gray-800);
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  justify-content: center;
  line-height: 40px;
  margin-bottom: 32px;
}

.btnGoogle {
  display: flex;
  padding: 7px 25px;
  border: 1px solid rgb(0 0 0);
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  flex: auto;
  font-size: 18px;
  font-weight: 600;
  gap: 10px;
  line-height: 24px;
}

.PasswordField {
  padding-right: 3rem !important;
}

.googleText {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
}

.orLine {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;

  .orText {
    margin: 0 6px;
    color: var(--ir-neutral-default-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.line {
  width: stretch;
}

.rememberMePasswordContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.ForgotPassword {
  text-align: center;
}

.ForgotPasswordLink {
  width: fit-content;
  color: var(--ir-segment-cyan-900);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-700);
    opacity: 0.7;
  }
}

.SuccessMessage {
  text-align: center;
  color: var(--color-dark-green);
  font-weight: 600;
  font-size: rem(16px);
  margin-top: rem(10px);
}
