.errorMessage {
  text-align: left;
  color: var(--ir-field-error);
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.3rem;
}

.errorMessage::first-letter {
  text-transform: capitalize;
}
