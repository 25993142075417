.irBtn {
  padding: 12px 30px;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 #0000000d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  &:global(.ir-btn-primary) {
    background-color: var(--ir-segment-cyan-900);
    border: 1px solid var(--ir-segment-cyan-900);
    box-shadow: 0 2px 4px rgb(147 157 177 / 30%);
    color: var(--ir-gray-050);

    &:hover {
      background-color: var(--ir-segment-cyan-700);
      opacity: 1;
    }
  }

  &:global(.ir-btn-sm) {
    padding: 4px 8px !important;
  }

  &:global(.ir-btn-outline) {
    background-color: transparent;
    border: 1px solid var(--ir-warm-gray-800);
    box-shadow: 0 2px 4px rgb(147 157 177 / 30%);
    color: var(--ir-warm-gray-800);
  }

  &:global(.ir-btn-link) {
    padding: 0;
    border: 0;
    color: var(--ir-warm-gray-800);
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &:global(.ir-btn-link-primary) {
    padding: 0;
    border: 0;
    color: var(--ir-segment-cyan-900);
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }
}
