@import '/src/stylesheets/utils';

.Container {
  .ActiveStatus {
    width: 100%;
    margin-bottom: rem(2px);
    text-align: end;
  }

  .HeaderContainer {
    background: var(--ir-warm-gray-030);
  }

  .CapsuleItem {
    display: flex;
    width: 100%;
    padding: rem(5px) rem(15px);
    border-radius: rem(5px);
    gap: rem(15px);
    justify-content: space-between;
  }

  .SkeletonItem {
    flex: 1;
    width: 100%;
  }

  .ApplicationContainer {
    display: flex;
    width: 100%;
    gap: rem(5px);
    align-items: center;
    border: 1px solid var(--ir-warm-gray-030);
  }

  .Application {
    display: flex;
    width: 100%;
    text-align: start;
    flex-direction: column;
    gap: rem(5px);
    flex: 1;
  }

  .NameContainer {
    display: flex;
    flex-direction: column;
    gap: rem(1px);
  }
}
