@import '/src/stylesheets/utils';

.container {
  width: fit-content;
  color: var(--ir-neutral-000);

  .formCheck input {
    width: 1rem;
    height: 1rem;
    box-shadow: none;
  }

  .formCheck input:checked {
    background-color: var(--ir-neutral-000);
    border: $border-base;
    box-shadow: none;
  }
}

@media (max-width: $screen-tablet) {
  .container {
    height: 100%;

    .formCheck {
      height: 100%;
    }

    .formCheck input {
      position: relative;
      top: 1px;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    .formCheck label {
      padding-left: rem(4px);
      padding-top: rem(4px);
    }
  }
}
