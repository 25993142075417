@import '/src/stylesheets/utils';

.vehiclesForm {
  .formikField {
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }

  .userLabel {
    position: absolute;
    top: 0.4rem;
    left: 0.8rem;
    z-index: 1;
    font-weight: 600;
    font-size: rem(10px);
    line-height: rem(14px);
    color: var(--ir-neutral-010);
  }

  .inputBackgroundColor {
    background-color: var(--ir-neutral-white-bg);
  }

  .lisenceStateContainer {
    display: flex;
    width: 100%;
    border-radius: rem(4px);

    .lisenceFieldContainer {
      flex: 4;

      [class*='-control'] {
        border-right: none;
      }

      .textFieldLabel {
        top: rem(9px);
      }
    }

    .stateFieldContainer {
      flex: 2;

      [class*='-control'] {
        border-left: none;
        padding-top: rem(8px);
        margin-left: rem(-5px);
        font-weight: 400;
      }

      [class*='-control'] > :last-child {
        margin-right: rem(17px);
      }

      [class^='ReactSelect_error'] {
        [class$='-control'] {
          border: 1px solid var(--ir-gray-200) !important;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: none !important;
          background-color: var(--ir-field-error-background);
        }
      }

      .textFieldLabel {
        top: rem(9px);
      }
    }
  }

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);

    [class*='-control'] {
      height: rem(55px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(15px);
      padding-top: rem(18px);
      font-weight: 400;
      border-radius: rem(4px);
    }

    .textFieldLabel {
      position: absolute;
      top: rem(4px);
      left: rem(16px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }
  }
}
