@import '/src/stylesheets/utils';

.InactiveButton {
  display: flex;
  width: rem(73px);
  height: rem(24px);
  padding: rem(10px) rem(20px);
  border: rem(2px) solid var(--ir-neutral-black-05) !important;
  background: var(--ir-gray-000);
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: none;
  border-radius: rem(100px);
  gap: rem(2px);
}

.Circle {
  min-width: rem(16px);
  min-height: rem(16px);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  background-color: var(--ir-warning-pill-text);
  transform: translateX(0);
}

.CircleActive {
  transform: translateX(40px);
  background-color: var(--ir-neutral-green-500);
}

.Text {
  font-size: rem(10px);
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  color: var(--ir-warning-pill-text);
}

.TextActive {
  transform: translateX(-15px);
  color: var(--ir-neutral-green-500);
}
