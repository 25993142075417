@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.container {
  align-items: center;
  background-color: var(--ir-segment-cyan-300);
  flex-direction: column;
  justify-content: center;
  width: 100%;

  [class$='row'] {
    --bs-gutter-x: 0;
  }
}

.propertyCount {
  color: var(--ir-warm-gray-500);
  font-size: rem(16px);
  font-weight: 400;
  margin-left: 0.9rem;
  margin-top: 1.2rem;
}

.propertyStatus {
  align-items: baseline;
  display: flex;
  font-size: rem(32px);
  font-weight: 300;
  gap: 0.5rem;
  justify-content: flex-start;
  padding-left: 10%;
}

.verticalLine {
  border-left: 1px solid rgb(0 0 0);
  height: rem(25px);
  margin-top: rem(11px);
}

.activeOption {
  color: rgb(0 0 0);
}

.horizontalLine {
  border-top: 1px solid var(--ir-neutral-050);
  width: 100%;
}

.applicantButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 10%;
  padding-top: 0.3rem;
  position: relative;
}

.dashButton {
  width: rem(177px);
  height: rem(39px);
  font-size: rem(13px);
  padding-top: 0.6rem;
}

.propertyRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 2rem;
  background-color: var(--ir-warm-gray-100);

  [class*='-ValueContainer'] {
    flex-direction: row;
    align-items: center;
    border: 1px solid var(--ir-warm-gray-100);
  }

  [class*='-control'] {
    border: none;
  }

  [class*='_floatingLabel'] {
    color: var(--ir-warm-gray-800);
    font-size: rem(32px);
    font-weight: 400;
  }

  [class*='-singleValue'] {
    display: none;
  }

  [class*='-option'] {
    padding: 0 rem(2px) 0 rem(20px);
    line-height: rem(20px);
    text-align: left;
  }

  [class*='ReactSelect_container'] {
    width: 15rem;
  }
}

.propertyRowLast {
  display: flex;
  justify-content: center;
  padding-top: 3.5rem;
}

.statusDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 11%;
  padding-top: 4%;
  text-align: start;
}

.statusDetailsH2 {
  font-size: rem(32px);
  font-weight: 400;
  line-height: rem(36px);
}

.statusDetailsP {
  font-size: rem(16px);
  font-style: normal;
  font-weight: 300;
  line-height: rem(30px);
}

.applicantImg img {
  padding-left: 10%;
}

.linkedButton {
  width: fit-content;
  padding: 0;
  border: none !important;
  text-align: start;
  box-shadow: none !important;
  outline: none !important;
}

.addProperty {
  width: fit-content;
  height: 2rem;
  padding: 0 1rem;
}

@media (max-width: 1150px) {
  .applicantImg img {
    width: 98%;
    height: auto;
    padding-left: 0%;
  }

  .statusDetails {
    width: 90%;
  }

  .propertyRowLast {
    padding-bottom: 10%;
  }

  .propertyRow {
    [class*='_floatingLabel'] {
      font-size: 1.4rem;
    }

    [class*='ReactSelect_container'] {
      width: 10rem;
    }

    .propertyStatus {
      font-size: 1.4rem;
      justify-content: center;
      padding-left: 5%;
    }
  }
}

@media (max-width: 900px) {
  .applicantButton {
    justify-content: center;
    padding-right: 0%;
  }

  .propertyCount {
    color: var(--ir-warm-gray-500);
    font-weight: 400;
    margin-left: 0.1rem;
    margin-top: 0.5rem;
  }

  .verticalLine {
    height: rem(15px);
  }
}

.propertyIndexPageBackground {
  background-color: var(--ir-segment-cyan-300);
  height: 100%;
  padding-bottom: 3rem;
}

.alignWithFlex {
  align-items: center;
  display: flex;
}

.alignWithFlexHorizontalAlign {
  display: flex;
  justify-content: center;

  .MarketingStatusContainer {
    display: flex;
    gap: rem(2px);
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .Circle {
    min-width: rem(10px);
    min-height: rem(10px);
    max-height: rem(10px);
    border-radius: 50%;
    background-color: var(--ir-warning-pill-text);
  }

  .CircleActive {
    background-color: var(--ir-neutral-green-500);
  }

  .Text {
    font-size: rem(11px);
    font-weight: 600;
    color: var(--ir-warning-pill-text);
  }

  .TextActive {
    color: var(--ir-neutral-green-500);
  }
}

.gapWithFlex {
  display: flex;
  flex-direction: column;
  gap: rem(2px);
}

.horizontalAlign {
  justify-content: end;
}

.textAlignCenter {
  text-align: center;
}

.textAlignEnd {
  text-align: end;
}

.PropertyDetailPadding {
  padding: rem(6px) rem(0) rem(0) rem(16px);
  text-align: left;
}

.propertyPadding {
  padding: rem(10px) 0 rem(26px) 0;
  text-align: end;
}

.propertyRowForData {
  position: relative;
  width: rem(1206px);
  padding: rem(5px);
  border: 1px solid var(--ir-warm-gray-030);
  background-color: var(--ir-warm-gray-100);
  margin-top: rem(20px);

  p {
    margin: 0;
  }
}

.addPadding {
  padding-top: rem(13px);
  cursor: pointer;
  font-weight: 400;
  font-size: rem(12px);
  color: var(--ir-neutral-black-primary) !important;

  &:hover {
    color: var(--ir-neutral-black-100) !important;
  }
}

.addTopPadding {
  padding-top: rem(45px);
}

.addLeftPadding {
  padding-left: rem(396px);
}

.addRightLarge {
  right: rem(138px);
}

.marketingLink {
  height: rem(16px);
  color: var(--ir-neutral-000);
  font-size: rem(12px);
  font-weight: 400;
  text-decoration: none;

  &.marketingLinkInactive {
    color: var(--ir-semantics-inactive);
  }
}

.ellipsisText {
  width: rem(270px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.propertyTitle {
  font-size: rem(16px);
  font-weight: 600;
  line-height: rem(22px);
  text-align: left;
  cursor: pointer;
}

.propertyText {
  color: var(--ir-neutral-dark-black-90);
  font-size: rem(12px);
  font-weight: 400;
  line-height: rem(16.34px);
}

.propertyTextlarge {
  color: var(--ir-neutral-dark-black);
  font-size: rem(14px);
  font-weight: 400;
  line-height: rem(16.34px);
}

.propertyLinks {
  color: var(--ir-segment-cyan-700);
  font-size: rem(12px);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;

  span {
    color: var(--ir-segment-cyan-700);
  }
}

.verticleLine {
  width: 1px;
  height: 100%;
  border: 1px solid var(--ir-warm-gray-030);

  @media (max-width: 575px) {
    display: none;
  }
}

.propertyLinksLarge {
  height: rem(16px);
  border: none;
  color: var(--ir-segment-cyan-700);
  background-color: transparent;
  font-size: rem(14px);
  font-weight: 600;
  text-decoration: none;

  &.disabledInvite {
    color: var(--ir-segment-cyan-light);
  }

  &:hover {
    color: var(--ir-segment-cyan-900);
    cursor: pointer;
  }
}

.propertyCheckbox {
  position: absolute;
  top: 5%;
  left: 1%;

  &.noImage {
    input {
      border: 1px solid var(--ir-neutral-000);
      background: transparent;
    }
  }

  input {
    border: 1px solid var(--ir-neutral-white-bg);
    background: transparent;
  }
}

.displayInline {
  display: inline-block;
  text-align: center;
}

.imageContainer {
  height: rem(100px);
}

.propertyImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.titleMinWidth {
  display: inline-block;
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  min-width: rem(62px);
}

.applicanttext {
  font-size: rem(12px);
  font-weight: 400;
  letter-spacing: 0;
  line-height: rem(16px);
  text-align: center;
}

.applicantTitle {
  color: var(--ir-neutral-black-primary);
  font-size: rem(10px);
  font-weight: 600;
  line-height: rem(13.62px);
}

.applicants {
  gap: rem(7px);
  position: relative;
  height: 100%;
}

@media (max-width: 575px) {
  .verticleLine {
    display: none;
  }

  .propertyCheckbox {
    position: absolute;
    top: 2%;
    left: 3%;

    input {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

// Card

.grid {
  background-color: var(--ir-segment-cyan-300);
}

.grid_container {
  display: grid;
  width: 85%;
  margin: 0 auto;
  padding: 2rem 1rem;
  grid-template-columns: repeat(auto-fit, minmax(294px, 294px));
  justify-content: flex-start;
  gap: rem(5px);

  .propertyRowForData {
    width: auto;
    padding: 0;
    border: none;
    margin-top: 0;
  }

  .propertyRowForData,
  .alignWithFlexHorizontalAlign {
    width: 100%;
  }

  .displayInline {
    line-height: 1rem;
  }

  .dollarIcon {
    font-size: 1rem;
  }

  .propertyText {
    display: inline-block;
    text-align: left;
    margin-bottom: 0.3rem;
  }

  .orgOwner {
    text-align: left;
    margin-top: -1rem;
  }

  .cardBody {
    margin-top: 1rem;
  }

  .divider {
    border: 0.8px solid var(--ir-neutral-black-200);
    border-radius: rem(5px);
    margin-top: 1rem;
  }

  .removePropertyTransactions {
    text-align: left;
    line-height: 1rem;
  }

  .leftPropertyInvite {
    text-align: right;
  }

  .stackCardTitleMinWidth {
    width: rem(75px);
  }

  .gapWithFlex {
    gap: 0.4rem;
    margin-top: 0.4rem;
  }

  .stackCardPropertyType {
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    color: var(--ir-warm-gray-800);
  }

  .stackCardToggleRow {
    align-items: baseline;
    margin-top: -0.3rem;
  }

  .propertyImage {
    width: rem(142px);
    height: rem(100px);
  }

  .propertyCheckbox {
    top: 0;
    left: 0.6rem;

    input {
      border-radius: 0.2rem;
    }
  }

  .applicantTitle {
    display: inline-block;
  }

  .applicantTitleBlock {
    display: block;
  }

  .propertyFooter {
    height: rem(77px);
  }
}

.card {
  display: flex;
  width: rem(294px);
  padding: 0.5rem;
  border: 1px solid var(--ir-gray-200);
  justify-content: center;
  align-items: center;
  font-size: rem(24px);
  border-radius: rem(4px);
  background-color: var(--ir-warm-gray-100);
  padding-bottom: 3.7rem;
  margin-bottom: 1rem;
}

@media (max-width: $screen-mobile-navbar) {
  .grid_container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: $screen-mobile-navbar) {
  .grid_container {
    padding-bottom: 6rem;
  }
}

.propertyActiveHouseHold {
  display: flex;
  flex-direction: row;
  align-items: center;

  .active {
    width: fit-content;
    color: var(--ir-neutral-black-100);
    font-style: normal;
    font-weight: 400;
    font-size: rem(14px);
    padding-right: 0.5rem;
  }

  .households {
    position: relative;
    width: fit-content;
    background: var(--color-neutral-cream-white-100);
    color: var(--ir-neutral-dark-black-90);
    font-style: normal;
    font-weight: 400;
    font-size: rem(12px);
    margin-top: 0.1rem;
  }
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
}

.avatarRow {
  padding-bottom: 0.1rem;
}

.overLapIcons {
  margin-left: -0.7rem;
}

.applicantInviteSection {
  justify-content: center;
}

.houseHoldRow {
  flex-direction: column;
}

.propertyCreationPosition {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.4rem;
  width: rem(294px);

  .propertyText {
    font-style: normal;
    font-weight: 600;
    font-size: rem(10px);
  }
}

.filterRow {
  display: flex;
  background-color: var(--ir-warm-gray-100);
  width: 100%;
  justify-content: center;

  .viewToggle {
    gap: 1rem;
    justify-content: flex-end;
    display: flex;
    flex: 0.8 0;
    padding: 1rem 0;
    cursor: pointer;

    :hover {
      color: var(--ir--neutral-sea-blue-400);
    }
  }
}

.active {
  color: var(--ir--neutral-sea-blue-400);
}

.activeHouseholdPadding {
  margin-top: 0.2rem;
}

.userIconPadding {
  margin-right: 0.1rem;
}

.skeletonWidth80 {
  width: 80% !important;
}

.skeletonWidth30 {
  width: 30% !important;
}

.skeletonWidth60 {
  width: 60% !important;
}

.alignRowItemEnd {
  text-align: end;
  padding-right: 13.5rem;
}

.displayContent {
  display: contents;
}

.containerStartText {
  text-align: start;
}

.breakPadding {
  padding-top: 27px;
}

.skeletonPadding {
  padding-top: 10rem;
}

.emptyPropertyContainer {
  display: flex;
  height: 100%;
  background-color: rgb(255 255 255);

  .emptyPropertyDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .emptyMessageContainer {
    display: flex;
    justify-content: center;
  }

  .emptyMessage {
    width: 80%;
    text-align: justify;
  }

  .iconDiv {
    margin-bottom: rem(32px);
  }

  .textHeading {
    font-size: rem(18px);
    font-weight: 600;
    margin-bottom: rem(16px);
  }
}

.propertyButton {
  position: fixed;
  right: rem(12px);
  bottom: rem(94px);
  width: rem(105px);
  height: rem(50px);
  padding: rem(14px) rem(12px);
  border-radius: 30px;
  z-index: 1033;
  font-size: rem(14px);
  font-weight: 600;
}

.propertyDisplayDiv {
  height: 100%;
}

@media (max-width: $screen-mobile-navbar) {
  .container {
    height: 100%;
  }
}

.manageDetailsPublish {
  border: none;
  background-color: var(--ir-warm-gray-100);
}

.manageDetailsPublish:hover {
  text-decoration: underline;
  cursor: pointer;
}
