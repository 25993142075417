@import '/src/stylesheets/utils';

.QuestionsListContainer {
  display: flex;
  padding: rem(8px) rem(13px) rem(16px) rem(10px);
  border: rem(1px) solid var(--ir-gray-92);
  border-radius: rem(4px);
  flex-direction: column;
  gap: rem(30px);

  .HeadingContainer {
    display: flex;
    justify-content: space-between;
  }

  .QuestionHeading {
    padding: rem(12px) rem(5px);
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .Question {
    padding: rem(12px) rem(8px);
    border: 1px solid var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    border-radius: rem(4px) rem(4px) 0 0;
  }

  .AnswerContainer {
    display: flex;
  }

  .Answer {
    padding: rem(8px) rem(12px);
    border: 1px solid var(--ir-gray-200);
    flex: 10;
    border-top: unset;
  }

  .Label {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-warm-gray-060);
  }

  .Response {
    font-weight: 600;
    font-size: rem(16px);
    color: var(--ir-neutral-black-100);
  }

  .LadderVerticalLine {
    border-left: 1px solid var(--ir-gray-200);
  }

  .LadderHorizontalLine {
    border-top: 1px solid var(--ir-gray-200);
  }

  .HorizontalDivider {
    border-top: 1px solid var(--ir-neutral-default-gray);
  }

  .ItemContainer {
    display: flex;
    flex-direction: column;
    gap: rem(30px);
  }

  .SelectContainer {
    height: fit-content;
    border: unset;

    [class*='_container'] {
      margin: unset;
    }

    [class*='-control'] {
      border: unset;
    }
  }

  .Unacceptable {
    [class*='-singleValue'] {
      color: var(--ir-field-error);
    }
  }

  .Acceptable {
    [class*='-singleValue'] {
      color: var(--ir-success-light-pill-text);
    }
  }
}
