@import '/src/stylesheets/utils';

.MainContainer {
  .addressBoxContainer {
    display: flex;
    padding: rem(8px) rem(11px);
    border: 1px solid var(--ir-gray-200);
    min-height: rem(59px);
    border-radius: rem(4px);
    justify-content: space-between;
    gap: rem(11.33px);
  }

  .pinDropIconContainer {
    margin-top: rem(5.33px);
  }

  .penIconContainer {
    min-width: rem(24px);
    min-height: rem(24px);
    margin-top: rem(3px);
    cursor: pointer;
  }

  .addressContainer {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;

    .addressLabel {
      font-size: rem(14px);
      font-weight: 600;
      color: var(--ir-neutral-000);
    }

    .dateRangeContainer {
      font-weight: 600;
      font-size: rem(10px);
      color: var(--ir-gray-1050);
    }

    .streetAddress {
      font-size: rem(14px);
      font-weight: 600;
      color: var(--ir-gray-1050);
    }

    .stateAddress {
      font-size: rem(12px);
      font-weight: 600;
      color: var(--ir-neutral-000);
    }

    .AddressTypeContainer {
      display: flex;
      gap: rem(8px);
      align-items: center;
    }

    .RentTag {
      height: fit-content;
      padding: 0 rem(2px);
      background: var(--ir-gray-200);
      color: var(--ir-neutral-black-100);
      border-radius: rem(2px);
      font-size: rem(10px);
      font-weight: 600;
    }
  }

  .Footer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .DateRanger {
    display: flex;
    flex-direction: column;
  }

  .Label {
    color: var(--ir-warm-gray-060);
    font-size: rem(10px);
    font-weight: 600;
  }

  .Value {
    color: var(--ir-neutral-black-100);
    font-size: rem(10px);
    font-weight: 600;
  }
}
