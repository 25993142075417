@import '/src/stylesheets/utils';

.renterPresentSourceOfIncome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(14px);
  width: 100%;

  .container {
    width: 100%;

    .fromContainer {
      align-items: flex-start;
      padding-top: rem(24px);

      .userFields {
        position: relative;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          appearance: none;
          margin: 0;
        }

        .userLabel {
          position: absolute;
          top: 0.4rem;
          left: 0.8rem;
          z-index: 1;
          font-weight: 600;
          font-size: 10px;
          line-height: 14px;
          color: var(--ir-neutral-010);
        }

        .customSelect {
          text-align: left;
          padding-bottom: 1rem;

          [class*='placeholder'] {
            padding-top: rem(10px);
            color: var(--ir-neutral-010);
            font-style: normal;
            font-weight: 400;
            font-size: rem(14px);
          }

          [class*='singleValue'] {
            padding-top: rem(10px);
          }
        }

        .userGrossDollar {
          position: absolute;
          top: 1.57rem;
          left: 0.75rem;
          z-index: 1;
          font-size: rem(14px);
          color: var(--ir-black);
        }

        .inputBackgroundColor {
          padding-top: 1rem;
          background-color: var(--ir-neutral-white-bg);
        }

        .textField {
          background-color: var(--ir-neutral-white-bg);
          min-height: rem(120px) !important;
          resize: none;
          padding-top: rem(20px);
        }

        .grossIncome {
          padding-left: 1.5rem;
        }

        .inputBackgroundColor::placeholder {
          color: var(--ir-neutral-010);
          font-style: normal;
          font-weight: 400;
          font-size: rem(14px);
        }

        .textField::placeholder {
          color: var(--ir-neutral-010);
          font-style: normal;
          font-weight: 400;
          font-size: rem(14px);
        }
      }

      .datePicker {
        margin-bottom: 2rem;

        [class*='_removeBorder']::placeholder {
          color: var(--ir-neutral-010);
        }

        .label {
          left: rem(-1px);
        }
      }

      .userGrossDollar {
        position: absolute;
        top: 1.57rem;
        left: 0.75rem;
        z-index: 1;
        font-size: rem(14px);
        color: var(--ir-black);
      }

      .inputBackgroundColor {
        padding-top: 1rem;
        background-color: var(--ir-neutral-white-bg);
      }
    }
  }

  .heading {
    text-align: start;
    color: var(--ir-gray-1050);
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(24px);

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .buttonContainer {
    padding-top: rem(20px);
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;

    .buttonPrimary {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
    }

    .buttonOutLine {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-neutral-010);
      color: var(--ir-neutral-010);
    }

    .buttonLinkedIn {
      display: flex;
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
      justify-content: flex-end;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: rem(18px);
      gap: rem(4px);
    }
  }
}

@media (max-width: 500px) {
  .container {
    .datePicker {
      margin-bottom: 3.5rem;
    }
  }
}
