@import '/src/stylesheets//utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
  padding: rem(3px) rem(5px);

  .InformationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(5px);
  }

  .ScoreValue {
    width: 100%;
    color: var(--ir-neutral-black-100);
    font-size: rem(11px);
    font-weight: 500;
    align-items: center;
  }

  .ScoreValueHighlighted {
    background: var(--ir-gray-110);
  }

  .Score {
    height: fit-content;
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    font-size: rem(11px);
    font-weight: 500;
    text-transform: uppercase;
    border-radius: rem(2px);
    align-content: center;
    align-self: center;
  }

  .VantageScoreContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: rem(5px);
    align-items: center;
  }

  .ScoresValueContainer {
    display: flex;
    gap: rem(5px);
  }

  .VantageHeading {
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .ErrorHeading{
    width: min-content;
    text-align: center;
    color: var(--ir-neutral-black-primary);
    font-size: 24px;
    font-weight: 600;
  }

  .VantageScore {
    color: var(--ir-segment-cyan-900);
    font-size: rem(60px);
    font-weight: 600;
  }

  .WarningIcon{
    width: rem(80px);
    height: rem(60px);
    margin-bottom: rem(10px);
  }

  .ScoreFactorsContainer {
    flex: 4 1;
    display: flex;
    flex-direction: column;
    gap: rem(5px);
  }

  .FactorHeading {
    text-align: center;
    color: var(--ir-segment-cyan-900);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
    line-height: rem(15px); /* 93.75% */
    letter-spacing: rem(3.2px);
  }

  .ScoresContainer {
    display: flex;
    padding: rem(3px) rem(5px);
    text-align: start;
    color: var(--ir-warm-gray-800);
    flex-direction: column;
    gap: rem(5px);
    font-size: rem(16px);
    font-weight: 600;
    flex: 1;
  }

  .FoooterText {
    text-align: justify;
    color: vat(--ir-neutral-dark-black-90);
    font-size: rem(12px);
    font-weight: 400;
  }

  .LearnMore{
    color: var(--ir-segment-cyan-900);
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:active{
      text-decoration: underline;
    }
  }

  .ScoreMainContainer {
    display: flex;
  }

  .DeceasedMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 11px;
    font-weight: 500;
    padding-bottom: rem(20px);
  }
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .ScoreMainContainer {
      flex-direction: column;
      width: 100%;
    }
  }
}
