@import '/src/stylesheets/utils';

.container {
  overflow-x: hidden;
  overflow-y: scroll;

  .nameDiv {
    text-align: left;
    color: var(--ir-gray-1050);
    font-size: rem(32px);
    line-height: rem(44px);
  }

  .pillContainer {
    height: max-content;
    padding: rem(2px) rem(10px);
    background: var(--ir--neutral-green-200);
    border-radius: rem(3px);
    text-transform: capitalize;
  }

  .pillDiv {
    display: flex;
    gap: rem(10px);
    padding-left: 0;
    margin-bottom: rem(12px);
    align-items: center;
  }

  .ResumeContentContainer {
    display: flex;
  }

  .ResumeContentMobile {
    max-width: calc(100vw - rem(20px));
    overflow-y: visible;
  }

  .ResumeContent {
    flex: 1;
    padding-left: rem(14px);
  }

  .ResumeContainer {
    padding: rem(80px);
  }

  .SideMenuContainer {
    width: 100%;
    min-width: 12rem;
    padding-right: rem(14px);
  }

  .ChildrenContainer {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  .ChildrenContainerMobile {
    padding-right: unset !important;
    padding-left: unset !important ;
  }
}

@media (max-width: 767px) {
  .container {
    .ResumeContent {
      padding-left: rem(7px);
    }
  }
}

@media (max-width: 744px) {
  .ChildrenContainer {
    width: 97vw;
    padding-bottom: rem(10px) !important;
  }
}
