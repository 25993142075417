@import '/src/stylesheets/utils';

.renterCriteriaInfo {
  display: flex;
  margin: auto;
  padding: rem(8px);
  border: 1px solid var(--ir-gray-200);
  font-weight: 600;
  font-size: rem(12px);
  margin-bottom: rem(8px);
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;

  .criteriaLeftDiv {
    display: flex;
    align-items: center;

    .icon {
      width: rem(26px);
      height: auto;
    }

    .text {
      text-align: start;
    }
  }

  .criteriaRightDiv {
    display: flex;
    align-items: center;
  }

  .criteriaBtn {
    padding: 0 rem(16px);
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
    font-size: rem(12px);
    margin-left: rem(8px);
  }

  .criteriaBadge {
    width: fit-content;
    padding: rem(2px) rem(4px);
    color: var(--ir-segment-cyan-700);
    background-color: var(--ir-gray-200);
    border-radius: 4px;
    margin-left: rem(8px);
  }
}
