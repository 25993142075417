@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(3px) rem(5px);
  gap: rem(12px);
  align-items: baseline;

  .LabelContainer {
    text-align: start;
    color: var(--ir-segment-cyan-900);
    font-size: rem(11px);
    align-items: start;
    flex: 0.5;
  }

  .BoldedText {
    font-weight: 600;
    font-size: rem(14px);
  }

  .ValueContainer {
    text-align: start;
    color: var(--ir-neutral-black-100);
    flex: 1;
    font-size: rem(13px);
    word-break: break-all;
  }

  .LinkValueContainer {
    flex: 1;
    text-align: left;
    word-break: break-all;
  }

  .LinkValue {
    color: var(--ir-segment-cyan-900);
    word-break: break-all;
  }

  .BoldedValueText {
    font-weight: 600;
    font-size: rem(14px);
    word-break: break-all;
  }

  .isCodeHighlighted {
    padding: 0 rem(2px);
    background: var(--ir-gray-200);
    border-radius: rem(2px);
    word-break: break-all;
  }

  @media (max-width: 991px) {
    .LabelContainer {
      flex: 1;
    }
  }
}

.GreyContainer {
  background: var(--ir-gray-110);
}
