@import '/src/stylesheets/utils';

.CursorPointer {
  cursor: pointer;
}

.Disabled {
  padding: rem(8px) rem(10px);
  opacity: 0.5;
}

.container {
  display: flex;
  text-align: left;
  color: var(--ir-neutral-black-primary);
  align-items: center;
  gap: rem(6px);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: rem(18px);

  .buttonContainer {
    button {
      padding: 0;
      border: none;
      background-color: transparent;
      text-overflow: clip;
    }
  }

  .filledSvg {
    width: rem(22px);
    height: rem(22px);
  }
}

.selectContainer {
  min-width: rem(250px);

  [class*='-MenuList'] {
    &::-webkit-scrollbar {
      width: rem(6px);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--ir-gray-200);
      border-radius: rem(100px);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--ir-gray-200);
    }
  }

  [class*='-control'] {
    border: none;
    text-align: end;
  }

  [class*='-singleValue'],
  [class*='-indicatorContainer'],
  [class*='-indicatorContainer']:hover {
    color: var(--ir-neutral-states-color);
  }
}
