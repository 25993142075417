@import '/src/stylesheets/utils';

.ApplicationDataRow {
  display: flex;
}

.OptionContainer {
  display: flex;
  align-items: center;
}

.PersonalDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: rem(10px);
}

.LeftBorder {
  border-left: 1px solid var(--ir-warm-gray-030);
}
