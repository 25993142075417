.dropdownToggle {
  display: flex;
  color: #2d3030;
  align-items: center;
  gap: 0.2rem;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-900);
  }

  &:global(.active) {
    color: var(--ir-segment-cyan-900);
  }

  &.active {
    color: var(--ir-segment-cyan-900);
  }
}

.popoverBtn {
  display: flex;
  color: var(--ir-warm-gray-800);
  align-items: center;
  font-weight: 400;
  gap: 10px;
  margin-bottom: 5px;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-900);
    text-decoration: underline;
  }

  &.active {
    color: var(--ir-segment-cyan-900);
  }
}

.dropDownIcon {
  color: var(--ir-neutral-black-primary);
  margin-bottom: 3px;
}

.popoverBody {
  border-radius: 3px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
}

.linkContainer,
.linkContainerActive {
  align-items: center;
  display: flex;
  font-size: 14px;
  gap: 0.2rem;
}

.linkContainerActive {
  color: var(--ir-segment-cyan-900);
  font-weight: 600;
}
