@import '/src/stylesheets/utils';

.inputBackgroundColor {
  background-color: var(--ir-neutral-white-bg);

  &:focus {
    background-color: var(--ir-neutral-white-bg);
  }
}

.documentRow {
  overflow-y: auto;
  padding: 1.5rem 1rem;
  text-align: left;
  mask-image: linear-gradient(
      to bottom,
      transparent,
      var(--ir-black) rem(32px),
      var(--ir-black) calc(100% - rem(32px)),
      transparent
    ),
    linear-gradient(var(--ir-black), var(--ir-black));
  mask-size: calc(100% - 10px) 100%, 8px 100%;
  mask-repeat: no-repeat, no-repeat;
}

.tooltip {
  font-size: rem(12px);
  line-height: normal;
  opacity: 1;

  :nth-last-child(2)::before {
    border-top-color: var(--ir-neutral-black-primary);
  }

  :last-child {
    background-color: var(--ir-neutral-black-primary);
    max-width: rem(235px);
    padding-bottom: 0.47em;
    padding-top: 0.47em;
  }
}
