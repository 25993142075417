@import '/src/stylesheets/utils';

.customValueClass {
  display: flex;
  height: rem(21px);
  color: var(--ir-warm-gray-800);
  font-size: rem(14px);
  font-style: normal;
  font-weight: 400;
}
