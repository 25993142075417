@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(8px);

  .Header {
    display: flex;
    gap: rem(10px);
  }

  .DashContainer {
    flex: 1;
    height: fit-content;
    margin: auto;
  }

  .IconButton {
    margin: unset;
    padding: unset;
    border: unset;
    background: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }

  .Dash {
    width: rem(10px);
    margin: auto;
    border: 1px solid var(--ir-neutral-states-color-700);
  }

  .InformationContainer {
    display: flex;
    padding: rem(15px) rem(15px) rem(15px) rem(30px);
    border: 1px solid var(--ir-warm-gray-030);
    background: var(--ir-gray-200);
    border-radius: rem(4px) rem(4px) 0 0;
    gap: rem(15px);
    justify-content: space-between;
  }

  .CapsulesContainer {
    flex: 1;
  }

  .OptionsContainer {
    flex: 0.5;
    display: flex;
    gap: rem(10px);
    max-width: rem(50px);
    height: fit-content;
    align-items: center;
  }

  .DotsIcon {
    transform: rotate(90deg);
  }

  .PersonIcon,
  .DotsIcon {
    cursor: pointer;
  }
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .InformationContainer {
      flex-direction: column;
      width: 100%;
    }

    .OptionsContainer {
      margin: auto;
    }
  }
}

.DropdownOptionDivider {
  border-bottom: rem(0.5px) solid var(--ir-warm-gray-045);
  margin: rem(2px) rem(5px) rem(4px);
}

.OptionDisabledContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: rem(120px);
  padding: 0 rem(8px);
}

.CheckIcon {
  opacity: 0.5;
}

.OptionDisabled {
  opacity: 0.5;

  &:hover {
    background: none;
  }
}

.DropdownDummyOption {
  font-size: rem(14px);
  font-weight: 600;
  padding: rem(1px) rem(8px);
  color: var(--ir-warm-gray-060);
}

.DropdownOptionLabel {
  font-size: rem(16px);
  font-weight: 600;
  color: var(--ir-warm-gray-800);
}

.DropdownContainer {
  display: flex;
  align-items: center;
}

.reactSelect {
  [class*='-control'] [class*='-ValueContainer'] {
    min-height: unset;
    padding: 0;

    [class*='-placeholder'] {
      margin: -2px;
    }
  }

  [class*='-control'] {
    padding: 0;
    border: rem(1px) solid var(--ir-gray-200);
    background: var(--ir-gray-200);
    min-height: rem(25px);

    [class*='-hlgwow'] {
      margin: -2px;
      padding: 0;
    }

    &:hover {
      border: rem(1px) solid var(--ir-warm-gray-060);
      border-radius: rem(4px);
    }

    :last-child {
      max-height: 2.2rem;
      align-items: center;
    }

    [class*='indicatorContainer'] {
      display: none !important;
    }
  }

  [class*='-menu'] {
    width: max-content !important;
    text-align: left;

    [class*='-option'] {
      padding: rem(1px) rem(8px) !important;
      cursor: pointer;
    }
  }

  [class*='-placeholder'] {
    font-size: rem(14px);
    font-weight: 600;
    color: var(--ir-warm-gray-800);
  }
}
