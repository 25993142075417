@import '/src/stylesheets/utils';

// Mobile first approach

.maritalContainer {
  display: flex;
  margin: 0.2rem 0;
  padding: 0 0.3rem;
  flex-direction: column;
  gap: 1rem;

  [class*='minHeight'] {
    min-height: 0;
  }

  [class^='ReactSelect_floatingLabel'] {
    width: fit-content;
  }

  [class^='ReactSelect_container'] {
    [class*='-menu'] {
      text-align: start;
    }

    [class*='control'] {
      height: rem(50px);
    }
  }

  .textFieldLabel {
    position: absolute;
    top: 9.3rem;
    left: 16rem;
    z-index: 1;
    font-size: rem(10px);
    font-weight: 600;
    color: var(--ir-neutral-010);
  }

  .phoneNumberContainer {
    [class*='country-list'] {
      height: 10rem;
      padding: 0;
    }

    [class*='form-control'] {
      padding-top: rem(30px);
      width: 100%;
    }

    [class*='flag'] {
      margin-top: rem(-2px) !important;
    }

    [class*='open'],
    [class*='selected-flag'],
    [class*='react-tel-input'],
    [class*='selected-flag']:hover,
    [class*='flag-dropdown'] {
      border: none;
      background: transparent !important;
      background-color: transparent;
    }

    [class*='react-tel-input'] {
      width: 100%;
      border: 1px solid var(--ir-gray-200);
      background-color: var(--ir-neutral-white-bg) !important;
      border-radius: rem(4px);
      font-size: rem(14px);
    }

    :global(.country) {
      padding: rem(10px) 0 0 rem(44px) !important;
    }

    [class*='form-control']:focus,
    [class*='form-control'],
    [class*='form-control']:hover {
      background: var(--ir-neutral-white-bg) !important;
      border-color: transparent;
      border-radius: 0 0 rem(4px) rem(4px);
      box-shadow: 0 0 0 1px transparent;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(20px);
      color: var(--ir-neutral-black-100);
    }

    [class*='special-label'] {
      top: rem(6px);
      left: rem(6px);
      text-align: left;
      color: var(--ir-neutral-010);
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(14px);
      background-color: transparent;
    }

    [class*='flag'] [class*='arrow'] {
      border-top: rem(4px) solid var(--ir-neutral-000);
    }

    [class='dial-code'] {
      width: 2rem;
    }

    [class='country'],
    [class='country_highlight'],
    [class*='preferred'] {
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;
      text-align: initial;
      justify-content: flex-end;
    }
  }

  .maritalButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0;
    gap: 1rem;

    .renterMaritalButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }

  .datePicker {
    background-color: var(--ir-neutral-white-bg);
    position: relative;
  }

  [class*='-control'] {
    height: rem(45px);
    background-color: var(--ir-neutral-white-bg);
  }

  [class*='floatingLabel'] {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-010);
  }

  [class*='-placeholder'] {
    font-size: 1rem;
    font-weight: 400;
    color: var(--ir-gray-650);
  }

  [class*='CustomDatePicker_label'] {
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-010);
    padding-top: 0.2rem;
  }

  [class*='_calendarContainer'] {
    left: 9rem;
  }

  .userField {
    position: relative;

    .userLabel {
      position: absolute;
      top: 0.5rem;
      left: 0.7rem;
      font-weight: 600;
      font-size: 10px;
      color: var(--ir-neutral-010);
      z-index: 1;
    }
  }

  .inputBackgroundColor {
    height: rem(50px);
    color: var(--ir-warm-gray-800);
    padding-top: 1rem;
    font-size: rem(16px);
    font-weight: 400;
  }

  .inputBackgroundColor::placeholder {
    font-size: rem(14px);
    font-weight: 400;
  }
}

.maritalContainerBorder {
  border: 1px solid rgb(0 0 0 / 42%);
  border-radius: rem(4px);
}

.renterRelationContainer {
  overflow-y: auto;
  overflow-x: visible;
}

.renterRelationContainer::-webkit-scrollbar {
  display: none;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.button {
  width: fit-content;
  padding: 0.4rem;
  margin-top: 1rem;
}

.heading {
  font-size: rem(24px);
  font-weight: 700;
  text-align: center;
}

.subHeading {
  font-size: rem(18px);
  font-weight: 500;
  text-align: center;
}

.spanColor {
  color: var(--ir-segment-cyan-700);
}

.renterButton {
  width: fit-content;
  padding: 0.4rem;
  border: 1px solid var(--ir-segment-cyan-700) !important;
  text-align: start;
  color: var(--ir-segment-cyan-700) !important;
  font-weight: 600;
  font-size: rem(16px);
}

.renterButtonContainer {
  text-align: right;
}

.dropDownFillArrow {
  width: rem(24px);
  height: rem(24px);
  text-align: center;
  background-color: var(--ir-neutral-white-bg);
  border-radius: rem(2px);
  margin-right: 1rem;
}

.contentClassName {
  border: none;
  border-radius: rem(24px);
}

@media (max-width: 300px) {
  .maritalContainer {
    [class*='_calendarContainer'] {
      left: 1rem;
    }
  }
}
