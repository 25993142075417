@import 'stylesheets/fonts';
@import 'stylesheets/variables';

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
.font-xs {
  margin: 2px 0;
}

// H1 Font

.typography--variant-h1 {
  font-size: $font-size-large;
  font-weight: $font-weight-semi;
}

// H2 Font

.typography--variant-h2 {
  font-family: $font-family-open-sans;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
}

// H3 Font

.typography--variant-h3 {
  font-family: $font-family-open-sans;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

// H4 Font

.typography--variant-h4 {
  font-family: $font-family-open-sans;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

// H5 Font

.typography--variant-h5 {
  font-family: $font-family-open-sans;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

// H6 Font

.typography--variant-h6 {
  font-family: $font-family-open-sans;
  font-size: $font-size-h6;
  font-weight: $font-weight-normal;
}

// P

p {
  font-size: $font-paragraph;
  line-height: $line-height-general;
}

// Heading-1

.typography--variant-subheading1 {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: $font-weight-xbold;
}

// Heading-2

.typography--variant-subheading2 {
  font-family: $font-family-open-sans;
  font-size: $font-size-base;
  font-weight: 600;
}

// Font Body-1

.typography--variant-body1 {
  font-family: $font-family-open-sans;
  font-size: $font-size-body1;
  font-weight: $font-weight-bold;
}

// Font Body-2

.typography--variant-body2 {
  font-family: $font-family-open-sans;
  font-size: 32px;
}

// Subtitle

.subtitle {
  display: block;
  color: var(--ir-neutral-black-primary);
  font-family: $font-family-open-sans;
}

// Spans

.basic-span {
  color: $span-dark-gray;
  font-family: $font-family-open-sans;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

.primary-span {
  color: var(--ir-warm-gray-800);
  font-family: $font-family-open-sans;
}

.secondary-span {
  color: var(--ir-segment-cyan-900);
  font-family: $font-family-open-sans;
  font-weight: $font-weight-bold;
}

.sm-span {
  color: $dark-gray;
  font-family: $font-family-open-sans;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.body1-span {
  color: var(--ir-warm-gray-800);
  font-family: $font-family-open-sans;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.font-sm {
  color: var(--ir-warm-gray-800);
  font-family: $font-family-open-sans;
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}

.font-xs {
  color: $dark-gray;
  font-family: $font-family-open-sans;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}

.font-smx {
  background-color: var(--ir-warm-gray-040);
  color: var(--ir-segment-cyan-700);
  font-family: $font-family-open-sans;
  font-size: $font-size-small;
}

h1 .subtitle {
  display: block;
  font-family: $font-family-open-sans;
  margin: 0 0 $margin-large-vertical;
}

.text-muted {
  color: #8d9899;
  font-family: $font-family-open-sans;
  font-size: $font-size-small;
}

// Typography Colors

.typography--color-primary {
  color: var(--ir-warm-gray-800);
}

.typography--color-secondary {
  color: $dark-gray;
}

.typography--color-success {
  color: var(--ir-segment-cyan-700);
}

.typography--color-subtitle {
  color: var(--ir-neutral-black-primary);
}

.typography--color-subheading {
  color: var(--ir-neutral-black-primary);
}

.text-primary,
.text-primary:hover {
  color: #1d62f0;
}

.text-info,
.text-info:hover {
  color: var(--ir-segment-blue-600);
}

.text-success,
.text-success:hover {
  color: var(--ir-segment-cyan-700);
}

.text-warning,
.text-warning:hover {
  color: var(--ir-semantics-warning);
}

.text-danger,
.text-danger:hover {
  color: var(--ir-semantics-danger);
}
