@import '/src/stylesheets/utils';

.MainContainer {
  display: flex;
  width: 100%;
  height: 100%;

  // border-top: rem(2px) solid rgb(163 176 178 / 50%);
  min-width: fit-content;
  padding: rem(40px) rem(100px);

  .SideBar {
    display: none;

    // position: sticky;
    // top: 0;
    // width: rem(17px);
    // height: 100vh;
    // border: rem(1px) solid var(--ir-gray-200);
    // background: var(--ir-segment-cyan-300);

    [class='EZDrawer'] {
      position: sticky;
      top: 0;
      height: 100vh;
    }

    [class*='__overlay'] {
      height: 100%;
    }
  }

  .RightArrowIcon {
    position: absolute;
    top: rem(15px);
    right: rem(-8px);
    rotate: 180deg;
    cursor: pointer;
  }

  .LeftArrowIcon {
    position: absolute;
    top: rem(15px);
    right: rem(-8px);
    cursor: pointer;
  }

  .DrawerClass {
    position: relative;
    width: rem(204px) !important;
    border: rem(1px) solid var(--ir-gray-200);
    background: var(--ir-segment-cyan-300);
    box-shadow: none;
    min-height: 100%;
  }

  .PageContent {
    position: relative;
    width: 100%;
    margin-left: rem(195px);
    margin-right: 0%;
    transition-duration: 500ms;
    text-align: center;
  }

  .PageContentUnselected {
    align-items: center;
    margin-left: 0%;
    margin-right: 0%;
    transition-duration: 500ms;
  }

  .PageOverLay {
    margin: unset;
    z-index: -10000;
  }
}

.MobileMainContainer {
  padding: unset !important;
}

[class^='main'] {
  height: 100%;
  z-index: -1;
}

@media (max-width: 575px) {
  .MainContainer {
    height: unset;

    [class*='_homeLogo'] {
      display: none;
    }

    .DrawerClass {
      width: 100%;
    }
  }
}
