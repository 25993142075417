@import '/src/stylesheets/utils';


.scrollContainer {
  overflow-y: auto;

  .exampleImageContainer {
    margin-bottom: rem(20px);
  }

  .renterCreditButton {
    width: fit-content;
  }
}

.scrollContainer::-webkit-scrollbar {
  display: none;
}
  

.dropzoneContainer {
  margin-bottom: rem(20px);
}

@media (max-width: 744px) {
  .dropzoneContainer {
    margin-bottom: rem(24px);
  }
}

.customOutterBox {
  padding: 16px 24px;
  border: 1px solid #e6eff0;
  border-radius: 4px;
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: 0 -4px 32px 0 #0facbc1a;
}

.otherstextContainer {
  margin-bottom: 5%;
}

.textField {
  min-height: rem(120px) !important;
  font-size: rem(10px);
  font-weight: 600;
  resize: none;
  width: 100%;
  padding-top: rem(18px);
  background-color: var(--ir-neutral-white-bg);
  border: 1px solid var(--ir-gray-200);
  border-radius: rem(4px);
}

