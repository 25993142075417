@import '/src/stylesheets/utils';


.InhabitantsInformation {
  display: flex;
  padding: rem(8px) 0 rem(8px) rem(8px);
  border: 1px solid var(--ir-warm-gray-030);
  border-radius: 0 0 rem(4px) rem(4px);
  gap: rem(12px);
  align-items: center;
  background-color: var(--ir-gray-60);
  margin-bottom: rem(16px);
}

.UnsetTopBorder {
  border-top: unset;
}

.InhabitantsStatus {
  display: flex;
  gap: rem(10px);
}

.ActiveStatus {
  display: flex;
  padding: rem(2px) rem(4px);
  border-radius: rem(4px);
  font-weight: 600;
  text-align: center;
  gap: rem(4px);
}

.ActiveValue {
  color: var(--ir-neutral-black-100);
}

.ActiveLabel {
  color: var(--ir-segment-cyan-700);
  text-transform: uppercase;
}


.HouseholdStatus {
  display: flex;
  align-items: center;
}

.HouseholdValue {
  padding: 0 rem(2px);
  font-size: rem(12px);
  font-weight: 600;
}

.ShowInformation {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.HouseholdLabel {
  background-color: var(--ir-warm-gray-040);
  padding: 0 rem(2px);
  border-radius: rem(2px);
  text-transform: uppercase;
  color: var(--ir-neutral-black-primary);
  font-size: rem(10px);
  font-weight: 600;
}

.DropDownIcon {
  rotate: 180deg;
  cursor: pointer;
}

.NoPropertyRow {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: var(--ir-gray-500);
  font-weight: 700;
  font-size: rem(14px);
}

.NoPropertyRow > p {
  margin: 0;
}
