@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  .Header {
    display: flex;
    width: 100%;
    padding: rem(12px) rem(15px) rem(12px) rem(30px);
    background: var(--ir-gray-200);
    justify-content: space-between;
    align-items: center;
    gap: rem(12px);
    align-self: stretch;
    border-radius: rem(4px) rem(4px) rem(0) rem(0);
  }

  .RenterInformation {
    display: flex;
    gap: rem(12px);
    align-items: center;
    width: 100%;
  }

  .ApplicationStatusContainer{
    display: flex;
    flex-direction: column;
  }

  .ApplicationStatusText{
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    color: #5E6566;
  }

  .RenterName {
    color: var(--ir-neutral-black-primary);
    font-size: rem(22px);
    font-weight: 600;
  }

  .StatusPillContainer {
    height: fit-content;
    padding: rem(2px) rem(10px);
    border: rem(3px);
    text-transform: capitalize;
    font-size: rem(12px);
  }

  .PeopleIcon {
    color: var(--ir-warm-gray-060);
  }

  .OccupantInformation {
    display: flex;
    width: rem(258.25px);
    padding: rem(5px) rem(15px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: rem(5px);
    border-radius: rem(5px);
    border: 1px solid var(--ir-warm-gray-030);
    background: var(--ir-gray-000);
  }

  .OccupantCount {
    width: 100%;
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
  }

  .OccupantIcon {
    display: flex;
    gap: rem(8px);
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .IconText {
    color: var(--ir-neutral-dark-black-90);
    font-size: rem(12px);
    font-weight: 600;
  }

  .Footer {
    display: flex;
    width: 100%;
    padding: rem(5px) rem(32px);
    background: rgb(230 239 240 / 50%);
    align-items: center;
    gap: rem(5px);
    border-radius: rem(0) rem(0) rem(4px) rem(4px);
  }

  .AddressTitle {
    text-align: right;
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-weight: 600;
  }

  .Address {
    overflow: hidden;
    color: var(--ir-neutral-black-100);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-weight: 600;
  }
}

@media (max-width: 600px) {
  .Container {
    .Header {
      flex-direction: column;
      align-items: flex-start;
    }

    .RenterInformation {
      justify-content: flex-start;
    }

    .Footer{
      display: block;

      .AddressTitle{
        text-align: left;
      }
    }

  }
}
