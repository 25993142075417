@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(4px) rem(2px) rem(2px);
  flex-direction: column;
  gap: rem(5px);

  .OptionClassName {
    display: flex;
    width: 100%;
    padding: rem(2px) rem(5px);
    color: var(--ir-warm-gray-800);
    gap: rem(5px);
    box-shadow: unset;
    border-radius: unset;
    font-size: rem(12px);
    font-weight: 600;

    &:hover {
      background-color: var(--ir-gray-200);
    }
  }

  .OptionIconContainer {
    min-height: rem(14px);
    min-width: rem(14px);
  }

  .HorizontalLine {
    border-top: 1px solid var(--ir-warm-gray-045);
    width: 100%;
  }
}

.TooltipClassName {
  display: none;
}

.ActionButton {
  padding: rem(4px) rem(8px);

  .ButtonContent {
    display: flex;
    color: var(--ir-neutral-black-100);
    font-size: rem(14px);
    font-weight: 600;
    align-items: center;
    gap: rem(8px);
  }
}
