@import '/src/stylesheets/utils';

thead {
  border: none;
}

.CustomTable {
  > :not(caption) {
    > * {
      > * {
        padding-bottom: rem(20px);
      }
    }
  }
}

table {
  border-spacing: 0 10px;
  border-collapse: separate;

  thead {
    tr {
      text-align: center;

      th {
        gap: rem(5px);
        border: none;
        color: #303333;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      text-align: center;

      td {
        margin: 10px 0;
        border: rem(1px) solid var(--ir-warm-gray-030);
        border-right: none;
        border-left: none;
        vertical-align: bottom;
        white-space: nowrap;
        font-size: rem(16px);
        font-weight: 600;
      }

      td:first-child {
        border: 1px solid var(--ir-warm-gray-030);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: none;
        padding-left: 20px;
        text-align: left;
      }

      td:last-child {
        border: 1px solid var(--ir-warm-gray-030);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
        text-align: end;
      }
    }
  }
}

.Page {
  display: flex;
  width: '100%';
  margin: rem(26px) rem(130px);
  flex-direction: column;
  justify-content: flex-start;
}

.Heading {
  display: flex;
  width: 100%;
  color: var(--ir-gray-1050);
  font-size: rem(16px);
  font-weight: 600;
  justify-content: flex-start;
  margin-top: rem(32px);
}

.Navbar {
  margin-top: rem(32px);
}

.HeadingContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.HeadingRow {
  display: flex;
  gap: rem(12px);
  align-items: center;
}

.OrganizationHeading {
  font-size: rem(32px);
  font-weight: 400;
  color: var(--ir-gray-1050);
}

.OrganizationCardContainer {
  border-bottom: 1px solid var(--color-horizantal-line);
  border-top: 1px solid #e1e8eb;
  box-shadow: 0 4px 8px 0 rgb(213 214 214 / 25%);
  margin: rem(26px) rem(130px);
  max-width: 100%;
  overflow-x: auto;
}

.CursorPointer {
  cursor: pointer;
}

.CardMargin {
  margin: rem(10px);
}

.Text {
  text-align: left;
  color: #5e6566;
  font-size: small;
  cursor: pointer;
}

.NumberColor {
  background-color: #e6eff0;
}

.MobilePage {
  display: flex;
  width: '100%';
  margin: rem(10px);
  flex-direction: column;
  justify-content: flex-start;
}

.MobileHeadingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.MobileHeadingRow {
  display: flex;
  flex-direction: column;
  padding-bottom: rem(10px);
}
