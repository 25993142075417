@import '/src/stylesheets/utils';

.renterBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;

  .reactHeightDiv {
    [class*='-control'] > :first-child {
      padding-block: rem(11px);
      height: rem(45px);
    }
  }

  .container {
    display: flex;
    text-align: left;
    flex-direction: column;

    .buttonsContainer {
      margin-top: rem(20px);
      padding-bottom: rem(24px);
    }

    .textFieldContainer {
      position: relative;
    }

    .minHeight {
      padding-bottom: rem(20px);
    }

    .headingLabel {
      font-size: rem(18px);
      font-weight: 500;
      color: var(--ir-warm-gray-800);
    }

    .renterSpan {
      color: var(--ir-segment-cyan-700);
    }

    .textFieldLabel {
      position: absolute;
      top: rem(8px);
      left: rem(12px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);

      &.assistiveAnimalFieldLabel {
        top: rem(8px);
      }

      &.reactSelectFieldLabel {
        top: rem(5px);
        left: 60%;
      }

      &.unitFieldLabel {
        top: rem(25px);
        left: 74%;
        font-size: rem(14px);
        font-weight: 400;
      }
    }

    .renterButtonContainer {
      padding-top: rem(10px);
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: flex-end;
      gap: 1rem;
    }

    .renterButton {
      width: fit-content;
      padding: rem(4px) rem(8px) rem(4px) rem(8px);
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}

.inputBackgroundColor {
  padding-top: 1rem;
  background-color: var(--ir-neutral-white-bg);

  [class*='_container'] [class*='form-control']:focus {
    background-color: var(--ir-neutral-white-bg) !important;
  }
}

.reactInputBackgroundColor {
  [class*='-control'] {
    background-color: var(--ir-neutral-white-bg);
    margin-bottom: rem(20px);
  }
}

.reactselectFieldsContainer {
  display: flex;
  gap: 1rem;

  .textFieldContainer {
    flex: 1;
  }
}

@media (max-width: 744px) {
  .renterBody {
    padding-left: rem(24px);
    padding-right: rem(12px);
    padding-top: rem(16px);
    margin-bottom: 0;
  }
}
