@import '/src/stylesheets/utils';

.confirmPriorAddressContainer {
  .placeAutoCompleteContainer {
    height: rem(50px);
    margin-bottom: rem(30px);

    [class*='_boldLabel'] {
      left: rem(16px);
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }

    [class*='_placesAutocomplete'] {
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      padding-left: 1rem;
      border-radius: rem(4px);
    }
  }

  .errorPlaceAutoComplete {
    margin-bottom: rem(34px);
  }

  .button {
    width: fit-content;
  }
}

@media (max-width: 745px) {
  .confirmPriorAddressContainer {
    margin-bottom: rem(10px);

    .placeAutoCompleteContainer {
      [class^='PlacesAutoCompleteComponent_suggestionInput'] {
        max-height: rem(85px);
        overflow-y: scroll;
      }
    }
  }
}
