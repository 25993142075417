@import '/src/stylesheets/utils';

.Container {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .SpinnerClass {
    width: rem(23px);
    height: rem(23px);
  }
}
