@import '/src/stylesheets/utils';

.SideMenu {
  position: fixed;
  left: 0;
  width: rem(204px);
  height: 100vh;
  background-color: var(--ir-segment-cyan-300);
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  border-right: rem(1px) solid var(--ir-gray-200);

  .MenuContainer {
    padding: rem(60px) 0 0 rem(30px);
    text-align: left;

    .MenuHeading {
      color: var(--ir-neutral-black-100);
      font-size: 12px;
      font-weight: 600;
      margin-bottom: rem(8px);
      margin-left: rem(7px);
    }

    .MenuOptionsContainer {
      display: flex;
      flex-direction: column;
      gap: rem(8px);

      .MenuItem {
        font-size: rem(14px);
        font-weight: 400;
        color: var(--ir-warm-gray-060);
        padding-left: rem(4px);
        line-height: 1;
        border-left: rem(2px) solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--ir-segment-cyan-900);
        }
      }

      .MenuItem.Active {
        color: var(--ir-segment-cyan-700);
        font-weight: 600;
        font-size: rem(14px);
        border-left: rem(2px) solid var(--ir-segment-cyan-700);
      }

      .SubMenuItems {
        display: flex;
        margin: rem(8px) 0 rem(2px) rem(10px);
        flex-direction: column;
        gap: rem(8px);
      }
    }
  }
}

.SideMenu.Show {
  transform: translateX(0);
}

.MenuToggle {
  cursor: pointer;
  position: relative;
  top: rem(20px);
  left: rem(10px);
  float: inline-start;
  border: 1px solid var(--ir-segment-cyan-300);
  border-radius: rem(3px);
}

.Wrapper {
  display: flex;
  position: fixed;
  width: 204px;
  height: 904px;
  padding: 20px 0;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f6f9fa;
  border-right: 1px solid #e6eff0;

  .MenuContainer {
    display: flex;
    gap: 5px;
    flex-direction: column;
    padding: 20px 30px;
  }

  .Heading {
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-size: 12px;
    font-weight: 600;
  }
}

.MenuSection {
  margin-left: rem(204px) !important;
}

.FormikMainContainer {
  display: flex;
  width: 100%;
  flex-direction: column;

  .BusinessStateStyle {
    [class*='control'] {
      border: 1px solid #d2d6d6;
    }
  }

  .DisableFieldViewStyle {
    color: #303333 !important;
    opacity: 1;
    background-color: var(--color-white) !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  [class$='-ValueContainer'] {
    min-height: unset;
    height: rem(53px);
  }

  .DisableBusiness {
    color: #303333 !important;
    opacity: 1;
    background-color: #f7fafa !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  [class*='form-control'] {
    border: 1px solid #d2d6d6 !important;
  }

  [class*='form-control']:hover {
    border: 1px solid #d2d6d6 !important;
    color: #303333 !important;

    // background-color: var(--color-white) !important;
    opacity: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    cursor: auto;
  }

  [class*='-singleValue'] {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #303333 !important;
  }

  .ViewContainer {
    display: flex;
    flex-direction: column;
  }

  .EditContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 8px 20px;
    border: 1px solid #abb9ba;
    background: #f7fafa;
    gap: 10px;
    border-radius: 4px;
  }
}

.HousingProviderCategory {
  [class*='-singleValue'] {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #303333 !important;
  }

  [class*='-indicatorContainer'] {
    color: #5e6566;
  }
}

.Padding {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 10%;
  padding-left: rem(40px);

  .OrganizationContainer {
    display: flex;
    width: 100%;
    padding: 21px 0;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    .OrganizationName {
      font-size: 14px;
      font-weight: 600;
      color: var(--ir-neutral-black-100);
    }

    .OptionSelected {
      display: flex;
      width: 100%;
      justify-content: space-between;
      z-index: 1;

      .Title {
        font-size: 2rem; // 32px equivalent
        font-weight: 400;
        color: var(--ir-neutral-black-100);
      }
    }
  }

  .EditButton {
    cursor: pointer;
    width: fit-content;
    padding: 0 rem(5px);
    align-self: flex-end;
    z-index: 1;
  }

  .Container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: rem(30px);
    border-bottom: 1px solid var(--color-horizantal-line);
    margin-bottom: rem(12px);

    .NavLinks {
      padding: rem(2px) rem(4px);
      color: var(--ir-neutral-dark-black-90);
      text-decoration: none;
      font-size: rem(14px);
      font-style: normal;
      font-weight: 600;
      line-height: rem(20px);
      border-bottom: 1px solid transparent;
      cursor: pointer;

      &:active,
      &:hover {
        color: var(--ir-segment-cyan-900);
        border-bottom: 1px solid var(--ir-segment-cyan-900);
      }
    }

    .textDiv {
      &:active,
      &:hover {
        color: var(--ir-segment-cyan-900);
      }
    }

    .novaScore {
      padding: 2px 5px;
      color: rgb(0 0 0);
      background-color: #e6eff0;
    }

    .active {
      color: var(--ir-segment-cyan-900);
      border-bottom: 1px solid var(--ir-segment-cyan-900);
    }

    .creditConateinerDiv {
      display: flex;
      gap: rem(8px);
      align-items: center;
    }

    .CreditContainer {
      display: flex;
    }

    .backgroundDiv {
      height: max-content;
      padding: rem(0) rem(2px);
      background: var(--ir-gray-200);
      border-radius: rem(2px);
    }

    .creditDiv {
      font-size: rem(14px);
      font-weight: 600;
      text-align: left;
      color: var(--ir-neutral-black-100);
    }
  }

  .ImageSize {
    width: 120px !important;
    height: 120px !important;
  }

  .ProfessionalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .ProfHeadingContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 8px;
      gap: 10px;

      .TextDiv {
        text-align: justify;
        color: var(--ir-neutral-black-100);
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .CenterContainer {
    display: flex;
    width: 100%;
    padding: 20px 0 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    .FirstContainer {
      display: flex;
      width: 100%;
      padding: 12px 8px;
      flex-direction: column;
      gap: 10px;

      .DropDownContainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      [class*='flag-dropdown'] {
        display: none;
      }

      [class*='label'] {
        display: none;
      }
    }
  }

  .SecondViewContainer {
    padding: 12px 18px 20px;
    border: 1px solid #abb9ba;
    background: #f7fafa;
  }

  .SecondContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    border-radius: 4px;

    [class*='flag-dropdown'] {
      display: none;
    }

    [class*='label'] {
      display: none;
    }

    [class*='react-tel-input'] {
      background-color: var(--color-white) !important;
    }

    .BusinessAddressStyle {
      border: 1px solid #d2d6d6;
      color: #303333;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    .AdministratorContainer {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .HeadingText {
        text-align: left;
        color: var(--ir-neutral-black-100);
        font-size: 18px;
        font-weight: 600;
        flex: 1 0 0;
      }

      .AdministratorEdit {
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        .AdministratorText {
          color: #5e6566;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .BoxText {
      text-align: left;
      color: var(--ir-neutral-black-100);
      font-size: 14px;
      font-weight: 400;
    }

    .VisibityContainer {
      display: flex;
      height: 28px;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;

      .Visibitytext {
        position: relative;
        top: 3px;
        color: #5e6566;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .ButtonContainerSettings {
    display: flex;
    width: 100%;
    padding: 21px 0;
    justify-content: flex-end;
    gap: 20px;
    border-bottom: solid 1px #d5d6d6;

    .ProfileEditRow {
      display: flex;
      padding: rem(5px) rem(26px);
      justify-content: flex-end;
      margin-bottom: rem(25px);

      .EditText {
        font-weight: 600;
        font-size: rem(14px);
        color: var(--ir-segment-cyan-900);
      }

      .ButtonsRow {
        display: flex;
        gap: rem(20px);

        .EditButton {
          height: rem(39px);
          padding: 10px 30px;
          background-color: var(--ir-gray-000);
        }

        .SaveButton {
          height: rem(39px);
          padding: rem(10px) rem(30px);
          border: none;
          background: var(--ir-segment-cyan-900);
          color: var(--ir-gray-000);
        }
      }
    }
  }

  .LastContainer {
    display: flex;
    width: 100%;
    padding: 24px 0;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;

    .PersonalBox {
      display: flex;
      padding: 12px 20px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #d2d6d6;

      .PersonalText {
        font-size: 13px;
        font-weight: 400;
        text-align: start;
        color: #5e6566;
      }

      .EditContainer {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .FullName {
        display: flex;
        flex-direction: column;
        width: 100%;

        .FullNameHeading {
          text-align: left;
          color: #5e6566;
          font-size: 14px;
          font-weight: 600;
        }

        .Name {
          text-align: left;
          font-size: 16px;
          font-weight: 600;
          color: var(--ir-neutral-black-100);
          margin-bottom: 5px;
        }
      }
    }
  }
}

.homeDesignTop {
  position: absolute;
  top: 1%;
  right: rem(50px);
  width: 300px;
  height: 320px;
  z-index: 0;
}

.OptionHeading {
  display: flex;
  position: relative;
  top: -rem(10px);
  text-align: left;
  color: var(--ir-neutral-black-100);
  justify-content: left;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.TextAlignForDropDown {
  text-align: left;
}

.AnyoneContainer {
  width: rem(100px);
}

.NavBarWidth {
  width: 100%;
}

.PersonalTextFields {
  border: none;
  background-color: '#ffffff';
}

.ProfHeading {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  color: var(--ir-neutral-black-100);
}

.stateSelectField {
  position: relative;
  text-align: left;

  .textFieldLabel {
    position: absolute;
    top: rem(4px);
    left: rem(20px);
    z-index: 1;
    font-size: rem(11.2px);
    color: var(--ir-neutral-000);
    font-weight: 600;
  }

  .phoneNumberContainer {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .MenuSection {
    margin-left: 0 !important;
  }

  .MenuToggle {
    display: block;
  }
}

@media (min-width: 768px) {
  .SideMenu {
    transform: translateX(0);
  }

  .MenuToggle {
    display: none;
  }

  .MenuSection {
    margin-left: 0;
  }
}
