@import '/src/stylesheets/utils';

.ApplicantPrimaryDetails {
  display: flex;
  flex-direction: column;
  padding: rem(10px) rem(20px);
  gap: rem(10px);
}

.Details {
  display: flex;
  align-items: flex-end;
  gap: rem(5px);
  flex: 1 0 0;
}

.CustomHeight {
  display: flex;
  width: auto;
  padding-left: rem(30px);
  padding-right: rem(30px);
  min-height: rem(125px);
}

.IncomeInfo {
  padding: rem(10px) rem(30px);
}

.ApplicantRow {
  align-items: center;

  .StatusPillsContainer {
    text-transform: capitalize;
    padding-left: rem(10px);
    padding-right: rem(10px);
  }

  .ReportStatusPills {
    font-weight: 600;
    font-size: rem(10px);
  }
}

.RenterInfoLinks {
  text-align: start;
}

.RightBorder {
  border-right: 1px solid var(--ir-neutral-050);
  box-sizing: border-box;
}

.MinHeight {
  display: flex;
  min-height: rem(125px);
  align-items: center;
}

.RenterInfoStatus {
  flex-direction: column;
  justify-content: center;
}

.ApplicantInfoLinksRow {
  margin-bottom: rem(5px);
}

@media (max-width: $screen-laptop-md) {
  .RightBorder {
    border-right: none;
  }

  .MinHeight,
  .CustomHeight {
    min-height: auto;
    padding-left: unset;
    padding-right: unset;
    width: unset;
  }

  .ApplicantRow {
    justify-content: center;
  }

  .IncomeInfo {
    padding: rem(10px) rem(10px);
  }
}
