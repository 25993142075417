@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
}

.TitileContainer {
  width: 100%;
  text-align: center;
  color: var(--ir-gray-1050);
  font-size: rem(22px);
  font-style: normal;
  font-weight: 600;
}

.RenterButtonContainer {
  align-self: flex-end;

  .RenterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }
}

.InfoPanel {
  font-size: rem(10px);
  font-weight: 600;
  text-align: left;
  box-shadow: unset;

  &.Description {
    padding: rem(12px) 0;
    text-align: justify;
    color: var(--ir-neutral-black-primary);
  }
}

.SubContainer {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
}

.DateContainer {
  display: flex;
  padding: rem(4px) rem(0) rem(4px) rem(16px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);
  border-radius: rem(4px);
}

.DetailsContainer {
  display: flex;
  padding: rem(8px);
  align-items: center;
  gap: rem(8px);
  border-right: 1px solid var(--greys-94, #e6eff0);
  border-bottom: 1px solid var(--greys-94, #e6eff0);
  border-left: 1px solid var(--greys-94, #e6eff0);
  background: var(--greys-98, #f7fafa);
}

.ProfileImage {
  width: auto;
  height: rem(24px);
  border-radius: rem(24px);
}

.StreetAddressContainer {
  font-size: rem(12px);
  font-style: normal;
  font-weight: 600;
  line-height: rem(16px);

  &.LocalAddressContainer {
    color: var(--ir-warm-gray-060);
  }
}

.AddressContainer {
  display: $webkit-box;
  height: 20%;
  padding: rem(8px);
  align-items: center;
  border-right: 1px solid var(--ir-gray-200);
  border-bottom: 1px solid var(--ir-gray-200);
  border-left: 1px solid var(--ir-gray-200);
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  text-align: center;
  padding-inline: 1rem;
}

@media (max-width: $screen-tablet) {
  .Container {
    padding: rem(31px) rem(12px) rem(31px) rem(24px);
  }
}
