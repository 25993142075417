@import '/src/stylesheets/utils';

.contentClassName {
  padding: rem(20px) rem(40px);
  border: none;
  border-radius: rem(24px);
  box-shadow: rem(0) rem(4px) rem(4px) rgb(0 0 0 / 25%);

  .errorPlaceAutoComplete {
    margin-bottom: rem(34px) !important;
  }

  .placeAutoCompleteContainer {
    height: rem(50px);
    margin-bottom: rem(24px);

    [class*='_boldLabel'] {
      left: rem(16px);
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }

    [class*='_placesAutocomplete'] {
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      padding-left: 1rem;
      border-radius: rem(4px);
    }
  }

  .renterH2 {
    text-align: center;
    color: var(--ir-neutral-black-100);
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: 2rem;

    .renterH2Color {
      color: var(--ir-segment-cyan-700);
      text-transform: capitalize;
    }
  }

  .renterButtonContainer {
    text-align: right;
    margin-top: 24px;

    .renterButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700) !important;
      text-align: start;
      color: var(--ir-segment-cyan-700) !important;
      font-weight: 600;
      font-size: rem(16px);
    }
  }

  .heading {
    font-size: rem(24px);
    font-weight: 700;
    text-align: center;
  }

  .ButtonsContainer {
    display: flex;
    width: 100%;
    gap: rem(16px);
    padding: rem(12px) 0;
  }

  .ButtonClass {
    display: flex;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-neutral-010);
    background: var(--color-white);
    color: var(--ir-neutral-010);
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    gap: rem(5px);
    border-radius: rem(4px);
    font-size: rem(16px);
    line-height: rem(24px);
    font-weight: 600;
  }

  .ActiveButton {
    border: 1px solid var(--ir-segment-cyan-900);
    color: var(--ir-segment-cyan-700);
    font-weight: 700;
    align-items: center;
  }

  .TickIcon {
    min-width: rem(20px);
    min-height: rem(20px);
  }
}

@media (max-width: 294px) {
  .contentClassName {
    .errorPlaceAutoComplete {
      margin-bottom: rem(44px) !important;
    }
  }
}

@media (max-width: 765px) {
  .contentClassName {
    box-shadow: none;
    border-radius: none;
  }
}
