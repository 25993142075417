@import '/src/stylesheets/utils';

.RenterInfoDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: rem(8px);
  border: 1px solid var(--ir-gray-200);
  background-color: var(--ir-gray-000);
  border-radius: rem(4px);
  gap: rem(8px);
  margin-bottom: rem(20px);
  font-style: normal;
  font-weight: 600;
  font-size: rem(10px);
  line-height: rem(14px);

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }

  .heading {
    color: var(--ir-gray-1050);
    text-transform: capitalize;
  }

  .subHeading {
    color: var(--ir-neutral-001);
  }

  .nameContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .renterName {
      font-size: rem(16px);
      line-height: rem(24px);
      color: var(--ir-gray-1050);
    }

    .editIcon {
      width: rem(18px);
      height: rem(18px);
    }
  }

  .addressContainer {
    display: flex;
    justify-content: space-between;

    .infomation {
      display: flex;
      flex-direction: column;
    }

    .editIcon {
      width: rem(18px);
      height: rem(18px);
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
  }

  .idContainer {
    display: flex;
    width: 100%;
  }

  .MiddleNameCheckContainer {
    display: flex;
    justify-content: space-between;
  }

  .MiddleNameText {
    color: var(--ir-gray-1050);
    font-weight: 500;
    font-size: rem(14px);
  }

  .MiddleNameFlag {
    display: flex;
    padding-left: rem(3px);
    gap: rem(2px);
    align-items: baseline;
    border-radius: rem(2px);
    background: var(--ir-warm-gray-040);
    color: var(--ir-segment-cyan-700);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
