@import '/src/stylesheets/utils';

.container {
  position: relative;
  text-align: start;
  -webkit-overflow-scrolling: touch;

  [class*='form-floating'] > label {
    font-weight: 600;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  .ConsentContainer {
    font-weight: 600;
  }

  .PersonalDetails {
    font-weight: 500;
    font-size: rem(18px);
  }

  .BusinessDetails {
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: rem(8px);
  }

  .LegalName {
    font-weight: 600;
    font-size: rem(12px);
    color: var(--ir-neutral-black-primary);
    padding-left: rem(8px);
  }

  .SsnInfo {
    font-weight: 500;
    font-size: rem(12px);
    padding: 0;
    color: var(--ir-neutral-black-primary);
  }

  .BusinessDetailsContainer {
    margin-bottom: rem(40px);
  }

  .title {
    height: 42px;
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-size: rem(32px);
    font-weight: 400;
    margin-bottom: 3rem;
  }

  .partnershipContainer {
    display: flex;
    width: 100%;
    margin: rem(10px) 0 rem(15px);
    padding: 1rem;
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
    border-radius: 4px;
  }

  [class*='flag-dropdown'] {
    display: none;
  }

  [class*='react-tel-input'] [class*='form-control'] {
    padding-left: rem(33px);
  }

  .partnershipIcon {
    padding-right: 1rem;
  }

  .HousingProviderCategoryContainer {
    [class*='ValueContainer'] {
      font-weight: 600;
    }
  }

  .partnershipText {
    margin: auto;
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-style: normal;
    font-weight: 600;
  }

  .inputContainer {
    align-items: center;
    display: flex;
    margin-top: 1rem;
    text-align: start;
  }

  .statement {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: 13px;
    font-weight: 400;
  }

  .btnPrimary {
    display: flex;
    width: fit-content;
  }

  .alignError {
    position: relative;
    text-align: start;
  }

  .inputLabel {
    color: var(--ir-neutral-black-primary);
    font-size: 14px;
    font-weight: 600;
  }

  .optionals {
    color: var(--ir-neutral-black-primary);
    font-size: 12px;
    font-weight: 400;
  }

  .stateDropDownContainer {
    position: relative;
  }

  .stateDropDown {
    position: absolute;
    top: 20px;
    right: -150px;
    width: 200px;
    z-index: 100;
  }
}

.floatingContainer {
  position: relative;
  color: var(--ir-neutral-000);
  margin-top: 10px;

  .label {
    position: absolute;
    left: 1rem;
    font-size: 0.8rem;
  }
}

.customErrorForAbsolutePosition {
  top: 60px;
}

.errorRelativenWithPlacesAutoComplete {
  margin-bottom: rem(60px);
  position: relative;
}

.housingProviderCategoryField {
  margin-bottom: rem(10px);

  [class$='control'] {
    height: rem(55px);
  }
}

.stateSelectField {
  position: relative;
  text-align: left;

  .textFieldLabel {
    position: absolute;
    top: rem(4px);
    left: rem(20px);
    z-index: 1;
    font-size: rem(11.2px);
    color: var(--ir-neutral-000);
    font-weight: 600;
  }

  [class$='-control'] {
    min-height: rem(55px) !important;
  }
}

.phoneTypeSelectField {
  height: rem(115px);
  margin-bottom: rem(5px);
}

@media (max-width: 700px) {
  .phoneTypeSelectField {
    margin-bottom: rem(50px) !important;
  }
}

@media screen and (max-width: 765px) {
  .CardRowContainer {
    padding-bottom: rem(100px);
  }
}

@media screen and (min-height: 1300px) {
  .AgentProfileFormContainer {
    height: 100%;
  }
}
