@import '/src/stylesheets/utils';

.container {
  margin-top: 5px;

  [class$='-ValueContainer'] {
    min-height: rem(48px);
  }
}

.cardWidth {
  position: relative;
  width: 300px;
}

.containerWidth {
  left: -120px;
}

.error {
  [class$='-control'] {
    background-color: var(--ir-field-error-background);
    border-color: var(--ir-semantics-danger) !important;
  }
}

.valueContainer {
  display: flex;

  &[class$='-ValueContainer'] {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }

  .floatingLabel {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }

  .floatingLabelNormal {
    position: relative;
    bottom: rem(6px);
    text-align: start;
    color: var(--ir-neutral-000);
    font-size: rem(12px);
  }

  .defaultChild {
    display: flex;
    height: 21px;
    color: var(--ir-warm-gray-800);
    align-items: center;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
  }

  .defaultSelectChild {
    align-items: center;
    display: flex;
    height: 21px;
  }

  .defaultValue {
    color: var(--ir-warm-gray-800);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
}

.withFloatingLabel {
  [class$='-control'] {
    border: 1px solid var(--ir-gray-200);
  }
}

.dropDownFillArrow {
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: var(--ir-neutral-white-bg);
  border-radius: 2px;
  margin-right: 1rem;
}
