@import '/src/stylesheets/utils';

.ContentContainer {
  .InformationContainer {
    display: flex;
    margin: 0 rem(30px);
    padding: rem(8px) rem(10px) rem(8px) rem(8px);
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
    align-items: flex-start;
    gap: rem(10px);
    border-radius: rem(4px);
  }

  .InformationText {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }

  .SecurityIcon {
    min-width: rem(24px);
    min-height: rem(24px);
  }

  .Requirements {
    display: flex;
    margin: rem(40px) 0 rem(60px);
    padding: 0 rem(35px);
    flex-direction: column;
    align-items: center;
    gap: rem(24px);
    align-self: stretch;
  }

  .Requirement {
    display: flex;
    align-items: flex-start;
    gap: rem(10px);
    align-self: stretch;
  }

  .RequirnmentText {
    text-align: start;
    color: var(--ir-gray-02);
    font-size: rem(18px);
  }

  .GetStartedButton {
    padding: rem(12px) rem(40px);
    align-items: center;
    text-align: center;
  }

  .Branded {
    background: var(--ir-segment-blue-710);
  }

  .SpinnerClass {
    width: rem(20px);
    height: rem(20px);
    align-items: center;
  }
}
