@import '/src/stylesheets/utils';

.ImageProgressContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: rem(58px);
  border: rem(1px) solid var(--ir-gray-200);
  border-radius: rem(4px);
  padding-left: rem(20px);
  align-items: center;
  padding-right: rem(16px);
  padding-top: rem(8px);
  margin-bottom: 2%;

  .imageIconContainer {
    .imageIcon {
      width: rem(24px);
      height: rem(24px);
      color: var(--ir-segment-cyan-900);
      background-color: var(--ir-segment-cyan-950);
      margin-right: rem(12px);
    }
  }

  .imageNameContainer {
    display: flex;
    width: 100%;
    color: var(--ir-gray-1050);
    flex-direction: column;
    font-size: rem(14px);
    font-weight: 400;

    .imageFileNameContainer {
      display: flex;
      justify-content: space-between;

      .imageRemoveContainer {
        display: flex;
        gap: rem(8px);

        .imageProgressIndicator {
          color: var(--ir-neutral-000);
          font-size: rem(12px);
          font-weight: 600;
        }

        .imageRemoveButton {
          margin-top: rem(2px);
          cursor: pointer;
        }
      }

      .imageFileName {
        text-align: left;
        margin-bottom: rem(4px);
      }
    }

    .progressBarContainer {
      width: 100%;
      padding-right: rem(22px);
    }
  }

  .imageLabelContainer {
    position: absolute;
    top: rem(0);
    font-size: rem(10px);
    font-weight: 600;
    left: rem(16px);
    color: var(--ir-neutral-010);
  }
}

@media (max-width: 355px) {
  .ImageProgressContainer {
    .imageNameContainer {
      .imageFileNameContainer {
        .imageFileName {
          max-width: rem(80px);
        }
      }
    }
  }
}

@media (max-width: 300px) {
  .ImageProgressContainer {
    .imageNameContainer {
      .imageFileNameContainer {
        .imageFileName {
          max-width: rem(50px);
        }
      }
    }
  }
}
