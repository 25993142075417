@import '/src/stylesheets/utils';

.container {
  display: flex;
  padding: rem(20px) rem(40px);
  border: none;
  border-radius: rem(24px);
  box-shadow: rem(0) rem(4px) rem(4px) rgb(0 0 0 / 25%);
  flex-direction: column;
  gap: rem(6px);

  .heading {
    font-size: rem(24px);
    font-weight: 700;
    text-align: center;
  }

  .subHeading {
    padding-bottom: rem(10px);
    font-size: rem(18px);
    font-weight: 500;
    text-align: center;
  }

  .spanColor {
    color: var(--ir-segment-cyan-700);
  }

  [class*='flag-dropdown'] {
    display: none;
  }

  [class*='react-tel-input'] [class*='form-control'] {
    padding-left: rem(12px);
  }

  .contactRentFieldContainer {
    [class*='-control'] {
      padding-left: rem(27px) !important;
    }

    [class*='_prefixString__sHtfS'] {
      padding-left: rem(5px);
      color: var(--ir-warm-gray-800);
      font-weight: 500;
    }
  }

  .rentErrorFieldContainer {
    [class*='_prefixString__sHtfS'] {
      margin-top: rem(-6px);
    }
  }

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      font-size: 0.6rem;
    }

    [class*='-control'] {
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(15px);
      padding-top: rem(18px);
      font-weight: 400;
      border-radius: rem(4px);
    }

    [class^='PhoneNumber_container'] {
      margin-top: rem(5px);

      [class*='-control'] {
        padding-left: rem(15px) !important;
      }

      [class^='PhoneNumber_label'] {
        font-weight: 600;
        font-size: rem(10px);
      }
    }

    .textFieldLabel {
      position: absolute;
      top: rem(4px);
      left: rem(16px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }
  }

  .ownerInformationContainer {
    position: relative;
    padding: rem(8px) rem(10px) rem(8px) rem(42px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    margin-top: rem(8px);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(24px);

    .ownerInformationIcon {
      position: absolute;
      top: rem(10px);
      left: rem(10px);
      width: rem(20px);
      height: rem(20px);
    }

    .ownerInformationText {
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-black-primary);
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .renterButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700) !important;
      text-align: start;
      color: var(--ir-segment-cyan-700) !important;
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}
