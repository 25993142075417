@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: var(--ir-gray-000);
  gap: rem(8px);
  font-style: normal;
  font-weight: 600;
  font-size: rem(10px);
  line-height: rem(14px);

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }

  .heading {
    color: var(--ir-gray-1050);
    text-transform: capitalize;
  }

  .headingEmail {
    color: var(--ir-gray-1050);
  }

  .subHeading {
    color: var(--ir-neutral-001);
  }

  .addressContainer {
    display: flex;
    justify-content: space-between;

    .infomation {
      display: flex;
      flex-direction: column;
    }

    .editIcon {
      width: rem(18px);
      height: rem(18px);
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .idContainer {
    display: flex;
    width: 100%;
  }

  .ssnIconContainer {
    display: flex;
    align-items: center;
    gap: rem(8px);

    .ssnNumber {
      font-size: rem(12px);
      font-weight: 600;
      line-height: rem(16px); /* 133.333% */
    }

    .icon {
      width: rem(16px);
      height: rem(16px);
    }
  }
}

.guarantorContainer {
  flex-direction: row;
  gap: rem(8px);

  .guarantorPresent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: rem(8px);

    .guarantorHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .guarantorEmail {
      font-size: 0.8rem;
      padding-bottom: rem(8px);
    }
  }
}
