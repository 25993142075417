@import '/src/stylesheets/utils';

.Container {
  padding: rem(12px) rem(30px);
  border: 1px solid var(--ir-warm-gray-030);

  .Header {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .StatusPillContainer {
    padding: rem(2px) rem(10px);
    border: rem(3px);
    text-transform: capitalize;
    font-size: rem(12px);
  }

  .OptionsContainer {
    flex: 0.5;
    display: flex;
    gap: rem(10px);
    max-width: rem(50px);
    height: fit-content;
    align-items: center;
  }

  .UpdatedDate {
    font-size: rem(12px);
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .ApplicantInformation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .InformationContainer {
    display: flex;
    gap: rem(15px);
    align-items: center;
  }

  .ApplicantName {
    width: 100%;
    text-align: left;
    padding-top: rem(4px);
    flex: 1;
  }

  .IconButton {
    margin: unset;
    padding: unset;
    border: unset;
    background: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }

  .NavigationButton {
    padding: unset;
    border: none;
    background: none;
    box-shadow: none;

    &:hover {
      background: none;
      text-decoration: underline;
    }
  }

  .Name,
  .NoExistence {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(20px);
  }

  .NoExistence {
    padding-bottom: rem(5px);
  }

  .Role {
    color: var(--ir-warm-gray-060);
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    text-transform: capitalize;
  }

  .CapsulesContainer {
    flex: 1;
  }

  .Count {
    margin-left: rem(5px);
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-black-100);
  }
}

.LastApplication {
  border-bottom-left-radius: rem(4px);
  border-bottom-right-radius: rem(4px);
}

.InvitedContainer {
  border-radius: rem(5px);
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .InformationContainer {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
    }

    .CapsulesContainer {
      width: 100%;
    }
  }
}
