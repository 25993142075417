@import '/src/stylesheets/utils';

.Container {
  .ApplicationContainer {
    display: flex;
    padding: rem(5px) 0 rem(5px) rem(8px);
  }

  .VerticalLine {
    margin: rem(10px) 0;
    margin-right: rem(25px);
    border-right: 1px solid var(--ir-neutral-states-color-400);
  }

  .MobileApplicationContainer {
    padding: rem(5px) 0;
  }

  .ApplicationList {
    display: flex;
    flex-direction: column;
    gap: rem(40px);
    width: 100%;
  }

  .InvitedApplicationList {
    display: flex;
    width: 100%;
    margin: rem(25px) rem(0);
    flex-direction: column;
    gap: rem(40px);
  }

  .Application {
    width: 100%;
  }
}
