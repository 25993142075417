@import '/src/stylesheets/utils';

.renterDrawer {
  .drawerContainer {
    border-top-left-radius: rem(25px);
    border-top-right-radius: rem(25px);
  }

  .childrenWrapper {
    display: flex;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: space-between;

    .backgroundIcon {
      position: absolute;
      top: -1.5rem;
      right: -9.8rem;
      color: var(--ir-segment-teal-primary-95);
    }

    .orgContainer {
      display: flex;
      align-items: center;
      padding: 0 rem(24px) rem(24px);
      justify-content: space-between;

      .iconsContainer {
        display: flex;
        gap: rem(24px);
      }
    }

    .childrenContainer {
      display: flex;
      height: 50rem;
      z-index: 2;
      overflow-y: auto;
      flex-direction: column;

      .crossContainer {
        display: flex;
        justify-content: flex-end;
        padding-right: rem(30px);
        margin-top: rem(20px);

        button {
          padding: 0;
          border: none;
          background: transparent;
        }

        .crossIcon {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
  }
}
