@import '/src/stylesheets/utils';


.Container {
  display: flex;
  width: 100%;
  border: 1px solid var(--ir-gray-200);
  flex-direction: column;
  justify-content: flex-start;

  .Title {
    display: flex;
    justify-content: flex-start;
    gap: rem(10px);
    width: 100%;
    padding: rem(16px) 0;
    padding-left: rem(5px);
  }

  .QuestionsContainer {
    padding-left: rem(24px);
  }

  .DropDownIcon{
    cursor: pointer;
  }

  .RotateDown { 
    transform: rotate(90deg);
    transition: transform 0.5s ease;    
  }

  .RotateUp {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }
}

.Heading {
  display: flex;
  width: 100%;
  justify-content: space-between  ;
  padding: rem(10px);
  color: var(--ir-neutral-black-primary);
  font-size: rem(14px);
  font-weight: 600;
}
