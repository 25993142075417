@import '/src/stylesheets/utils';

.presentAddressContainer {
  .renterH2Color {
    color: var(--ir-segment-cyan-700);
  }

  .addresBoxContainer {
    margin-bottom: rem(8px);
  }

  .addressButtonConatiner {
    .presentAddressButton {
      width: fit-content;
    }
  }
}
