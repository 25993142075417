@import '/src/stylesheets/utils';

.Guidelines {
  margin-top: rem(20px);
  color: var(--ir-neutral-black-primary);
  font-weight: 500;
}

.Cross {
  width: 15px;
  height: 15px;
}



.GuidelineRow {
  display: flex;
  gap: rem(10px);
  align-items: center;
  white-space: nowrap;
}
