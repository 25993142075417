@import '/src/stylesheets/utils';

.BranchContainer {
  display: flex;
  min-height: 100%;
  flex: 0.2;
  margin-left: rem(4px);
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-350);

  &.MarginContainer {
    margin-top: rem(2px);
  }
}

.HorizontalLine {
  width: 100%;
  margin-top: rem(10px);
  border-top: rem(1px) solid var(--ir-gray-350);
}

.BottomPadding {
  margin-bottom: rem(10px);
}
