@import '/src/stylesheets/utils';

.container {
  :global(.modal-footer) {
    padding: 0.5rem 2rem;
  }

  [class^='modal-body'] {
    background: var(--ir-warm-gray-100);
    border-top: 1px solid var(--ir-warm-gray-030);
  }

  .messageSentIcon {
    margin-top: rem(20px);
    margin-right: auto;
    margin-left: auto;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    margin: rem(10px) rem(10px) rem(60px) rem(10px);
    text-align: center;
    font-weight: 600;
    font-size: rem(18px);
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .button {
    width: fit-content;
    height: rem(28px);
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-neutral-black-100);
    background: var(--ir-warm-gray-100);
    color: unset;
    font-size: rem(12px);
    margin-left: auto;
    margin-right: auto;

    &:hover {
      background-color: unset;
    }
  }
}

.customContainer {
  .modalTitle {
    font-size: rem(24px);
    font-weight: 400;
  }
}
