@import '/src/stylesheets/utils';
@import '/src/stylesheets/colors';

.Container {
  display: flex;
  padding: rem(12px) 0;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(12px);
  align-self: stretch;

  .ScrollAbleContent {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    overflow-x: auto;
    padding-top: rem(10px);
    width: 100%;
  }

  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: rem(20px);
    align-items: baseline;
  }

  .RowContainer {
    width: 100%;
  }

  .LeftContent {
    display: flex;
    flex-direction: column;
    gap: rem(5px);
    width: 100%;
    justify-content: flex-start;
  }

  .UpperLeftContent {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 98%;
  }

  .UpperMiniLeftContent {
    display: flex;
    gap: rem(15px);
    align-items: baseline;
    padding: rem(3px) 0;
  }

  .LowerLeftContent {
    display: flex;
    gap: rem(10px);
    align-items: center;
    flex-wrap: wrap;
  }

  .OutterLowerLeftContent {
    display: flex;
    justify-content: space-between;
    width: 98%;
    align-items: baseline;
  }

  .LowerLeftHeaderLabel {
    padding: rem(0) rem(2px) rem(0) rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .LowerLeftMiniContent {
    display: flex;
    gap: rem(10px);
  }

  .LowerLeftMiniContentDesign {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .LowerLeftMiniContentDesign2 {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .DisplayName {
    color: var(--ir-warm-gray-800);
    font-size: rem(24px);
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.2px;
  }

  .KindOfBusiness {
    overflow: hidden;
    color: var(--ir-warm-gray-800);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .RightContent {
    display: flex;
    height: fit-content;
    padding: 0 rem(3px);
    background: var(--ir-danger-pill);
    color: var(--ir-danger-pill-text);
    align-items: center;
    gap: rem(2px);
    border-radius: rem(2px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .CustomLabelClass {
    flex: 1 -1;
  }

  .PaymentHistoryContainer {
    display: flex;
    padding: rem(17px) 10px rem(15px) 0;
    gap: rem(5px);
    align-items: center;
    text-align: center;

    .Tag {
      color: var(--ir-segment-cyan-1050);
      font-size: rem(16px);
      font-weight: 600;
      text-transform: uppercase;
    }

    .TagValue {
      color: var(--ir-neutral-black-primary);
      font-size: rem(16px);
      font-weight: 500;
      line-height: normal;
    }
  }

  .LowerHeaderLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem(16px);
    color: var(--ir-warm-gray-800);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Divider {
    position: relative;
    top: rem(-1px);
    color: var(--ir-warm-gray-060);
  }
}

@media (max-width: $screen-laptop-md) {
  .Container {
    .CustomLabelClass {
      flex: 1;
    }
  }
}

@media (max-width: 900px) {
  .Container {
    .ScrollAbleContent {
      max-width: calc(100vw - 260px);
    }
  }
}

@media (max-width: 767px) {
  .Container {
    .ScrollAbleContent {
      max-width: calc(100vw - 40px);
    }
  }
}
