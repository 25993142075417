@import '/src/stylesheets/utils';

.menuContainer {
  padding: rem(20px) rem(30px) rem(20px) rem(30px);
  text-align: left;
  color: var(--ir-neutral-000);
  margin-top: rem(20px);
  cursor: pointer;

  .mainOptionClass {
    margin-bottom: rem(8px);
    padding-left: 4px;

    &:hover {
      color: var(--ir-warm-gray-600);
    }
  }

  .mainSelectedClass {
    color: var(--ir-segment-cyan-700);
    border-left: rem(2px) solid var(--ir-segment-cyan-900);
    font-weight: 600;

    &:hover {
      color: var(--ir-segment-cyan-700);
    }
  }

  .subSelectedClass {
    color: var(--ir-segment-cyan-700) !important;
    font-weight: 600;
  }

  .subOptionClass {
    color: var(--ir-neutral-000);
    margin-bottom: rem(8px);
    padding-left: rem(14px);

    &:hover {
      color: var(--ir-warm-gray-600);
    }
  }
}
