@import '/src/stylesheets/utils';

.Container {
  display: flex;
  align-items: center;
  gap: rem(5px);
}

.Pending {
  color: var(--ir-warning-pill-status-text);
}

.Complete {
  color: var(--color-dark-green);
}

@media (max-width: $screen-mobile-medium) {
  .Container {
    justify-content: center;
  }
}
