@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;

  .Text,
  .ButtonText {
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }

  .EditGroup {
    display: flex;
    gap: rem(4px);
  }

  .HorizontalLine {
    width: 100%;
    border-top: 0.5px solid var(--ir-warm-gray-045);
  }

  .FileChanges {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    width: 100%;
  }

  .ButtonText {
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--ir-warm-gray-040);
    }
  }

  .Label {
    text-transform: uppercase;
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-weight: 600;
  }
}
