@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.Title {
  font-size: 30px !important;
  font-weight: 500 !important;
  line-height: 38.13px;
}

.CustomCLass {
  [class='modal-content'] {
    height: 95vh;
    overflow: hidden;
    border-radius: rem(24px);
  }
}

@media (max-width: $screen-laptop-md) {
  .CustomCLass {
    [class*='Modal_dialogClassName__'] {
      max-width: unset;
      margin: rem(30px);
    }
  }
}

.CustomHeader {
  padding: 2.5rem 3rem 0.5rem 2rem !important;
}

.CustomButton {
  gap: rem(5px);
}

.DropDownContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.RenterText {
  font-size: rem(16px);
  font-weight: 600;
  color: #5e6566;
}

.Denied {
  background-color: #fcdbe3;
  padding: 2px;
  border-radius: 2px;
}

.Formcontainer {
  padding: 4.5%;

  .FormHeading {
    font-size: rem(18px);
    font-weight: 500;
    line-height: 24.51px;
  }

  .FormPara {
    text-align: justify;
    font-size: rem(14px);
    font-weight: 400;
    line-height: 19.07px;
  }

  .checkBoxOption {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
  }

  .optionalDesign {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: #636a6b;
  }

  .CheckboxLabel {
    width: max-content;
    color: #303333;
    font-size: rem(16px);
    font-weight: 400;
  }

  .CheckBoxFields {
    margin-bottom: 3%;
    width: 'max-content';
  }

  .OptionButtonContainer {
    display: flex;
    gap: 2%;
    align-items: center;
    margin-bottom: 2%;
  }

  .OptionButtonDesign {
    position: relative;
    top: rem(-1px);
    border: none;
    background-color: transparent;
  }

  .CrossOptions {
    padding: rem(15px) rem(1px) rem(5px) rem(95px);
  }

  .NameStyle {
    font-size: rem(16px);
    font-weight: 600;
    color: #303333;
  }

  .RoleStyle {
    color: #636a6b;
    font-size: rem(16px);
    font-weight: 600;
  }

  .ReactSelect1 {
    width: rem(640px);
  }

  .InputField {
    width: 100%;
    height: rem(45px);
    border-radius: rem(3px);
    border: 1px solid #a3b0b2;
    padding-left: rem(10px);
  }

  .InputFieldOutterDiv {
    margin-bottom: 3%;
  }

  .InputError {
    border: rem(1px) solid #f00;
  }

  .ErrorMessageFormik {
    padding: rem(5px) 0 0 rem(5px);
    color: #f00;
  }

  .ReportButton {
    width: auto;
    height: rem(34px);
    padding: rem(4px) rem(8px);
  }

  .buttonOutterDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttonOutterDiv2 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .buttonInnerDiv {
    display: flex;
    position: relative;
    width: 150px;
  }

  .submitButton {
    position: relative;
    top: -2px;
    right: 7px;
    width: 81%;
    height: 40px;
    z-index: 1;
    border-radius: 5px 0 0 5px;
    outline: none;
    background-color: #fff !important;
  }

  .SendDenialButton {
    width: 175px;
    height: 34px;
    padding: 4px 8px 4px 12px;
    border: 1px solid #17adbc;
    background: #17adbc;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .SendDenialButtonDisabled {
    opacity: 0.5;
  }

  .submitButtonDisabled {
    position: relative;
    top: -2px;
    right: 7px;
    width: 81%;
    height: 40px;
    z-index: 1;
    border-radius: 5px 0 0 5px;
    outline: none;
    background-color: #fff !important;
    border: 1px solid #eaf1f2 !important;
    color: #8d9899 !important;
  }

  .submitButton:active {
    border-style: outset;
  }

  .ReactSelect2 {
    position: absolute !important;
    right: 0;
    bottom: rem(0);
    width: 100%;
    height: 42px;
  }

  .TimeLogcontainer {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(25px);
  }

  .TimeLogTitle {
    color: #303333;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: rem(10px);
  }

  .InnerTimeLogcontainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(7px);
  }

  .MiniTimeLogcontainer {
    display: flex;
    gap: rem(10px);
  }

  .TimeLogP1 {
    color: #303333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
  }

  .TimeLogP2 {
    color: #303333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
  }

  [class*='ValueContainer'] {
    min-height: 0;
  }

  [class*='control'] {
    min-height: 40px;
  }

  .dropDownIconContainer {
    position: relative;
    right: rem(12px);
  }

  @media (max-width: 744px) {
    .ReactSelect1 {
      width: 260px;
    }

    .submitButton {
      padding: 4px 8px;
      margin-right: 31px;
    }
  }

  @media (max-width: 991px) and (min-width: 745px) {
    .ReactSelect1 {
      width: 390px;
    }

    .submitButton {
      padding: 4px 8px;
      margin-right: 31px;
    }
  }
}
