@import '/src/stylesheets/utils';

.renterIncomeInformation {
  display: flex;
  padding: 0 rem(10px) rem(16px) rem(10px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-gray-000);
  flex-direction: column;
  border-radius: rem(4px);
  margin-bottom: rem(20px);
  margin-top: rem(13px);
}

.heading {
  color: var(--ir-neutral-black-100);
  font-weight: 500;
  font-size: rem(18px);
  margin-bottom: rem(2px);
}

// On screens that are 744px or less

// If screen size is more than 1025 wide
@media (min-width: 1025px) {
  .renterIncomeInformation {
    overflow: visible;
  }

  .heading {
    color: var(--ir-neutral-black-100);
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: 2rem;
  }
}
