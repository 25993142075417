@import '/src/stylesheets/utils';

.renterBody {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;

  .container {
    .dropdownContainer {
      padding: rem(20px);
      border: 1px solid var(--ir-gray-200);
    }

    .animalSummaryDropDownContainer {
      padding: unset !important;
      border: unset !important;
    }

    .labelText {
      font-size: rem(12px);
      font-weight: 600;
      text-align: start;
      color: var(--ir-neutral-black-primary);
    }

    .animalDetailCardDiv {
      display: flex;
      flex-direction: column;
    }

    .AnimalHeader {
      display: flex;
      padding: rem(8px);
      align-items: center;
      border-radius: rem(4px);
      border: 1px solid var(--ir-gray-220);
      background: var(--Neutral-White, var(--ir-gray-000));
      justify-content: space-between;
    }

    .HeaderContent {
      display: flex;
      gap: rem(8px);
    }

    .HeaderText {
      color: var(--ir-warm-gray-800);
      font-weight: 600;
    }

    .IconButton {
      margin: unset;
      padding: unset;
      border: unset;
      box-shadow: unset;

      &:hover {
        background: unset;
      }
    }

    .buttonPrimary {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
      font-size: rem(16px);
    }

    .buttonPrimarySmall {
      font-size: rem(12px);
      padding: rem(4px) rem(8px);
    }

    .headingLabel {
      text-align: start;
      color: var(--ir-warm-gray-800);
      font-size: rem(18px);
      font-weight: 400;
      padding-bottom: rem(10px);
    }

    .SingleAnimal {
      margin-top: rem(20px);
    }

    .renterSpan {
      color: var(--ir-segment-cyan-700);
    }

    [class*='dropzone'],
    [class*='_ImageProgressContainer'] {
      margin-top: 0;
      margin-bottom: rem(10px);
    }
  }
}

.renterButton {
  width: fit-content;
  padding: rem(4px) rem(8px) rem(4px) rem(8px);
  border: 1px solid var(--ir-segment-cyan-700) !important;
  text-align: start;
  color: var(--ir-segment-cyan-700) !important;
  font-weight: 600;
  font-size: rem(16px);
}

.renterButtonContainer {
  margin-top: rem(30px);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-end;
  gap: 1rem;
}

.Ladder {
  display: flex;
  margin-left: rem(2px);
  width: rem(10px);
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-220);
  margin-bottom: rem(20px);
}

.HorizontalLine {
  width: 100%;
  margin-top: rem(20px);
  border-top: rem(1px) solid var(--ir-gray-220);
}

.LadderContainer {
  display: flex;
  width: 100%;
  padding: unset;
  border: unset;
  gap: rem(4px);
}

.DropZoneContainer {
  width: 100%;
}

@media (max-width: 744px) {
  .renterBody {
    padding-left: rem(24px);
    padding-right: rem(12px);
    padding-top: rem(16px);
    margin-bottom: 0;

    .container {
      .dropdownContainer {
        overflow: visible;
      }

      .animalDetailCardDiv {
        overflow: visible;
      }
    }
  }
}

// // If screen size is more than 1025 wide
// @media (min-width: 1025px) {
//   .renterBody {
//     .container {
//       .dropdownContainer {
//         max-height: 40vh;
//       }
//     }
//   }
// }
