@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(8px);
  text-align: start;
  color: var(--ir-neutral-black-primary);

  .Header {
    padding: 0 rem(5px);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .LastUpdated {
    font-size: rem(12px);
    display: flex;
    gap: rem(5px);
    padding: 0 rem(5px);
  }

  .Value {
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }
}
