@import '/src/stylesheets/utils';

.SupportingDocuments {
  display: flex;
  flex-direction: column;

  .TitleDiv {
    display: flex;
    flex: 1 0 0;

    .Heading {
      display: flex;
    }

    .Title {
      flex: 1 0 0;
    }
  }

  [class*='accordion-button'] {
    display: flex;
    flex-direction: row;

    [class^='CollapsibleComponent_heading'] {
      font-size: rem(14px);
      font-weight: 600;
      text-transform: capitalize;
      color: var(--ir-neutral-black-primary);
    }
  }

  [class*='accordion-collapse']:not([class*='show']) {
    display: block;
    height: rem(65px);
    overflow: hidden;
  }

  .BorderDiv {
    margin-top: rem(5px);
    display: flex;
    padding: rem(4px) rem(8px);
    background-color: var(--ir-neutral-white-bg);
    border: 1px solid var(--ir-gray-200);
    flex-wrap: wrap;
    
  }
}
