@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: rem(4px);
  padding: rem(4px) rem(8px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);

  .Information {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: rem(15px);

    .Heading {
      color: var(--ir-neutral-black-primary);
      font-size: rem(14px);
      font-style: normal;
      line-height: normal;
      text-transform: capitalize;
    }

    .Data {
      color: var(--ir-neutral-black-100);
      font-size: rem(16px);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .Pill {
      padding: 0 2px;
      border-radius: 2px;
      background: var(--ir-gray-200);
      color: var(--ir-neutral-black-100);
      font-size: rem(12px);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }

  .Document {
    flex: 1;
    display: flex;
    padding: rem(4px) rem(5px) rem(8px);
    align-items: center;
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
    gap: rem(8px);
    text-overflow: ellipsis;

    .DocumentName {
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    flex-direction: column;
  }
}
