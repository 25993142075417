@import '/src/stylesheets/utils';

.vehiclesConfirmation {
  .outerContainer {
    display: inline-flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem(8px);
    max-height: 48vh;
    overflow-y: auto;
  }

  .innerContainer {
    display: inline-flex;
    width: 100%;
    padding: rem(8px);
    border: 0.5px var(--ir-gray-200) solid;
    background: var(--ir-warm-gray-100);
    border-radius: rem(4px);
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem(8px);
  }

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }

  .buttonContainer {
    padding-top: rem(20px);
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;

    .buttonPrimary {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
      font-size: rem(16px);
    }

    .buttonPrimarySmall {
      font-size: rem(12px);
      padding: rem(4px) rem(8px);
    }
  }
}

@media (max-width: 744px) {
  .vehiclesConfirmation {
    .outerContainer {
      overflow: visible;
    }
  }
}
