@import '/src/stylesheets/utils';

.guarantorSummaryContainer {
  .informationBox {
    margin-bottom: rem(24px);
  }

  .ownerInformation {
    position: relative;
    padding: rem(8px) rem(10px) rem(8px) rem(42px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    margin-top: rem(8px);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(24px);

    .ownerInformationIcon {
      position: absolute;
      top: rem(10px);
      left: rem(10px);
      width: rem(20px);
      height: rem(20px);
    }

    .ownerInformationText {
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-black-primary);
    }
  }
}
