@import '/src/stylesheets/utils';

.ApplicationItemContainer {
  display: flex;
  flex-direction: column;
  margin-top: rem(10px);
  margin-left: rem(108px);
  margin-right: rem(108px);
}

.LargeBoldText {
  font-size: rem(16px);
  font-weight: 600;
  color: var(--ir-neutral-dark-black);
}

.NormalBoldText {
  font-size: rem(14px);
  font-weight: 600;
  color: var(--ir-neutral-dark-black);
}

.LighterLabels {
  font-size: rem(12px);
  color: var(--ir-neutral-dark-black-90);
}

.LighterLabelsBold {
  font-size: rem(12px);
  color: var(--ir-neutral-dark-black-90);
  font-weight: 600;
}

.NormalLightText {
  font-size: rem(12px);
  color: var(--ir-neutral-black-100);
}

.RegularText {
  color: var(--ir-neutral-dark-black);
  font-size: rem(14px);
}

.CyanText {
  color: var(--ir-segment-cyan-700);
  font-size: rem(12px);
}

.ActiveLinks {
  color: var(--ir-segment-cyan-700);
  font-size: rem(12px);
  text-decoration: underline;
  cursor: pointer;
}

.ItalicLabel {
  font-size: rem(12px);
  color: var(--ir-neutral-dark-black-90);
  letter-spacing: -0.3px;
  font-style: italic;
}

.GoodCreditScore {
  font-size: rem(14px);
  font-weight: 700;
  color: var(--ir-neutral-green-510);
}

.BadCreditScore {
  font-size: rem(14px);
  font-weight: 700;
  color: var(--ir-field-error);
}

.ApplicantList {
  margin-bottom: rem(60px);
}

@media (max-width: $screen-mobile-navbar) {
  .ApplicationItemContainer {
    margin-left: rem(5px);
    margin-right: rem(5px);
  }
}
