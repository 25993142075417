@import '/src/stylesheets/utils';

.renterScrollBar {
  overflow-y: auto;

  .renterPhotoInformationtButtonContainer {
    display: flex;
    gap: rem(24px);
    flex-direction: column;
    align-items: flex-end;

    .renterPhotoInformationButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }

    .renterPhotoDullButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-neutral-010);
      text-align: start;
      color: var(--ir-neutral-010);
      font-weight: 600;
      font-size: rem(16px);
    }
  }

  .linkButtonColor {
    color: var(--ir-segment-cyan-700);
  }
}

.InfoContainer {
  display: flex;
  gap: rem(8px);
  justify-content: center;
  align-items: center;
  margin-bottom: rem(24px);
}

.DescriptionContainer {
  color: var(--ir-warm-gray-800);
  font-size: rem(14px);
  font-style: normal;
  font-weight: 500;
}

.renterScrollBar::-webkit-scrollbar {
  display: none;
}

.Container {
  text-align: start;
}
