@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: 0 rem(10px) rem(28px) rem(25px);
  flex-direction: column;
  gap: rem(10px);

  .NonScrollableContent {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    padding-right: rem(15px);
  }

  .Heading {
    font-size: rem(32px);
    color: var(--ir-neutral-black-100);
  }

  .RenterName {
    font-size: rem(22px);
    font-weight: 600;
    color: var(--ir-neutral-black-primary);
  }

  .ScrollableContent {
    max-height: 70vh;
    overflow-y: auto;
    padding-right: 15px;
  }
}

.CustomCLass {
  [class='modal-content'] {
    height: 95vh;
    overflow: hidden;
    border-radius: rem(24px);
  }

  .CustomHeader{
    padding: 20px 30px;
  }

  .CancelButton {
    padding: 10px 0;
  }
}

@media (max-width: $screen-laptop-md) {
  .CustomCLass {
    [class*='Modal_dialogClassName__'] {
      max-width: unset;
      margin: rem(30px);
    }
  }
}

@media (max-height: $screen-laptop) {
  .Container {
    .ScrollableContent {
      max-height: 65vh;
    }
  }
}

@media (max-height: $screen-tablet) {
  .Container {
    .ScrollableContent {
      max-height: 60vh;
    }
  }
}

@media (max-height: $screen-tablet-md) {
  .Container {
    .ScrollableContent {
      max-height: 55vh;
    }
  }
}
