@import '/src/stylesheets/utils';

.customDropzoneContainer {
  .customDropzone {
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D2D6D6FF' stroke-width='3' stroke-dasharray='8%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-color: #F7FAFA;

    .customDropzoneTextContainer {
      margin-top: rem(43px);
      text-align: center;

      .customDropzoneAcceptedText {
        font-size: rem(10px);
        font-weight: 600;
        color: var(--ir-neutral-010);
      }

      .customDropzoneText {
        display: flex;
        color: var(--ir-neutral-000);
        font-size: rem(14px);
        font-weight: 600;
        justify-content: center;

        .customDropzoneP {
          margin-bottom: rem(16px);

          .customDropzoneButton {
            width: auto;
            padding: unset;
            border: none;
            background: unset;
            color: var(--ir-segment-cyan-900);
            box-shadow: unset;
            margin-bottom: unset;
            margin-left: rem(3px);
            margin-top: rem(-14.5px);
            outline: none;
          }
        }
      }
    }
  }
}

@media (max-width: 744px) {
  .customDropzoneTextContainer {
    margin-top: rem(33px);
  }
}

.imageRenderContainer {
  overflow: hidden;
}

.hideCustomDropzone {
  display: none;
}

.customImageBackground {
  background-color: var(--ir-neutral-white-bg);

  .customDropzoneImage {
    margin-top: rem(19.5px) !important;

    .customImageText {
      justify-content: flex-start !important;
      margin-left: rem(16px);
      margin-right: rem(16px);

      .lowerOpacity {
        width: rem(81px);
        height: rem(81px);
        opacity: 0.3;
      }

      .customImageTextContainer {
        display: flex;
        flex-direction: column;
        margin-left: rem(16px);
        justify-content: center;
        height: 100%;
        margin-bottom: rem(6px);

        .customImageStatement {
          font-size: rem(14px);
          font-weight: 600;
          color: var(--ir-neutral-001);

          .customImageButton {
            width: auto;
            padding: unset;
            border: none;
            background: unset;
            color: var(--ir-segment-cyan-900);
            box-shadow: unset;
            margin-bottom: unset;
            margin-top: rem(-14.5px);
            outline: none;
          }
        }

        .customImageAcceptedText {
          text-align: start;
          color: var(--ir-neutral-010);
          font-size: rem(10px);
          font-weight: 600;
        }
      }
    }
  }
}
