@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  margin-bottom: rem(22px);

  .EmploymentCol{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: rem(10px);
  }

  .Section {
    display: flex;
    flex-direction: column;
    padding: rem(10px);
    border: 1px solid var(--ir-gray-92);
    border-radius: rem(4px);
    gap: rem(12px);
    margin-bottom: rem(8px);

    .SmallButton {
      display: flex;
      width: fit-content;
      padding: rem(4px) rem(8px);
      border-radius: rem(4px);
      border: 1px solid var(--ir-gray-1050);
      margin-left: rem(5px);
    }
  }

  .LeftDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

  .RightDiv {
    display: flex;
    padding: 5px 0;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  }

  .Header {
    display: flex;
    justify-content: space-between;
    padding: rem(5px) rem(0);

    .Text {
      font-weight: 600;
      font-size: rem(16px);
      color: var(--ir-neutral-black-primary);
      text-transform: uppercase;
      line-height: rem(16px);
    }
  }

  .Data {
    display: flex;
    flex-direction: column;
    color: var(--ir-gray-1050);
    gap: rem(5px);

    .Title {
      display: flex;
      font-size: rem(18px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .Text {
      flex: 1 0 0;
      font-size: rem(16px);
    }

    .Date {
      display: flex;
      gap: rem(8px);

      .Time {
        text-transform: lowercase;
        border-radius: rem(2px);
        align-items: center;
        display: flex;
      }
    }
  }

  .Info {
    display: flex;
    flex-direction: row;
  }

  .MonthlyIncome {
    display: flex;
    align-items: center;
    gap: rem(8px);
    align-self: stretch;
  }

  .VerifierCard {
    padding-top: rem(12px);
    margin-left: rem(10px);
    flex: 1;

    .Data {
      display: flex;
      flex-direction: column;
    }
  }

}
