@import '/src/stylesheets/utils';

.FooterContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;

  .HorizantalLine {
    height: rem(1px);
    margin: 0;
    color: var(--ir-warm-gray-200);
    opacity: 1;
  }

  .Footer {
    background-color: var(--ir-neutral-white-bg);
    padding-block: rem(30px);
    color: var(--ir-dark-blue-100);
    font-size: rem(13px);
    font-style: normal;
  }
}
