@import '/src/stylesheets/utils';

.Container {
  width: 100%;
  margin-bottom: rem(10px);
  padding: unset;

  .HeadingContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .SectionHeading {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  }

  .ReasonForMovingContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-bottom: rem(12px);

    .Label {
      display: flex;
      width: 100%;
      padding: rem(8px) rem(12px);
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      font-weight: 600;
      text-transform: capitalize;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: rem(8px);
      flex: 1 0 0;
      border-right: 1px solid var(--ir-gray-200);
      border-bottom: 1px solid var(--ir-gray-200);
      border-left: 1px solid var(--ir-gray-200);
    }

    .Value {
      display: flex;
      width: 100%;
      padding: rem(8px);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: rem(12px);
      font-weight: 600;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: rem(8px);
      flex: 1 0 0;
      border-radius: 0 0 rem(4px) rem(4px);
      border-right: 1px solid var(--ir-gray-200);
      border-bottom: 1px solid var(--ir-gray-200);
      border-left: 1px solid var(--ir-gray-200);
    }
  }

  .NoBottomMargin {
    margin-bottom: unset !important;
  }

  .AddressInformation {
    display: flex;
    flex-direction: column;
    padding: rem(10px) rem(8px);
    gap: rem(12px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-92);
    background: var(--ir-warm-gray-100);
    margin-bottom: rem(10px);
    overflow: hidden;
    color: var(--ir-neutral-black-100);
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .StreetAddress {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: rem(18px);
    }

    .States {
      font-size: rem(16px);
    }

    .MonthlyRent {
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      line-height: rem(14px);
      text-transform: capitalize;
    }

    .TimePillContainer {
      display: flex;
      gap: rem(8px);
      align-items: center;

      .TimePill {
        display: flex;
        width: fit-content;
        padding: 0 rem(3px);
        background: var(--ir-gray-200);
        color: var(--ir-neutral-black-primary);
        align-items: center;
        border-radius: rem(2px);
        font-size: rem(12px);
        font-weight: 600;
      }
    }
  }

  .DetailsContainer {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .DetailsHeading {
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      text-transform: capitalize;
    }

    .Details {
      color: var(--ir-neutral-black-100);
      text-overflow: ellipsis;
      font-size: rem(16px);
    }
  }

  .RowContainer {
    gap: 0;
  }

  .VerificationPending {
    overflow: hidden;
    color: var(--ir-neutral-black-100);
    text-overflow: ellipsis;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .Heading {
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      font-weight: 600;
      margin-bottom: rem(8px);
    }

    .MessageContainer {
      display: flex;
      padding: rem(12px) rem(5px);
      align-items: center;
      gap: rem(8px);
      align-self: stretch;
      border-radius: rem(4px);
      border: 1px solid var(--ir-gray-92);
      background: var(--ir-warm-gray-100);
    }
  }
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .AddressInformation {
      gap: rem(8px);
    }

    .RowContainer {
      display: flex;
      flex-direction: column;
      gap: rem(8px);
    }
  }
}

.LadderContainer {
  display: flex;
  gap: rem(10px);
  margin-bottom: rem(12px);
}

.Ladder {
  display: flex;
  margin-left: 4px;
  width: 10px;
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-350);
}

.VerticalLineMargin {
  margin-top: rem(2px);
  margin-bottom: rem(20px);
}

.HorizontalLine {
  width: 100%;
  margin-top: rem(9px);
  border-top: rem(1px) solid var(--ir-gray-350);
}

.VerifierCardHorizontalLine {
  margin-top: rem(28px);
}

.LadderDetails {
  margin-left: rem(10px);
}
