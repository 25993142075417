.AccountPreferencesContainer {
  display: flex;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;

  .MainContainer {
    display: flex;
    width: 90%;
    border-top: 1px solid #d5d6d6;
    border-bottom: 1px solid #d5d6d6;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 35%;
    gap: 20px;
  }

  .LeftContainer {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    gap: 20px;
  }

  .RightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }

  .FontStyle1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: #303333;
    margin-bottom: 0;
  }

  .FontStyle2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.03em;
    color: #303333;
    margin-bottom: 0;
  }

  .LearnMoreContainer {
    display: flex;
    padding: 10px 15px;
    border: 1px solid #95c5f6;
    background: #95c5f680;
    justify-content: space-between;
    gap: 12px;
    border-radius: 4px;
  }

  .LearnMoreContainer2 {
    display: flex;
    padding: 10px 15px;
    border: 1px solid #86e39f;
    background: #86e39f80;
    justify-content: space-between;
    gap: 12px;
    border-radius: 4px;
  }

  .TextMsgContainer {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    gap: 10px;
  }

  .FontStyle3 {
    width: 100%;
    color: #303333;
    font-size: 12px;
    font-weight: 600;
    line-height: 16.34px;
    margin-bottom: 0;
  }

  .FontStyle4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: -0.03em;
    margin-bottom: 0;
  }

  .MiniContainer {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .ManageButton {
    padding: 8px 30px;
  }

  @media (max-width: 768px) {
    .MainContainer {
      flex-direction: column;
      min-height: 40%;
    }

    .LearnMoreContainer {
      flex-direction: column;
      align-items: center;
    }

    .TextMsgContainer {
      flex-direction: column;
      margin-bottom: 10px;
    }

    .FontStyle1,
    .FontStyle2,
    .FontStyle3,
    .FontStyle4 {
      text-align: center;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .MainContainer {
      flex-direction: column;
      gap: 50px;
      padding: 20px 0;
    }
  }
}
