@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
  text-align: left;

  [class*='-ValueContainer'] {
    min-height: unset;
  }

  [class*='-control'] {
    min-height: rem(32px);
  }

  [class*='-IndicatorsContainer'] {
    height: rem(19px);
  }

  [class*='-indicatorContainer'] {
    height: rem(24px);
  }
}

.RenterButtonContainer {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;

  .RenterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }
}

.SelectContainer {
  [class*='-placeholder'] {
    color: var(--ir-gray-1050);
  }
}

.FieldsContainer {
  display: flex;
  flex-direction: column;
}

.Heading {
  text-align: left;
  color: var(--ir-gray-1050);
  font-size: rem(18px);
  font-weight: 500;
}
