@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.container {
  text-align: center;
  font-size: rem(18px);
  font-style: normal;
  font-weight: 400;
  line-height: rem(24px);
  margin-top: 10%;
  padding-left: rem(30px);
  padding-right: rem(30px);
  color: var(--ir-neutral-black-100);
  overflow: auto;

  .arrowLoader {
    animation: rotate 2s linear infinite;
  }

  .heading {
    font-weight: 700;
    font-size: rem(24px);
    margin-bottom: rem(20px);
    margin-top: rem(20px);
  }

  .subHeading {
    font-weight: 600;
    font-size: rem(20px);
    margin-bottom: rem(20px);
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: rem(20px);
  }

  .mainText {
    color: var(--ir-neutral-black-primary);
    font-size: rem(18px);
    margin-bottom: rem(25px);
  }

  .ButtonContainer {
    display: flex;
    margin: rem(40px);
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;

    button {
      width: fit-content;
      font-weight: 600;
      font-size: rem(16px);
    }

    .ButtonText {
      display: flex;
      align-items: center;
      gap: rem(5px);
    }
  }

  .InfoContainer {
    margin: rem(20px) 0;
    padding: rem(16px) rem(13px);
    border: rem(1px) solid var(--ir-neutral-states-color-700);
    border-radius: rem(8px);
    justify-content: start;

    .InfoText {
      text-align: left;
      color: var(--ir-neutral-black-primary);
      font-weight: 600;
      font-size: rem(14px);
      margin-bottom: rem(12px);
    }

    .InfoItemContainer {
      .InfoItem {
        display: inline-flex;
        float: left;
        align-items: center;
        gap: rem(4px);

        .InfoItemTitle {
          font-size: rem(12px);
          font-weight: 600;
          color: var(--ir-neutral-black-primary);
        }
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: scaleX(-1) rotate(0deg);
    }

    0% {
      transform: scaleX(-1) rotate(360deg);
    }
  }
}

@media (max-width: $screen-mobile-navbar) {
  .container {
    margin: auto;
  }
}
