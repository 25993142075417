@import '/src/stylesheets/utils';
@import '/src/stylesheets/colors';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .PublicRecords {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .PublicRecordItem {
    margin-bottom: rem(20px);
  }

  .ItemDivider {
    border-bottom: 1px solid var(--ir-gray-92);
  }

  .publicReportHeader {
    text-align: left;
  }

  .publicReportStatus {
    color: var(--ir-neutral-black-100);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1rem (2px);
  }

  .publicReportDiv {
    display: flex;
    gap: rem(8px);
  }

  .publicReportEvaluation {
    padding: 0 3px;
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    border-radius: rem(2px);
    font-size: rem(13px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .publicReportEvaluationVal {
    color: var(--ir-neutral-black-100);
    font-size: rem(13px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
