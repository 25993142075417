@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  padding: 2px;
  min-height: 91vh;
  align-items: center;
  justify-content: center;

  .CardClass {
    height: 100%;
    padding: unset;
    background: var(--ir-neutral-white-bg);
    border-radius: unset !important;
  }

  [class*='row'] {
    --bs-gutter-x: 0 !important;
  }

  [class*='containerRow'] {
    width: 100%;
  }
}

@media (max-width: $screen-tablet) {
  .Container {
    display: block;
    padding: unset !important;

    .CardClass {
      padding: unset;
    }

    [class*='containerRow'] {
      height: 92vh;
    }
  }
}
