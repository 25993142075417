@import '/src/stylesheets/utils';

.connectFinicityContainer {
  .finicityInformationContainer {
    display: flex;
    width: 100%;
    padding: rem(8px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    margin-top: rem(8px);
    gap: rem(12px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(16px);

    .finicityInformationText {
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-000);
    }
  }

  .horizontalLine {
    width: 100%;
    border-top: rem(1px) solid var(--ir-gray-200);
    margin-bottom: rem(24px);
  }

  .finicityButtonConatiner {
    display: flex;
    justify-content: flex-end;

    .finicityButton {
      display: flex;
      width: fit-content;
    }
  }
}
