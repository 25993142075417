@import '/src/stylesheets/utils';

.renterPriorIncomeSummary {
  display: flex;
  flex-direction: column;
  gap: rem(16px);
  align-items: flex-start;

  .heading {
    text-align: start;
    color: var(--ir-gray-1050);
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(24px);

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .cardContainer {
    width: 100%;
  }

  .employmentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: rem(10px);
    max-height: 36rem;
    overflow-y: auto;
  }

  .renterAddButton {
    width: fit-content;
    height: rem(24px);
    padding: rem(0) rem(5px) !important;
    font-size: rem(12px);
  }

  .buttonContainer {
    padding-top: rem(20px);
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;

    .buttonPrimary {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
    }
  }
}

@media (max-width: 744px) {
  .renterPresentIncomeSummary {
    .employmentContainer {
      max-height: 27rem;
    }
  }
}
