@import '/src/stylesheets/utils';

.container {
  :global(.modal-footer) {
    display: flex;
    padding: 0.5rem 3rem;
    border: none;
    flex: 1 1;
    justify-content: space-between;
  }

  .button {
    width: fit-content;
  }

  .backButton {
    margin-right: 2rem;
    width: fit-content;
  }

  :global(.modal-body) {
    padding: 0 3rem 3.5rem;
    background: var(--ir-segment-cyan-300);
  }

  .unitSpecs,
  .amenities {
    padding-top: rem(25px);

    [class$='-control'] {
      height: rem(55px);
    }
  }
}

.datePicker {
  [class^='CustomDatePicker_dateContainer'] {
    height: rem(55px);
  }
}

.propertyType {
  color: var(--ir-neutral-black-primary);
  font-size: rem(14px);
  font-weight: 600;
  line-height: rem(20px);
}

.propertyBasicInformation {
  height: 50px;
  margin-bottom: 2rem;

  input {
    font-size: rem(16px);
    font-weight: 600;
  }

  input::placeholder {
    font-weight: 400;
  }
}

.row {
  display: flex;
  width: 100%;
  padding: 1rem 0;
  align-items: center;
}

.formHeading {
  color: var(--ir-neutral-black-primary);
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.checkBoxRowPadding {
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.addressHelpText {
  font-size: 11px;
  font-weight: 400;
  text-align: start;
}

.dateCol {
  display: flex;
  justify-content: flex-start;
}

.buttonCol {
  display: flex;
  justify-content: flex-end;
  padding: 0;
}

.checkboxLabel {
  display: flex;
  color: var(--ir-warm-gray-800);
  align-items: center;
  font-size: 12px;

  input {
    width: 1.143rem !important;
    height: 1.143rem !important;
    border: 2px solid var(--ir-neutral-black-primary);
  }

  label {
    line-height: 14px;
    margin-left: 0.3rem;
    margin-top: 0.2rem;
  }
}

.infoToolTip {
  margin: 0 0 5px 2px;
}

.dropDownFillArrow {
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: var(--ir-neutral-white-bg);
  border-radius: 2px;
  margin-right: 1rem;
}

.alignToolTip {
  display: flex;

  .inputBackgroundColor {
    background-color: var(--ir-warm-gray-100);

    &:focus {
      background-color: var(--ir-warm-gray-100);
    }
  }
}

.customSelect {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .buttonCol {
    justify-content: center;
    margin-top: 1rem;
  }
}

.amenitiesDescription {
  margin: auto;
  color: var(--ir-warm-gray-800);
  font-size: 12px;
  line-height: 14px;
  padding-top: 5px;
}

.enterCustom {
  display: flex;
  width: 100%;
  height: 2.5rem;
  border: none !important;
  text-align: center;
  color: var(--ir-neutral-black-primary) !important;
  box-shadow: none !important;
  cursor: pointer;
  font-size: rem(14px);
  font-style: italic;
  font-weight: 400;
  justify-content: center;
}

.inputReactSelectContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.inputReactSelectP {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 1rem;
  overflow: hidden;
  width: 80%;
}

.inputReactSelectCross {
  display: flex;
  position: absolute;
  right: 4px;
  bottom: rem(31px);
  height: 50%;
  padding: 1rem 1rem 0 0;
  align-items: center;
  background-color: var(--ir-warm-gray-100);
  cursor: pointer;
  justify-content: center;
  padding-left: 0.3rem;

  p {
    margin: 0;
    margin-right: 0.4rem;
  }
}

.error {
  color: var(--ir-semantics-error) !important;
}

.backgroundColor {
  background-color: var(--ir-field-error-background);
}

.crossIconContainer {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  background-color: var(--ir-neutral-white-bg);
  border-radius: 0.09rem;
  justify-content: center;

  .crossIcon {
    width: 14px;
    height: 14px;
    color: var(--ir-neutral-000);
  }

  &:hover .crossIcon {
    color: var(--ir-neutral-black-100);
  }
}

.utilsTextField {
  position: relative;
}

.tooltipWidth {
  width: 95px !important;
}

.counterValue {
  color: var(--ir-neutral-black-100);
  font-size: 1rem;
  font-weight: 600;
}

.counterValueLast {
  color: var(--ir-neutral-000);
  font-size: 1rem;
  font-weight: 400;
}

.customTextFieldWithLabel {
  :global(.form-floating > label) {
    top: rem(-4px);
    color: var(--ir-neutral-black-primary);
    opacity: 1 !important;
    font-size: rem(12px);
    font-weight: 600;
    line-height: rem(14px);
    padding-left: 0.5rem;
    scale: 1.2;
  }

  input {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(20px);
    padding-top: 1rem;

    &::placeholder {
      color: var(--ir-neutral-black-10);
      font-size: rem(14px);
      font-weight: 400;
    }
  }
}

.label {
  position: absolute;
  top: rem(2px);
  left: rem(10px);
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  font-weight: 600;
}

.descriptionEditor {
  height: rem(363px);
  margin-bottom: rem(16px);
}

.descriptionText {
  color: var(--ir-warm-gray-800);
  font-size: rem(12px);
  line-height: rem(14px);
  margin-bottom: rem(10px);
  margin-top: rem(8px);
}

.descriptionInfoText {
  color: var(--ir-warm-gray-800);
  font-size: rem(10px);
  font-weight: 400;
  line-height: rem(14px);
  margin-left: rem(6);
}

.descriptionInfoParagraph {
  display: flex;
}

.descriptionError {
  margin-bottom: 8px;
  text-align: end;
}

.petsLabel {
  font-size: rem(10px);
  font-weight: 600;
  margin-bottom: rem(5px);
  text-align: left;
}

.wordCount {
  min-height: 20px !important;
}

.mediaContainer {
  position: relative;
  color: var(--ir-neutral-black-primary);
  padding-left: rem(8px);
  padding-right: rem(8px);

  .urlAddIconContainer {
    display: flex;
    position: relative;
    color: var(--ir-neutral-black-primary);
    gap: rem(5px);
    padding-left: rem(8px);
    padding-right: rem(8px);

    .urlAddIcon {
      position: absolute;
      top: rem(-12px);
      right: rem(5px);
      color: var(--ir-neutral-000);
    }
  }

  .formHeading {
    color: var(--ir-neutral-black-primary);
    padding-bottom: unset;
  }

  .videoText {
    font-size: rem(11px);
    margin-top: rem(2px);
  }
}
