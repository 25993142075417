@import '/src/stylesheets/utils';

.container {
  min-width: rem(590px);

  .userContainer {
    width: 100%;
    border: rem(1px) solid var(--ir-warm-gray-030);
    border-radius: rem(4px);
  }

  .contentContainer {
    margin-bottom: rem(20px);
    border-radius: rem(4px);

    .userOptions {
      display: none;
    }

    &:hover {
      .userInfoContainer {
        background-color: var(--ir-segment-blue-100);
      }

      .userOptions {
        display: block;
      }
    }
  }

  .guestsPresent {
    display: flex;
    height: fit-content;
    padding-left: rem(4px);
  }

  .headerContainer {
    display: flex;
    padding: rem(5px) rem(20px) 0 rem(8px);
    justify-content: space-between;
  }

  .TeamInformation {
    display: flex;
    gap: rem(68px);

    .hoveredStyle {
      display: block;
    }

    .nonHoveredStyle {
      display: none;
    }
  }

  .toggleButtonDown {
    rotate: 180deg;
  }

  .checkBoxContainer {
    width: rem(24px);
    height: rem(24px);

    .form-check-input {
      cursor: pointer;
    }
  }

  .guestToggler {
    cursor: pointer;
    margin-right: rem(10px);
  }

  .AgentTeam {
    padding: 2px;
    background: var(--ir-neutral-white-bg);
    text-align: center;
    color: var(--ir-neutral-black-100);
    border-radius: rem(2px);
    font-size: rem(10px);
    font-weight: 600;
    text-transform: capitalize;
    margin-top: rem(5px);
  }

  .GuestAgentTeam {
    margin-left: rem(70px);
  }

  .UserInformation {
    display: flex;
    transition: background-color 0.3s ease;
    padding: rem(0) rem(10px) rem(20px) rem(30px);

    .avatar {
      display: flex;
      min-width: rem(50px);
      min-height: rem(50px);
      align-items: center;
    }
  }

  .profileImage {
    border-radius: rem(30px);
    height: rem(38px);
  }

  .personalInformation {
    display: flex;
    gap: rem(20px);
    align-items: center;
    flex: 1.7;
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    padding-top: rem(5px);
    text-align: start;
  }

  .agentName {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .agentEmail {
    text-align: left;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    word-break: break-all;
    padding-right: rem(10px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .roleContainer {
    display: flex;
    flex: 1;
    text-align: start;
    flex-direction: column;
    padding-top: rem(5px);
    justify-content: center;
  }

  .agentRole {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .roleStatus {
    width: fit-content;
    height: fit-content;
    margin: auto;
    padding: rem(0) rem(3px) rem(0) rem(3px);
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
    font-size: rem(10px);
    background-color: var(--ir-warm-gray-040);
    border-radius: rem(2px);
    margin-left: rem(2px);
  }

  .totalGuests {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    font-weight: 600;
  }

  .totalGuestsCount {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    font-weight: 400;
    margin-left: rem(8px);
  }

  .activeContainer {
    display: flex;
    flex: 1;
    color: var(--ir-neutral-black-primary);
    margin-top: rem(5px);
    font-weight: 600;
    font-size: rem(16px);
    align-items: center;
  }

  .activeStatus {
    height: fit-content;
    padding: rem(0) rem(3px) rem(0) rem(3px);
    background-color: var(--ir-segment-yellow-100);
    border-radius: rem(2px);
    font-size: rem(10px);
    font-weight: 600;
    margin-left: rem(2px);
  }

  .lastActive {
    width: fit-content;
    text-align: start;
    padding-left: rem(5px);
  }

  .userOptionsContainer {
    display: flex;
    padding-right: rem(8px);
  }

  .guestsContainer {
    width: 100%;

    .UserInformation {
      padding: rem(0) rem(10px) rem(5px) rem(10px);
    }

    .userOptionsContainer {
      padding-right: rem(5px);
    }
  }

  .guestsBox {
    width: 100%;
    border: rem(1px) solid var(--ir-warm-gray-030);
  }

  .NotFirstGuest {
    border-top: unset;
  }

  .LastGuestBox {
    border-bottom-left-radius: rem(4px);
    border-bottom-right-radius: rem(4px);
  }

  .FirstGuestBox {
    border-top-left-radius: rem(4px);
    border-top-right-radius: rem(4px);
  }

  .guestsList {
    display: flex;
    padding-left: rem(10px);
  }

  .FirstGuest {
    margin-top: rem(5px);
  }

  .branchContainer {
    display: flex;
    min-height: 100%;
    width: rem(10px);

    .verticalLine {
      border-left: rem(1px) solid var(--ir-neutral-states-color-700);
    }

    .horizontalLine {
      width: 100%;
      margin: auto;
      border-top: rem(1px) solid var(--ir-neutral-states-color-700);
    }
  }

  .bottomPadding {
    margin-bottom: rem(10px);
  }

  .guestsContainerMain {
    display: flex;
    width: 100%;
    border-top: rem(1px) solid var(--ir-warm-gray-030);
    justify-content: flex-start;
    padding: rem(8px);
    align-items: center;
  }
}

@media (max-width: 590px) {
  .container {
    max-width: rem(520px);
    overflow: scroll;

    .guestsBox {
      max-width: rem(510px);
      overflow: scroll;
    }

    .userContainer {
      overflow: scroll;
    }
  }
}

@media (max-width: 490px) {
  .container {
    max-width: rem(420px);
    overflow: scroll;

    .guestsBox {
      max-width: rem(410px);
      overflow: scroll;
    }

    .userContainer {
      overflow: scroll;
    }
  }
}

@media (max-width: 440px) {
  .container {
    max-width: rem(340px);
    overflow: scroll;

    .guestsBox {
      max-width: rem(330px);
      overflow: scroll;
    }

    .userContainer {
      overflow: scroll;
    }
  }
}

@media (max-width: 375px) {
  .container {
    max-width: rem(300px);
    overflow: scroll;

    .guestsBox {
      max-width: rem(290px);
      overflow: scroll;
    }

    .userContainer {
      overflow: scroll;
    }
  }
}

@media (max-width: 800px) {
  .agentEmail {
    max-width: rem(180px);
  }
}
