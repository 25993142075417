@import '/src/stylesheets/utils';

.container {
  margin-inline: rem(100px);

  .mainContainer {
    display: flex;
    text-align: center;
    align-items: flex-end;
    gap: rem(10px);
  }

  .headingDiv {
    display: flex;
    justify-content: flex-end;
    margin-bottom: rem(10px);
    padding-top: rem(10px);
    gap: rem(30px);
    align-items: center;
  }

  .BackButtonContainer {
    display: flex;
    gap: rem(10px);
  }

  .arrowContainer {
    align-self: center;
    cursor: pointer;
  }

  .statusDiv {
    color: var(--ir-neutral-black-primary);
    font-size: rem(28px);
    font-style: normal;
    line-height: rem(26px);
    text-transform: capitalize;
  }

  .titleContainer {
    display: flex;
    align-items: flex-end !important;
    gap: rem(10px);
  }

  .button {
    padding: rem(4px) rem(8px);

    .spinner {
      width: rem(15px);
      height: rem(15px);
      margin-left: rem(4px);
    }
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    gap: rem(8px);
    padding-left: 0;
  }

  [class*='ReactSelect_container'] {
    margin-top: unset;
  }

  .organizationOwnerContainer {
    display: flex;
    flex-direction: column;
  }

  //// Property Details

  .propertyDiv {
    padding: rem(10px);
    border: rem(1px) solid var(--ir-warm-gray-030);
    margin-bottom: rem(5px);
  }

  .addressContainer {
    display: flex;
    width: 100%;
  }

  .propertyNameDiv {
    font-size: rem(16px);
    font-weight: 600;

    &.black {
      color: var(--ir-neutral-dark-black);
    }
  }

  .depositContainer {
    background: none;
    font-weight: 600;
    font-size: rem(10px);
  }

  .countsDiv {
    display: flex;
    justify-content: space-evenly;
  }

  .addressDiv {
    text-align: left;
    color: var(--ir-neutral-dark-black-90);
    font-size: rem(12px);
  }

  .verticleLine {
    width: rem(1px);
    height: 100%;
    margin: 0;
    border: rem(1px) solid var(--ir-warm-gray-030);

    @media (max-width: $screen-mobile-navbar) {
      display: none;
    }
  }

  .organizationDiv {
    color: var(--ir-segment-cyan-700);
    font-size: rem(12px);
  }

  //// Renter Details

  .renterDetailsContainer {
    display: flex;
    gap: rem(15px);
    border-radius: rem(4px);
    text-align: left;
    margin-bottom: rem(30px);
  }

  .renterDetailsDiv {
    display: flex;
    padding: rem(4px) rem(16px);
    border: rem(1px) solid var(--ir-warm-gray-030);
    border-radius: rem(4px);
    gap: rem(8px);
  }

  .internelContainer {
    display: flex;
  }

  .pillContainer {
    font-size: rem(10px);
    font-weight: 600;
  }

  .LabelDiv {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
    white-space: nowrap;

    &.normalFontWeight {
      font-weight: unset;
    }
  }

  .totalCountDiv {
    padding: 0 rem(2px);
    gap: rem(10px);
    border-radius: rem(2px);
    background: var(--ir-gray-200);
  }

  .countLabelContainer {
    display: flex;
    gap: rem(5px);
  }

  .infoContainer {
    padding: rem(4px) rem(0);
  }
}

@media (max-width: $screen-mobile-navbar) {
  .container {
    margin-inline: rem(15px);
  }
}
