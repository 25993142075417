@import '/src/stylesheets/utils';

.novaCreditContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--ir-segment-blue-96);
  padding: rem(8px);
  margin-bottom: rem(20px);
  gap: rem(12px);

  .novaCreditLines {
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-weight: 600;

    .novaLink {
      color: var(--ir--neutral-sea-blue-500);
      text-decoration: none;
    }
  }
}

.renterCreditButtonContainer {
  .renterCreditButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);

    .boxArrowIcon {
      margin-left: rem(8px);
      margin-bottom: rem(1px);
    }
  }

  .nextButton {
    width: fit-content;
  }
}

.disabledNovaButton {
  opacity: 0.5;
  cursor: initial;

  &:hover {
    opacity: 0.5;
    cursor: initial;
  }
}
