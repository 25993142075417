@import '/src/stylesheets/utils';
@import '/src/stylesheets/colors';

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: rem(20px);
  padding: rem(12px) 0;

  .RecordItemsContainer {
    display: flex;
    gap: rem(30px);
    flex-wrap: wrap;
  }

  .RecordItemsContainer2 {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .RecordItemsContainerSecond {
    display: flex;
    justify-content: center;
    width: 94%;
    gap: rem(30px);
    flex-wrap: wrap;
  }

  .RecordItem {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .SummaryTable {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    width: 99%;
    overflow-x: auto;
  }

  .SummaryTableHeader {
    display: flex;
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    min-width: calc(100vh - 40px);

  }

  .SummaryTableHeaderGrey {
    display: flex;
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    background: var(--ir-gray-110);
    min-width: calc(100vh - 40px);

  }

  .SummaryTableLeft {
    width: 15%;
    text-align: left;
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .SummaryTableRight {
    display: flex;
    width: 85%;
    color: var(--ir-neutral-black-100);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .SummaryHeaders {
    width: 100%;
    text-align: left;
  }

  .TableHeadingStyle {
    color: var(--ir-segment-cyan-1050);
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1%;
  }

  .SummaryTableTotal {
    display: flex;
    margin-top: rem(2px);
    margin-bottom: rem(2px);
    border-top: rem(2px) solid var(--ir-gray-110);
    min-width: calc(100vh - 40px);

  }
}
