@import '/src/stylesheets/utils';

.ApplicantContainer {
  border-top: rem(1px) solid var(--ir-warm-gray-030);
  border-bottom: rem(1px) solid var(--ir-warm-gray-030);
  padding: rem(0);
  overflow-x: hidden;
}

.ApplicantCard {
  border: none;
}
