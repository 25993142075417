@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .QuickInformationText {
    padding: 0 rem(5px);
    text-align: start;
    color: var(--ir-neutral-dark-black-90);
    font-size: rem(12px);
    font-weight: 400;
  }

  .AddressInformation {
    margin-bottom: rem(22px);
  }

  .AddressReportContainer {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    width: 99%;
  }
}
