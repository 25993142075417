@import '/src/stylesheets/utils';

.ModalContainer {
  [class*='modal-lg'] {
    max-width: 90vw;
    width: 90vw;
  }

  [class*='modal-content'] {
    height: 95vh;
    max-height: 95vh;
    overflow-y: auto;
    border-radius: rem(24px);
  }

  [class*='modal-header'] {
    padding-bottom: unset;
  }

  [class*='modal-body'] {
    padding: unset;
    padding-bottom: rem(48px);
  }

  .HeaderContainer {
    display: none;
  }

  .BodyContainer {
    height: 100%;
    background: unset;
  }

  .IncomeHeading {
    padding: rem(12px) rem(28px);
    text-align: start;
    color: var(--ir-neutral-black-100);
    padding-top: unset;
    font-size: rem(32px);
  }

  .UserInformationContainer {
    padding: 0 rem(12px) rem(5px);
  }

  .SourceInformation {
    padding: 0 rem(40px);
  }

  .SourceContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(2px);
    align-items: center;
    margin-bottom: rem(13px);
  }

  .SourceHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .DropDownArrow {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .RotateArrow {
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
  }

  .IncomeReportInformation {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    padding: rem(18px) rem(40px) rem(12px);
  }

  .IncomeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .IncomeReportHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: capitalize;
  }

  .IncomeReportText {
    color: var(--ir-neutral-black-primary);
    font-weight: 500;
  }

  .FinancialReports {
    display: flex;
    padding: 0 rem(40px);
    flex-direction: column;
    gap: rem(12px);
  }

  .FinancialReportHeader {
    display: flex;
    gap: rem(10px);
  }

  .FinancialHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .InstitutionCount {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .Divider {
    width: 100%;
    margin: rem(18px) rem(40px) 0;
    border-top: 1px solid var(--ir-warm-gray-045);
  }

  .LoaderIcon {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
  }

  .IncomeReportNavbar {
    width: 100%;
    padding: rem(15px) rem(40px) rem(12px);
  }

  .SupportingDocContainer {
    margin-top: rem(10px);
    padding: rem(12px) rem(40px);
  }

  .ErrorContainer {
    width: 100%;
    padding: rem(12px) 0 rem(58px) 0;
    min-height: rem(290px);
  }
}
