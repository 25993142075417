:global {
  :local(.notification) {
    width: 500px;
    margin-left: -100px;
    font-weight: 400;
    color: #000;
  }

  .Toastify__toast-container {
    z-index: 999999;
  }
}

@media (max-width: 770px) {
  :global {
    :local(.notification) {
      width: auto;
      margin-left: auto;
    }
  }
}
