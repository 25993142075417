@import '/src/stylesheets/utils';

.profileContainer {
  cursor: pointer;
  display: flex;
  height: fit-content;

  .profileImg {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 0.5rem;
    overflow: hidden;
    object-fit: fill;
  }

  .profileImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profileName {
    font-size: 14px;
    font-weight: 700;
    overflow-wrap: anywhere;
  }

  .profileInformation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .profileLabel {
      font-size: rem(12px);
    }
  }

  .WebDropDownArrow {
    width: rem(9px);
    height: rem(9px);
    margin-top: rem(8px);
  }
}
