@import '/src/stylesheets/utils';

.InfoBoxContainer {
  padding: rem(8px) rem(0) rem(8px) rem(0);
  border: rem(1px) solid var(--ir-gray-200);
  border-radius: rem(4px);
  background-color: var(--ir-gray-000);
  box-shadow: rem(0) rem(4px) rem(4px) rem(0) var(--ir-shadow-midnight-express-000);

  .PropertyImgae {
    width: rem(40px);
    height: rem(40px);
  }

  .SubmittedDate {
    color: var(--ir-neutral-black-primary);
    font-size: rem(11px);
    font-weight: 600;
  }

  .HeaderContainer {
    display: flex;
    justify-content: space-between;
    padding: rem(5px) rem(16px) rem(0) rem(12px);
  }

  .SubmitInfo {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
  }

  .StatusContaienr {
    display: flex;
    gap: rem(5px);
    text-align: center;
    align-items: center;
  }

  .CompleteStatus {
    border-radius: rem(3px);
    padding: rem(2px) rem(10px) rem(2px) rem(10px);
    font-size: rem(12px);
    font-style: italic;
    letter-spacing: rem(-0.3px);
    text-transform: capitalize;
  }

  .SelectedAddressContainer {
    padding-right: unset;
    text-align: start;
  }

  .AddressContainer {
    display: flex;
    gap: rem(5px);
    padding: rem(0) rem(12px) rem(0) rem(5px);
  }

  .DropDownIconContainer {
    width: rem(24px);
    height: rem(24px);
    padding-top: rem(5px);
    cursor: pointer;
  }

  .UpArrow {
    transform: rotate(180deg);
    transition: transform 0.5s ease;
  }

  .DropDownIcon {
    transition: transform 0.5s ease;
  }

  .Address {
    margin: unset;
    color: var(--ir-warm-gray-060);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: rem(22px);
    font-weight: 600;
    cursor: pointer;
    max-width: 80vw;
  }

  .PropertyAddress {
    color: var(--ir-neutral-black-100);
  }

  .ExtraInfoContainer {
    display: flex;
    padding: rem(8px) rem(12px) rem(0) rem(12px);
    text-align: left;
    border-radius: rem(4px);
    flex-direction: column;
    gap: rem(2px);
  }

  .AvailableStatusContainer {
    display: flex;
    justify-content: space-between;
    padding: rem(0) rem(8px);
  }

  .AvailableTag,
  .MoveInTag {
    display: flex;
    width: fit-content;
    margin: unset;
    padding: rem(0) rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-primary);
    gap: rem(5px);
    font-size: rem(12px);
  }

  .AvailableTag {
    background: var(--ir-segment-yellow-100);
  }

  .AvailableStatus {
    color: var(--ir-neutral-black-100);
    font-size: rem(12px);
    font-weight: 600;
  }

  .PropertyInfoContainer {
    display: flex;
    padding: rem(8px);
    border: rem(1px) solid var(--ir-gray-200);
    border-radius: rem(4px) rem(4px) rem(0) rem(0);
    gap: rem(8px);
  }

  .DetailsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .PropertySizeContainer {
    display: flex;
    justify-content: space-between;
  }

  .Property {
    width: fit-content;
    padding: rem(0) rem(2px);
    color: var(--ir-gray-000);
    background-color: var(--ir-neutral-black-primary);
    border-radius: rem(2px);
    font-size: rem(12px);
    font-weight: 600;
  }

  .PropertyMeasure {
    display: flex;
    margin: unset;
    color: var(--ir-neutral-dark-black-90);
    font-size: rem(11px);
    font-weight: 600;
    gap: rem(8px);
    padding-right: rem(10px);
    white-space: nowrap;
  }

  .Measure {
    color: var(--ir-neutral-dark-black);
    font-size: rem(12px);
    font-weight: 600;
  }

  .PropertyDetails {
    display: flex;
    flex-direction: column;
    gap: rem(5px);
    width: 100%;
  }

  .UpperDetails {
    display: flex;
    padding: rem(0) rem(2px);
    text-align: left;
    justify-content: space-between;
  }

  .RentDetailsContainer {
    display: flex;
    gap: rem(8px);
    flex: 4;
  }

  .RentContainer,
  .DepositContainer,
  .BedsContainer,
  .BathsContainer {
    display: flex;
    flex-direction: column;
  }

  .Name {
    font-size: rem(11px);
    font-weight: 600;
    color: var(--ir-neutral-dark-black-90);
    white-space: nowrap;
  }

  .Value {
    color: var(--ir-neutral-dark-black);
    font-size: rem(12px);
    font-weight: 600;
  }

  .BedsContainer {
    flex: 1;
  }

  .BathsContainer {
    flex: 1;
  }

  .PetsContainer {
    display: flex;
    gap: rem(5px);
    padding: rem(0) rem(2px);
  }

  .AgentDetailsContainer {
    display: flex;
    margin: rem(0) rem(12px);
    padding: rem(8px);
    border: rem(1px) solid var(--ir-gray-200);
    border-radius: rem(0) rem(0) rem(4px) rem(4px);
    background-color: var(--ir-neutral-white-bg);
    align-items: center;
    gap: rem(8px);
  }

  .AgentProfilePic {
    border-radius: 50%;
  }

  .AgentInformation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .AgentDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .AgentIcons {
    display: flex;
    gap: rem(22px);
    align-items: center;
  }

  .AgentName {
    display: flex;
    margin: unset;
    color: var(--ir-neutral-black-100);
    font-size: rem(12px);
    font-weight: 700;
    gap: rem(3px);
  }

  .AgentEmail {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
    word-break: break-all;
  }

  .ButtonsContainer {
    display: flex;
    margin: rem(0) (12px);
    padding: rem(8px) rem(5px) rem(0) rem(5px);
    color: var(--ir-neutral-black-primary);
    gap: rem(12px);
    justify-content: center;
  }

  .TermsButton,
  .RentalButton {
    display: flex;
    width: 100%;
    padding: rem(7px) rem(17px);
    border: rem(1px) solid var(--ir-warm-gray-060);
    color: inherit;
    gap: rem(10px);
    justify-content: center;
    align-items: center;
  }

  .CompletionStatusContainer {
    padding: rem(12px);
  }

  .SelectedPropertyInfoContainer {
    margin: rem(2px) rem(12px) rem(0) rem(12px);
  }

  .SelectedRent {
    text-align: start;
  }

  .SelectedAddress {
    font-size: rem(12px);
    cursor: auto;
    white-space: normal;
  }

  .SelectedAgentContainer {
    cursor: auto;
  }

  .SelectedCollapsedInfo {
    display: flex;
    border: rem(1px) solid var(--ir-gray-200);
    border-radius: rem(4px);
    box-shadow: 0 rem(4px) rem(4px) 0 rgb(0 0 0 / 25%);
    padding-top: rem(8px);
    margin-top: rem(12px);
    flex-direction: column;
    gap: rem(8px);
  }

  .SelectedContainer {
    .AvailableStatusContainer {
      padding: rem(0) rem(16px);
    }
  }

  .SelectedInformation {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .SelectedAgentInformation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(8px);
  }

  .SelectedAgentName {
    padding-top: rem(3px);
  }

  .CustomLabelContainer {
    display: flex;
    gap: rem(5px);
  }

  .CustomLabel {
    width: fit-content;
    height: fit-content;
    padding: 0 rem(3px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    align-items: center;
    border-radius: rem(2px);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .CustomPropertyInfoContainer {
    justify-content: space-between;
    align-items: center;
  }

  .CustomIcon {
    margin-left: rem(8px);
  }

  .ButtonReset {
    padding: unset;
    border: unset;
    background: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }
}

.SelectedInfoBoxContainer {
  border: unset;
}

.OptionsContainer {
  padding: rem(12px) rem(16px);
}

@media (max-width: $screen-mobile-portrait-sm) {
  .InfoBoxContainer {
    .SelectedAddress {
      overflow-wrap: anywhere;
    }
  }
}
