@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(24px);
  -webkit-overflow-scrolling: touch;

  .RenterButton {
    width: fit-content;
    padding: rem(10px) rem(30px) rem(10px) rem(30px);
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-gray-000);
    font-weight: 600;
    font-size: rem(16px);
  }

  .RenterButtonContainer {
    display: flex;
    justify-content: center;
  }
}

.Title {
  text-align: center;
  font-weight: 300;
  color: var(--ir-gray-1050);
  font-size: rem(31px);
}

.Heading {
  color: var(--ir-gray-1050);
  font-weight: 600;
}

.QuestionnaireContainer {
  display: flex;
  flex-direction: column;
  gap: rem(12px);
}

.QuestionContainer {
  display: flex;
  padding: rem(8px);
  flex-direction: column;
  gap: rem(8px);
  border-radius: rem(4px);
  border: 1px solid var(--ir-gray-200);
  text-align: left;
}

.Question {
  color: var(--ir-gray-1050);
  font-size: rem(12px);
  font-weight: 600;
}

.AnswerContainer {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
}

.ExperianContainer {
  display: flex;
  padding: rem(2px) rem(20px) rem(20px) rem(12px);
  border: 1px solid var(--ir-segment-blue-96);
  text-align: start;
  flex-direction: column;
  background-color: var(--ir-segment-blue-100);
  border-radius: rem(4px);
}

.ExperianText {
  text-align: justify;
  color: var(--ir-neutral-black-primary);
  font-size: rem(12px);
  font-weight: 600;
}

.ExperianLogo {
  padding-block: rem(5px);
}

[class*='agentProfileContainer'] {
  min-height: unset;
}

.CardRowContainer {
  margin-bottom: rem(40px);
}

@media screen and (max-width: 765px) {
  .CardRowContainer {
    padding-bottom: rem(100px);
  }
}

@media screen and (min-height: 1300px) {
  .AgentSecurityQuestionnaireContainer {
    height: 100%;
  }
}
