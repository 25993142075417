@import '/src/stylesheets/utils';

.DetailsContainer {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  .DetailsHeading {
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    text-transform: capitalize;
  }

  .Details {
    color: var(--ir-neutral-black-100);
    text-overflow: ellipsis;
    font-size: rem(16px);
    overflow-x: hidden;
  }
}
