@import '/src/stylesheets/utils';

.Container {
  display: flex;
  gap: rem(5px);

  .PillsContainer {
    height: fit-content;
    margin: auto;
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
    padding-left: rem(3px);
  }

  .Date {
    font-weight: 600;
    color: var(--ir-warm-gray-060);
  }
}
