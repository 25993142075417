@import '/src/stylesheets/utils';

.MainRow{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
}

.ImageSection {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ImageSection img {
  width: rem(25px);
  height: rem(25px);
  border-radius: rem(16px);
  object-fit: cover;
}

.ImageSection img:first-child{
  position: relative;
  left: rem(8px);
}

.NameRow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.NameRow p:first-child {
  font-weight: 700;
}

.NameRow p:nth-child(2) {
  font-weight: 700;
  color: var(--ir-gray-500);
}

.NameRow > p {
  margin: 0;
}
