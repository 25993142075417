@import '/src/stylesheets/utils';

.Specification {
  .SpecificationValue {
    font-size: rem(18px);
    font-weight: 600;
    text-align: center;
    color: var(--ir-neutral-dark-black);
    white-space: nowrap;
    max-width: 8vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .SpecificationLabel {
    font-size: rem(11px);
    font-weight: 600;
    text-align: center;
    color: var(--ir-neutral-dark-black-90);
    max-width: 8vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
