@import '/src/stylesheets/utils';

.questionContainer {
  display: flex;
  align-items: center;
  gap: rem(8px);
  padding: rem(12px) rem(10px);
  border: 1px solid #e6eff0;
  border-radius: 4px;

  .icon {
    width: rem(16px);
    height: rem(16px);
  }

  .iconContainer {
    flex: 1;
  }

  .questions {
    font-style: normal;
    font-weight: 600;
    font-size: rem(12px);
    line-height: rem(16px);
    color: var(--ir-warm-gray-800);
    flex: 10;
  }

  .toggleContainer {
    flex: 1;

    .button {
      padding: 0;
      border: none;
      background: transparent;
    }
  }
}
