@import '/src/stylesheets/utils';

.userIndexContainer {
  width: 100%;
  padding-bottom: rem(23px);

  .FakeImage {
    width: rem(65px);
  }

  .ButtonClass {
    padding: unset;
    border: unset;
    background: unset;
    box-shadow: unset;

    &:hover {
      background: unset;
    }
  }

  .userInformationContainer {
    overflow-x: scroll;
    background: var(--ir-gray-000);
  }

  .dataNotFound {
    font-size: rem(16px);
    font-weight: 600;
    margin-top: rem(50px);
  }

  .skeletonContainer {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .spinnerContainer {
    margin-top: rem(50px);
  }

  .loadingSkeleton {
    width: 100%;
    height: rem(80px);
    opacity: 0.8;
  }

  .pageTitleContent {
    text-align: left;
    color: var(--ir-neutral-black-100);
    margin-top: rem(18px);
  }

  .usersInformation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .statsInformation {
    display: flex;
    gap: rem(12px);
  }

  .organizationName {
    font-size: rem(16px);
    font-weight: 600;
  }

  .selectedStepName {
    font-size: rem(32px);
  }

  .userStats {
    margin-top: rem(17px);
    font-size: rem(16px);
    font-weight: 400;
    color: var(--ir-neutral-000);
  }

  .inviteButton {
    width: fit-content;
    height: fit-content;
    margin: 0;
    padding: rem(10px) rem(15px) rem(10px) rem(15px);
    text-align: center;
    font-size: rem(14px);
    font-weight: 600;
    white-space: nowrap;
  }

  .searchContainer {
    margin-top: rem(35px);
    margin-bottom: rem(20px);
    display: flex;
    gap: rem(20px);

    .hideField {
      display: none;
    }

    .searchBar {
      max-width: rem(374px);
    }

    .searchContainer {
      flex: 2;
    }

    .statusSelectContainer {
      margin-top: rem(18px);
      flex: 2;

      [class*='-control'] {
        width: rem(80px);
        border: none;
        background: none;
      }

      [class*='_container'] {
        width: fit-content;
      }

      [class$='singleValue'] {
        font-weight: 600;
        font-size: rem(14px);
      }
    }
  }

  .userInfoContainer {
    min-width: rem(590px);
  }

  .controlBarContainer {
    display: flex;
    width: 100%;
    border-top: rem(1px) solid var(--ir-warm-gray-030);
    padding-top: rem(5px);
    justify-content: flex-start;
    min-width: rem(590px);

    .checkBoxContainer {
      width: rem(24px);
      height: rem(24px);
      padding-top: rem(2px);
    }

    .optionsContainer {
      display: flex;
      width: 100%;
      padding: rem(5px) 0 rem(5px) rem(6px);
      align-items: center;
    }

    .controlOption {
      display: flex;
      padding-top: rem(10px);
      cursor: pointer;

      &:hover {
        background-color: var(--ir-gray-250);
        border-radius: rem(4px);
      }
    }

    .upSortIcon > :nth-child(1) {
      opacity: 0.2;
    }

    .downSortIcon > :nth-child(2) {
      opacity: 0.2;
    }

    .option {
      color: var(--ir-neutral-black-100);
      font-weight: 600;
      font-size: rem(12px);
      padding-left: rem(5px);
    }

    .controlIcon {
      cursor: pointer;
      margin-top: rem(-7px);
      padding-left: rem(5px);
    }

    .Options {
      display: flex;
      width: 100%;
      margin-right: rem(10px);
    }

    .roleOption {
      flex: 1;
    }

    .NameOptionContainer {
      display: flex;
      text-align: start;
      align-items: center;
      flex: 1.7;
    }

    .activeOption {
      flex: 1;
    }
  }

  .innerHeader {
    display: flex;
    width: fit-content;
    flex: 3;
  }

  .selectedOption {
    background-color: var(--ir-gray-200);
    border-radius: rem(4px);
  }
}

@media (max-width: 575px) {
  .statsInformation {
    display: flex;
    flex-direction: column;
    gap: unset !important;

    .userStats {
      margin: unset;
    }
  }
}
