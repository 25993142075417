@import '/src/stylesheets/utils';

.ApplicantCard {
  border-radius: 0;
  border: rem(1px) solid var(--ir-warm-gray-030);
  background: var(--ir-warm-gray-100);
}

.MarginLeft {
  margin-left: rem(30px);
}

.GuarantorLeft {
  margin-left: rem(65px);
  top: rem(-11px);
}

.AdultCard {
  top: rem(-11px);
}

.Gradient {
  height: rem(5px);
  background: linear-gradient(180deg, var(--ir-warm-gray-030) 0%, rgb(225 232 235 / 0%) 100%);
}

.Ladder {
  display: flex;
  margin-left: rem(50px);
  width: rem(15px);
}

.VerticalLine {
  border-left: rem(1px) solid var(--ir-gray-350);
}

.HorizontalLine {
  width: 100%;
  margin-top: rem(22px);
  border-top: rem(1px) solid var(--ir-gray-350);
}
