@import '/src/stylesheets/utils';

.InvitedContainer {
  display: flex;
  width: 100%;
  padding: rem(10px) rem(20px);
  border: rem(1px) solid var(--ir-warm-gray-030);
  flex-direction: column;
  align-items: flex-start;
}

.Header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.MenuRow{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
}

.MenuRow > p {
  margin: 0;
  font-style: italic;
  font-weight: 500;
  color: var(--ir-gray-500);
}

.ThreeDotIcon {
  fill: var(--ir-black);
}

.PillHeader {
  display: flex;
  justify-content: center;
  gap: rem(10px);
  align-items: flex-end;
}

.PillRow{
  display: flex;
  justify-content: center;
  gap: rem(5px);
}

.UpdatedDate {
  font-size: rem(12px);
  font-weight: 600;
  color: var(--ir-neutral-black-primary);
}

.StatusPillContainer {
  padding: rem(2px) rem(10px) !important;
  border: rem(3px);
  text-transform: capitalize;
  font-size: rem(12px);
}

.ApplicantInfoRow {
  display: flex;
  width: 100%;
  margin-top: rem(28px);
}

@media (max-width: 768px){
  .ApplicantInfoRow{
    flex-direction: column;
    gap: rem(16px);
    align-items: center;
  }
}

.RowItem {
  display: flex;
  font-weight: 600;
  font-size: rem(16px);
  margin: 0;
}

.Name {
  width: 30%;
}

.CopyIcon {
  width: rem(24px);
  height: rem(24px);
}

.EmailRow{
  justify-content: space-between;
  width: 40%;
  font-size: rem(30px);
  align-items: center;
}

.EmailRow > p {
  margin: 0;
}

@media (max-width: 1250px) {
  .EmailRow {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

@media (max-width: 500px){
  .Name {
    justify-content: center;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  .Name {
    width: 30%;
    padding: 0 rem(16px);
    justify-content: flex-start;
  } 
}

.RequestRow {
  width: 30%;
  height: 10%;
  justify-content: flex-end;
  align-items: flex-start;
}

@media (max-width: 500px){
  .RequestRow { 
    width: 100%;
    justify-content: center;
  }
}

.SendRequestBtn{
  padding: rem(4px) rem(10px) ;
  border: rem(1px) solid var(--ir-black);
  color: var(--ir-black);
  background-color: var(--ir-gray-000);
  border-radius: 4px;
  font-weight: 500;
  margin-right: rem(40px); 
}

.SendRequestBtn:hover{
  background-color: var(--ir-gray-000);
  border: rem(1px) solid var(--ir-black);
  color: var(--ir-black);
}

@media (max-width: 768px) {
  .SendRequestBtn{
    margin-right: 0;
  }
}

.ClipBoard {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: rem(4px) rem(8px);
  border: none !important;
  box-shadow: none !important;
  background: transparent;
  font-size: rem(14px);
  font-style: normal;
  font-weight: 600;
  line-height: rem(24px);
}

.ClipBoardSVG{
  width: rem(24px);
  height: rem(24px);
}
