@import '/src/stylesheets/utils';

.HeadingContainer {
  display: flex;
  padding: rem(5px);
  margin-bottom: rem(15px);
}

.LeftArrowIcon {
  cursor: pointer;
}

.Heading {
  width: 100%;
  text-align: center;
  color: var(--ir-neutral-black-100);
  font-size: rem(20px);
  font-weight: 600;
  line-height: rem(22px);
  align-items: center;
}

.ViewApplicationContainer {
  margin-bottom: rem(10px);

  .InformationBox {
    display: flex;
    padding: rem(8px) rem(10px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(18px);

    .InformationIcon {
      width: rem(24px);
      height: rem(24px);
    }

    .InformationText {
      text-align: left;
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-weight: 600;
      margin-bottom: unset;
    }
  }

  .ScrollableContent {
    ::-webkit-scrollbar {
      width: rem(6px);
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--ir-warm-gray-400);
      border-radius: rem(100px);
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--ir-warm-gray-400);
    }
  }

  .CreditbackgrounfInformation {
    display: flex;
    padding-top: rem(8px);
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
    box-shadow: 0 rem(4px) rem(4px) 0 rgb(0 0 0 / 25%);
  }

  .RemainingDays {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: rem(16px);
    padding-bottom: rem(2px);
  }

  .PillContainer {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .ReportDatesContainer {
    display: flex;
    width: 100%;
    padding: 0 rem(16px) rem(8px) rem(12px);
    justify-content: space-between;
  }

  .DateContainer {
    display: flex;
    gap: rem(5px);
    color: var(--ir-neutral-black-primary);
    font-size: rem(11px);
    font-style: normal;
    font-weight: 400;
  }

  .Date {
    font-weight: 600;
  }

  .LogoContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: rem(8px) rem(16px) 0 rem(8px);
  }

  .ActionButton {
    padding: rem(4px) rem(8px);
  }

  .ButtonContent {
    display: flex;
    color: var(--ir-neutral-black-100);
    font-size: rem(14px);
    font-weight: 600;
    align-items: center;
    gap: rem(8px);
  }

  .CollapsibleComponents {
    width: 100%;
    padding: rem(12px) rem(10px);
  }

  .ClearButton {
    padding: unset;
    border: unset;
    box-shadow: unset;

    &:focus-visible {
      outline: unset;
    }
  }

  .ErrorContainer {
    position: relative;
    width: 100%;
    padding: 0;
    min-height: rem(290px);
    z-index: 1;
  }

  .ExperianLogo {
    display: flex;
    align-items: center;
    color: var(--ir-neutral-black-100);
    font-size: rem(24px);
  }

  .ExperianIcon {
    min-width: rem(30px);
    min-height: rem(30px);
  }

  .ReportStatusText {
    padding: 0 rem(13px);
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
  }

  .LinkText {
    text-decoration: unset;
    color: var(--ir-segment-cyan-900);
  }

  [class*='accordion-button']:not(.CollapsibleComponent_collapsed__DYDRw)::after {
    background-image: url('../../../../assets/svgs/GreyArrowUpWard.svg');
    transform: rotate(180deg);
  }

  [class*='accordion-button'][aria-expanded='false']::after {
    transform: rotate(0deg);
  }

  [class*='ApplicationInfoBox_InfoBoxContainer'] {
    border: unset;
  }

  [class*='PropertyInfoContainer'] {
    border-radius: rem(4px);
  }

  [class*='SelectedPropertyInfoContainer'] {
    margin-left: rem(4px);
    margin-right: rem(4px);
  }

  [class*='Popover_overlayTrigger'] {
    height: fit-content;
  }

  [class*='CollapsibleComponent_heading'] {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }
}

@media (max-width: 786px) {
  .HeadingContainer {
    margin-top: 10px;
  }
}

.DocumentItem{
  background-color: var(--ir-gray-200);
  padding-left: rem(20px);
  margin-bottom: rem(20px);
  border-radius: rem(4px);
  cursor: pointer;
  padding-bottom: rem(8px);
}

.SelectedDocument{
  border: 1px solid var(--ir-gray300);
  box-shadow: 0 rem(4px) rem(4px) 0 rgb(74 74 74 / 25%);
  transition: all 0.3s ease;
}

.DocumentImage {
  width: 90%;
}