@import '/src/stylesheets/utils';

thead {
  border: none;
}

.CustomTable {
  > :not(caption) {
    > * {
      > * {
        padding-top: rem(20px);
        padding-bottom: rem(20px);
        padding-left: rem(20px);
      }
    }
  }
}

table {
  border-spacing: 0 10px;

  thead {
    tr {
      text-align: center;

      th {
        padding: 0 20px 10px !important;
        border: none;
        text-align: left;
        color: #303333;
        gap: rem(5px);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      text-align: left;

      &:hover,
      :active {
        background-color: rgb(230 239 240 / 30%);
      }

      td {
        margin: 10px 0;
        border: rem(1px) solid var(--ir-warm-gray-030);
        border-right: none;
        border-left: none;
        vertical-align: bottom;
        white-space: nowrap;
        font-size: rem(14px);
        font-weight: 500;
      }

      td:first-child {
        border: 1px solid var(--ir-warm-gray-030);
        border-right: none;
        padding-left: 20px;
        text-align: left;
      }

      td:last-child {
        border: 1px solid var(--ir-warm-gray-030);
        border-left: none;
      }
    }
  }
}

.CursorPointer {
  cursor: pointer;
}

.TableContainer {
  box-shadow: 0 4px 8px 0 rgb(213 214 214 / 25%);
  border: 1px solid #e1e8eb;

  // width: fit-content;
}

.SearchContainer {
  margin-top: rem(35px);
  margin-bottom: rem(20px);
  display: flex;
  gap: rem(20px);

  .hideField {
    display: none;
  }

  .SearchBar {
    max-width: rem(500px);
  }

  .searchContainer {
    flex: 2;
  }

  .statusSelectContainer {
    margin-top: rem(18px);
    flex: 2;

    .NavigationHeading {
      font-size: rem(16px);
      font-weight: 600;
    }

    [class*='-control'] {
      width: rem(80px);
      border: none;
      background: none;
    }

    [class*='_container'] {
      width: fit-content;
    }

    [class$='singleValue'] {
      font-weight: 600;
      font-size: rem(14px);
    }
  }
}

.NavigationHeading {
  font-size: rem(16px);
  font-weight: 600;
  color: --ir-gray-1050;
}

.NavLink {
  color: #303333;

  &:hover,
  :active {
    color: #303333;
  }
}

.TabContainer {
  display: flex;
  justify-content: space-evenly;
}

.CountHeading {
  color: #5e6566;
  font-size: rem(14px);
  font-weight: 500;
  padding-left: rem(53px);
}

.NavItem {
  color: '#303333';
  font-size: '16px';
  font-weight: 600;
  background-color: #f6f9fa;
}

.TableTopContainer {
  padding-left: rem(10.5px);
}

.horizontalLine {
  border-top: 1px solid var(--ir-neutral-050);
  width: 100%;
}

.MainContainer {
  padding-bottom: 100px;
}

.noBorder {
  border-bottom: none;
}

@media (max-width: 1250px) {
  .TableContainer {
    min-width: fit-content;
  }
}
