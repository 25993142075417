@import '/src/stylesheets/utils';
@import '/src/stylesheets/colors';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(15px);
  width: fit-content;
  align-items: center;

  .CircularValue {
    display: flex;
    width: rem(64px);
    height: rem(64px);
    border: rem(2px) solid var(--ir-segment-cyan-1000);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: rem(24px);
  }

  .Label {
    width: rem(100px);
    text-align: center;
    color: var(--ir-warm-gray-800);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: rem(14px);
  }
}
