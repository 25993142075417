@import '/src/stylesheets/utils';

.title {
  flex: 1;
  margin-bottom: rem(2px);
  border-radius: rem(4px);
  display: flex;
  flex-direction: column;
  gap: rem(12px);

  .topDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    display: flex;
    flex-direction: row;
    font-size: rem(12px);
    line-height: rem(16px);
    gap: rem(8px);
    align-items: center;

    .heading {
      font-weight: 600;
      color: var(--ir-gray-1050);
      font-size: rem(24px);
    }

    .description {
      font-size: rem(10px);
      color: var(--ir-neutral-000);
    }
  }

  .bottomDiv {
    display: flex;
    align-items: center;
    gap: rem(10px);
    justify-content: space-between;

    .text {
      font-size: rem(16px);
      font-weight: 600;
      line-height: rem(24px);
      color: var(--ir-neutral-black-primary);
      text-transform: capitalize;
    }

    .innerBottomDiv {
      display: flex;
      flex: 0 0 auto; /* Prevent text-div from growing */
      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide overflowing text */
      gap: rem(10px);
    }
  }
}
