@import '/src/stylesheets/utils';

.Container {
  display: flex;
  position: relative;
  width: rem(24px);
  height: rem(24px);
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  .Count {
    display: inline-flex;
    position: absolute;
    top: rem(-7.5px);
    right: rem(-5.5px);
    width: fit-content;
    padding: 0 rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    align-items: flex-end;
    gap: rem(10px);
    border-radius: rem(2px);
    font-size: rem(13px);
    font-weight: 600;
    line-height: rem(15px); /* 115.385% */
    text-transform: uppercase;
  }
}

.LabelContainer {
  border: 1px solid var(--ir-neutral-black-primary);
  color: var(--ir-neutral-black-100);
  font-size: rem(12px);
  font-weight: 600;
  line-height: normal;
}

.TickIcon {
  display: flex;
  width: rem(24px);
  height: rem(24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-dark-green);
}
