@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: rem(32px) 0 rem(60px) 0;
  border-radius: rem(20px) rem(20px) 0 0;
  background: var(--ir-gray-000);
  justify-content: center;

  .FooterContainer {
    display: flex;
    width: fit-content;
    margin: auto;
    padding: rem(12px) rem(32px);
    background: var(--ir-gray-000);
    align-items: center;
    gap: rem(8px);
    border-radius: rem(4px);
    box-shadow: 0 rem(4px) rem(28px) 0 rgb(0 60 77 / 10%);
    margin-top: rem(48px);
  }

  .AgentInformation {
    display: flex;
    flex-direction: column;
  }

  .AgentName {
    color: var(--ir-warm-gray-800);
    font-size: rem(12px);
    font-weight: 700;
  }

  .AgentLabel {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }
}
