@import '/src/stylesheets/utils';

.amenities {
  position: relative;
  width: 100%;
}

.amenitiesContainer {
  position: absolute;
  top: rem(48px);
  width: 100%;
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-warm-gray-100);
  border-radius: rem(4px);
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  max-height: 250px;
  z-index: 100;
}

.marginTop {
  display: flex;
  width: 100%;
  height: rem(30px);
  color: var(--ir-gray-750);
  align-items: center;
  border-top: 1px solid var(--ir-gray-200);
  cursor: pointer;
  font-size: rem(14px);
  font-style: normal;
  font-weight: 600;
  justify-content: flex-end;
  padding-right: 1.8rem;
}

.container {
  border-top: 1px solid var(--ir-gray-200);
  margin: 0 15px;
  max-height: rem(200px);
  overflow-y: auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.tagContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* adjust minmax value according to your need */
  margin: 0.3rem 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  .tag {
    display: flex;
    width: fit-content;
    padding: 3px 6px;
    text-align: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: rem(20px);
      height: rem(20px);
    }

    &:hover {
      background-color: var(--ir-gray-200);
      border-radius: 0.2rem;
      transition: 0.3s;
    }
  }
}

.tagName {
  font-size: 12px;
  font-weight: 600;
  margin-left: 0.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectedTagName {
  color: var(--ir-gray-1000) !important;
}

.tagsContainerTitle {
  color: var(--ir-gray-1000);
  font-size: rem(11px);
  font-style: italic;
  font-weight: 400;
}

.allTags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.crossBtn {
  display: flex;
  position: absolute;
  right: 30px;
  bottom: 10px;
  width: 30px;
  height: 30px;
  color: var(--ir-warm-gray-100);
  align-items: center;
  background-color: var(--ir-warm-gray-950);
  border-radius: 100%;
  cursor: pointer;
  justify-content: center;
  z-index: 100;
}

.labelAmenities {
  top: rem(3px);
  left: rem(10px);
}

.customTextFieldWithLabelAmenities {
  input {
    height: rem(50px) !important;
  }
}

.customAmenitiesButton {
  display: flex;
  width: fit-content;
  padding: 0.2rem;
  color: var(--ir-neutral-black-primary);
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  line-height: rem(20px);

  span {
    display: inline-block;
    padding-right: 0.4rem;

    svg {
      width: rem(20px);
      height: rem(20px);
    }
  }

  &:hover {
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
  }
}

.crossIcon {
  cursor: pointer;
}

.selectedTags {
  cursor: default !important;

  &:hover {
    background-color: inherit !important;
  }
}

.containerPadding {
  display: flex;
  width: 100%;
  padding: 0 1.2rem;
  justify-content: space-between;
  margin-top: 0.6rem;
}

.amenitiesLabel {
  color: var(--ir-warm-gray-800);
  font-size: 12px;
  font-weight: 600;
  line-height: rem(14px);
}

.amenitiesParagraph {
  color: var(--ir-warm-gray-800);
  font-size: rem(12px);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.2rem;
}

.notFound {
  display: flex;
  color: var(--ir-neutral-black-primary);
  align-items: center;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  justify-content: center;

  div {
    width: 45%;
    text-align: center;
  }
}

.amenitiesDiv {
  margin-top: 0.8rem;
}

@media (max-width: 998px) {
  .tagContainer {
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  }

  .allTags {
    justify-content: space-between;
  }

  .notFound {
    div {
      width: 80%;
    }
  }

  .saveButtonContainer {
    margin-bottom: 0.4rem;
  }
}

.addCustomFeature {
  position: relative;
  width: 100%;
  padding: 1rem;
  background: var(--ir-warm-gray-100);
  border-radius: 4px;
  margin-top: 0.5rem;
  padding-bottom: 0.1rem;

  .inputFiled {
    height: 28px;
  }

  .saveButtonContainer {
    display: flex;
  }

  .saveButton {
    display: flex;
    width: 70px;
    height: 28px !important;
    padding: 0 !important;
    border: 1px solid var(--ir-gray-250);
    color: var(--ir-neutral-000);
    align-items: center;
    box-shadow: none;
    justify-content: center;
    margin-top: rem(20px);

    &:hover {
      background: var(--ir-gray-200);
    }
  }

  .saveButtonActive {
    display: flex;
    width: 70px;
    height: 28px !important;
    padding: 0 !important;
    border: 1px solid var(--ir-segment-cyan-700);
    color: var(--ir-segment-cyan-700);
    align-items: center;
    box-shadow: none;
    justify-content: center;
  }

  .saving {
    background-color: var(--ir-gray-200);
  }

  .reactSelectCustomStyling {
    height: 28px !important;
  }

  [class$='menu'] {
    top: unset;
  }

  [class*='ReactSelect_container'] {
    height: rem(55px);
    margin-bottom: rem(10px);
  }

  [class$='-ValueContainer'] {
    height: rem(28px);
    min-height: rem(28px) !important;
  }

  [class$='-control'] {
    height: rem(28px);
    min-height: rem(28px) !important;
  }

  [class$='-indicatorContainer'] {
    padding-top: 10px;

    svg {
      margin-top: -6px;
    }
  }

  [class$='-option'] {
    height: rem(22px) !important ;
    padding-top: 0;
  }

  .crossBtnCustomFeature {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }

  .featureLabel {
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
  }
}

.saveButtonActive {
  display: flex;
  width: 60px;
  height: 28px !important;
  padding: 0 !important;
  border: 1px solid var(--ir-segment-cyan-700);
  color: var(--ir-segment-cyan-700);
  align-items: center;
  box-shadow: none;
  justify-content: center;
}

.customCreatedFeature {
  animation-duration: 3s;
  animation-name: color-transition;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.icon {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

@keyframes color-transition {
  from {
    color: var(--ir-segment-cyan-700);
  }

  to {
    color: inherit;
  }
}

.saveSpan {
  display: inline-block;
  padding-left: 0.3rem;
}

.plus {
  width: 12px;
}

.customFeatureError {
  display: none;
  height: rem(17px);
}

.amenitiesPadding {
  padding: 0 0.3rem;
}

.tagError {
  color: var(--ir-field-error);
  font-size: 0.8rem;
  font-weight: 600;
}

@media (max-width: 991px) {
  .saveButtonContainer {
    margin-top: rem(10px);

    .saveButton {
      margin: auto;
    }
  }
}

@media (max-width: 767px) {
  .AddIconClass {
    width: rem(24px);
    height: rem(24px);
  }

  .crossIcon {
    width: rem(18px);
    height: rem(18px);
  }
}
