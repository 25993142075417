@import '/src/stylesheets/utils';

.paymentFormContainer {
  .buttonContainer {
    display: flex;
    width: 100%;
    text-align: center;

    .button {
      width: 100%;
    }
  }

  .infoIconContainer {
    position: absolute;
    top: rem(-1px);
    left: rem(31px);
  }

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);
    margin-bottom: rem(18px);
    margin-top: rem(24px);

    ::placeholder {
      font-size: rem(15.5px);
    }

    [class^='TextField_error__gNUlo form-control'] {
      border: rem(1px) var(--ir-semantics-error) solid !important;
      background-color: unset !important;
      color: var(--ir-semantics-error) !important;
    }

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      margin-left: rem(-2px);
      color: var(--ir-semantics-error);
      font-weight: 500;
    }

    [class*='-control'] {
      height: rem(43.5px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-gray-000);
      color: var(--ir-neutral-black-100);
      padding-left: rem(10px);
      font-weight: 400;
      border-radius: rem(4px);
      box-shadow: 0 rem(1px) rem(1px) rgb(0 0 0 / 3%), 0 rem(3px) rem(6px) rgb(0 0 0 / 2%);
    }
  }

  .textFieldLabel {
    font-size: rem(15px);
    font-weight: 400;
  }

  .coupenTextField {
    position: relative;
  }

  .horizontalLine {
    width: 100%;
    border-top: rem(1px) solid var(--ir-gray-200);
    margin-top: rem(10px);
  }

  .coupenButton {
    position: absolute;
    right: rem(20px);
    bottom: rem(12px);
    height: rem(20px);
    padding: unset;
    background: var(--ir-gray-000);
    color: var(--ir-neutral-000);
    min-width: rem(40px);
    font-size: rem(10px);
    border-color: var(--ir-gray-200);

    &:hover,
    &:focus {
      background: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .spinnerClass {
    width: rem(20px);
    height: rem(20px);
    margin-left: rem(10px);
  }

  .paymentFieldContainer {
    position: relative;
    margin-bottom: rem(18px);
    margin-top: rem(24px);
    box-shadow: none;
  }

  .paymentField {
    height: rem(43.5px);
    border: 1px solid var(--ir-gray-200);
    border-radius: rem(4px);
    padding-top: rem(13px);
    padding-left: rem(10px);
    margin-top: rem(5px);
  }

  .paymentFieldError {
    border: rem(1px) var(--ir-semantics-error) solid;
  }

  .paymentDetails {
    display: flex;
    width: 100%;
    gap: rem(10px);

    .paymentFieldContainer {
      margin-top: (8px);
      width: 100%;
    }
  }

  .hideFields {
    display: none;
  }

  .spinnerContainer {
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.BackIcon {
  margin-left: 0.5rem;
  text-align: start;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  text-align: center;
  padding-inline: 1rem;
}
