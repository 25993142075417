@import '/src/stylesheets/utils';

.infoBoxContainer {
  width: 100%;
  padding: rem(8px);
  border: rem(1px) solid var(--ir-gray-200);
  background-color: var(--ir-gray-000);
  border-radius: rem(4px);

  .PenIcon {
    cursor: pointer;
  }

  .infoBoxLabel {
    margin-left: rem(24px);
    text-align: start;
    font-weight: 600;
    font-size: rem(10px);
    color: var(--ir-neutral-010);
  }

  .editIconContainer {
    margin-top: rem(4px);
  }

  .informationContainer {
    display: flex;
    justify-content: flex-start;
    gap: rem(11px);
    padding-left: rem(11px);
  }

  .relationshipInformation {
    display: flex;
    width: 100%;
    text-align: start;
    flex-direction: column;
    gap: rem(8px);
  }

  .emailAddress {
    font-weight: 600;
    font-size: rem(16px);
    color: var(--ir-neutral-black-100);
    word-break: break-all;
  }

  .fieldsContainer {
    display: flex;
  }

  .relationInformation {
    flex: 1;
    display: flex;
    flex-direction: column;

    .information {
      font-weight: 600;
      font-size: rem(10px);
      color: var(--ir-neutral-black-100);
    }

    .informationType {
      font-weight: 600;
      font-size: rem(10px);
      color: var(--ir-neutral-000);
    }
  }
}
