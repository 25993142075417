@import '/src/stylesheets/utils';

.avatarText {
  display: flex;
  width: rem(25px);
  height: rem(25px);
  color: var(--ir-warm-gray-100);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
}
