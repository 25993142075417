@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;

  .ExpandedInformation {
    display: flex;
    flex-direction: column;
  }

  .ReportComponents {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: rem(32px);
    padding-left: rem(31px);
  }

  .ReportComponentsRenter {
    padding-left: unset !important;
  }

  .InfoMessageContainer {
    display: flex;
    gap: rem(10px);
    justify-content: flex-end;
    align-items: center;
    width: 99%;
  }

  .InfoMsgLabel {
    color: var(--ir-segment-cyan-900);
    font-size: rem(11px);
    font-weight: 600;
    line-height: rem(15px);
    text-transform: uppercase;
  }

  .InfoDivider {
    color: var(--ir-warm-gray-060);
  }

  .InfoMsgValue {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .CreditIconContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .CreditReportHeader {
    padding-left: rem(38px);
    margin-bottom: rem(40px);
  }

  .NameContainer {
    text-align: start;
    color: var(--ir-neutral-black-100);
    font-size: rem(24px);
    font-weight: 600;
    letter-spacing: rem(-1.2px);
  }

  .ArrowIcon {
    cursor: pointer;
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
  }

  .ArrowIconExpanded {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .Heading {
    padding: rem(3px) rem(5px);
    color: var(--ir-warm-gray-800);
    font-size: rem(24px);
    font-weight: 600;
  }

  .RemainingDays {
    display: flex;
    padding: rem(3px) rem(5px);
  }

  .SpaceProvider {
    flex: 1;
  }

  .PillContainer {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .ValidityDates {
    display: flex;
    gap: rem(5px);
    justify-content: flex-end;
  }

  .DateContainer {
    display: flex;
    padding: rem(3px) rem(5px);
    gap: rem(5px);
    align-items: baseline;
  }

  .Label {
    color: var(--ir-gray-02);
    font-size: rem(11px);
    text-transform: uppercase;
  }

  .Date {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
  }

  .InformationTag {
    display: flex;
    gap: rem(12px);
    padding: rem(20px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
  }

  .InformationIcon {
    min-width: rem(24px);
    min-height: rem(24px);
  }

  .InformationText {
    text-align: start;
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
  }

  .ErrorReport {
    margin: auto;
  }
}

@media (max-width: 767px) {
  .Container {
    .CreditReportHeader,
    .ReportComponents {
      padding-left: unset;
      padding-right: rem(15px);
    }
  }
}
