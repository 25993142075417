@import '/src/stylesheets/utils';

.LivingSituationContainer {
  display: flex;
  width: 100%;

  .VerticalLine {
    min-height: 100%;
    margin-left: 1px;
    margin-bottom: rem(10px);
    border-left: 1px solid var(--ir-gray-350);
  }

  .InformationContainer {
    display: flex;
    gap: 1px;
    width: 100%;
  }

  .HorizontalLinesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: rem(24px) 0;
  }

  .HorizontalLine {
    width: rem(15px);
    border-top: 1px solid var(--ir-gray-350);
  }

  .Information {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: rem(8px);
    align-items: flex-start;
    gap: rem(8px);
    align-self: stretch;
    border: 1px solid var(--ir-gray-200);
    border-top: unset;
    border-radius: 0 0 rem(4px) rem(4px);
    background: var(--ir-gray-000);
    margin-right: 1px;
  }

  .MainHeading {
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(20px);
  }

  .FieldValue,
  .ReasonForMoving {
    display: flex;
    flex-direction: column;
  }

  .Value,
  .Label {
    color: var(--ir-neutral-black-100);
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
  }

  .Label {
    color: var(--ir-warm-gray-060);
  }

  .ReasonForMoving {
    gap: rem(5px);
  }

  .InformationHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .PenIcon {
    cursor: pointer;
  }
}
