@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .IconContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .ArrowIcon {
    cursor: pointer;
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
  }

  .ArrowIconExpanded {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .Heading {
    padding: rem(3px) rem(5px);
    color: var(--ir-warm-gray-800);
    font-size: rem(24px);
    font-weight: 600;
  }

  .ExpandedInformation {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
  }

  .ErrorContainer {
    width: 100%;
    padding: rem(12px) 0 rem(58px) 0;
    min-height: rem(290px);
  }
}
