@import '/src/stylesheets/utils';

.container {
    display: flex;
    padding: 0 rem(24px) rem(24px);
    flex-direction: column;
    gap: rem(20px);

    [class^='ErrorMessage'] {
        position: relative;
        top: 4rem;
        text-align: center;
        font-size: rem(10px);
      }

    .heading {
        font-size: rem(24px);
        font-weight: 700;
        text-align: center;
    }

    .subHeading {
        font-size: rem(18px);
        font-weight: 500;
        text-align: center;
    }

    .spanColor {
        color: var(--ir-segment-cyan-700);
    }

    .renterButton {
        width: 9rem;
        padding: 0.4rem;
        border: 1px solid var(--ir-segment-cyan-700);
        text-align: center;
        color: var(--ir-segment-cyan-700);
        font-weight: 600;
        font-size: rem(16px);
    }

    .renterButtonContainer {
        margin-top: 1rem;
        display: flex;
        width: 100%;
        text-align: center;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
    }

    .textField {
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('/assets/svgs/SSNBackground.svg');
        background-size: contain;
        height: 39%;
        min-height: rem(170px);
        flex-direction: column;
      }

      .ssnTextField {
        top: rem(13px);
        left: 8%;
        width: 85%;
        height: auto;
        padding: rem(4px) 0 rem(4px) rem(5px);
        text-align: center;
        border-radius: rem(4px);
        font-size: rem(14px);
        font-weight: 400;
      }

      .error {
        background-color: var(--ir-field-error-background) !important;
        border: 1px solid var(--ir-field-error);
      }

      .policyDiv {
        display: flex;
        padding: rem(8px);
        border: 1px solid var(--ir-segment-blue-96);
        background: var(--ir-segment-blue-100);
        justify-content: center;
        flex-direction: row;
        align-items: center;
        gap: rem(12px);
        border-radius: rem(4px);
        text-align: left;
      }

      .transUnionText {
        font-style: normal;
        font-weight: 600;
        font-size: rem(10px);
        line-height: rem(14px);
        color: var(--ir-neutral-black-primary);
      }

      .policyText {
        padding: 0 rem(2px);
        border: none;
        color: var(--ir-segment-blue-110);
        text-decoration: none;
        background-color: transparent;
        font-style: normal;
        font-weight: 600;
        font-size: rem(10px);
        line-height: 1rem;
      }
}
