@import '/src/stylesheets/utils';

.SectionContainer {
  width: 100%;
}

.container {
  margin-bottom: rem(20px);

  .outerDiv {
    scrollbar-gutter: stable;
  }

  .phoneContainer {
    margin-bottom: 1.8rem;

    [class*='flag-dropdown'] {
      display: none;
    }

    [class*='react-tel-input'] [class*='form-control'] {
      padding-left: rem(12px);
    }
  }

  .datePicker {
    margin-bottom: 1.5rem;

    [class*='_removeBorder']::placeholder {
      color: var(--ir-neutral-010);
    }

    .label {
      left: rem(-1px);
    }
  }

  .continueButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .userFields {
    position: relative;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    .userLabel {
      position: absolute;
      top: 0.4rem;
      left: 0.8rem;
      z-index: 1;
      font-weight: 600;
      font-size: rem(10px);
      line-height: rem(14px);
      color: var(--ir-neutral-010);
    }

    .customSelect {
      text-align: left;
      padding-bottom: 1rem;

      [class*='placeholder'] {
        padding-top: rem(10px);
        color: var(--ir-neutral-010);
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
      }

      [class*='singleValue'] {
        padding-top: rem(10px);
      }

      [class*='-control'] {
        height: rem(50px);
      }
    }

    .userGrossDollar {
      position: absolute;
      top: 1.57rem;
      left: 0.75rem;
      z-index: 1;
      font-size: rem(14px);
      color: var(--ir-black);
    }

    .inputBackgroundColor {
      padding-top: 1rem;
      background-color: var(--ir-neutral-white-bg);
    }

    .textField {
      background-color: var(--ir-neutral-white-bg);
      max-height: rem(120px) !important;
      resize: none;
      padding-top: rem(20px);
    }

    .grossIncome {
      padding-left: 1.5rem;
    }

    .inputBackgroundColor::placeholder {
      color: var(--ir-neutral-010);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
    }

    .textField::placeholder {
      color: var(--ir-neutral-010);
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
    }
  }

  .linkedinHeading {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: 2rem;
  }

  .centeralized {
    text-align: center;
  }

  .textContainer {
    color: var(--ir-segment-cyan-900);
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.4rem;

    .button {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
    }

    .buttonLinkedIn {
      display: flex;
      width: 100%;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: rem(18px);
      margin-bottom: 0.8rem;
    }

    .buttonGray {
      padding: 0.4rem;
      border: 1px solid var(--ir-neutral-010);
      color: var(--ir-neutral-010);
      font-style: normal;
      font-weight: 600;
      font-size: rem(16px);
    }
  }

  .infoCard {
    display: flex;
    padding: 1rem;
    color: var(--ir-neutral-black-primary);
    background-color: var(--ir-segment-blue-100);
    font-weight: 600;
    font-size: rem(10px);

    .icons {
      width: 10%;
      text-align: start;
    }

    .para {
      text-align: start;
    }
  }

  .phoneNumber::placeholder {
    color: var(--ir-neutral-010);
  }

  .ownerInformationContainer {
    position: relative;
    padding: rem(8px) rem(10px) rem(8px) rem(42px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(24px);

    .ownerInformationIcon {
      position: absolute;
      top: rem(10px);
      left: rem(10px);
      width: rem(20px);
      height: rem(20px);
    }

    .ownerInformationText {
      text-align: start;
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-weight: 600;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 320px) {
  .container {
    .datePicker {
      [class*='_presentTextContainer'] {
        padding-left: 0.1rem;
      }

      .label {
        margin-left: 0.2rem;
      }
    }
  }
}
