@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  gap: rem(5px);
  color: var(--ir-gray-1050);

  .heading {
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px); /* 100% */
  }

  .subHeading {
    font-size: rem(12px);
    line-height: normal;
  }

  .HeadingContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .IDVButton {
    display: flex;
    padding: rem(4px) rem(8px);
    align-items: center;
    gap: rem(8px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-segment-cyan-900);
    background: var(--ir-segment-cyan-900);
    color: var(--ir-gray-000);
    text-decoration: unset;
    font-weight: 600;
  }
}
