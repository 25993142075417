@import '/src/stylesheets/utils';

.container {
  display: flex;
  padding: rem(18px);
  color: var(--ir-gray-1050);
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .heading {
    font-size: rem(24px);
    font-style: normal;
    font-weight: 700;
    line-height: rem(28px);
  }

  .subHeading {
    font-size: rem(18px);
    font-style: normal;
    font-weight: 500;
    line-height: rem(24px);
    padding: 0 rem(50px);
    text-align: center;

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .buttonContainer {
    padding-bottom: rem(25px);

    .buttonText {
      display: flex;
      align-items: center;
      gap: rem(5px);
    }
  }
}

@media (max-width: 744px) {
  .container {
    .subHeading {
      padding: 0 rem(30px);
    }
  }
}
