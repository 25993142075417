@import '/src/stylesheets/utils';

.propertyInformation {
  width: 40%;
  border: 1px solid var(--ir-gray-200);
  border-radius: rem(4px);
  padding-top: rem(8px);
  padding-bottom: rem(8px);
  z-index: 1;
  padding-inline: rem(5px);
  margin-left: -6rem;
  align-items: center;
  font-size: rem(14px);
  line-height: rem(20px);
  text-align: start;
  font-weight: 600;

  .propertyThumbnail img {
    width: rem(40px);
    height: rem(40px);
    object-fit: cover;
  }

  .alignLeft {
    padding-left: 0;
  }

  .propertyAddress {
    color: var(--ir-neutral-000);
  }
}

.popoverBody {
  border-radius: rem(3px);
  background-color: var(--ir-neutral-black-primary);
  padding: rem(5px) rem(8px);
}

.tooltipText {
  color: var(--ir-gray-000);
}

@media (max-width: $screen-tablet) {
  .propertyInformation {
    width: 100%;
    font-size: rem(12px);
    margin-right: unset;
  }
}

@media (max-width: $screen-landscpe-extra-large) {
  .propertyInformation {
    width: 64%;
  }
}

@media (max-width: $screen-mobile-landscape-medium-small) {
  .propertyInformation {
    width: 64%;
    margin-left: unset;
    text-align: end;
  }
}

@media (min-width: $screen-mobile-portrait) and (max-width: $screen-mobile-landscape) {
  .propertyInformation {
    width: 75%;
    text-align: end;
  }
}

@media (max-width: $screen-mobile-portrait) {
  .propertyInformation {
    width: rem(270px);
    font-size: rem(12px);
    text-align: end;
  }
}
