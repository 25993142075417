@import '/src/stylesheets/utils';

.documentTypesContainer {
  text-align: start;
  margin-top: rem(5px);

  .questionLabel {
    display: flex;
    text-align: start;
    align-items: flex-start;
    margin-bottom: rem(24px);

    .textContainer {
      font-weight: 500;
      font-size: rem(18px);
      color: va(--ir-gray-1050);
    }
  }

  .title {
    font-size: rem(12px);
    color: var(--ir-neutral-black-primary);
    font-weight: 600;
    line-height: rem(20px);

    .actionText {
      color: var(--ir-segment-cyan-900);
      cursor: pointer;
    }
  }

  .lightText {
    color: var(--ir-neutral-000);
    font-size: rem(10px);
    font-weight: 400;
    line-height: rem(14px);

    .italic {
      font-style: italic;
    }
  }

  .documentTypes {
    display: flex;
    flex-direction: column;

    .button {
      display: flex;
      width: fit-content;
      padding: rem(4px) rem(8px);
      border: 1px solid var(--ir-segment-cyan-700);
      color: var(--ir-segment-cyan-700);
      margin-bottom: rem(8px);
      font-size: rem(16px);
      font-weight: 600;
      border-radius: rem(4px);
      line-height: rem(24px);
      align-items: center;

      .text {
        margin-left: rem(5px);
      }
    }

    .otherButton {
      .text {
        margin-left: 0;
      }
    }

    .continueButton {
      margin-top: rem(24px);
    }
  }
}
