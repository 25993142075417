@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .MaintenanceLogo {
    margin-bottom: rem(15px);
  }

  .Title {
    font-weight: 400;
    font-size: rem(21px);
    color: var(--ir-gray-1050);
  }

  .Description {
    font-weight: 400;
    font-size: rem(14px);
    padding: rem(1px);
    color: var(--ir-neutral-black-primary);
  }

  .SubscribeText {
    font-weight: 400;
    font-size: rem(12px);
    padding: rem(1px);
    color: var(--ir-neutral-black-primary);
  }

  .Underline {
    text-decoration: underline;
    cursor: pointer;
  }

  .Footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    padding: rem(15px);

    .MaintenanceLogoSmall {
      width: rem(20px);
      height: rem(20px);
    }

    .Brand {
      font-size: 24px;
      font-weight: 200;
      margin-left: 4px;

      .Grey {
        color: #58595b;
      }

      .Blue {
        color: #0facbc;
      }
    }
  }
}
