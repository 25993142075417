@import '/src/stylesheets/utils';

.SsnTextField {
  background-color: var(--ir-neutral-white-bg);

  &::placeholder {
    color: var(--ir-neutral-black-00);
  }

  &:focus {
    background-color: var(--ir-neutral-white-bg);
  }
}

.AgentSSNInputWrapper {
  position: relative;
  padding: rem(20px) 0 rem(5px);

  label {
    position: absolute;
    top: rem(-13px);
    left: 0;
    color: var(--ir-neutral-000);
    font-size: rem(11.2px);
  }
}

.Error {
  background-color: var(--ir-field-error-background) !important;
  border: 1px solid var(--ir-field-error) !important;

  &:focus {
    border: 1px solid var(--ir-field-error);
  }
}
