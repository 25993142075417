@import '/src/stylesheets/utils';

.Container {
  .InformationContainer {
    display: flex;
    border-radius: rem(4px) rem(4px) rem(0) rem(0);
    padding: rem(15px);
    border: 1px var(--ir-warm-gray-030) solid;
    gap: rem(15px);
    justify-content: space-between;
    align-items: center;
  }

  .HomeIcon {
    min-width: rem(40px);
    min-height: rem(40px);
    object-fit: contain;
  }

  .Information {
    display: flex;
    gap: rem(15px);
    align-items: center;
  }

  .MainInformation {
    display: flex;
    flex-direction: column;
    gap: rem(2px);
  }

  .PropertyAddress {
    display: flex;
    gap: rem(5px);
    align-items: center;
  }

  .Address {
    font-size: rem(22px);
    font-weight: 600;
    line-height: rem(30px);
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 40vw;
    text-align: left;
  }

  .StreetAddress1 {
    color: var(--ir-neutral-black-100);
  }

  .StreetAddress2 {
    color: var(--ir-warm-gray-060);
  }

  .PropertyAttributes {
    display: flex;
    gap: rem(10px);
    padding: rem(0) rem(12px);
    font-size: rem(12px);
  }

  .AvailableContainer {
    display: flex;
    height: fit-content;
    padding: rem(0) rem(2px);
    background: var(--ir-gray-200);
    gap: rem(5px);
  }

  .AvailableLabel {
    color: var(--ir-neutral-black-primary);
  }

  .AvailableValue {
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }

  .PropertyType {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background: var(--ir-neutral-black-primary);
    text-transform: uppercase;
    color: var(--ir-gray-000);
    font-size: rem(12px);
    font-weight: 600;
  }

  .PropertyDimensions {
    display: flex;
    gap: rem(8px);
    white-space: nowrap;
  }

  .DimensionScale {
    font-size: rem(11px);
    font-weight: 600;
    color: var(--ir-neutral-dark-black-90);
  }

  .DimensionValue {
    color: var(--ir-neutral-dark-black);
    font-size: rem(12px);
    font-weight: 600;
  }

  .PropertySpecifications {
    display: flex;
    flex-wrap: wrap;
    padding: 0 rem(2px);
    gap: rem(12px);
  }

  .OwnerContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
    padding-bottom: rem(5px);
  }

  .OwnerAvatar {
    border-radius: 50%;
    width: rem(24px);
    height: rem(24px);
  }

  .OwnerName {
    font-weight: 600;
    font-size: rem(12px);
    color: var(--ir-neutral-black-primary);
    line-height: rem(16.34px);
  }

  .SelectedAgentInformation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: rem(8px);
    width: 100%;
  }

  .AgentDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .AgentName {
    display: flex;
    margin: unset;
    color: var(--ir-neutral-black-100);
    font-size: rem(12px);
    font-weight: 700;
    gap: rem(3px);
    text-transform: capitalize;
  }

  .AgentEmail {
    color: var(--ir-neutral-black-primary);
    font-size: rem(12px);
    font-weight: 600;
  }

  .SelectedAgentName {
    padding-top: rem(3px);
  }

  .AgentIcons {
    display: flex;
    gap: rem(22px);
    align-items: center;
  }

  .AgentInformation {
    display: flex;
  }

  .OwnerOrganization {
    font-size: rem(12px);
    color: var(--ir-neutral-dark-black-90);
    line-height: rem(16.34px);
  }

  .ArrowIcon {
    cursor: pointer;
    color: var(--ir-neutral-black-primary);
  }

  .CustomContainer {
    display: flex;
    gap: rem(5px);
    padding: 0 (12px);
  }

  .Custom {
    padding: 0 rem(3px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    font-size: rem(14px);
    font-weight: 600;
    border-radius: rem(2px);
    text-transform: uppercase;
  }
}

.AgentProfilePic {
  width: rem(40px);
  height: auto;
}

@media (max-width: $screen-landscpe-extra-large) {
  .Container {
    .InformationContainer {
      flex-direction: column;
      justify-content: left;
      align-items: start;
    }

    .Address {
      width: fit-content;
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
    }

    .PropertySpecifications {
      border-top: 1px solid var(--ir-warm-gray-030);
      width: 100%;
      justify-content: space-evenly;
      padding-top: rem(5px);
    }

    .OwnerContainer {
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-top: rem(5px);
      border-top: 1px solid var(--ir-warm-gray-030);
    }

    .PropertyAttributes {
      flex-wrap: wrap;
    }

    .HomeIcon {
      margin-top: rem(7px);
      margin-bottom: auto;
      object-fit: contain;
    }

    .SelectedAgentInformation {
      padding-top: rem(15px);
      border-top: 1px solid var(--ir-warm-gray-030);
    }

    [class*='SpecificationValue'],
    [class*='SpecificationLabel'] {
      max-width: unset;
    }
  }
}

@media (max-width: $screen-tablet-lg) and (min-width: $screen-mobile-navbar) {
  .Container {
    .Address {
      width: 35vw;
    }
  }
}
