@import '/src/stylesheets/utils';

.Container {
  padding: unset;

  .EditButton,
  .DeleteButton {
    font-size: rem(16px) !important;
    margin-bottom: unset !important;

    &:hover {
      background: var(--ir-neutral-white-bg) !important;
    }
  }

  .DeleteButton {
    display: flex;
    padding: rem(4px) rem(5px);
    justify-content: center;
    font-weight: 600;
  }
}
