@import '/src/stylesheets/utils';

.container {
  :global(.modal-header) {
    display: flex;
    padding: 0.5rem 3rem;
    border: none;
    align-items: center;
    justify-content: space-between;
  }

  .HeaderContent {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .HeaderTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .TitleDescription {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
  }

  .buttonWidth {
    display: flex;
    width: fit-content;
    padding: 0;
    border: 0 !important;
    align-items: center;
    box-shadow: none !important;
    font-size: 14px;
    justify-content: flex-end;
  }

  .crossIcon {
    width: 1.2rem;
    height: 1.2rem;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;

    .titleIcon {
      margin-right: rem(10px);
      display: flex;
    }
  }

  .syncIcon {
    color: var(--ir-neutral-000);
    font-size: 1.25rem;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    margin-left: 0.562rem;
  }

  .icon {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(-359deg);
    }
  }
}

.backdropColor {
  background-color: var(--ir--modal-backdrop);
  opacity: 0.6;
}

.storyModalBody {
  align-items: center;
  background-color: var(--ir-segment-cyan-300);
  display: flex;
  height: 200px;
  justify-content: center;
}

.storyModalFooter {
  background-color: #ffff;
  border-radius: 2px;
  height: 50px;
}

@media (max-width: 744px) {
  .dialogClassName {
    margin: auto;
  }
}
