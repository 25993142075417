@import '/src/stylesheets/utils';

.Container {
  display: flex;
  justify-content: flex-start;
  gap: rem(12px);
  padding: rem(3px) rem(8px);

  .Month,
  .Description,
  .Amount {
    text-align: start;
    color: var(--ir-warm-gray-800);
  }

  .Month {
    flex: 1;
  }

  .Description {
    flex: 3;
  }

  .Amount {
    flex: 3;
    text-align: end;
  }
}

.GreyContainer {
  background: var(--ir-gray-110);
}
