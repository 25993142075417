@import '/src/stylesheets/utils';

.container {
  display: flex;
  position: relative;
  top: rem(8px);
  left: rem(8px);
  width: fit-content;
  padding: rem(0) rem(2px);
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  border-radius: 2px;
  background-color: var(--ir-neutral-black-primary);

  .text {
    font-size: rem(11px);
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ir-gray-000);
  }
}
