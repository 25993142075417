@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(2px);

  .MainHeading {
    text-align: start;
  }

  .RenterH2Color {
    color: var(--ir-segment-cyan-700);
  }

  .TextFieldContainer {
    position: relative;
    border-radius: rem(4px);

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      font-size: 0.8rem;
    }

    [class*='-control'] {
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(16px);
      padding-top: rem(18px);
      border-radius: rem(4px);
    }

    .TextFieldLabel {
      position: absolute;
      top: rem(6px);
      left: rem(16px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-black-primary);
    }
  }

  .SelectContainer {
    [class*='-control'] {
      padding: rem(4px) 0 rem(4px) rem(16px);
      background: var(--ir-neutral-white-bg);

      [class*='valueContainer'] {
        padding: unset;
        gap: rem(4px);
      }

      [class*='floatingLabel'] {
        color: var(--ir-neutral-black-primary);
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
      }

      &:hover {
        border-color: var(--ir-gray-200);
      }
    }
  }

  .DropDownIconContainer {
    margin-right: rem(16px);
    margin-top: rem(5px);
  }

  .SubmitButton {
    width: fit-content;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-segment-cyan-900);
    background: var(--color-white);
    color: var(--ir-segment-cyan-700);
    border-radius: rem(4px);
    font-size: rem(16px);
    font-weight: 600;
    margin-left: auto;
    margin-top: rem(24px);

    &:hover {
      background-color: unset;
      opacity: 0.8;
    }
  }
}
