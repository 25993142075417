@import '/src/stylesheets/utils';

.CapsuleContainer {
  display: flex;
  flex-direction: column;
  padding: rem(5px) rem(15px);
  border-radius: rem(5px);
  border: 1px var(--ir-warm-gray-030) solid;
  gap: rem(5px);
  align-items: center;
  background: var(--ir-gray-000);

  .InformationContainer {
    display: flex;
    gap: rem(8px);
  }

  .CountContainer {
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }

  .MinorContainer {
    font-size: rem(10px);
    font-weight: 600;
    padding-right: rem(8px);
  }

  .MinorCount {
    background: var(--ir-neutral-black-half);
  }

  .LabelContainer {
    display: flex;
    gap: rem(8px);
    align-items: center;
    text-transform: capitalize;
  }

  .OccupantsLabel {
    color: var(--ir-neutral-dark-black-90);
    font-size: rem(12px);
    font-weight: 600;
    text-transform: capitalize;
  }

  .PeopleIcon {
    color: var(--ir-warm-gray-060);
  }
}
