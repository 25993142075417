@import '/src/stylesheets/utils';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: rem(55px);
  padding: rem(8px) rem(16px);
  border-radius: rem(4px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-gray-000);
  color: var(--ir-gray-1050);

  .buttonLoader{
    margin-right: rem(5px);
  }

  .text {
    flex: 9;
    overflow-x: hidden;
    font-size: rem(14px);
    line-height: rem(20px);
    border: none;
    background-color: transparent;
    padding-right: rem(10px);
  }

  .button {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    padding: rem(4px) rem(8px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-1050);
    background: transparent;
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(24px);
  }

  .innerButton {
    display: flex;
    gap: rem(8px);
  }
}
