@import '/src/stylesheets/utils';

.Container {
  position: relative;
  width: 100%;
  margin-bottom: rem(10px);

  .ApplicationText{
    font-weight: 400;
    font-size: rem(32px);
    color: var(--ir-neutral-010);
    margin-top: rem(40px);
  }

  .InfoText{
    font-weight: 400;
    font-size: rem(14px);
    margin-top: rem(30px);
  }

  .LearnMoreText{
    text-decoration: underline;
    color: var(--ir-segment-cyan-900);
  }

  .AddressInformation {
    display: flex;
    flex-direction: column;
    padding: rem(10px) rem(8px);
    gap: rem(12px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-92);
    background: var(--ir-warm-gray-100);
    margin-bottom: rem(10px);
    overflow: hidden;
    color: var(--ir-neutral-black-100);
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .DetailsContainer {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    .DetailsHeading {
      color: var(--ir-warm-gray-060);
      font-size: rem(14px);
      text-transform: capitalize;
    }

    .Details {
      color: var(--ir-neutral-black-100);
      text-overflow: ellipsis;
      font-size: rem(16px);
    }
  }

  .RowContainer {
    gap: 0;
  }

  .RenterContainer {
    display: flex;
    justify-content: space-between;
    gap: rem(22px);

    .InfoContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: rem(12px);

      .DobContainer {
        display: flex;
        flex-direction: column;
        font-style: normal;
        line-height: normal;

        .Heading {
          color: var(--ir-neutral-black-primary);
          font-size: rem(14px);
          text-transform: capitalize;
        }

        .Data {
          color: var(--ir-neutral-black-100);
          font-size: rem(16px);
          font-weight: 600;
        }
      }
    }
  
    .ContactContainer {
      flex: 1;
      display: flex;
      padding: rem(12px);
      flex-direction: column;
      gap: rem(12px);
      align-self: stretch;
      border-radius: rem(4px);
      border: 1px solid var(--ir-gray-70);

      .ContactHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--ir-neutral-black-primary);
        font-size: rem(16px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;

        .Button {
          width: fit-content;
          padding: rem(4px) rem(8px);
        }
      }
    }
  }
}

.AgentButton{
  margin-top:rem(20px);
  padding: rem(4px) rem(8px);
  border-radius: rem(4px); 
}

.InvitesApplyContainer {
  display: flex;
  align-items: center;
  gap: rem(8px);
}

.AddPhoneNumber{
  display: flex;
  justify-content: space-between;
}

.PillsContainer {
  position: absolute;
  top: rem(10px);
  right: rem(8px);
}

@media (max-width: $screen-mobile-navbar) {
  .Container {
    .AddressInformation {
      gap: rem(8px);
    }

    .RowContainer {
      display: flex;
      flex-direction: column;
      gap: rem(8px);
    }

    .RenterContainer {
      flex-direction: column;
    }
  }
}
