.link {
  text-decoration: none;
}

.icon {
  color: var(--ir-neutral-black-primary);
}

.StackIconLabel {
  display: flex;
  flex-direction: column;

  .label {
    margin-left: 0;
  }
}

.label {
  color: var(--ir-neutral-dark-black);
  font-size: 14px;
  margin-left: 6px;
}

.container:hover .icon,
.container:hover .label {
  color: var(--ir-segment-cyan-900);
}

.activeLink {
  color: var(--ir-segment-cyan-900);

  .icon {
    color: inherit;
  }

  .label {
    color: inherit;
  }
}
