@import '/src/stylesheets/utils';

.Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: rem(24px);

  .Heading {
    color: var(--ir-neutral-black-100);
    font-size: rem(18px);
  }

  .SubmitButton {
    width: fit-content;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-segment-cyan-900);
    background: var(--color-white);
    color: var(--ir-segment-cyan-700);
    border-radius: rem(4px);
    font-size: rem(16px);
    font-weight: 600;
    margin-left: auto;
    margin-top: rem(24px);

    &:hover {
      background-color: unset;
      opacity: 0.8;
    }
  }

  .AddressInformation {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
  }

  .InformationBox {
    align-items: flex-start;
    display: flex;
    padding: rem(8px) rem(10px) rem(8px) rem(8px);
    gap: rem(10px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-segment-blue-96);
    background: var(--ir-segment-blue-100);
  }

  .InformationIcon {
    width: rem(24px);
    height: rem(24px);
  }

  .InformationText {
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
  }

  .PermissionCheck {
    display: flex;
    gap: rem(8px);
    align-items: flex-start;
  }

  .PermissionText {
    color: var(--ir-neutral-black-100);
    font-weight: 500;
  }
}
