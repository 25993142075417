@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: row;
  gap: rem(30px);
  border-bottom: 1px solid var(--color-horizantal-line);
  margin-bottom: rem(12px);

  .NavLinks {
    padding: rem(2px) rem(4px);
    color: var(--ir-neutral-dark-black-90);
    text-decoration: none;
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20px);
    border-bottom: 1px solid transparent;
    cursor: pointer;

    &:active,
    &:hover {
      color: var(--ir-segment-cyan-900);
      border-bottom: 1px solid var(--ir-segment-cyan-900);
    }
  }

  .textDiv {
    &:active,
    &:hover {
      color: var(--ir-segment-cyan-900);
    }
  }

  .novaScore {
    padding: 2px 5px;
    color: rgb(0 0 0);
    background-color: #e6eff0;
  }

  .active {
    color: var(--ir-segment-cyan-900);
    border-bottom: 1px solid var(--ir-segment-cyan-900);
  }

  .creditConateinerDiv {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .CreditContainer {
    display: flex;
  }

  .backgroundDiv {
    height: max-content;
    padding: rem(0) rem(2px);
    background: var(--ir-gray-200);
    border-radius: rem(2px);
  }

  .creditDiv {
    font-size: rem(14px);
    font-weight: 600;
    text-align: left;
    color: var(--ir-neutral-black-100);
  }
}
