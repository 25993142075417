@import '/src/stylesheets/utils';

.answerContainer {
  .readContainer {
    display: flex;
    justify-content: flex-end;
  }

  .readDiv {
    padding: unset;
    border: unset;
    box-shadow: unset;
    padding-top: rem(5px);
  }

  .textContainer {
    width: 100%;
  }

  .textField {
    background-color: var(--ir-neutral-white-bg);
    font-size: rem(10px);
    font-weight: 600;
    color: var(--ir-gray-1050);
  }

  .labelStyle {
    top: 0;
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
  }
}
