@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: rem(4px);
  padding: rem(4px) rem(8px);
  border: 1px solid var(--ir-gray-200);
  background: var(--ir-neutral-white-bg);

  .Heading {
    color: var(--ir-neutral-black-primary);
    font-size: rem(14px);
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
  }

  .Details {
    display: flex;
    justify-content: space-between;
    gap: 10rem;
  }

  button {
    padding: 0;
    border: none;
    background: transparent;
  }
}
