@import '/src/stylesheets/utils';

.phoneContainer {
  display: flex;
  padding: 0 rem(24px) rem(24px);
  flex-direction: column;
  gap: rem(20px);

  .buttonMargin {
    margin-top: rem(20px);
  }

  .description {
    display: flex;
    width: 75%;
    flex-direction: column;
    gap: 1rem;

    [class*='-menu'] {
      width: 100%;
    }
  }

  .heading {
    font-size: rem(24px);
    font-weight: 700;
    text-align: center;
  }

  .subHeading {
    font-size: rem(18px);
    font-weight: 500;
    text-align: center;
  }

  .spanColor {
    color: var(--ir-segment-cyan-700);
  }

  .renterButton {
    width: 9rem;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: center;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);
  }

  .renterButtonContainer {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .renterButtonSmall {
    width: fit-content;
  }

  // phone Number field

  [class^='PhoneNumber_container'] {
    [class*='flag-dropdown'] {
      border: none;
    }

    [class*='react-tel-input'] [class*='country-list'] [class*='search'] {
      z-index: 1;
      position: sticky;
      top: 0;
      padding: rem(4px) 0 rem(1px) 0;
      text-align: center;
    }

    [class*='country-list'] [class*='search'] [class*='search-emoji'] {
      display: revert;
    }

    [class*='form-control']:focus,
    [class*='form-control'],
    [class*='form-control']:hover {
      background: var(--ir-neutral-white-bg) !important;
      border-color: transparent;
      border-radius: 0 0 rem(4px) rem(4px);
      box-shadow: 0 0 0 1px transparent;
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      padding-top: rem(27px) !important;
      font-size: rem(14px);
      line-height: rem(20px);
      color: var(--ir-neutral-black-100);
    }

    [class*='special-label'] {
      top: rem(6px);
      left: rem(6px);
      text-align: left;
      color: var(--ir-neutral-black-primary);
      font-family: 'Open Sans', sans-serif;
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(14px);
      background-color: transparent;
    }

    [class*='country-list'] {
      height: 10rem;
      padding: 0;
    }

    [class*='form-control'] {
      padding-top: rem(30px);
    }

    [class*='flag'] {
      margin-top: rem(-2px) !important;
    }

    [class*='open'],
    [class*='selected-flag'],
    [class*='react-tel-input'],
    [class*='selected-flag']:hover,
    [class*='flag-dropdown'] {
      border: none;
      background: transparent !important;
      background-color: transparent;
    }

    [class*='react-tel-input'] {
      background-color: var(--ir-neutral-white-bg) !important;
      border: 1px solid var(--ir-gray-200);
      border-radius: rem(4px);
      font-size: rem(14px);
    }

    :global(.country) {
      padding: rem(5px) 0 0 rem(44px) !important;
    }

    [class*='flag'] [class*='arrow'] {
      border-top: rem(4px) solid var(--ir-neutral-000);
    }

    [class='dial-code'] {
      width: 2rem;
    }

    [class='country'],
    [class='country_highlight'],
    [class*='preferred'] {
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;
      text-align: initial;
      justify-content: flex-end;
    }
  }

  // React select phone number type field

  .phoneNumberSelectConatiner {
    [class*='-control'] {
      width: 100%;
      height: rem(54px);
      margin: 0;
      border-radius: 0 0 rem(4px) rem(4px);
      font-size: rem(16px);
    }

    [class*='-control']:hover {
      border-color: var(--ir-gray-200);
    }

    [class*='-option'] {
      padding: 0 rem(2px) 0 rem(20px);
      line-height: rem(20px);
      text-align: left;
    }

    [class*='-placeholder'] {
      font-size: rem(14px);
      font-weight: 400;
      line-height: rem(20px);
      text-align: left;
    }
  }

  .spinnerMinHeight {
    min-height: rem(28px);
    display: flex;
  }
}

@media (max-width: 745px) {
  .container {
    height: 100%;
    justify-content: end;
  }

  .description {
    width: 100% !important;
    padding: 2rem;
  }

  .phoneNumberSelectConatiner {
    [class$='-ValueContainer'] {
      min-height: 0 !important;
      padding-top: 0;
    }

    [class*='-indicatorContainer'] {
      width: 100%;
      height: rem(32px) !important;
      min-height: 0 !important;
      padding: 4px 8px;
    }

    [class*='-control'] {
      width: 100%;
      height: rem(32px) !important;
      min-height: 0 !important;
    }
  }
}
