@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 0 rem(24px);
  color: var(--ir-warm-gray-800);
  font-style: normal;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(24px);

  .title {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 2rem;

    .renterPhotoInformationButton {
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }

    .buttonWihIcon {
      display: flex;
      align-items: center;
      gap: rem(5px);
    }
  }
}
