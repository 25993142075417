@import '/src/stylesheets/utils';

.renterCriteriaDoc {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  padding: rem(24px);

  .renterCriteriaHeader {
    align-items: baseline;
    text-align: center;

    .actionButtons {
      border: none;
      background: none;
      box-shadow: none;
    }

    .h1 {
      font-size: rem(16px);
      font-weight: 500;
    }
  }

  .outerDiv {
    overflow-y: scroll;
    scrollbar-gutter: stable;
    overflow-x: hidden;
    max-height: 65vh;
    margin-bottom: rem(24px);

  }

  .documentRow {
    text-align: justify;
    line-height: rem(26px);
  }
}

@media (min-width: 745px) {
  .renterCriteriaDoc {
    padding: 0;

    .outerDiv {
      max-height: 70.5vh;
      padding: rem(19px) 2rem 3rem 3rem;
    }
  }
}
