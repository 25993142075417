@import '/src/stylesheets/utils';

.addressEditDetailsContainer {
  .YellowBorder {
    [class*='-control'] {
      border: 1px solid var(--ir-warning-pill-text) !important;
      background: var(--ir-neutral-white-bg) !important;
    }
  }

  .stateCityContainer {
    display: flex;
    width: 100%;
    border-radius: rem(4px);

    .cityFieldContainer {
      flex: 4;

      [class*='-control'] {
        border-right: none;
      }

      .textFieldLabel {
        top: rem(9px);
      }
    }

    .stateFieldContainer {
      flex: 2;

      [class*='-control'] {
        border-left: none;
        padding-top: rem(8px);
        margin-left: rem(-5px);
        font-weight: 400;
      }

      [class*='-control'] > :last-child {
        margin-right: rem(17px);
      }

      .textFieldLabel {
        top: rem(9px);
      }
    }
  }

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      font-size: 0.6rem;
    }

    [class*='-control'] {
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(15px);
      padding-top: rem(18px);
      font-weight: 400;
      border-radius: rem(4px);
    }

    .textFieldLabel {
      position: absolute;
      top: rem(4px);
      left: rem(16px);
      z-index: 1;
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-010);
    }
  }

  .DateContainer {
    margin: rem(8px) 0;
  }

  .WarningInformation {
    display: flex;
    padding: rem(8px) rem(10px) rem(8px) rem(8px);
    align-items: flex-start;
    gap: rem(10px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-segment-yellow-100);
    background: var(--ir-warning-pill);
    margin-top: rem(8px);
  }

  .WarningIcon {
    min-width: rem(24px);
    min-height: rem(24px);
  }

  .WarningText,
  .WarningTextItalic {
    color: var(--ir-neutral-black-primary);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px); /* 140% */
  }

  .WarningTextItalic {
    font-style: italic;
    font-weight: 500;
  }
}
