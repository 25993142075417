@import '/src/stylesheets/utils';

.documentRow {
  height: 63vh;
  text-align: justify;
  overflow-x: hidden;
}

.documentHeading {
  margin-top: rem(20px);
  font-weight: 600;
  font-size: rem(14px);
}

.Space {
  padding: rem(5px) 0;
}

.List {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.ListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: rem(6px);
}

.SecondListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-left: rem(15px);
}

.ThirdListItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-left: rem(40px);
}

.Dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--ir-neutral-black-100);
}

.Bold {
  font-weight: 600;
}

@media (max-height: 1100px) {
  .documentRow {
    overflow-y: auto;
  }
}

@media (max-height: 896px) {
  .documentRow {
    overflow-y: auto;
  }
}

@media (max-height: 720px) {
  .documentRow {
    overflow-y: auto;
  }
}
