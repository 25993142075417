@import '/src/stylesheets/utils';

.noFilesDropzone {
  min-height: rem(150px) !important;

  .dropzoneText {
    top: 50% !important;
  }
}

:global(.dropzone) {
  position: relative;
  width: 100%;
  height: auto;
  padding: rem(8px) rem(12px);
  border: 0.5px solid var(--ir-neutral-states-color-500);
  background-color: var(--ir-warm-gray-100);
  border-radius: rem(4px);
  margin-top: rem(10px);
  max-height: rem(234px);
  min-height: rem(102px);
  overflow-y: auto;

  .dropzoneLabel {
    color: var(--ir-neutral-010);
    font-size: rem(10px);
  }

  .previewList {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .imagesListContainer {
      display: flex;
      margin: unset;
      padding: unset;
      list-style: none;
      flex-wrap: wrap;
      min-height: rem(65px);
    }
  }

  .fileContainer {
    position: relative;
    margin: 0;
    border: 1px solid var(--ir-warm-gray-100);

    .featuredText {
      position: absolute;
      bottom: 1%;
      left: 2%;
      padding: rem(2px);
      color: var(--ir-warm-gray-800);
      opacity: 0.8;
      background-color: var(--ir--neutral-green-400);
      border-radius: rem(2px);
      font-size: rem(8px);
      font-weight: 600;
      z-index: 1;
    }
  }

  .greenBorder {
    border: 1px solid var(--ir--neutral-green-400);
    margin-right: 0.1rem;
  }

  .previewContainer {
    position: relative;
    padding: rem(6px);

    .previewImage {
      width: rem(45px);
      height: rem(45px);
      border-radius: rem(4px);
    }

    .removeButton {
      position: absolute;
      top: rem(0);
      right: rem(0);
      cursor: pointer;
    }

    .loadingIcon {
      position: absolute;
      top: 18%;
      left: 26%;
      animation: rotation 2s infinite linear;
    }
  }

  .dropzoneText {
    display: flex;
    position: absolute;
    top: 65%;
    left: 50%;
    color: var(--ir-neutral-000);
    transform: translate(-50%, -50%);

    .selectButton {
      width: auto;
      margin: unset;
      padding: unset;
      border: none;
      background: unset;
      color: var(--ir-segment-cyan-900);
      box-shadow: unset;
      margin-bottom: rem(14px);
      margin-left: rem(7px);
      outline: none;
    }

    .addIcon {
      margin-right: rem(5px);
      margin-top: rem(3.5px);
    }
  }
}

.dropzoneBottomText {
  display: flex;
  color: var(--ir-neutral-black-primary);
  font-size: rem(10px);
  justify-content: center;
  margin-top: 0.2rem;
}

.dragDropField {
  display: flex;
  width: 100%;
  height: rem(50px);
  padding: rem(4px) rem(24px);
  background-color: var(--ir-warm-gray-100);
  border-radius: rem(4px);
  justify-content: center;
  margin-top: rem(10px);

  .selectButton {
    width: auto;
    padding: unset;
    border: none;
    background: unset !important;
    color: var(--ir-segment-cyan-900);
    box-shadow: unset;
    margin-bottom: unset;
    margin-left: rem(7px);
    outline: none;
  }

  .dragDropText {
    color: var(--ir-neutral-000);
    margin-top: rem(10px);
  }

  .addIcon {
    margin-right: rem(5px);
    margin-top: rem(13px);
  }
}

.emptyDropzone {
  .selectButton {
    position: absolute;
    top: 55%;
    left: 40%;
  }
}

.dropzoneContentContainer {
  .selectButton {
    width: auto;
    padding: unset;
    border: none;
    background-color: unset !important;
    color: var(--ir-segment-cyan-900);
    box-shadow: unset;
    margin-bottom: unset;
    margin-left: rem(7px);
    outline: none;
  }
}

@media (max-width: 991px) {
  .emptyDropzone {
    .dropzoneText {
      .dragText {
        width: rem(200px);
      }
    }

    .selectButton {
      left: 30%;
    }
  }
}

@media (max-width: 460px) {
  .emptyDropzone {
    .selectButton {
      left: 25%;
    }
  }
}

@media (max-width: 360px) {
  .emptyDropzone {
    .dropzoneText {
      .dragText {
        width: rem(130px);
      }
    }

    .selectButton {
      top: 60%;
      left: 20%;
    }
  }

  .dragDropField {
    padding-left: unset;
    padding-right: unset;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

.dropzone {
  padding: rem(20px);
  border: 2px dashed #ccc;
  text-align: center;
}

.dropzone-active {
  background-color: #eee;
}

.imagesRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.4rem !important;
}
