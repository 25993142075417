@import '/src/stylesheets/utils';

.container {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: var(--ir-loader-backdrop);
  z-index: 100000000;
  justify-content: center;
  align-items: center;
}
