@import '/src/stylesheets/utils';

.guarantorInformationContainer {
  margin-bottom: rem(24px);

  .phoneNumberContainer {
    [class*='country-list'] {
      height: 10rem;
      padding: 0;
    }

    [class*='form-control'] {
      padding-top: rem(30px);
      width: 100%;
    }

    [class*='flag'] {
      margin-top: rem(-2px) !important;
    }

    [class*='open'],
    [class*='selected-flag'],
    [class*='react-tel-input'],
    [class*='selected-flag']:hover,
    [class*='flag-dropdown'] {
      border: none;
      background: transparent !important;
      background-color: transparent;
    }

    [class*='react-tel-input'] {
      width: 100%;
      border: 1px solid var(--ir-gray-200);
      background-color: var(--ir-neutral-white-bg) !important;
      border-radius: rem(4px);
      font-size: rem(14px);
    }

    :global(.country) {
      padding: rem(10px) 0 0 rem(44px) !important;
    }

    [class*='form-control']:focus,
    [class*='form-control'],
    [class*='form-control']:hover {
      background: var(--ir-neutral-white-bg) !important;
      border-color: transparent;
      border-radius: 0 0 rem(4px) rem(4px);
      box-shadow: 0 0 0 1px transparent;
      font-style: normal;
      font-weight: 400;
      font-size: rem(14px);
      line-height: rem(20px);
      color: var(--ir-neutral-black-100);
    }

    [class*='special-label'] {
      top: rem(6px);
      left: rem(6px);
      text-align: left;
      color: var(--ir-neutral-010);
      font-size: rem(10px);
      font-weight: 600;
      line-height: rem(14px);
      background-color: transparent;
    }

    [class*='flag'] [class*='arrow'] {
      border-top: rem(4px) solid var(--ir-neutral-000);
    }

    [class='dial-code'] {
      width: 2rem;
    }

    [class='country'],
    [class='country_highlight'],
    [class*='preferred'] {
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;
      text-align: initial;
      justify-content: flex-end;
    }
  }

  .textFieldContainer {
    position: relative;
    border-radius: rem(4px);
    margin-bottom: rem(8px);

    [class^='TextField_error__gNUlo form-control'] {
      border: 1px var(--ir-semantics-error) solid !important;
    }

    [class*='_minHeight'] {
      min-height: fit-content;
    }

    [class*='_errorMessage'] {
      font-size: 0.6rem;
    }

    [class*='-control'] {
      height: rem(50px);
      border: rem(1px) solid var(--ir-gray-200);
      background: var(--ir-neutral-white-bg);
      color: var(--ir-neutral-black-100);
      padding-left: rem(15px);
      padding-top: rem(18px);
      font-weight: 400;
      border-radius: rem(4px);
    }

    [class^='PhoneNumber_container'] {
      margin-top: rem(5px);

      [class^='PhoneNumber_label'] {
        font-weight: 600;
        font-size: rem(10px);
      }
    }
  }

  .textFieldLabel {
    position: absolute;
    top: rem(4px);
    left: rem(16px);
    z-index: 1;
    font-size: rem(10px);
    font-weight: 600;
    color: var(--ir-neutral-010);
  }
}
