@import '/src/stylesheets/utils';

.container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  margin-inline: 1rem;
  text-align: center;

  .heading {
    font-size: rem(24px);
    font-weight: 700;
    color: var(--ir-gray-1050);
  }

  .subHeading {
    font-size: rem(18px);
    color: var(--ir-gray-1050);
  }

  .renterButton {
    width: fit-content;
    padding: rem(12px) rem(40px);
    font-weight: 600;
    font-size: rem(18px);
    border-radius: rem(4px);
  }

  [class*='_children'] [class*='_childrenContainer'] {
    justify-content: center;
  }
}

.closeButton {
  display: none !important;
}

.modalPriorityDiv {
  z-index: 1061;
}
