@import '/src/stylesheets/utils';

.ModalContainer {
  [class*='modal-dialog'] {
    display: flex;
    height: 100%;
  }

  [class*='modal-content'] {
    margin: auto;
    border: unset;
    border-radius: rem(6px);
    max-width: rem(600px);
  }

  .CustomHeader {
    display: none;
    margin: auto;
  }

  .HeaderContainer {
    border-bottom: unset;
    border-top-left-radius: rem(6px);
    border-top-right-radius: rem(6px);
  }

  .BodyContainer {
    padding: unset;
    background: var(--ir-warm-gray-100);
    padding-bottom: rem(20px);
    border-bottom-left-radius: rem(6px);
    border-bottom-right-radius: rem(6px);
  }
}
