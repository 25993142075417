@import '/src/stylesheets/utils';

.descriptionCard {
  display: flex;
  position: relative;
  width: 100%;
  padding: rem(10px);
  border: rem(1px) solid var(--ir-gray-200);
  color: var(--ir-warm-gray-800);
  border-radius: rem(4px);
  align-items: flex-start;
  justify-content: space-between;
  margin-top: rem(5px);

  .FloatingLabel {
    display: flex;
    position: absolute;
    top: rem(-8px);
    left: rem(8px);
    padding: 0 rem(2px);
    background: var(--ir-neutral-black-primary);
    color: var(--color-white);
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    font-size: rem(11px);
    font-weight: 600;
    text-transform: uppercase;
    border-radius: rem(2px);
  }

  .Header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .EmploymentType {
    display: flex;
    height: fit-content;
    padding: 0 rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-warm-gray-800);
    align-items: flex-start;
    gap: rem(10px);
    border-radius: rem(2px);
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px); /* 140% */
    text-transform: capitalize;
  }

  .leftDiv {
    display: flex;
    width: 100%;
    margin-right: rem(10px);

    .workIcon {
      padding: rem(10px);
    }
  }

  .descriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: rem(10px);
    gap: rem(8px);
    width: 100%;

    .company {
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(24px);
    }

    .upcaseText {
      text-transform: capitalize;
    }

    .title {
      font-weight: 600;
      font-size: rem(10px);
      line-height: rem(14px);
      max-width: rem(300px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .label {
        color: var(--ir-neutral-000);
        margin-right: rem(9px);
      }
    }
  }

  .editButton {
    padding: 0;
    border: none;
    background: transparent;
  }

  .EditButtonContainer {
    position: absolute;
    right: rem(10px);
  }
}
