@import '/src/stylesheets/utils';

.ModalContainer {
  [class*='modal-content'] {
    max-height: 95vh;
    overflow-y: auto;
    border-radius: rem(24px);
  }

  [class*='modal-header'] {
    padding-bottom: unset;
  }

  [class*='modal-body'] {
    padding: unset;
    padding-bottom: rem(48px);
  }

  .CancelButton {
    padding: 10px 0;
    gap: 10px;
  }

  .HeaderContainer {
    display: none;
  }

  .BodyContainer {
    height: 50%;
    background: unset;
  }

  .IncomeHeading {
    padding: rem(12px) rem(28px);
    text-align: start;
    color: var(--ir-neutral-black-100);
    padding-top: unset;
    font-size: rem(32px);
  }

  .UserInformationContainer {
    padding: 0 rem(12px) rem(5px);
  }

  .SourceInformation {
    padding: 0 rem(40px);
  }

  .SourceContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 rem(2px);
    align-items: center;
    margin-bottom: rem(13px);
  }

  .SourceHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .DropDownArrow {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.5s ease;
  }

  .RotateArrow {
    transform: rotate(-90deg);
    transition: transform 0.5s ease;
  }

  .IncomeReportInformation {
    display: flex;
    flex-direction: column;
    gap: rem(12px);
    padding: rem(18px) rem(40px) rem(12px);
  }

  .IncomeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .IncomeReportHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: capitalize;
  }

  .IncomeReportText {
    color: var(--ir-neutral-black-primary);
    font-weight: 500;
  }

  .FinancialReports {
    display: flex;
    padding: 0 rem(40px);
    flex-direction: column;
    gap: rem(12px);
  }

  .FinancialReportHeader {
    display: flex;
    gap: rem(10px);
  }

  .FinancialHeading {
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .InstitutionCount {
    padding: 0 rem(2px);
    border-radius: rem(2px);
    background: var(--ir-gray-200);
    color: var(--ir-neutral-black-100);
    font-size: rem(16px);
    font-weight: 600;
  }

  .Divider {
    width: 100%;
    margin: rem(18px) rem(40px) 0;
    border-top: 1px solid var(--ir-warm-gray-045);
  }

  .LoaderIcon {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
  }

  .IncomeReportNavbar {
    width: 100%;
    padding: rem(15px) rem(40px) rem(12px);
  }

  .SupportingDocContainer {
    margin-top: rem(10px);
    padding: rem(12px) rem(40px);
  }

  .ErrorContainer {
    width: 100%;
    padding: rem(12px) 0 rem(58px) 0;
    min-height: rem(290px);
  }
}

.ExportContainer {
  margin: rem(50px) rem(50px) 0;

  .Title {
    font-size: rem(16px);
    font-weight: 400;
    color: var(--ir-warm-gray-800);
    margin-bottom: rem(5px);
  }

  .DropDownIconContainer {
    padding: rem(10px);
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(45px);

    button {
      padding: rem(4px) rem(6px);
      font-weight: 600;
      font-size: rem(14px);
    }
  }
}

.Cancelbutton {
  gap: 5px;
}

.PdfSectionModalBody {
  height: 515px;
  overflow-y: auto;
}

.SectionContainer {
  display: flex;
  flex-direction: column;
  height: 50%;
  gap: 12px;

  .OptionsContainer {
    display: flex;
    gap: 10px;

    .checkboxLabel {
      display: flex;
      color: var(--ir-neutral-black-100);
      font-size: rem(16px);
      line-height: normal;
      font-weight: 600;
      gap: rem(10px);
      align-items: flex-end;

      [class*='form-check-input'] {
        width: rem(18px);
        height: rem(18px);
      }
    }
  }

  .FirstOption {
    display: flex;
    gap: 10px;
    padding-bottom: rem(12);
  }
}

.SelectedHeading {
  font-size: 16px;
  font-weight: 600;
  color: var(--ir-neutral-black-100);
  padding-bottom: 10px;
}

.SelectedOptionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .SelectedOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    gap: 10px;
    padding: 10px 16px;

    .RemoveButton {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f7fafa;
      width: rem(24px);
      height: rem(24px);
    }
  }

  .SelectedOptionName {
    font-size: 16px;
    font-weight: 600;
    color: var(--ir-neutral-black-100);
  }
}

.PdfSpinner {
  width: rem(15px);
  height: rem(15px);
}

.CustomModal {
  [class*='modal-footer'] {
    padding: 20px 30px;
  }

  .ButtonsContainer{
    display: flex;
    gap: rem(10px);
  }

  .CustomHeaderContainer {
    padding: 20px 30px;

    .CustomHeaderContent {
      gap: 10px;
    }
  }
}

.NextButton {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
}
