@import '/src/stylesheets/utils';

.backgroundQuestionsContainer {
  text-align: start;
  font-style: normal;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(24px);
  color: var(--ir-gray-1050);

  .heading {
    font-size: rem(18px);
    font-style: normal;
    line-height: rem(24px);
    padding-bottom: rem(10px);
  }

  .subHeading {
    font-size: rem(18px);
    font-weight: 500;
    padding-bottom: rem(10px);
    text-align: center;
  }

  .modalHeading {
    font-size: rem(24px);
    font-weight: 700;
    text-align: center;
    padding-bottom: rem(10px);
  }

  .questionSelect {
    font-size: rem(14px);

    [class*='-control'] {
      border: 1px solid var(--ir-gray-200) !important;
    }
  }

  .titileDiv {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    padding-bottom: rem(30px);
    font-size: rem(16px);
    font-weight: 600;
  }

  .questionContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    gap: rem(8px);

    &.noScroll {
      overflow-y: unset;
    }

    .borderContainer {
      display: flex;
      width: 100%;
      padding: rem(8px);
      border: 1px solid var(--ir-gray-200);
      background: var(--ir-gray-000);
      flex-direction: column;
      border-radius: 4px;
      font-size: rem(12px);
      font-style: normal;
      line-height: rem(16px);

      .dropDownContainer {
        display: flex;
        justify-content: flex-end;
        text-align: left;

        [class*='placeholder'] {
          color: var(--ir-neutral-010);
          font-style: normal;
          font-weight: 400;
          font-size: rem(14px);
        }

        [class*='-control'] {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border-radius: rem(4px);
          border: 1px solid var(--ir-gray-200);
          background: var(--ir-neutral-white-bg);
          font-size: rem(14px);
          font-weight: 600;
          min-height: rem(28px);
        }

        [class*='-ValueContainer'] {
          min-height: unset;
        }

        [class*='indicatorContainer'] {
          padding: unset;
        }
      }
    }
  }

  .renterButtonContainer {
    margin-top: rem(50px);
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: flex-end;
    gap: 1rem;

    &.dialogDiv {
      justify-content: space-around;
      flex-direction: row;
      margin-bottom: 1rem;
    }
  }

  .renterButton {
    width: fit-content;
    padding: 0.4rem;
    border: 1px solid var(--ir-segment-cyan-700);
    text-align: start;
    color: var(--ir-segment-cyan-700);
    font-weight: 600;
    font-size: rem(16px);

    &.dialogButton {
      padding: rem(10px) rem(60px);
      color: var(--ir-gray-200);
    }

    &.smallDialogButton {
      padding: rem(4px) rem(8px);
      color: var(--ir-gray-200);
    }
  }

  .ErrorMessage {
    color: var(--ir-field-error);
    font-size: 0.8rem;
    font-weight: 600;
    padding-left: 0.3rem;
  }

  .answerContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;

    .textContainer {
      width: 100%;
      padding-top: rem(18px);
      background-color: var(--ir-neutral-white-bg);
      border: 1px solid var(--ir-gray-200);
      border-radius: rem(4px);
    }

    .textField {
      min-height: rem(120px) !important;
      font-size: rem(10px);
      font-weight: 600;
      resize: none;
      width: 100%;
      padding-top: rem(18px);
      background-color: var(--ir-neutral-white-bg);
      border: 1px solid var(--ir-gray-200);
      border-radius: rem(4px);
    }

    .labelStyle {
      top: 0;
      color: var(--ir-neutral-black-primary);
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;

      > :nth-child(2) {
        padding-top: unset;
      }

      > :nth-child(1) {
        border: unset;
        padding-top: unset;
      }
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: rem(20px);

    .button {
      width: fit-content;
    }
  }
}

@media (max-width: $screen-tablet) {
  .backgroundQuestionsContainer {
    .buttonContainer {
      padding-top: rem(10px);
    }
  }
}
