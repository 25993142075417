.tooltip {
  font-size: 11px;
  line-height: 14.98px;
  opacity: 1 !important;

  :nth-last-child(2)::before {
    border-top-color: var(--ir-neutral-black-primary) !important;
  }

  :last-child {
    background-color: var(--ir-neutral-black-primary) !important;
    max-width: 235px !important;
    padding-bottom: 0.47em;
    padding-top: 0.47em;
  }
}
