@import '/src/stylesheets/utils';

.container {
  .linkedinHeading {
    width: 80%;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: 1rem;
  }

  .textContainer {
    color: var(--ir-segment-cyan-900);
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    align-items: flex-end;

    .button {
      width: fit-content;
      padding: 0.5rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
    }

    .buttonLinkedIn {
      display: flex;
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-900);
      color: var(--ir-segment-cyan-900);
      justify-content: flex-end;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: rem(18px);
      margin-bottom: 0.8rem;
      gap: rem(4px);
    }

    .buttonGray {
      padding: 0.4rem;
      border: 1px solid var(--ir-neutral-010);
      color: var(--ir-neutral-010);
      font-style: normal;
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}

@media (min-width: 800px) {
  .container {
    .linkedinHeading {
      width: 100%;
    }
  }
}
