@import '/src/stylesheets/utils';

.CapsuleContainer {
  display: flex;
  flex-direction: column;
  padding: rem(5px) rem(15px);
  border-radius: rem(5px);
  border: 1px var(--ir-warm-gray-030) solid;
  gap: rem(5px);
  align-items: center;
  background: var(--ir-gray-000);

  .CapsuleLabel {
    font-weight: 600;
    font-size: rem(12px);
    color: var(--ir-neutral-dark-black-90);
  }

  .PillContainer {
    font-size: rem(16px);
    font-weight: 600;
    border-radius: rem(2px);
    background-color: unset;
  }

  .LabelContainer {
    display: flex;
    gap: rem(8px);
    align-items: center;
  }

  .EmptyState {
    position: relative;
    top: -5px;
    color: var(--ir-warm-gray-060);
    font-weight: 600;
    font-size: rem(16px);
  }
}

.CapsuleContainer:hover {
  border: 1px solid #abb9ba;
  background: rgb(230 239 240 / 30%);
}

.customNavButton {
  display: flex;
  width: 100%;
  min-height: 44px;
  border: none;
  align-items: center;
  background-color: transparent;
}

.CountContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
