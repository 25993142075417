.agentProfileContainer {
  width: 100%;
  padding: 1rem 0;
  background-color: var(--ir-neutral-white-bg);
  min-height: 100vh;
  overflow: hidden;

  .containerRow {
    display: flex;
    justify-content: center;
  }

  .card {
    padding: 24px 40px;
    background: var(--ir-warm-gray-100);
    border-radius: 24px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }
}
