@import '/src/stylesheets/utils';

.renterNavbarContainer {
  padding: 0 1rem;

  .renterNavbarRow {
    display: flex;
    width: 100%;
    height: rem(72px);
    margin: 0;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(
      to right,
      rgb(250 250 250),
      rgb(250 250 250),
      rgb(250 250 250),
      rgb(84 189 200 / 15%) 83%,
      rgb(250 250 250),
      rgb(250 250 250)
    );
  }

  .intellirentLogo {
    width: fit-content;
    text-align: start;
  }

  .renterNabBarMenuIcons {
    display: flex;
    width: fit-content;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }
}

@media (max-width: 744px) {
  .renterNavbarContainer {
    height: auto;
    padding: 0;

    .renterNavbarRow {
      padding: 0 0.7rem;
    }

    [class^='PropertyInformation'] {
      display: none;
    }
  }
}
