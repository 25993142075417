@import '/src/stylesheets/utils';

.container {
  .readContainer {
    display: flex;
    justify-content: flex-end;
  }

  .textField {
    background-color: var(--ir-neutral-white-bg);
    font-size: rem(10px);
    font-weight: 600;
    color: var(--ir-gray-1050);

    &.heightDiv {
      display: $webkit-box;
      height: rem(30px);
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.overflow {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .readDiv {
    padding: unset;
    border: unset;
    box-shadow: unset;
    padding-top: rem(5px);
    text-align: end;
  }
}
