@import '/src/stylesheets/utils';

.Container {
  width: 100%;

  .HeadingRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(30px);
  }

  .Heading {
    font-size: rem(16px);
    font-weight: 400;
  }

  .IncomeRow {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: rem(5px);
    margin-bottom: rem(20px);
  }

  .IncomeItem {
    display: flex;
    width: 33%;
    padding: rem(10px);
    border: 1px solid var(--ir-gray-300);
    flex-direction: column;
    gap: rem(5px);
    border-radius: rem(5px);
  }

  .ItemHeading {
    word-break: break-all;
    color: var(--ir-gray-500);
  }

  .ItemText {
    display: flex;
    font-size: rem(20px);
    font-weight: 500;
  }

  .DocumentInput {
    width: rem(200px);
    border: none;
  }

  .DocumentInput:focus {
    outline: none;
  }

  .DocumentSection {
    width: 100%;
    padding: rem(20px) rem(5px);
    border: 1px solid var(--ir-gray-300);
    margin-bottom: rem(20px);

    .ArrowIcon {
      margin-right: rem(5px);
      rotate: (90deg);
    }

    .RotateDown {
      transform: rotate(0);
      transition: transform 0.5s ease;
    }

    .RotateUp {
      transform: rotate(-90deg);
      transition: transform 0.5s ease;
    }

    .DocText {
      font-size: rem(16px);
      font-weight: 400;
    }

    .DocumentHeadingRow {
      display: flex;
      width: 100%;
      gap: rem(10px);
      align-items: center;
      margin-bottom: rem(20px);
    }

    .PayStubRow {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(10px);
      padding: 0 rem(10px);
      color: var(--ir-neutral-black-primary);
    }
  }

  .AttachmentRow {
    margin-top: rem(5px);
    display: flex;
    padding: rem(4px) rem(8px);
    background-color: var(--ir-neutral-white-bg);
    border: 1px solid var(--ir-gray-200);
    overflow-x: scroll;
    white-space: nowrap;
  }

  .AttachmentRow::-webkit-scrollbar {
    display: none;
  }
}
