@import '/src/stylesheets/utils';
@import '/src/stylesheets/variables';

.Divider {
  box-shadow: 0 5px 5px -5px rgb(120 120 120 / 50%);
  width: 100%;
  padding: rem(6px);
  border-bottom: rem(0.5px) solid #a3b0b280;
}

.Spinner {
  width: rem(15px);
  height: rem(15px);
  margin-left: rem(4px);
}

.Container {
  margin-left: rem(80px);
  margin-right: rem(40px);
  padding-right: rem(40px);
  display: flex;
  flex-direction: column;
  border-right: rem(1px) solid #a3b0b280;

  .PropertyRow {
    display: flex;
    margin: rem(30px) rem(0);
    flex-flow: row wrap;
    justify-content: space-between;

    .LeftItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(15px);

      .BackIcon {
        width: rem(16px);
        height: rem(16px);
        color: #a3b0b2;
        cursor: pointer;
      }

      .DropdownIcon {
        width: rem(24px);
        height: rem(24px);
      }

      .CreatedAt {
        color: var(--ir-neutral-black-primary);
        font-size: rem(12px);
        font-weight: 600;

        &.normalFontWeight {
          font-weight: unset;
        }
      }

      h1 {
        text-transform: capitalize;
        background-color: #e6f8fa;
      }
    }

    .RightItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: rem(25px);

      .AgentInfoContainer {
        display: flex;
        flex-direction: row;
        gap: rem(15px);
        align-items: center;

        .ProfileImage {
          border-radius: rem(50px);
        }

        .NameContainer {
          display: flex;
          flex-direction: column;
          margin-top: rem(15px);

          .Name {
            text-transform: capitalize;
            font-weight: 700;
            font-size: rem(12px);
          }

          .Type {
            font-weight: 400;
            font-size: rem(12px);
            color: var(--ir-neutral-dark-black-90);
          }
        }
      }
    }
  }

  .AddressContainer {
    display: flex;
    flex-direction: row;
    gap: rem(10px);
  }

  .AddressHeader {
    display: flex;
    flex-direction: row;
    gap: rem(15px);
    margin: 0;
    padding: 0;

    .EditAddressButton {
      flex: 1;
      text-align: flex-end;
      margin-right: rem(20px);
    }
  }

  .ApplicantAvatarContainer {
    display: flex;
    margin-top: rem(10px);
    justify-content: center;

    .ApplicantCircle {
      border-radius: 50%;
      width: fit-content;
      padding: rem(10px);
    }
  }

  .PropertyType {
    display: flex;
    width: fit-content;
    padding: 0 rem(5px);
    background: var(--ir-neutral-black-primary);
    text-align: center;
    color: var(--ir-gray-000);
    text-transform: uppercase;
    font-size: rem(12px);
    font-weight: 600;
    border-radius: rem(2px);
    align-items: center;
  }

  .AvailableContainer {
    display: flex;
    width: fit-content;
    padding: 0 rem(5px);
    background: var(--ir-gray-200);
    gap: rem(5px);
    border-radius: rem(2px);

    .AvailableLabel {
      color: var(--ir-neutral-black-primary);
    }

    .AvailableValue {
      font-weight: 600;
      color: var(--ir-neutral-black-100);
    }
  }

  .EditButtonContainer {
    margin-right: rem(28px);
  }

  .EditButton {
    width: fit-content;
    padding: 0 rem(5px);
    align-self: flex-end;
  }

  .ApplicantContainer {
    padding: rem(30px) rem(60px);
    border: 1px solid var(--ir-gray-200);
    background-color: var(--ir-warm-gray-030);

    .ApplicantContainerInner {
      display: flex;
      border: 1px solid var(--ir-gray-200);
      border-radius: rem(6px);
      background-color: var(--ir-gray-000);
      box-shadow: 0 0 rem(5px) rgb(223 223 223 / 50%);
      flex-flow: row wrap;

      .Col1,
      .Col3 {
        display: flex;
        padding: 10px;
        flex: 1;
        flex-flow: column wrap-reverse;
        justify-content: space-between;

        .ApplicantSelect {
          width: fit-content;
        }

        .SendRequestButton {
          width: fit-content;
          padding: rem(5px);
        }
      }

      .Col2 {
        display: flex;
        padding: 10px;
        flex: 0 1 auto;
        flex-direction: column;

        .ApplicantHeading {
          font-weight: 600;
          font-size: rem(24px);
        }

        .ApplicantStatusContainer {
          display: flex;
          gap: rem(10px);
          flex-direction: row;
          margin: rem(10px) 0;

          .ApplicantStatusCount {
            font-weight: 600;
            margin-right: rem(4px);
          }

          .ApplicantStatusHousehold {
            background-color: #e6f8fa;
            border-radius: rem(4px);
          }
        }
      }
    }
  }
}

.UserIconPadding {
  margin-right: 0.1rem;
}

.OverLapIcons {
  margin-left: -0.7rem;
}

.AvatarRow {
  padding-bottom: 0.1rem;
  justify-content: center;
  margin-top: rem(10px);
  margin-bottom: rem(5px);
}

.PropertyAddress {
  font-weight: 600;
  font-size: rem(28px);
  display: flex;
  align-items: center;
  text-align: start;
  gap: rem(5px);
  margin-top: rem(10px);
}

.PropertyAddress2 {
  text-align: start;
  color: var(--ir-neutral-black-primary);
  font-weight: 400;
  font-size: rem(20px);
  margin-left: rem(30px);
}

.PropertyAdditionalInfoContainer {
  margin-top: rem(50px);
  text-align: start;

  .PropertySpecsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: rem(1px) solid var(--ir-neutral-black-300);
    margin: rem(10px) 0;
  }

  .PropertySpecsEditRow {
    display: flex;
    padding: rem(5px) rem(26px);
    background: var(--ir-warm-gray-040);
    justify-content: flex-end;
    margin-bottom: rem(25px);

    .EditText {
      font-weight: 600;
      font-size: rem(16px);
      color: var(--ir-segment-cyan-900);
    }

    .ButtonsRow {
      display: flex;
      gap: rem(20px);

      .EditButton {
        background-color: var(--ir-gray-000);
      }

      .SaveButton {
        display: flex;
        padding: rem(1px) rem(8px);
        border: none;
        background: var(--ir-segment-cyan-900);
        color: var(--ir-gray-000);
      }
    }
  }

  .Title {
    font-weight: 600;
    font-size: 24px;
    width: 100%;
    padding-bottom: rem(8px);
  }
}

.CustomTextFieldWithLabel {
  & :global(.form-floating > label) {
    top: -4px;
    color: var(--ir-warm-gray-060);
    font-size: 14px;
    font-weight: 600;
    padding-left: 0.5rem;
    opacity: 1 !important;
  }

  & :global(.form-floating > input) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 1rem;
    border: rem(1px) solid var(--ir-gray-200);
    padding-left: rem(10px);

    &:disabled {
      border: none;
      background-color: transparent;
    }

    &::placeholder {
      color: var(--ir-neutral-black-10);
      font-size: 14px;
      font-weight: 400;
    }
  }

  [class*='-control'] {
    padding: rem(5px);
    border: rem(1px) solid var(--ir-gray-200);
  }

  [class*='-control'][aria-disabled='true'] {
    border: none !important;
  }

  [class*='-control'] [class*='-ValueContainer'] {
    min-height: unset;
    font-size: rem(14px);
    font-weight: 600;
    color: var(--ir-warm-gray-800);

    span {
      top: -4px;
      color: var(--ir-warm-gray-060);
      font-size: 12px;
      font-weight: 600;
    }
  }

  [class*='-control'] [class*='-singleValue'] {
    color: var(--ir-neutral-dark-black);
  }

  .PetsLabel {
    color: var(--ir-warm-gray-060) !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  .PetsRow {
    margin-left: rem(-2px);
    margin-top: rem(12px);

    .PetsCol {
      width: fit-content;
      padding-right: rem(0);
    }

    .PetsColLg {
      width: stretch;
    }

    input {
      width: rem(18px);
      height: rem(18px);
      opacity: 1;

      &:checked {
        background-color: var(--ir-segment-cyan-900);
      }
    }

    label {
      color: var(--ir-neutral-dark-black);
      font-weight: 600;
      font-size: rem(16px);
      opacity: 1 !important;
    }
  }
}

.AdditionalAmenetiesContainer {
  align-items: center;
  margin-top: rem(35px);
  padding-right: 0;

  .AdditionalAmeneties {
    width: fit-content;
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .AdditionalAmenetiesDivider {
    background-color: var(--ir-warm-gray-060);
    height: rem(1px);
    flex: 1;
  }
}

.EditMediaRow {
  display: flex;
  position: relative;
  width: stretch;
  margin: rem(20px);
  justify-content: space-between;
  z-index: 10;
  margin-bottom: rem(-45px);

  .MarketingButton {
    display: flex;
    float: left;
    width: fit-content;
    padding: rem(2px);
    border: 1px solid var(--ir-warm-gray-800);
    background: var(--ir-gray-000);
    font-weight: 700;
    font-size: rem(14px);
  }

  .DisabledStatus {
    padding: 0 rem(2px);
    border-radius: rem(4px);
    background: var(--ir-gray-220);
    color: var(--ir-neutral-dark-black-90);
  }

  .EnabledStatus {
    background: var(--ir--neutral-green-400);
  }

  .EditMediaButton {
    float: right;
    width: fit-content;
    padding: 0 rem(5px);
    border: 1px solid var(--ir-warm-gray-800);
    background: var(--ir-gray-000);
    font-weight: 700;
    font-size: rem(14px);
    text-transform: uppercase;
  }
}

.AmenetiesDiv {
  margin-bottom: rem(30px);

  .AmenitiesParagraph {
    color: var(--ir-warm-gray-800);
    font-size: rem(12px);
    font-style: normal;
    font-weight: 400;
    margin-top: rem(8px);
    margin-bottom: rem(10px);
  }

  .AmenitiesPadding {
    padding: rem(0);

    .AddIcon {
      margin-right: rem(5px);
    }

    h4 {
      font-weight: 600;
      text-transform: uppercase;
      font-style: normal;
    }
  }

  .CustomAmenitiesButton {
    display: flex;
    width: fit-content;
    padding: 0.2rem;
    color: var(--ir-neutral-black-primary);
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    justify-content: center;
    line-height: rem(20px);

    span {
      display: inline-block;
      padding-right: 0.4rem;
    }

    .AddCustomText {
      padding-top: rem(4px);
    }

    &:hover {
      background: var(--ir-gray-200);
      border-radius: rem(4px);
      color: var(--ir-neutral-black-100);
    }
  }

  [class^='AmenitiesFeature_tagName'] {
    color: var(--ir-neutral-dark-black);
    font-weight: 600;
    font-size: rem(16px);
  }
}

.FeaturedPropertyImage {
  max-height: rem(400px);
  border-radius: rem(10px);
}

.DefaultPropertyImage {
  border-radius: rem(10px);

  img {
    max-height: rem(400px);
    object-fit: cover;
    border-radius: inherit;
  }
}

.PropertyImageCol {
  max-height: rem(400px);
  overflow: scroll;
}

.PropertImage {
  object-fit: 'cover';
  max-height: rem(188px);
  border-radius: rem(10px);
}

.container {
  :global(.modal-footer) {
    display: flex;
    padding: 0.5rem 3rem;
    border: none;
    flex: 1 1;
    justify-content: flex-end;
  }

  .button {
    width: fit-content;
  }

  .backButton {
    margin-right: 2rem;
    width: fit-content;
  }

  :global(.modal-body) {
    padding: 0 3rem 3.5rem;
    background: var(--ir-segment-cyan-300);
  }

  .unitSpecs,
  .amenities {
    padding-top: rem(25px);

    [class$='-control'] {
      height: rem(55px);
    }
  }
}

.NoAmenities {
  text-align: center;
  color: var(--ir-warm-gray-060);
  font-weight: 400;
  font-style: italic;
  font-size: rem(14px);
  line-height: rem(20px);
}

.DescriptionContainer {
  padding: rem(10px) rem(15px);
  border: rem(1px) solid var(--ir-neutral-black-300);
  color: var(--ir-neutral-black-100);
  font-size: rem(14px);
  border-radius: rem(2px);
  min-height: rem(250px);
}

@media screen and (max-width: 765px) {
  .Container {
    margin-left: rem(10px);
    margin-right: rem(10px);
    padding-right: rem(0);
    border-right: none;
    overflow-x: hidden;
  }

  .LeftItem {
    width: 100%;
    justify-content: space-between;
  }

  .RightItem {
    width: 100%;
    justify-content: space-between;
    margin-right: rem(0) !important;
  }

  .ApplicantContainerInner {
    justify-content: center;

    .Col3 {
      flex: none;
      align-items: center;
    }

    .Col1 {
      display: none !important;
    }
  }
}

@media (max-width: 767px) {
  .AddIconClass {
    width: rem(24px);
    height: rem(24px);
  }
}
