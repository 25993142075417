@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  width: 100%;
  min-width: rem(640px);

  .MonthHeadings {
    display: flex;
    gap: rem(15px);
    padding: rem(5px) 0;
  }

  .Month {
    color: var(--ir-segment-cyan-1050);
    font-size: rem(16px);
    font-weight: 600;
    flex: 1;
  }

  .DashContainer {
    font-size: rem(12px);
    font-weight: 600;
  }

  .TradeFooter {
    display: flex;
    padding: rem(12px) 0;
    color: var(--ir-neutral-black-100);
    align-self: flex-start;
    font-size: rem(13px);
    justify-content: center;
    align-items: center;
    gap: rem(10px);
  }
}
