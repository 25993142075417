@import '/src/stylesheets/utils';

.formContainer {
  .pageTitleContent {
    text-align: left;
    color: var(--ir-neutral-black-100);
    margin-top: rem(18px);
  }

  .organizationName {
    font-size: rem(16px);
    font-weight: 600;
  }

  .selectedStepName {
    font-size: rem(32px);
  }

  .mainContentContainer {
    display: flex;
    justify-content: flex-start;
  }

  .leftContentContainer {
    flex: 1;
    padding: rem(100px) rem(20px) rem(100px) rem(20px);
    text-align: start;
    color: var(--ir-neutral-black-100);
    max-width: rem(380px);
  }

  .paragraphTitle {
    font-size: rem(18px);
    font-weight: 700;
    margin-bottom: rem(10px);
  }

  .paragraphContent {
    font-size: rem(16px);
    margin-bottom: rem(10px);
  }

  .paragraphButton {
    padding: unset;
    border: none;
    box-shadow: none;
    font-weight: 400;
    font-size: rem(16px);
  }

  .rightContentContainer {
    display: flex;
    text-align: start;
    flex: 1;
    margin-top: rem(50px);
    max-width: rem(580px);
    flex-direction: column;
  }

  .emailContainer {
    flex: 1;
  }

  .emailInnerContainer {
    display: flex;
    justify-content: space-between;

    [class='formik-field'] {
      flex: 2;
    }
  }

  .formTitle {
    color: var(--ir-black);
    font-size: rem(24px);
    font-weight: 600;
    margin-bottom: rem(37px);
  }

  .roleContainer {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
  }

  .roleTitle {
    color: var(--ir-neutral-black-primary);
    font-size: rem(16px);
    font-weight: 600;
  }

  .selectContainer {
    margin-bottom: rem(30px);

    .description {
      font-size: rem(12px);
      color: var(--ir-neutral-black-primary);
    }

    .label {
      font-size: rem(14px);
      color: var(--ir-warm-gray-800);
    }

    [class*='-control'] {
      background-color: unset;
      border: none;
      border-bottom: 1px solid var(--ir-neutral-000);
      border-radius: unset;
    }

    [class*='-control'] > :first-child {
      padding: unset;
    }
  }

  .managerInformation {
    position: relative;
    padding: rem(8px) rem(10px) rem(0) rem(42px);
    border: rem(1px) solid var(--ir-segment-blue-96);
    margin-top: rem(8px);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir-segment-blue-100);
    margin-bottom: rem(23px);

    .managerInformationIcon {
      position: absolute;
      top: rem(10px);
      left: rem(10px);
      width: rem(20px);
      height: rem(20px);
    }

    .managerInformationText {
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-neutral-black-primary);
    }
  }

  .permissionChecks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    [class='form-check-input'] {
      width: rem(16px);
      height: rem(16px);
    }

    [class='form-check-label'] {
      font-size: rem(10px);
      font-weight: 600;
      color: var(--ir-gray-1050);
    }

    .checkboxLabel {
      display: flex;
      color: var(--ir-neutral-black-primary);
      font-size: rem(12px);
      line-height: rem(14px);
      font-weight: 600;
      gap: rem(10px);
      align-items: flex-end;
    }
  }

  .dropDownFillArrow {
    width: rem(24px);
    height: rem(24px);
    text-align: center;
    background-color: var(--ir-neutral-white-bg);
    border-radius: 2px;
    margin-right: 1rem;
  }

  .emailField {
    padding: unset;
    border: none;
    background-color: unset;
    border-bottom: 1px solid var(--ir-neutral-000);
    border-radius: unset;
    margin-bottom: rem(30px);

    &:focus-visible {
      outline: unset;
    }

    &:focus {
      border: unset;
      border-bottom: 1px solid var(--ir-neutral-000);
    }
  }

  .inviteButtonContainer {
    padding: rem(20px) rem(0) rem(20px) rem(0);
    margin-left: rem(10px);
    text-align: end;
    flex: 1;

    .inviteButton {
      width: max-content;
      height: fit-content;
      padding: rem(10px) rem(30px) rem(10px) rem(30px);
    }
  }

  .shareLinkContainer {
    margin-top: rem(30px);
  }

  .orgTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: rem(5px);
    margin: rem(10px) 0;

    .text {
      color: var(--ir-neutral-black-primary);
      font-size: rem(16px);
      font-weight: 600;
      line-height: normal;
    }

    .lighterText {
      font-size: rem(14px);
      color: var(--ir-neutral-black-primary);
    }
  }

  .fieldsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    [class='formik-field'] {
      width: 85%;
    }
  }

  [class^='TextCopyButton_container'] {
    flex: 2;
  }
}

@media (max-width: 800px) {
  .mainContentContainer {
    display: flex;
    flex-direction: column;

    .leftContentContainer {
      padding-bottom: rem(20px);
    }

    .rightContentContainer {
      margin-bottom: rem(24px);
    }
  }

  .emailInnerContainer {
    flex-direction: column;
  }

  .fieldsDiv {
    display: block !important;
  }

  .inviteButtonContainer {
    padding: rem(20px) 0 0 0 !important;

    .inviteButton {
      width: max-content;
      height: fit-content;
      padding: rem(10px) rem(30px);
    }
  }

  .emailField {
    margin-bottom: rem(0) !important;
  }
}
