@import '/src/stylesheets/utils';

.presentAddressContainer {
  .errorDatePickerContainer {
    margin-bottom: rem(34px) !important ;

    [class*='_dateContainer'] {
      height: rem(45px);
    }
  }

  .rangeDatePickerContainer {
    margin-bottom: rem(24px);
  }

  .addressButtonConatiner {
    .presentAddressButton {
      width: fit-content;
    }
  }
}
