@import '/src/stylesheets/utils';

.RenterInfoDetailsModal {
  padding: 0 rem(24px);
  text-align: center;
  color: var(--ir-warm-gray-800);
  font-style: normal;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(24px);

  .title {
    font-weight: 700;
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .heading {
    padding: rem(24px) 0 rem(16px);

    span {
      color: var(--ir-segment-cyan-700);
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: rem(20px);
  }

  .borderbox {
    display: flex;
    padding: rem(8px);
    align-items: center;
    gap: rem(8px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
    margin-bottom: 1rem;

    .infoContainer {
      display: flex;
      text-align: start;
      flex: 4;
      flex-direction: column;
      gap: rem(8px);
      font-size: rem(10px);
      font-style: normal;
      font-weight: 600;
      line-height: rem(14px);

      .flexContainer {
        flex: 1;
      }

      .dateHeading {
        text-transform: capitalize;
        color: var(--ir-gray-1050);
      }

      .subHeading {
        color: var(--ir-neutral-000);
      }

      .information {
        display: flex;
      }
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: rem(40px);

    .renterPhotoInformationButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }
}

.contentClassName {
  margin-top: rem(100px);
  border: none;
  border-radius: rem(24px);
}
