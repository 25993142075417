@import '/src/stylesheets/utils';

.descriptionCard {
  display: flex;
  width: 100%;
  padding: rem(10px);
  border: rem(1px) solid var(--ir-gray-200);
  color: var(--ir-warm-gray-800);
  border-radius: rem(4px);
  align-items: flex-start;
  justify-content: space-between;

  .descriptionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .company {
      font-weight: 600;
      font-size: rem(16px);
      line-height: rem(24px);
    }

    .title {
      font-weight: 600;
      font-size: rem(10px);
      line-height: rem(14px);
    }
  }

  .editButton{
    padding: 0;
    border: none;
    background: transparent;
  }
}
