@import '/src/stylesheets/utils';


.MenuIcon {
  cursor: pointer;
}

.Item {
  font-size: rem(14px);
  cursor: pointer;
  padding: 2px 10px;

  &:hover{
    background-color: var(--ir-gray-100);
  }
}

.PopoverBody {
  padding: 0;
}

.AttachItem {
  font-size: rem(14px);
  font-weight: 600;
  padding: 4px 10px;

  &:hover{
    background-color: var(--ir-gray-100);
  }
}

