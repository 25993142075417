@import '/src/stylesheets/utils';

.Container {
  .TitleHeadings {
    display: flex;
    justify-content: flex-start;
    gap: rem(12px);
    padding: rem(3px) rem(8px);
    align-items: baseline;
    align-self: stretch;
    border-bottom: 1px solid var(--ir-black);
  }

  .Month,
  .Description,
  .Amount {
    text-align: start;
    color: var(--ir-warm-gray-800);
    font-weight: 600;
  }

  .Month {
    flex: 1;
  }

  .Description {
    flex: 3;
  }

  .Amount {
    flex: 3;
    text-align: end;
  }

  .TotalContainer {
    display: flex;
    justify-content: space-between;
    padding: rem(5px) rem(8px);
    gap: rem(12px);
    border-top: 1px solid var(--ir-warm-gray-030);
  }

  .TotalTitle,
  .TotalAmount {
    color: var(--ir-neutral-black-100);
    font-weight: 600;
  }
}
