@import '/src/stylesheets/utils';

.dropdownToggle {
  display: flex;
  color: var(--ir-neutral-dark-black);
  align-items: center;
  gap: 0.2rem;
  text-decoration: none;

  &:hover {
    color: var(--ir-segment-cyan-900);
  }

  &:global(.active) {
    color: var(--ir-segment-cyan-900);
  }
}

.toolTipMarginDiv {
  margin-top: 4.5rem;
}

.iconDiv {
  min-width: 2rem;
  text-align: center;
}

.popoverBtn {
  display: flex;
  font-size: rem(14px);
  padding: rem(5px) rem(14px);
  color: var(--ir-warm-gray-800);
  align-items: center;
  font-weight: 400;
  gap: rem(5px);
  margin-bottom: rem(5px);
  text-decoration: none;
  min-height: rem(38px);

  &:hover {
    background-color: var(--ir-warm-gray-040);
    color: rgb(0 0 0);
    font-weight: 700;
  }

  &.active {
    color: rgb(0 0 0);
    font-weight: 700;
  }
}

.bold {
  font-weight: 700;
}

.popoverContainer {
  &:global(.popover) {
    border: 0.5px solid var(--ir-warm-gray-045);
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  }

  [class*='popover-arrow'] {
    display: none;
  }
}

.popoverBody {
  border-radius: 3px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  display: flex;
  flex-direction: column;
  min-width: rem($popover-body-min-width);
  padding: rem(8px);
  overflow: auto;
  max-height: rem(300px);
}

.linkContainer,
.linkContainerActive {
  align-items: center;
  display: flex;
  font-size: rem(14px);
  gap: 0.2rem;
}

.linkContainerActive {
  color: var(--ir-segment-cyan-900);
  font-weight: 600;
}

.hrLine {
  margin: rem(10px) 0;

  &.hrLineWithNoTopMargin {
    margin-top: 0;
  }
}

.organiztionDiv {
  display: flex;
  text-align: left;
  flex-direction: row;

  .organizationIcon {
    width: rem(30px);
    height: rem(30px);
    filter: grayscale(100%);
  }

  .organiztionNameDiv {
    padding: 0;
    text-align: left;
    align-self: center;
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-neutral-001);
  }

  &:hover {
    .organiztionNameDiv {
      color: var(--ir-gray-1050);
    }

    .organizationIcon {
      filter: grayscale(0%);
    }
  }
}

.selectedOrganization {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: rem(5px);
}

.selectedOrgDiv {
  display: flex;
  gap: rem(5px);
  align-items: center;
}

.profileDropDownOptions {
  font-size: rem(16px);
  font-weight: 600;
  color: var(--ir-neutral-black-100);

  &.paddingAlighment {
    padding: rem(5px) rem(14px);
  }
}
