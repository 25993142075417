@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(20px);

  .EmploymentInformation {
    margin-bottom: rem(24px);
  }
}
