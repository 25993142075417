@import '/src/stylesheets/utils';

.container {
  color: var(--ir-gray-1050);

  .heading {
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(14px); /* 100% */
    padding-bottom: rem(4px);
  }

  .subHeading {
    font-size: rem(12px);
    padding-bottom: rem(12px);
  }

  .UserInputWrapper {
    display: flex;
    gap: rem(8px);
    width: 100%;
  }

  .inputContainer {
    width: 100%;
  }

  .HighlightedText {
    color: var(--ir-segment-cyan-700);
    font-size: rem(12px);
  }

  .inputWrapper {
    display: flex;
    flex-direction: column;

    .emailFieldContainer {
      margin-top: rem(4.5px);

      [class='form-control'] {
        height: rem(58px);
      }
    }

    .phoneNumberContainer {
      width: 100%;

      [class*='country-list'] {
        height: 10rem;
        padding: 0;
      }

      [class*='form-control'] {
        padding-top: rem(25px);
        width: 100%;
        height: rem(55px);
      }

      [class*='flag'] {
        margin-top: rem(-3px) !important;
      }

      [class*='open'],
      [class*='selected-flag'],
      [class*='react-tel-input'],
      [class*='selected-flag']:hover,
      [class*='flag-dropdown'] {
        border: none;
        background: transparent !important;
        background-color: transparent;
      }

      [class*='react-tel-input'] {
        width: 100%;
        border: 1px solid var(--ir-gray-200);
        background-color: var(--ir-warm-gray-100) !important;
        border-radius: rem(4px);
        font-size: rem(14px);
      }

      :global(.country) {
        padding: rem(10px) 0 0 rem(44px) !important;
      }

      [class*='form-control']:focus,
      [class*='form-control'],
      [class*='form-control']:hover {
        background: var(--ir-gray-000) !important;
        border-color: transparent;
        border-radius: 0 0 rem(4px) rem(4px);
        box-shadow: 0 0 0 1px transparent;
        font-style: normal;
        font-weight: 400;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--ir-neutral-black-100);
      }

      [class*='special-label'] {
        top: rem(6px);
        left: rem(6px);
        text-align: left;
        color: var(--ir-neutral-010);
        font-size: rem(10px);
        font-weight: 600;
        line-height: rem(14px);
        background-color: transparent;
      }

      [class*='flag'] [class*='arrow'] {
        border-top: rem(4px) solid var(--ir-neutral-000);
      }

      [class='dial-code'] {
        width: 2rem;
      }

      [class='country'],
      [class='country_highlight'],
      [class*='preferred'] {
        display: flex;
        flex-direction: row-reverse;
        gap: 1rem;
        text-align: initial;
        justify-content: flex-end;
      }
    }
  }
}

@media (max-width: 575px) {
  .container {
    .inputWrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
