@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(8px);

  .RenterInfoDetailsContainer {
    margin-bottom: unset;
  }

  .TitileContainer {
    width: 100%;
    text-align: center;
    color: var(--ir-gray-1050);
    font-size: rem(22px);
    font-style: normal;
    font-weight: 600;
  }

  .RenterButtonContainer {
    align-self: flex-end;

    .RenterButton {
      width: fit-content;
      padding: 0.4rem;
      border: 1px solid var(--ir-segment-cyan-700);
      text-align: start;
      color: var(--ir-segment-cyan-700);
      font-weight: 600;
      font-size: rem(16px);
    }
  }

  .InfoPanel {
    font-size: rem(10px);
    font-weight: 600;
    text-align: left;
    box-shadow: unset;

    &.Description {
      padding: rem(12px) 0;
      text-align: justify;
      color: var(--ir-neutral-black-primary);
    }
  }

  .SubContainer {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
  }

  .DateContainer {
    display: flex;
    padding: rem(4px) rem(0) rem(4px) rem(16px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-neutral-white-bg);
    border-radius: rem(4px);
  }

  .DetailsContainer {
    display: flex;
    padding: rem(8px);
    align-items: center;
    gap: rem(8px);
    border-right: 1px solid var(--greys-94, #e6eff0);
    border-bottom: 1px solid var(--greys-94, #e6eff0);
    border-left: 1px solid var(--greys-94, #e6eff0);
    background: var(--greys-98, #f7fafa);
  }

  .ProfileImage {
    width: auto;
    height: rem(24px);
    border-radius: rem(24px);
  }

  .StreetAddressContainer {
    font-size: rem(12px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px);

    &.LocalAddressContainer {
      color: var(--ir-warm-gray-060);
    }
  }

  .AddressContainer {
    display: $webkit-box;
    height: 20%;
    padding: rem(8px);
    align-items: center;
    border-right: 1px solid var(--ir-gray-200);
    border-bottom: 1px solid var(--ir-gray-200);
    border-left: 1px solid var(--ir-gray-200);
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  .HeaderContainer {
    display: flex;
    text-align: left;
    color: var(--ir-neutral-black-100);
    align-items: center;
    font-weight: 500;
    font-size: rem(18px);
    margin-bottom: rem(16px);
  }

  .ContactInformationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: rem(8px);
    align-items: flex-start;
    gap: rem(8px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
  }

  .ContactHeader {
    display: flex;
    align-items: flex-start;
    gap: rem(10px);
    align-self: stretch;
    justify-content: space-between;
  }

  .Heading {
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20px); /* 142.857% */
  }

  .ValueContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: rem(10px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(14px);
    text-align: start;
  }

  .Value {
    color: var(--ir-neutral-black-100);
  }

  .Label {
    color: var(--ir-warm-gray-060);
  }

  .RenterSSNContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: rem(8px);
    align-items: flex-start;
    gap: rem(8px);
    align-self: stretch;
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-200);
    background: var(--ir-gray-000);
  }

  .SSNHeading {
    color: var(--ir-warm-gray-060);
    font-size: rem(14px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(20px); /* 142.857% */
  }

  .SSNContainer {
    color: var(--ir-neutral-black-100);
    font-size: rem(12px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px); /* 133.333% */
  }

  .ContinueButton {
    display: flex;
    width: fit-content;
    padding: rem(4px) rem(8px);
    border: 1px solid var(--ir-segment-cyan-900);
    color: var(--ir-segment-cyan-700);
    align-items: flex-start;
    font-size: rem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(24px); /* 150% */
    align-self: flex-end;
    margin-top: rem(16px);
  }

  .EditIcon {
    min-width: rem(24px);
    min-height: rem(24px);
  }
}
