@import '/src/stylesheets/utils';

.Container {
  max-width: 100%;
  overflow-x: auto;
  border-bottom: 1px solid var(--color-horizantal-line);
  padding: unset;

  .NavList {
    display: flex;
    gap: rem(30px);
  }

  .NavItem {
    display: flex;
    gap: rem(2px);
    align-items: center;
    cursor: pointer;
  }

  .Title {
    font-weight: 600;
    color: var(--ir-neutral-dark-black-90);
    white-space: nowrap;
  }

  .SelectedTitle {
    color: var(--ir-segment-cyan-900);
    border-bottom: rem(1.5px) solid var(--ir-segment-cyan-700);
  }
}
