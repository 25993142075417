@import '/src/stylesheets/utils';

.noFilesDropzone {
  min-height: rem(150px) !important;

  .dropzoneText {
    top: 50% !important;
  }
}

.minimizedDropzone {
  :global(.dropzone) {
    position: relative;
    width: 100%;
    height: auto;
    margin: rem(10px) 0 !important;
    padding: rem(8px) rem(12px);
    border: 1px solid var(--ir-gray-200);
    background-color: var(--ir-neutral-white-bg);
    border-radius: rem(4px);
    max-height: 100%;
    overflow-y: auto;

    .dropzoneLabel {
      color: var(--ir-neutral-010);
      font-size: rem(10px);
    }

    .previewList {
      display: flex;
      flex-direction: column;

      .imageUploadList {
        display: flex;
        width: 100%;
      }

      .imagesListContainer {
        display: flex;
        width: 100%;
        margin: unset;
        padding: unset;
        list-style: none;
        flex-wrap: wrap;
      }
    }

    .fileContainer {
      position: relative;
      width: 100%;
      margin: 0;
      border: 1px solid var(--ir-warm-gray-100);

      [class*='ImageProgressBox_ImageProgressContainer'] {
        border: 1px solid var(--ir-gray-200);
        background: var(--ir-gray-000);
      }

      .featuredText {
        position: absolute;
        bottom: 1%;
        left: 2%;
        padding: rem(2px);
        color: var(--ir-warm-gray-800);
        opacity: 0.8;
        background-color: var(--ir--neutral-green-400);
        border-radius: rem(2px);
        font-size: rem(8px);
        font-weight: 600;
        z-index: 1;
      }
    }

    .greenBorder {
      border: 1px solid var(--ir--neutral-green-400);
      margin-right: 0.1rem;
    }

    .previewContainer {
      display: flex;
      position: relative;
      width: 100%;
      padding: rem(6px);

      .previewImage {
        width: rem(45px);
        height: rem(45px);
        border-radius: rem(4px);
      }

      .removeButton {
        position: absolute;
        top: rem(0);
        right: rem(0);
        cursor: pointer;
      }

      .loadingIcon {
        position: absolute;
        top: 18%;
        left: 26%;
        animation: rotation 2s infinite linear;
      }
    }

    .dropzoneText {
      display: flex;
      position: absolute;
      top: 65%;
      left: 50%;
      color: var(--ir-neutral-000);
      transform: translate(-50%, -50%);
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .selectButton {
    width: fit-content;
    padding: rem(4px) rem(8px);
    border-radius: rem(4px);
    border: 1px solid var(--ir-gray-92);
    background: var(--ir-gray-000);
    color: var(--ir-neutral-000);
    font-size: rem(12px);
    font-style: normal;
    font-weight: 600;
    line-height: rem(16px);
    box-shadow: none;
  }

  .noFileSelected {
    display: flex;
    height: rem(58px);
    margin: 0 rem(7px);
    border: 1px solid var(--ir-gray-200);
    justify-content: flex-start;
    align-items: center;
    background-color: var(--ir-warm-gray-100);
    border-radius: rem(4px);

    .text {
      margin-left: rem(20px);
      font-size: rem(12px);
      color: var(--ir-neutral-010);
      line-height: rem(20px);
    }
  }

  .title {
    text-align: start;
    color: var(--ir-neutral-000);
    font-size: rem(10px);
    font-weight: 600;
    line-height: rem(14px);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: rem(2px);
  }
}

.dropzoneBottomText {
  display: flex;
  color: var(--ir-neutral-black-primary);
  font-size: rem(10px);
  justify-content: center;
  margin-top: 0.2rem;
}

.dragDropField {
  display: flex;
  width: 100%;
  height: rem(50px);
  padding: rem(4px) rem(24px);
  background-color: var(--ir-warm-gray-100);
  border-radius: rem(4px);
  justify-content: center;
  margin-top: rem(10px);

  .dragDropText {
    color: var(--ir-neutral-000);
    margin-top: rem(10px);
  }

  .addIcon {
    margin-right: rem(5px);
    margin-top: rem(13px);
  }
}

@media (max-width: 991px) {
  .emptyDropzone {
    .dropzoneText {
      .dragText {
        width: rem(200px);
      }
    }
  }
}

@media (max-width: 360px) {
  .emptyDropzone {
    .dropzoneText {
      .dragText {
        width: rem(130px);
      }
    }
  }

  .dragDropField {
    padding-left: unset;
    padding-right: unset;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

.dropzone {
  padding: rem(20px);
  border: 2px dashed var(--ir-gray-80);
  text-align: center;
}

.dropzone-active {
  background-color: var(--ir-gray-000);
}

.imagesRow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.4rem !important;
}

.footer {
  text-align: center;
  margin-bottom: rem(12px);
}
