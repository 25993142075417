@import '/src/stylesheets/utils';

.ApplicationIndexContainer {
  margin-bottom: rem(23px);

  .MainHeading {
    margin: rem(5px);
    color: var(--ir-neutral-black-100);
    font-size: rem(20px);
    font-weight: 600;
    margin-top: rem(23px);
  }

  .FilterButtonsContainer {
    display: flex;
    margin: rem(10px) rem(0);
    padding: rem(5px) rem(0);
    gap: rem(10px);
    justify-content: center;
  }

  .ProgressButton,
  .SubmittedButton {
    padding: rem(4px) rem(38px);
    font-weight: 600;
    border: 1px solid var(--ir-warm-gray-0650);
    color: var(--ir-warm-gray-0650);
    background-color: var(--ir-neutral-white-bg);
  }

  .SelectedButton {
    background-color: var(--ir-gray-000);
    border: rem(1px) solid var(--ir-warm-gray-030);
    color: var(--ir-neutral-black-primary);
  }

  .InformationBox {
    display: flex;
    padding: rem(8px) rem(10px);
    border: rem(1px) solid var(--ir--neutral-sea-blue-100);
    gap: rem(10px);
    border-radius: rem(4px);
    background-color: var(--ir--neutral-sea-blue-150);
    margin-bottom: rem(18px);

    .InformationIcon {
      width: rem(24px);
      height: rem(24px);
    }

    .InformationText {
      text-align: left;
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-weight: 600;
      margin-bottom: unset;
    }
  }

  .ApplicationsContainer {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
  }

  .WarningBox {
    display: flex;
    padding: rem(8px) rem(10px);
    border-radius: rem(4px);
    border: rem(1px) solid var(--ir-semantics-warning-dark);
    background-color: var(--ir-segment-yellow-100);
    gap: rem(10px);
    margin-top: rem(20px);

    .WarningIcon {
      width: rem(20px);
      height: rem(20px);
    }

    .WarningText {
      color: var(--ir-neutral-black-primary);
      font-size: rem(10px);
      font-weight: 600;
      margin-top: rem(2px);
      margin-bottom: unset;
    }
  }

  .NavigationButton {
    padding: unset;
    border: unset;
    background: unset;
    color: var(--ir-segment-blue-900);
    box-shadow: unset;
    font-size: rem(10px);
    font-weight: 600;
    text-decoration: underline;

    &:focus-visible {
      outline: unset;
    }
  }

  ::-webkit-scrollbar {
    width: rem(6px);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--ir-warm-gray-400);
    border-radius: rem(100px);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--ir-warm-gray-400);
  }
}
