@import '/src/stylesheets/utils';

.Container {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  width: 100%;
  min-width: rem(640px);

  .HorizontalLegend {
    display: flex;
    gap: rem(30px);
  }

  .LegendIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: rem(5px);
  }

  .LegendText {
    color: var(--ir-neutral-black-100);
    font-size: rem(11px);
    font-style: normal;
    line-height: rem(15px); /* 136.364% */
    text-transform: uppercase;
  }

  .VerticalLegendIconContainer {
    display: flex;
    gap: rem(10px);
    align-items: center;
  }

  .DashIcon {
    width: rem(24px);
    height: rem(24px);
  }
}
