@import '/src/stylesheets/utils';

.Container {
  display: flex;
  padding: rem(22px) 0;
  flex-direction: column;
  align-items: flex-start;
  gap: rem(5px);
  align-self: stretch;

  .Title {
    color: var(--ir-warm-gray-800);
    font-size: rem(16px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .Statement {
    color: var(--ir-neutral-black-primary);
    font-weight: 500;
  }

  .Transactions{
    width: 100%;
  }
}
