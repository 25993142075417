@import '/src/stylesheets/utils';

.container {
  display: flex;
  color: var(--ir-gray-1050);
  font-weight: 600;

  .cardBody {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
    flex: 1;
  }

  .company {
    font-size: rem(16px);
    line-height: rem(24px);
  }

  .title {
    font-size: rem(10px);
    line-height: rem(14px);
  }

  .details {
    display: flex;
    flex-direction: row;
    gap: rem(9px);
    align-items: center;
  }

  .label {
    color: var(--ir-neutral-000);
    font-size: rem(10px);
  }

  .jobDescription {
    display: flex;
    flex-direction: column;
  }

  .documents {
    flex: 10;
  }

  .verificationSection {
    .verificationLabel {
      font-size: rem(14px);
      font-weight: 600;
      line-height: rem(20px);
      color: var(--ir-warm-gray-060);
    }

    .info {
      display: flex;
      flex-direction: column;
    }
  }
}
