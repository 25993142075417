@import '/src/stylesheets/utils';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BackIcon {
  margin-left: 0.5rem;
  text-align: start;
}

@media (max-width: 744px) {
  .BackIcon {
    margin: 2rem 2rem 0;
  }
}
