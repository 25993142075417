@import '/src/stylesheets/utils';

.Container {
  margin: rem(24px) 0;
  text-align: start;

  .Heading {
    padding: rem(3px) rem(5px);
    font-size: rem(16px);
    font-weight: 600;
    color: var(--ir-gray-02);
  }

  .ColoredHeading {
    color: var(--ir-segment-cyan-900);
  }
}
