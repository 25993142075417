@import '/src/stylesheets/utils';

.confirmAddressHistoryContainer {
  .addresBoxContainer {
    margin-top: rem(8px);
  }

  .addressHistoryButtonConatiner {
    .addressHistoryButton {
      width: fit-content;
    }
  }

  .historyAddressesContainer {
    margin-bottom: rem(24px);
  }

  .renterAddButton {
    width: fit-content;
    height: rem(24px);
    padding: rem(0) rem(5px);
    font-size: rem(12px);
  }
}
